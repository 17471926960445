import React from "react";

import { DateTime } from "luxon";

type Props = {
  month: DateTime;
  selection?: DateTime;
  min?: DateTime;
  max?: DateTime;
  select: (dt: CalendarRowItem) => void;
};

export type CalendarRowItem = {
  label: string;
  color: "black" | "gray" | "blue" | "lightgray";
  selected: DateTime;
  dt: DateTime;
};

const Grid: React.FC<Props> = (props) => {
  // Determine the month and year of the date selector
  const yearOfView = props.month.year;
  const monthOfView = props.month.month;

  // Determine the day of week of the first day of the currently open month
  const firstDayOfMonthWeekday = DateTime.fromObject({
    year: yearOfView,
    month: monthOfView,
    day: 1,
  }).weekday;
  const previousMonthDt = props.month.minus({ months: 1 });

  // Determine how many days are in the previous and current month
  const daysInPrevMonth = previousMonthDt.daysInMonth;

  // Determine starting day for the current view
  const startingDateForCalendar =
    firstDayOfMonthWeekday === 7
      ? DateTime.fromObject({
          year: yearOfView,
          month: monthOfView,
          day: 1,
          hour: props.selection ? props.selection.hour : undefined,
          minute: props.selection ? props.selection.minute : undefined,
        })
      : DateTime.fromObject({
          year: previousMonthDt.year,
          month: previousMonthDt.month,
          day: daysInPrevMonth - firstDayOfMonthWeekday + 1,
          hour: props.selection ? props.selection.hour : undefined,
          minute: props.selection ? props.selection.minute : undefined,
        });

  // If user has not selected a day, set selected day to current day
  const selection = props.selection;

  // Create 7 rows for the calendar, each with 7 selectable dates
  const rows: CalendarRowItem[][] = [];
  let renderedDate = startingDateForCalendar;
  let label, color, selected;
  for (let i = 1; i < 7; i++) {
    const current_row: CalendarRowItem[] = [];
    for (let j = 1; j < 8; j++) {
      label = renderedDate.day;
      // If renderedDate is not in the current month view, it should not be selectable
      color = renderedDate.month === monthOfView ? "black" : "gray";
      // If renderedDate is before the minimum date, it should not be selectable
      color = props.min ? (renderedDate.toISODate() < props.min.toISODate() ? "lightgray" : color) : color;
      // If renderedDate is after the maximum date, it should not be selectable
      color = props.max ? (renderedDate.toISODate() > props.max.toISODate() ? "lightgray" : color) : color;
      // Determine if renderedDate is the selected date
      if (selection && selection.toISODate() === renderedDate.toISODate()) {
        color = "blue";
        selected = true;
      }
      current_row.push({
        label: label,
        color: color,
        selected: selected,
        dt: renderedDate,
      });
      renderedDate = renderedDate.plus({ days: 1 });
    }
    rows.push(current_row);
  }

  return (
    <div className="grid-container">
      <div className="grid-row">
        <div className={"grid-day dow "}>
          <span>S</span>
        </div>
        <div className={"grid-day dow "}>
          <span>M</span>
        </div>
        <div className={"grid-day dow "}>
          <span>T</span>
        </div>
        <div className={"grid-day dow "}>
          <span>W</span>
        </div>
        <div className={"grid-day dow "}>
          <span>T</span>
        </div>
        <div className={"grid-day dow "}>
          <span>F</span>
        </div>
        <div className={"grid-day dow "}>
          <span>S</span>
        </div>
      </div>
      {rows.map((row, index) => {
        return (
          <div className="grid-row" key={index}>
            {row.map((day, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    props.select(day);
                  }}
                  className={" grid-day " + day.color}
                >
                  <span>{day.label}</span>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Grid;
