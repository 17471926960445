import { DateTime } from "luxon";
import React, { useState, useRef, useEffect, FC } from "react";

type Props = {
  selection: DateTime | null | undefined;
  select: (datetime: DateTime) => void;
};

const TimeSelect: FC<Props> = ({ selection, select }) => {
  const selectedHourRef = useRef<HTMLInputElement>(null);
  const selectedMinuteRef = useRef<HTMLInputElement>(null);

  const selectedHour = selection ? (selection.hour > 11 ? selection.hour - 12 : selection.hour) : null;

  const selectedMinute = selection ? selection.minute : null;

  const [ampm, setAmpm] = useState(selection && selection.hour > 11 ? "PM" : "AM");

  const hours: { label: string | number; selected: boolean }[] = [];
  for (let i = 0; i < 12; i++) {
    hours.push({
      label: i,
      selected: selectedHour === i,
    });
  }

  const minutes: { label: string | number; selected: boolean }[] = [];
  for (let i = 0; i < 60; i++) {
    minutes.push({
      label: i,
      selected: selectedMinute === i,
    });
  }

  const ampmList = [
    {
      label: "AM",
      selected: ampm === "AM",
    },
    { label: "PM", selected: ampm === "PM" },
  ];

  const onHourClick = (hour) => {
    // Convert hour from 12 hour system to 24 hour system
    if (ampm === "PM") {
      hour = hour + 12;
    }
    const new_dt = selection?.set({ hour: hour });
    if (!new_dt) return;

    select(new_dt);
  };

  const onMinuteClick = (minute) => {
    const new_dt = selection?.set({ minute: minute });
    if (!new_dt) return;

    select(new_dt);
  };

  const onAmPmClick = (item) => {
    const currentHour = selection ? selection.hour : null;
    if (!currentHour) return;

    if (item === "AM" && currentHour > 11) {
      const new_dt = selection?.set({ hour: currentHour - 12 });
      if (!new_dt) return;

      select(new_dt);
    }
    if (item === "PM" && currentHour < 12) {
      const new_dt = selection?.set({ hour: currentHour + 12 });
      if (!new_dt) return;

      select(new_dt);
    }
    setAmpm(item);
  };

  const scrollIntoView = () => {
    if (selectedHourRef.current) {
      selectedHourRef.current.scrollIntoView();
    }
    if (selectedMinuteRef.current) {
      selectedMinuteRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    scrollIntoView();
  }, []);

  return (
    <div className="time-select-container">
      <div className="scroll-container">
        <div className="scroller-heading">
          <span>Hour</span>
        </div>
        <div className="hour scroller">
          {hours.map((hour, index) => {
            return (
              <div
                key={index}
                onClick={() => onHourClick(hour.label)}
                className={"scroller-option " + hour.selected}
                ref={hour.selected ? selectedHourRef : null}
              >
                <span>{hour.label}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="scroll-container">
        <div className="scroller-heading">
          <span>Minute</span>
        </div>
        <div className="minute scroller">
          {minutes.map((minute, index) => {
            return (
              <div
                key={index}
                onClick={() => onMinuteClick(minute.label)}
                className={"scroller-option " + minute.selected}
                ref={minute.selected ? selectedMinuteRef : null}
              >
                <span>{minute.label}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="scroll-container">
        <div className="scroller-heading">
          <span>AM/PM</span>
        </div>
        <div className="minute scroller">
          {ampmList.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => onAmPmClick(item.label)}
                className={"scroller-option " + item.selected}
              >
                <span>{item.label}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TimeSelect;
