import { TranslationMap } from "./i18n";

const translation: TranslationMap = {
  "Good morning": {
    en: "Good morning",
    es: "Buenos días",
  },
  "Good afternoon": {
    en: "Good afternoon",
    es: "Buenas tardes",
  },
  "Good evening": {
    en: "Good evening",
    es: "Buenas noches",
  },
  "Welcome to your Miter portal": {
    en: "Welcome to your Miter portal",
    es: "Bienvenido a tu portal Miter",
  },
  "Important links": {
    en: "Important links",
    es: "Enlaces importantes",
  },
  "Finish your onboarding": {
    en: "Finish your onboarding",
    es: "Termina tu integración",
  },
  "Check out your most recent paystub": {
    en: "Check out your most recent paystub",
    es: "Revisa tu recibo de pago más reciente",
  },
  "Edit your personal information": {
    en: "Edit your personal information",
    es: "Edita tu información personal",
  },
  Close: {
    en: "Close",
    es: "Cerrar",
  },
};

export default translation;
