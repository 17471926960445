import React, { FC } from "react";
import {
  FormAnswer,
  FormField,
  buildFieldSpecificPropsForFormField,
  buildValidationRuleSetForFormField,
  getCustomFieldTypeForFormBlock,
  renderCustomDefaultValue,
  renderCustomFieldDefaultString,
} from "miter-utils";
import { Formblock, Label } from "ui";
import { DateTime } from "luxon";
import { useFormContext } from "react-hook-form";
import { ESignatureItem } from "dashboard/miter";

/** Requires being wrapped in a FormProvider */
export const CollectFormFields: FC<{
  formFields: FormField[];
  formAnswers: FormAnswer[];
  readonly?: boolean;
  disabled?: boolean;
}> = ({ formFields, readonly, formAnswers, disabled }) => {
  const form = useFormContext();

  return (
    <div>
      {formFields.map((field: FormField) => {
        const cfv = formAnswers.find((a) => a.form_field_id === field._id);
        const type = getCustomFieldTypeForFormBlock(field);
        const defaultString = renderCustomFieldDefaultString(field, cfv);
        const defaultValue = renderCustomDefaultValue(field, cfv?.value);

        const editable = !readonly;

        const validations = buildValidationRuleSetForFormField(field);
        const fieldSpecificProps = buildFieldSpecificPropsForFormField(field);

        if (!editable && field.type === "esignature") {
          const esignature = defaultValue as ESignatureItem;
          return (
            <div style={{ marginBottom: 30 }}>
              <Label
                label={(field?.name || "Untitled Field") + (field?.validations?.required ? "*" : "")}
                style={{
                  marginBottom: 10,
                  width: "100%",
                }}
                sublabel={field?.description}
              ></Label>

              {!!esignature?.signature?.image
                ? `Signed on ${DateTime.fromSeconds(esignature?.created_at).toFormat("FF")}`
                : "No signature provided."}
            </div>
          );
        }

        return (
          <Formblock
            key={field._id}
            label={(field?.name || "Untitled Field") + (field?.validations?.required ? "*" : "")}
            sublabel={field?.description}
            name={field._id}
            form={form}
            /* eslint-disable @typescript-eslint/no-explicit-any */
            defaultValue={defaultValue as any}
            defaultString={defaultString}
            editing={editable || (field.type === "select" && field.multiple)}
            disabled={disabled != null ? disabled : !editable}
            className="modal"
            placeholder={field?.placeholder}
            rules={validations}
            val={validations}
            onChange={(_e) => {
              form.trigger();
            }}
            dateOnly={true}
            variant="dropzone"
            hideUploadIcon={true}
            compact={true}
            maxFilesAllowed={field.max_files_allowed}
            // Add select options if select field
            {...fieldSpecificProps}
            type={type as $TSFixMe}
          />
        );
      })}
    </div>
  );
};
