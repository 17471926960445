import { Form, FormSubmission, FrontendModel, MiterAPI } from "dashboard/miter";
import { FormField as FormField_, FormSection as FormSection_ } from "../../../backend/models/form";
import type { CreateFormSubmissionParams, UpdateFormSubmissionParams } from "dashboard/miter";
import { formatFilesForUpload, saveESignatures } from "miter-utils";
import { FilePickerFile } from "packages/ui/form/FilePicker";

type FormField = FrontendModel<FormField_>;
type FormSection = FrontendModel<FormSection_>;

export type GroupedFormComponentsObject = {
  [section_id: string]: {
    section: FormSection | undefined;
    fields: FormField[];
  };
};

// The input dictates whether the viewer has access to fill out the form field
type FormFieldWithAccessibility = FormField & {
  accessible?: boolean;
};

export type GroupedFormComponentsArray = {
  section?: FormSection;
  fields: FormFieldWithAccessibility[];
}[];

export const buildGroupedFormComponentsObject = (formItem: Form): GroupedFormComponentsObject => {
  return formItem.components.reduce(
    (acc, component) => {
      if (component.type === "section") {
        acc[component._id] = {
          section: component,
          fields: [],
        };
      } else {
        if (component.form_section_id) {
          acc[component.form_section_id]?.fields.push(component);
        } else {
          acc["no-section"]!.fields.push(component);
        }
      }
      return acc;
    },
    { "no-section": { section: undefined, fields: [] } } as GroupedFormComponentsObject
  );
};

const savePhotoAndFileAnswers = async (
  argParams: SaveExternalFormDocuments
): Promise<FormSubmission["answers"]> => {
  const { params, formItem, account } = argParams;

  const { company_id } = account;
  const answersToReturn = [...(params.answers || [])];
  await Promise.all(
    (params.answers || []).map(async (answer) => {
      const field = formItem.components.find((f) => f._id === answer.form_field_id);
      if (!field || (field.type !== "file" && field.type !== "photo") || !answer.value) return;
      const uploadableValue = answer.value as FilePickerFile[];
      const filesToUpload = formatFilesForUpload(uploadableValue || [], company_id);

      // upload function only returns filest that were actually uploaded
      const filesUploadedWithBlob = await MiterAPI.files.upload({ files: filesToUpload });
      if ("error" in filesUploadedWithBlob) {
        throw new Error(filesUploadedWithBlob.error);
      }

      // original files from last submissions
      const originalFiles = uploadableValue.filter((f) => f.data?._id && !f?.deleted).map((f) => f.data!._id);

      const updateIndex = answersToReturn.findIndex((a) => a._id === answer._id);
      if (updateIndex !== -1) {
        answersToReturn[updateIndex] = {
          ...answersToReturn[updateIndex],
          _id: answersToReturn[updateIndex]!._id,
          form_field_id: answersToReturn[updateIndex]!.form_field_id,
          value: filesUploadedWithBlob.map((r) => r.file._id).concat(originalFiles),
        };
      }
    })
  );

  return answersToReturn;
};

export type SaveExternalFormDocuments = {
  formSubmissionId: string;
  params: CreateFormSubmissionParams | UpdateFormSubmissionParams;
  formItem: Form;
  account: {
    company_id: string;
    full_name: string;
    roleId?: string;
    userId: string;
    accountType: "admin" | "team_member";
    title: string;
    team_member_id?: string;
  };
  deviceType: "desktop" | "team-portal";
};

export const saveFormsAndUpdateAnswers = async (params: SaveExternalFormDocuments): Promise<void> => {
  const signatureUpdatedAnswers = await saveESignatures(params);

  const updatedFileAnswers = await savePhotoAndFileAnswers({
    ...params,
    params: { ...params.params, answers: signatureUpdatedAnswers },
  });

  const trueParams = {
    ...params.params,
    answers: [...updatedFileAnswers],
  };

  await MiterAPI.form_submissions.update(params.formSubmissionId, trueParams);
};

export const canCompleteField = (field: FormFieldWithAccessibility): boolean =>
  !(field.accessible != null && field.accessible === false);
