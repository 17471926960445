import * as React from "react";

export type WizardContextProps = {
  /** Current index of the wizard */
  curIndex: number;
  /** Sets the current index of the wizard */
  setCurIndex: React.Dispatch<React.SetStateAction<number>>;
  /** Handler for when a user wants to go to the next screen */
  handleNext: () => void;
  /** Handler for when a user wants to go to the previous screen */
  handleBack: () => void;
  /** Handler for when a user wants to exit the wizard early and save */
  handleEarlyExit: () => void;
  /** Handler for when a user wants to go to a specific screen */
  handleGoTo: (screen: number) => void;
  /** Handler for when a user wants to exit the wizard */
  handleExit: () => void;
  /** Handler for when a user wants to complete the wizard */
  handleComplete: () => void;
  /** Loading state for the next button */
  loadingNext: boolean;
  /** Sets the loading state for the next button */
  setLoadingNext: React.Dispatch<React.SetStateAction<boolean>>;
  /** Loading state for the back button */
  loadingBack: boolean;
  /** Loading state for the early exit button */
  loadingEarlyExit: boolean;
  /** Binds a custom handler to the next button */
  setNext: (next: () => Promise<void> | void) => void;
  /** Binds a custom handler to the back button */
  setBack: (back: () => Promise<void> | void) => void;
  /** Binds a custom handler to the early exit button */
  setEarlyExit: (earlyExit: () => Promise<void> | void) => void;
  /** Binds a custom handler to the exit button */
  setExit: (exit: () => Promise<void> | void) => void;
  /** Binds a custom handler to the complete button */
  setComplete: (complete: () => Promise<void> | void) => void;
  /** Whether the user can go to the next screen */
  canNext: boolean;
  /** Whether the user can go to the previous screen */
  canBack: boolean;
  /** Whether the user can exit the wizard early */
  canEarlyExit: boolean;
  /** Set whether the user can go to the next screen */
  setCanNext: React.Dispatch<React.SetStateAction<boolean>>;
  /** Set whether the user can go to the previous screen */
  setCanBack: React.Dispatch<React.SetStateAction<boolean>>;
  /** Set whether the user can exit the wizard early */
  setCanEarlyExit: React.Dispatch<React.SetStateAction<boolean>>;
  /** Custom text for the back button */
  backButtonText: string | React.ReactElement;
  /** Sets the custom text for the back button */
  setBackButtonText: React.Dispatch<React.SetStateAction<string | React.ReactElement>>;
  /** Custom text for the next button */
  nextButtonText: string | React.ReactElement;
  /** Sets the custom text for the next button */
  setNextButtonText: React.Dispatch<React.SetStateAction<string | React.ReactElement>>;
  /** Custom text for the early exit button */
  earlyExitButtonText: string | React.ReactElement;
  /** Sets the custom text for the early exit button */
  setEarlyExitButtonText: React.Dispatch<React.SetStateAction<string | React.ReactElement>>;
  /** List of screens in the wizard */
  screens: string[];
  /** See the list of screens in the wizard */
  showSidebar: boolean | undefined;
  /** Sets whether to show the sidebar */
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const WizardContext = React.createContext<WizardContextProps>({
  curIndex: 0,
  setCurIndex: () => {},
  handleNext: () => {},
  handleBack: () => {},
  handleEarlyExit: () => {},
  handleGoTo: () => {},
  handleExit: () => {},
  handleComplete: () => {},

  loadingNext: false,
  setLoadingNext: () => {},
  loadingBack: false,
  loadingEarlyExit: false,

  setNext: () => {},
  setBack: () => {},
  setEarlyExit: () => {},
  setExit: () => {},
  setComplete: () => {},

  canNext: false,
  canBack: false,
  canEarlyExit: false,
  setCanNext: () => {},
  setCanBack: () => {},
  setCanEarlyExit: () => {},

  backButtonText: "Back",
  setBackButtonText: () => {},

  nextButtonText: "Next",
  setNextButtonText: () => {},

  earlyExitButtonText: "Save and exit",
  setEarlyExitButtonText: () => {},
  screens: [],

  showSidebar: undefined,
  setShowSidebar: () => {},
});

export default WizardContext;
