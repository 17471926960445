import React, { ReactNode, useEffect } from "react";
import { ClickAwayListener } from "@material-ui/core";
import xPng from "dashboard/assets/x.png";
import "./modal.css";
import { ActionMenuRelative, Button } from "ui";

type Props = {
  headerText: React.ReactNode;
  subheaderText?: React.ReactNode;
  bodyText?: React.ReactNode;
  button1Text?: React.ReactNode;
  button1Loading?: boolean;
  button2Text: React.ReactNode;
  button1Action?: React.MouseEventHandler<Document | HTMLButtonElement>;
  button2Action: React.MouseEventHandler;
  button2Loading?: boolean;
  loading?: boolean;
  button2Disabled?: boolean;
  headerStyle?: React.CSSProperties;
  subheaderStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  yellowBodyText?: boolean;
  className?: string;
  wrapperClassName?: string;
  button2ClassName?: string;
  showCloseX?: boolean;
  onHide?: () => void;
  bloackClickaway?: boolean;
  actions?: [];
  children?: ReactNode;
};

const BasicModal: React.FC<Props> = ({
  headerText,
  subheaderText,
  bodyText,
  children,
  button1Text,
  button2Text,
  wrapperStyle,
  button1Action,
  button1Loading,
  button2Action,
  button2Disabled,
  button2Loading,
  loading,
  actions,
  yellowBodyText,
  className,
  wrapperClassName,
  subheaderStyle,
  button2ClassName,
  headerStyle,
  showCloseX,
  onHide,
  bloackClickaway,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleClickAway = (e) => {
    if (bloackClickaway) return;
    else if (onHide) {
      onHide();
    } else if (button1Action) {
      button1Action(e);
    }
  };

  return (
    <div className={"modal-background " + (className ? className : "")}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={"modal-wrapper " + (wrapperClassName ? wrapperClassName : "")} style={wrapperStyle}>
          {showCloseX && <img className={"exit-icon basic-modal-exit-icon"} src={xPng} onClick={onHide} />}
          <div className="flex">
            <h3 style={{ marginTop: 0, marginBottom: 0, ...headerStyle }}>{headerText}</h3>
            <div className="flex-1"></div>
            {actions ? <ActionMenuRelative links={actions} /> : null}
          </div>
          {subheaderText ? (
            <p style={subheaderStyle} className="basic-modal-subheader">
              {subheaderText}
            </p>
          ) : (
            <></>
          )}

          <div className="modal-body">
            {children ? (
              children
            ) : (
              <div className={`${yellowBodyText ? "yellow-text-container" : ""}`}>
                <span>{bodyText}</span>
              </div>
            )}
          </div>
          <div className="modal-footer">
            {button1Text && button1Action && (
              <Button
                className="button-1"
                onClick={button1Action}
                loading={button1Loading}
                text={button1Text}
              />
            )}
            <Button
              loading={loading || button2Loading}
              className={"button-2 no-margin " + button2ClassName}
              text={button2Text}
              disabled={button2Disabled}
              onClick={button2Action}
            />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default BasicModal;
