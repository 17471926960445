import { ColumnConfig, TData } from "ui/table-v2/Table";

import { CustomField, CustomFieldValue } from "dashboard/miter";
import { EditableCallbackParams, ValueFormatterParams } from "ag-grid-community";

const CUSTOM_FIELD_MAGIC_STRING = "triceratops";

/** Prefix for custom field columns */
export const TABLE_COLUMN_CUSTOM_FIELD_PREFIX = `custom_field_${CUSTOM_FIELD_MAGIC_STRING}_`;

const typeMap = {
  text: "string",
  number: "number",
  select: "string",
  date: "date",
  checkbox: "boolean",
};

type EditorType = Extract<ColumnConfig<TData>["editorType"], string>;

const getCellEditorType = (cf: CustomField): EditorType => {
  switch (cf.type) {
    case "select":
      return cf.multiple ? "multiselect" : "select";
    case "text":
      return "text";
    case "number":
      return "number";
    case "date":
      return "date";
    case "checkbox":
      return "checkbox";
    case "paragraph":
      return "paragraph";
    case "quantity":
      return "text";
    default:
      throw new Error(`Unsupported custom field type: ${cf.type}`); // We do not support photo, file, and esignature custom fields
  }
};

const getCellEditorParams = (
  cf: CustomField
): { options: { label: string; value: string }[]; isClearable: boolean } | undefined => {
  switch (cf.type) {
    case "select":
      return {
        options:
          cf.options_list?.map((option) => ({
            label: option.value,
            value: option.value,
          })) ?? [],
        isClearable: true,
      };
    default:
      return undefined;
  }
};

/**
 * Get the options map for a multiselect custom field
 * @param cf - The custom field
 * @returns The options map
 */
const getCellEditorParamsMap = (cf: CustomField): Record<string, string> => {
  const options = getCellEditorParams(cf)?.options || [];
  return options.reduce((acc, option) => {
    acc[option.value] = option.label;
    return acc;
  }, {} as Record<string, string>);
};

const stringCustomFieldTypes = ["text", "number", "select", "date", "paragraph", "quantity"];

type newValueType = string | number | boolean | string[] | { value: string; label: string }[] | null;

/** The value of a custom field cannot be null, so we need to convert null to an empty string when editing */
const getValueEditor = (
  cf: CustomField
): ((newValue: newValueType) => CustomFieldValue["value"]) | undefined => {
  if (getCellEditorType(cf) === "multiselect") {
    return (newValue) => {
      if (!Array.isArray(newValue)) return [];
      return newValue.map((option) => (option as { value: string; label: string }).value);
    };
  }
  if (stringCustomFieldTypes.includes(cf.type)) {
    return (newValue) => {
      return newValue == null ? "" : (newValue as CustomFieldValue["value"]);
    };
  }

  return undefined;
};

/**
 * Get the value formatter for a multiselect custom field
 * @param cf - The custom field
 * @returns The value formatter function
 */
const getValueFormatter = (cf: CustomField) => {
  if (getCellEditorType(cf) === "multiselect") {
    const optionsMap = getCellEditorParamsMap(cf);
    return (params: ValueFormatterParams<TData>): string => {
      return (
        params.data?.[buildCustomFieldColumnKey(cf)]?.map((value: string) => optionsMap[value]).join(", ") ||
        "-"
      );
    };
  }
  return undefined;
};

/** Build the custom field column key */
export const buildCustomFieldColumnKey = (cf: CustomField): string =>
  `${TABLE_COLUMN_CUSTOM_FIELD_PREFIX}${cf._id}`;

/** Get the custom field id from the column key */
export const getCustomIdFromColumnKey = (key: string): string | undefined => {
  return key.split(TABLE_COLUMN_CUSTOM_FIELD_PREFIX)[1];
};

/**
 * Clean the custom field value params from the table data
 * @param params - The params to clean
 * @returns The cleaned params
 */
export const parseCustomFieldValueParams = <T extends Record<string, unknown>>(
  params: T
): {
  custom_field_id: string;
  value: string | number | string[] | boolean;
}[] => {
  return Object.entries(params)
    .filter(([key]) => key.startsWith(TABLE_COLUMN_CUSTOM_FIELD_PREFIX))
    .map(([key, value]) => ({
      custom_field_id: getCustomIdFromColumnKey(key)!,
      value: value as string | number | string[] | boolean,
    }));
};

/**
 * Generates columns for a table from custom fields
 * @param customFields - The custom fields to generate columns from
 * @param parentType - The parent type of the custom fields
 * @param editable - Whether the columns should be editable
 * @returns The columns
 */
export const generateCustomFieldColumns = <T extends TData>(
  customFields: CustomField[],
  parentType: string,
  editable: boolean | ((params: EditableCallbackParams<T>) => boolean) = false
): ColumnConfig<T>[] => {
  const filteredFields = customFields.filter((cf) => cf.parent_type === parentType);

  return filteredFields.map(
    (cf) =>
      ({
        headerName: cf.name,
        field: buildCustomFieldColumnKey(cf),
        dataType: typeMap[cf.type],
        sortable: true,
        ...(editable && {
          editable,
          editorType: getCellEditorType(cf),
          cellEditorParams: getCellEditorParams(cf),
          valueEditor: getValueEditor(cf),
          valueFormatter: getValueFormatter(cf),
        }),
      } as ColumnConfig<T>)
  );
};
