import { TranslationMap } from "./i18n";

const translation: TranslationMap = {
  "We are unable to get your paystub at this time. Please contact support.": {
    en: "We are unable to get your paystub at this time. Please contact support.",
    es: "No podemos obtener su talón de pago en este momento. ",
  },
  "Your account does not have an email listed. Please provide one on your profile page.": {
    en: "Your account does not have an email listed. Please provide one on your profile page.",
    es: "Su cuenta no tiene un correo electrónico. Por favor proporcione uno en su página de perfil.",
  },
  "There was an error emailing the paystub. We're looking into it!": {
    en: "There was an error emailing the paystub. We're looking into it!",
    es: "Hubo un error al enviar por correo electrónico el talón de pago. ¡Lo estamos investigando!",
  },
  "Paystub emailed successfully.": {
    en: "Paystub emailed successfully.",
    es: "Talón de pago enviado por correo electrónico con éxito.",
  },
  "PAY PERIOD": {
    en: "PAY PERIOD",
    es: "PERÍODO DE PAGO",
  },
  PAYDAY: {
    en: "PAYDAY",
    es: "DÍA DE PAGO",
  },
  "NET PAY": {
    en: "NET PAY",
    es: "PAGO NETO",
  },
  STATUS: {
    en: "STATUS",
    es: "ESTADO",
  },
  ACTIONS: {
    en: "ACTIONS",
    es: "ACCIONES",
  },
  "There was an error fetching paystubs. We're looking into it!": {
    en: "There was an error fetching paystubs. We're looking into it!",
    es: "Hubo un error al obtener los talones de pago. ¡Lo estamos investigando!",
  },
  "You don't have any paystubs yet.": {
    en: "You don't have any paystubs yet.",
    es: "Aún no tienes recibos de pago..",
  },
  "Check back later!": {
    en: "Check back later!",
    es: "¡Vuelve más tarde!",
  },
};

export default translation;
