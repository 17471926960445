import { AggregatedForm } from "dashboard/miter";

export type AggregatedFormRow = AggregatedForm & {
  complete: boolean;
};

export const formatFormRow = (forms: AggregatedForm[]): AggregatedFormRow[] => {
  return forms.map((form) => {
    return {
      ...form,
      complete: form.submissions.every((submission) => submission.status === "completed"),
    };
  });
};
