import { TranslationMap } from "./i18n";

const translation: TranslationMap = {
  "Select a payment method": {
    en: "Select a payment method",
    es: "Seleccione un método de pago",
  },
  "Thanks for finishing onboarding! 🎉": {
    en: "Thanks for finishing onboarding! 🎉",
    es: "¡Gracias por completar la incorporación! 🎉",
  },
  "Complete payment method task first": {
    en: "Complete payment method task first",
    es: "Complete primero la tarea del método de pago",
  },
  "Completed onboarding task.": {
    en: "Completed onboarding task.",
    es: "Se completó tarea de incorporación.",
  },
  "Completed onboarding tasks.": {
    en: "Completed onboarding tasks.",
    es: "Se completaron tareas de incorporación.",
  },
  "Your screening task needs to be completed via Checkr.": {
    en: "Your screening task needs to be completed via Checkr.",
    es: "Su tarea de evaluación debe completarse a través de Checkr.",
  },
  "Your screening tasks need to be completed via Checkr.": {
    en: "Your screening tasks need to be completed via Checkr.",
    es: "Sus tareas de evaluación deben completarse a través de Checkr.",
  },
  "Checklist completed": {
    en: "Checklist completed",
    es: "Lista de verificación completada",
  },
  "Complete checklist": {
    en: "Complete checklist",
    es: "Completar lista de verificación",
  },
  Start: {
    en: "Start",
    es: "Comenzar",
  },
  "Personal information and EEO data": {
    en: "Personal information and EEO data",
    es: "Información personal y datos EEO",
  },
  "Payroll and banking": {
    en: "Payroll and banking",
    es: "Nómina y banca",
  },
  "Work authorization: Form I-9 employee portion": {
    en: "Work authorization: Form I-9 employee portion",
    es: "Autorización de trabajo: Parte del empleado de Form I-9",
  },
  Documents: {
    en: "Documents",
    es: "Documentos",
  },
  Forms: {
    en: "Forms",
    es: "Formularios",
  },
  Certifications: {
    en: "Certifications",
    es: "Certificaciones",
  },
  "Custom fields": {
    en: "Custom fields",
    es: "Campos personalizados",
  },
  "Custom tasks": {
    en: "Custom tasks",
    es: "Tareas personalizadas",
  },
  Screenings: {
    en: "Screenings",
    es: "Evaluaciones",
  },
  "Completed tasks": {
    en: "Completed tasks",
    es: "Tareas completadas",
  },
  "Complete personal information": {
    en: "Complete personal information",
    es: "Completar la información personal",
  },
  "Complete EEO information": {
    en: "Complete EEO information",
    es: "Completar la información EEO",
  },
  "Add payment method": {
    en: "Add payment method",
    es: "Agregar método de pago",
  },
  "Add bank accounts": {
    en: "Add bank accounts",
    es: "Agregar cuentas bancarias",
  },
  "Submit withholdings": {
    en: "Submit withholdings",
    es: "Enviar retenciones",
  },
  "Complete I-9: Employee portion": {
    en: "Complete I-9: Employee portion",
    es: "Completar I-9: Parte del empleado",
  },
  "Complete I-9: Employer portion": {
    en: "Complete I-9: Employer portion",
    es: "Completar I-9: Parte del empleador",
  },
  "Fill document": {
    en: "Fill document",
    es: "Rellenar documento",
  },
  "Sign document": {
    en: "Sign document",
    es: "Firmar documento",
  },
  "Complete form": {
    en: "Complete form",
    es: "Completar formulario",
  },
  "Upload certification": {
    en: "Upload certification",
    es: "Subir certificación",
  },
  "Complete screening": {
    en: "Complete screening",
    es: "Completar evaluación",
  },
  "Complete custom field": {
    en: "Complete custom field",
    es: "Completar campo personalizado",
  },
  "Complete custom task": {
    en: "Complete custom task",
    es: "Completar tarea personalizada",
  },
  Sign: {
    en: "Sign",
    es: "Firmar",
  },
  Fill: {
    en: "Fill",
    es: "Rellenar",
  },
  "Complete form:": {
    en: "Complete form:",
    es: "Completar formulario:",
  },
  Upload: {
    en: "Upload",
    es: "Subir",
  },
  "Complete custom field:": {
    en: "Complete custom field:",
    es: "Completar campo personalizado:",
  },
  "Complete custom task:": {
    en: "Complete custom task:",
    es: "Completar tarea personalizada:",
  },
  Checklist: {
    en: "Checklist",
    es: "Lista de verificación",
  },
  "Save and exit": {
    en: "Save and exit",
    es: "Guardar y salir",
  },
  "Save and continue": {
    en: "Save and continue",
    es: "Guardar y continuar",
  },
  "Form is not valid": {
    en: "Form is not valid",
    es: "El formulario no es válido",
  },
  "Personal information": {
    en: "Personal information",
    es: "Información personal",
  },
  "EEO information": {
    en: "EEO information",
    es: "Información EEO",
  },
  "Payment method": {
    en: "Payment method",
    es: "Método de pago",
  },
  "Bank accounts": {
    en: "Bank accounts",
    es: "Cuentas bancarias",
  },
  Withholdings: {
    en: "Withholdings",
    es: "Retenciones",
  },
  "Complete I-9": {
    en: "Complete I-9",
    es: "Completar I-9",
  },
  Certification: {
    en: "Certification",
    es: "Certificación",
  },
  "Please provide your personal information to complete the onboarding process": {
    en: "Please provide your personal information to complete the onboarding process",
    es: "Por favor, proporcione su información personal para completar el proceso de incorporación",
  },
  "Add the team member's personal information": {
    en: "Add the team member's personal information",
    es: "Agregar la información personal del miembro del equipo",
  },
  Birthday: {
    en: "Birthday",
    es: "Cumpleaños",
  },
  SSN: {
    en: "SSN",
    es: "SSN",
  },
  "Language preference": {
    en: "Language preference",
    es: "Preferencia de idioma",
  },
  "This is the language you will use for the mobile app and team member portal": {
    en: "This is the language you will use for the mobile app and team member portal",
    es: "Este es el idioma que utilizará para la aplicación móvil y el portal de miembros del equipo",
  },
  "This is the language that the team member will use the mobile app and team member portal in": {
    en: "This is the language that the team member will use the mobile app and team member portal in",
    es: "Este es el idioma que el miembro del equipo utilizará en la aplicación móvil y el portal de miembros del equipo",
  },
  Address: {
    en: "Address",
    es: "Dirección",
  },
  "Use same address for mailing address": {
    en: "Use same address for mailing address",
    es: "Usar la misma dirección para la dirección postal",
  },
  "Mailing address": {
    en: "Mailing address",
    es: "Dirección postal",
  },
  "Emergency contact #1*": {
    en: "Emergency contact #1*",
    es: "Contacto de emergencia #1*",
  },
  Name: {
    en: "Name",
    es: "Nombre",
  },
  Relationship: {
    en: "Relationship",
    es: "Relación",
  },
  Phone: {
    en: "Phone",
    es: "Teléfono",
  },
  "Emergency contact #2": {
    en: "Emergency contact #2",
    es: "Contacto de emergencia #2",
  },
  "Failed to save profile picture": {
    en: "Failed to save profile picture",
    es: "No se pudo guardar la foto de perfil",
  },
  Edit: {
    en: "Edit",
    es: "Editar",
  },
  "Edit profile picture": {
    en: "Edit profile picture",
    es: "Editar foto de perfil",
  },
  "Drag and drop or click to upload a picture": {
    en: "Drag and drop or click to upload a picture",
    es: "Arrastra y suelta o haz clic para subir una foto",
  },
  Submit: {
    en: "Submit",
    es: "Enviar",
  },
  Cancel: {
    en: "Cancel",
    es: "Cancelar",
  },
  Delete: {
    en: "Delete",
    es: "Eliminar",
  },
  Back: {
    en: "Back",
    es: "Atrás",
  },
  Continue: {
    en: "Continue",
    es: "Continuar",
  },
  Exit: {
    en: "Exit",
    es: "Salir",
  },
  "Please provide your EEO information to complete the onboarding process": {
    en: "Please provide your EEO information to complete the onboarding process",
    es: "Por favor, proporcione su información EEO para completar el proceso de incorporación",
  },
  "Add the team member's EEO information": {
    en: "Add the team member's EEO information",
    es: "Agregar la información EEO del miembro del equipo",
  },
  "This information is required to be collected by insurance carriers.": {
    en: "This information is required to be collected by insurance carriers.",
    es: "Esta información es requerida por las compañías de seguros.",
  },
  "Select a gender": {
    en: "Select a gender",
    es: "Seleccione un género",
  },
  "Select an ethnicity": {
    en: "Select an ethnicity",
    es: "Seleccione una etnia",
  },
  "Select a marital status": {
    en: "Select a marital status",
    es: "Seleccione un estado civil",
  },
  "Select a disability status": {
    en: "Select a disability status",
    es: "Seleccione un estado de discapacidad",
  },
  "Select a veteran status": {
    en: "Select a veteran status",
    es: "Seleccione un estado de veterano",
  },
  "Select a job category": {
    en: "Select a job category",
    es: "Seleccione una categoría de trabajo",
  },
  // EEO Ethnicity Categories
  "White (Not Hispanic or Latino)": {
    en: "White (Not Hispanic or Latino)",
    es: "Blanco (No hispano o latino)",
  },
  "Black or African American (Not Hispanic or Latino)": {
    en: "Black or African American (Not Hispanic or Latino)",
    es: "Negro o afroamericano (No hispano o latino)",
  },
  "Hispanic or Latino": {
    en: "Hispanic or Latino",
    es: "Hispano o latino",
  },
  "American Indian or Alaska Native (Not Hispanic or Latino)": {
    en: "American Indian or Alaska Native (Not Hispanic or Latino)",
    es: "Indio americano o nativo de Alaska (No hispano o latino)",
  },
  "Asian (Not Hispanic or Latino)": {
    en: "Asian (Not Hispanic or Latino)",
    es: "Asiático (No hispano o latino)",
  },
  "Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)": {
    en: "Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)",
    es: "Hawaiano nativo u otra isla del Pacífico (No hispano o latino)",
  },
  "Two or More Races (Not Hispanic or Latino)": {
    en: "Two or More Races (Not Hispanic or Latino)",
    es: "Dos o más razas (No hispano o latino)",
  },
  "Prefer Not To Disclose": {
    en: "Prefer Not To Disclose",
    es: "Prefiere no divulgar",
  },

  // EEO Job Categories
  "Executive/Senior Level Officials and Managers": {
    en: "Executive/Senior Level Officials and Managers",
    es: "Funcionarios y gerentes de nivel ejecutivo/senior",
  },
  "First/Mid Level Officials and Managers": {
    en: "First/Mid Level Officials and Managers",
    es: "Funcionarios y gerentes de nivel medio/primero",
  },
  Professionals: {
    en: "Professionals",
    es: "Profesionales",
  },
  Technicians: {
    en: "Technicians",
    es: "Técnicos",
  },
  "Sales Workers": {
    en: "Sales Workers",
    es: "Trabajadores de ventas",
  },
  "Administrative Support Workers": {
    en: "Administrative Support Workers",
    es: "Trabajadores de apoyo administrativo",
  },
  "Craft Workers": {
    en: "Craft Workers",
    es: "Trabajadores artesanales",
  },
  Operatives: {
    en: "Operatives",
    es: "Operativos",
  },
  "Laborers and Helpers": {
    en: "Laborers and Helpers",
    es: "Trabajadores y ayudantes",
  },
  "Service Workers": {
    en: "Service Workers",
    es: "Trabajadores de servicios",
  },

  // EEO Gender Categories
  Male: {
    en: "Male",
    es: "Masculino",
  },
  Female: {
    en: "Female",
    es: "Femenino",
  },

  // EEO Veteran Status Categories
  "Identifies as a disabled veteran": {
    en: "Identifies as a disabled veteran",
    es: "Se identifica como veterano discapacitado",
  },
  "Identifies as a protected veteran": {
    en: "Identifies as a protected veteran",
    es: "Se identifica como veterano protegido",
  },
  "Identifies as a recently separated veteran": {
    en: "Identifies as a recently separated veteran",
    es: "Se identifica como veterano recientemente separado",
  },
  "Identifies as an active duty wartime or campaign badge veteran": {
    en: "Identifies as an active duty wartime or campaign badge veteran",
    es: "Se identifica como veterano en servicio activo en tiempo de guerra o con insignia de campaña",
  },
  "Identifies as an armed forces service medal veteran": {
    en: "Identifies as an armed forces service medal veteran",
    es: "Se identifica como veterano con medalla de servicio de las fuerzas armadas",
  },
  "Not a protected veteran": {
    en: "Not a protected veteran",
    es: "No es un veterano protegido",
  },

  // EEO Marital Status Categories
  Single: {
    en: "Single",
    es: "Soltero",
  },
  Married: {
    en: "Married",
    es: "Casado",
  },
  Divorced: {
    en: "Divorced",
    es: "Divorciado",
  },
  Widowed: {
    en: "Widowed",
    es: "Viudo",
  },
  Separated: {
    en: "Separated",
    es: "Separado",
  },

  // EEO Disability Status Categories
  "Yes, I have a disability (or previously had a disability)": {
    en: "Yes, I have a disability (or previously had a disability)",
    es: "Sí, tengo una discapacidad (o tuve una discapacidad anteriormente)",
  },
  "No, I do not have a disability and have not had one in the past": {
    en: "No, I do not have a disability and have not had one in the past",
    es: "No, no tengo una discapacidad y no la he tenido en el pasado",
  },
  "The Checkr screening has been completed": {
    en: "The Checkr screening has been completed",
    es: "La evaluación de Checkr se ha completado",
  },
  "Please complete the Checkr screening that was sent to your email": {
    en: "Please complete the Checkr screening that was sent to your email",
    es: "Por favor complete la evaluación de Checkr que se envió a su correo electrónico",
  },
  "I wish to receive paperless W2 statements and related tax information from Miter.": {
    en: "I wish to receive paperless W2 statements and related tax information from Miter.",
    es: "Deseo recibir declaraciones W2 sin papel e información fiscal relacionada de Miter.",
  },
  "I wish to receive paperless 1099 statements and related tax information from Miter.": {
    en: "I wish to receive paperless 1099 statements and related tax information from Miter.",
    es: "Deseo recibir declaraciones 1099 sin papel e información fiscal relacionada de Miter.",
  },
  "Payment method updated successfully": {
    en: "Payment method updated successfully",
    es: "Método de pago actualizado con éxito",
  },
  "Failed to update payment method": {
    en: "Failed to update payment method",
    es: "No se pudo actualizar el método de pago",
  },
  "Bank account created.": {
    en: "Bank account created.",
    es: "Cuenta bancaria creada.",
  },
  "Could not create bank account.": {
    en: "Could not create bank account.",
    es: "No se pudo crear la cuenta bancaria.",
  },
  "There was an error opening this page. Please contact support.": {
    en: "There was an error opening this page. Please contact support.",
    es: "Hubo un error al abrir esta página. Por favor, contacte con el soporte.",
  },
  "There was an error starting your I9. Please contact support.": {
    en: "There was an error starting your I9. Please contact support.",
    es: "Hubo un error al iniciar su I9. Por favor, contacte con el soporte.",
  },
  "I-9 saved successfully": {
    en: "I-9 saved successfully",
    es: "I-9 guardado con éxito",
  },
  "Team member saved successfully": {
    en: "Team member saved successfully",
    es: "Miembro del equipo guardado con éxito",
  },
  "Mark task as complete": {
    en: "Mark task as complete",
    es: "Marcar tarea como completa",
  },
  "Certification uploaded successfully": {
    en: "Certification uploaded successfully",
    es: "Certificación subida con éxito",
  },
  "There was an error uploading the certification: ": {
    en: "There was an error uploading the certification: ",
    es: "Hubo un error al subir la certificación: ",
  },
  "Form submission saved": {
    en: "Form submission saved",
    es: "Envío del formulario guardado",
  },
  "Document signed successfully": {
    en: "Document signed successfully",
    es: "Documento firmado con éxito",
  },
  "Fillable document completed successfully.": {
    en: "Fillable document completed successfully.",
    es: "Documento rellenable completado con éxito.",
  },
  "Please add your payment method to complete the onboarding process": {
    en: "Please add your payment method to complete the onboarding process",
    es: "Por favor, agregue su método de pago para completar el proceso de incorporación",
  },
  "Add bank account": {
    en: "Add bank account",
    es: "Agregar cuenta bancaria",
  },
  "Please add your bank account to complete the onboarding process": {
    en: "Please add your bank account to complete the onboarding process",
    es: "Por favor, agregue su cuenta bancaria para completar el proceso de incorporación",
  },
  "Connect with Plaid": {
    en: "Connect with Plaid",
    es: "Conectar con Plaid",
  },
  "Or add manually": {
    en: "Or add manually",
    es: "O agregar manualmente",
  },
  "Routing number": {
    en: "Routing number",
    es: "Número de ruta",
  },
  "Account number": {
    en: "Account number",
    es: "Número de cuenta",
  },
  Type: {
    en: "Type",
    es: "Tipo",
  },
  "Could not connect account to Miter.": {
    en: "Could not connect account to Miter.",
    es: "No se pudo conectar la cuenta a Miter.",
  },
  "Set up withholdings": {
    en: "Set up withholdings",
    es: "Configurar retenciones",
  },
  "Please set up your tax withholdings to complete the onboarding process": {
    en: "Please set up your tax withholdings to complete the onboarding process",
    es: "Por favor, configure sus retenciones fiscales para completar el proceso de incorporación",
  },
  "Your tax withholdings have been set up.": {
    en: "Your tax withholdings have been set up.",
    es: "Sus retenciones fiscales han sido configuradas.",
  },
  "Team member ID is required": {
    en: "Team member ID is required",
    es: "Se requiere el ID del miembro del equipo",
  },
  // I-9 translations
  "Complete your I-9": {
    en: "Complete your I-9",
    es: "Complete su I-9",
  },
  "Please follow the interactive steps to complete your section of the I-9": {
    en: "Please follow the interactive steps to complete your section of the I-9",
    es: "Por favor, siga los pasos interactivos para completar su sección del I-9",
  },
  "I9 has been completed.": {
    en: "I9 has been completed.",
    es: "El I9 ha sido completado.",
  },
  "Edit I-9": {
    en: "Edit I-9",
    es: "Editar I-9",
  },
  "Fill out I-9": {
    en: "Fill out I-9",
    es: "Rellenar I-9",
  },
  Residency: {
    en: "Residency",
    es: "Residencia",
  },
  "Select documents": {
    en: "Select documents",
    es: "Seleccionar documentos",
  },
  "Upload documents": {
    en: "Upload documents",
    es: "Subir documentos",
  },
  "Preparer/translator": {
    en: "Preparer/translator",
    es: "Preparador/traductor",
  },
  "Review and sign": {
    en: "Review and sign",
    es: "Revisar y firmar",
  },
  "Enter your residency information": {
    en: "Enter your residency information",
    es: "Ingrese su información de residencia",
  },
  "Citizenship/residency status": {
    en: "Citizenship/residency status",
    es: "Estado de ciudadanía/residencia",
  },
  "I attest, under penalty of perjury, that I am a:": {
    en: "I attest, under penalty of perjury, that I am a:",
    es: "Declaro, bajo pena de perjurio, que soy:",
  },
  "Permanent Resident Number Type*": {
    en: "Permanent Resident Number Type*",
    es: "Tipo de Número de Residente Permanente*",
  },
  "Enter Noncitizen Registration Number*": {
    en: "Enter Noncitizen Registration Number*",
    es: "Ingrese el Número de Registro de No Ciudadano*",
  },
  "USCIS Number* (without hyphen)": {
    en: "USCIS Number* (without hyphen)",
    es: "Número de USCIS* (sin guion)",
  },
  "Work Authorization Expiration Date": {
    en: "Work Authorization Expiration Date",
    es: "Fecha de Vencimiento de la Autorización de Trabajo",
  },
  "Select a date or leave blank if not applicable": {
    en: "Select a date or leave blank if not applicable",
    es: "Seleccione una fecha o deje en blanco si no aplica",
  },
  "Noncitizens authorized to work must provide only one of the following document numbers to complete Form I-9":
    {
      en: "Noncitizens authorized to work must provide only one of the following document numbers to complete Form I-9",
      es: "Los no ciudadanos autorizados a trabajar deben proporcionar solo uno de los siguientes números de documento para completar el Formulario I-9",
    },
  "A Noncitizen Registration Number/USCIS Number": {
    en: "A Noncitizen Registration Number/USCIS Number",
    es: "Un Número de Registro de No Ciudadano/Número de USCIS",
  },
  "Form I-94 Admission Number": {
    en: "Form I-94 Admission Number",
    es: "Número de Admisión del Formulario I-94",
  },
  "Foreign Passport Number": {
    en: "Foreign Passport Number",
    es: "Número de Pasaporte Extranjero",
  },
  "Document number type*": {
    en: "Document number type*",
    es: "Tipo de número de documento*",
  },
  "Enter Foreign Passport Number*": {
    en: "Enter Foreign Passport Number*",
    es: "Ingrese el Número de Pasaporte Extranjero*",
  },
  "Enter Country of Issuance*": {
    en: "Enter Country of Issuance*",
    es: "Ingrese el País de Emisión*",
  },
  "Enter Form I-94 Admission Number*": {
    en: "Enter Form I-94 Admission Number*",
    es: "Ingrese el Número de Admisión del Formulario I-94*",
  },
  "This field is required.": {
    en: "This field is required.",
    es: "Este campo es requerido.",
  },
  "U.S. Citizen": {
    en: "U.S. Citizen",
    es: "Ciudadano estadounidense",
  },
  "Lawful Permanent Resident": {
    en: "Lawful Permanent Resident",
    es: "Residente permanente legal",
  },
  "Non-Citizen National (U.S. Territory)": {
    en: "Non-Citizen National (U.S. Territory)",
    es: "Nacional no ciudadano (Territorio de EE.UU.)",
  },
  "Alien Authorized to Work": {
    en: "Alien Authorized to Work",
    es: "Extranjero autorizado para trabajar",
  },
  "Alien Registration Number (A-Number)": {
    en: "Alien Registration Number (A-Number)",
    es: "Número de registro de extranjero (Número A)",
  },
  "USCIS Number": {
    en: "USCIS Number",
    es: "Número de USCIS",
  },
  "I-94 Admission Number": {
    en: "I-94 Admission Number",
    es: "Número de admisión I-94",
  },
  "Select the documents you would like to use": {
    en: "Select the documents you would like to use",
    es: "Seleccione los documentos que desea usar",
  },
  "Your employer needs these documents to verify that you are eligible to work in the US. Select one document in List A or select a document in List B + a document in List C.":
    {
      en: "Your employer needs these documents to verify that you are eligible to work in the US. Select one document in List A or select a document in List B + a document in List C.",
      es: "Su empleador necesita estos documentos para verificar que es elegible para trabajar en los EE. UU. Seleccione un documento en la Lista A o seleccione un documento en la Lista B + un documento en la Lista C.",
    },
  "List A": {
    en: "List A",
    es: "Lista A",
  },
  "Identity + employment authorization docs": {
    en: "Identity + employment authorization docs",
    es: "Documentos de identidad + autorización de empleo",
  },
  "List B": {
    en: "List B",
    es: "Lista B",
  },
  "Identity documents": {
    en: "Identity documents",
    es: "Documentos de identidad",
  },
  "List C": {
    en: "List C",
    es: "Lista C",
  },
  "Employment authorization documents": {
    en: "Employment authorization documents",
    es: "Documentos de autorización de empleo",
  },
  "US Passport": {
    en: "U.S. Passport",
    es: "Pasaporte de EE.UU.",
  },
  "US Passport Card": {
    en: "U.S. Passport Card",
    es: "Tarjeta de Pasaporte de EE.UU.",
  },
  "Permanent Resident Card": {
    en: "Permanent Resident Card",
    es: "Tarjeta de Residente Permanente",
  },
  "Alien Registration Receipt Card (Form I-551)": {
    en: "Alien Registration Receipt Card (Form I-551)",
    es: "Tarjeta de Registro de Extranjero (Formulario I-551)",
  },
  "Foreign passport with I-551 Stamp or MRIV": {
    en: "Foreign passport with I-551 Stamp or MRIV",
    es: "Pasaporte extranjero con sello I-551 o MRIV",
  },
  "Employment Authorization Document (Form I-766)": {
    en: "Employment Authorization Document (Form I-766)",
    es: "Documento de Autorización de Empleo (Formulario I-766)",
  },
  "Foreign passport with Form I-94": {
    en: "Foreign passport with Form I-94",
    es: "Pasaporte extranjero con Formulario I-94",
  },
  "Passport from Federated States of Micronesia or Republic of the Marshall Islands with Form I-94 or Form I-94A":
    {
      en: "Passport from Federated States of Micronesia or Republic of the Marshall Islands with Form I-94 or Form I-94A",
      es: "Pasaporte de los Estados Federados de Micronesia o la República de las Islas Marshall con Formulario I-94 o Formulario I-94A",
    },
  "Driver's License": {
    en: "Driver's License",
    es: "Licencia de Conducir",
  },
  "ID Card": {
    en: "ID Card",
    es: "Tarjeta de Identificación",
  },
  "State ID Card": {
    en: "State ID Card",
    es: "Tarjeta de Identificación Estatal",
  },
  "School ID Card": {
    en: "School ID Card",
    es: "Tarjeta de Identificación Escolar",
  },
  "Voter's Registration Card": {
    en: "Voter's Registration Card",
    es: "Tarjeta de Registro de Votante",
  },
  "US Military card": {
    en: "US Military card",
    es: "Tarjeta Militar de EE.UU.",
  },
  "Draft Record": {
    en: "Draft Record",
    es: "Registro de Reclutamiento",
  },
  "Military Dependent's ID Card": {
    en: "Military Dependent's ID Card",
    es: "Tarjeta de Identificación de Dependiente Militar",
  },
  "US Coast Guard Merchant Mariner Card": {
    en: "US Coast Guard Merchant Mariner Card",
    es: "Tarjeta de Marinero Mercante de la Guardia Costera de EE.UU.",
  },
  "Native American Tribal Document": {
    en: "Native American Tribal Document",
    es: "Documento Tribal Nativo Americano",
  },
  "Canadian Driver's License": {
    en: "Canadian Driver's License",
    es: "Licencia de Conducir Canadiense",
  },
  "School Record": {
    en: "School Record",
    es: "Registro Escolar",
  },
  "Day-care/Nursery School Record": {
    en: "Day-care/Nursery School Record",
    es: "Registro de Guardería/Escuela Infantil",
  },
  "Clinic/Doctor/Hospital Record": {
    en: "Clinic/Doctor/Hospital Record",
    es: "Registro de Clínica/Doctor/Hospital",
  },
  "Social Security Card": {
    en: "Social Security Card",
    es: "Tarjeta de Seguro Social",
  },
  "Certification of Report of Birth": {
    en: "Certification of Report of Birth",
    es: "Certificación de Informe de Nacimiento",
  },
  "US Birth Certificate": {
    en: "US Birth Certificate",
    es: "Certificado de Nacimiento de EE.UU.",
  },
  "US Citizen ID Card (Form I-197)": {
    en: "US Citizen ID Card (Form I-197)",
    es: "Tarjeta de Identificación de Ciudadano de EE.UU. (Formulario I-197)",
  },
  "ID Card for Use of Resident Citizen in the US (Form I-179)": {
    en: "ID Card for Use of Resident Citizen in the US (Form I-179)",
    es: "Tarjeta de Identificación para Uso de Ciudadano Residente en EE.UU. (Formulario I-179)",
  },
  "Employment authorization document issued by the Department of Homeland Security": {
    en: "Employment authorization document issued by the Department of Homeland Security",
    es: "Documento de autorización de empleo emitido por el Departamento de Seguridad Nacional",
  },
  "Arrival/Departure Record (Form I-94)": {
    en: "Arrival/Departure Record (Form I-94)",
    es: "Registro de Llegada/Salida (Formulario I-94)",
  },
  "Arrival/Departure Record (Form I-94A)": {
    en: "Arrival/Departure Record (Form I-94A)",
    es: "Registro de Llegada/Salida (Formulario I-94A)",
  },
  "Certificate of Eligibility for Nonimmigrant (F-1) Student Status (Form I-20)": {
    en: "Certificate of Eligibility for Nonimmigrant (F-1) Student Status (Form I-20)",
    es: "Certificado de Elegibilidad para Estatus de Estudiante No Inmigrante (F-1) (Formulario I-20)",
  },
  "Certificate of Eligibility for Exchange Visitor (J-1) Status (Form DS-2019)": {
    en: "Certificate of Eligibility for Exchange Visitor (J-1) Status (Form DS-2019)",
    es: "Certificado de Elegibilidad para Estatus de Visitante de Intercambio (J-1) (Formulario DS-2019)",
  },
  "U.S. Passport": {
    en: "U.S. Passport",
    es: "Pasaporte de los EE. UU.",
  },
  "U.S. Passport Card": {
    en: "U.S. Passport Card",
    es: "Tarjeta de Pasaporte de los EE. UU.",
  },
  "Foreign passport that contains a temporary I-551 stamp or temporary I-551 printed notation on a machine-readable immigrant visa":
    {
      en: "Foreign passport that contains a temporary I-551 stamp or temporary I-551 printed notation on a machine-readable immigrant visa",
      es: "Pasaporte extranjero que contiene un sello I-551 temporal o una notación impresa temporal I-551 en una visa de inmigrante legible por máquina",
    },
  "Employment Authorization Document that contains a photograph (Form I-766)": {
    en: "Employment Authorization Document that contains a photograph (Form I-766)",
    es: "Documento de Autorización de Empleo que contiene una fotografía (Formulario I-766)",
  },
  "For a nonimmigrant alien authorized to work for a specific employer because of his or her status:\na. Foreign passport; and\nb. Form I-94 or Form I-94A that has\nthe following:\n(1) The same name as the passport; and\n(2) An endorsement of the alien's nonimmigrant status as long as that period of endorsement has not yet expired and the proposed employment is not in conflict with any restrictions or limitations identified on the form.":
    {
      en: "For a nonimmigrant alien authorized to work for a specific employer because of his or her status:\na. Foreign passport; and\nb. Form I-94 or Form I-94A that has\nthe following:\n(1) The same name as the passport; and\n(2) An endorsement of the alien's nonimmigrant status as long as that period of endorsement has not yet expired and the proposed employment is not in conflict with any restrictions or limitations identified on the form.",
      es: "Para un extranjero no inmigrante autorizado a trabajar para un empleador específico debido a su estatus:\na. Pasaporte extranjero; y\nb. Formulario I-94 o Formulario I-94A que tiene\nlo siguiente:\n(1) El mismo nombre que el pasaporte; y\n(2) Un respaldo del estatus de no inmigrante del extranjero siempre que el período de respaldo no haya expirado y el empleo propuesto no esté en conflicto con ninguna restricción o limitación identificada en el formulario.",
    },
  "A passport from the Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with Form I-94 or Form I-94A indicating nonimmigrant admission under the Compact of Free Association Between the United States and the FSM or RMI":
    {
      en: "A passport from the Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with Form I-94 or Form I-94A indicating nonimmigrant admission under the Compact of Free Association Between the United States and the FSM or RMI",
      es: "Un pasaporte de los Estados Federados de Micronesia (FSM) o de la República de las Islas Marshall (RMI) con el Formulario I-94 o Formulario I-94A que indica admisión de no inmigrante bajo el Pacto de Libre Asociación entre los Estados Unidos y el FSM o RMI",
    },
  "Driver's license issued by a state or outlying possession of the United States, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address":
    {
      en: "Driver's license issued by a state or outlying possession of the United States, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address",
      es: "Licencia de conducir emitida por un estado o posesión periférica de los Estados Unidos, siempre que contenga una fotografía o información como nombre, fecha de nacimiento, género, altura, color de ojos y dirección",
    },
  "ID card issued by a state or outlying possession of the United States, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address":
    {
      en: "ID card issued by a state or outlying possession of the United States, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address",
      es: "Tarjeta de identificación emitida por un estado o posesión periférica de los Estados Unidos, siempre que contenga una fotografía o información como nombre, fecha de nacimiento, género, altura, color de ojos y dirección",
    },
  "State ID card issued by a state or outlying possession of the United States, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address":
    {
      en: "State ID card issued by a state or outlying possession of the United States, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address",
      es: "Tarjeta de identificación estatal emitida por un estado o posesión periférica de los Estados Unidos, siempre que contenga una fotografía o información como nombre, fecha de nacimiento, género, altura, color de ojos y dirección",
    },
  "School ID card, provided it contains a photograph": {
    en: "School ID card, provided it contains a photograph",
    es: "Tarjeta de identificación escolar, siempre que contenga una fotografía",
  },
  "Voter's registration card": {
    en: "Voter's registration card",
    es: "Tarjeta de registro de votante",
  },
  "US military": {
    en: "US military",
    es: "Militar de los EE. UU.",
  },
  "Draft record": {
    en: "Draft record",
    es: "Registro de reclutamiento",
  },
  "Military dependent's ID card": {
    en: "Military dependent's ID card",
    es: "Tarjeta de identificación de dependiente militar",
  },
  "Native American tribal document": {
    en: "Native American tribal document",
    es: "Documento tribal de nativo americano",
  },
  "Canadian driver's license": {
    en: "Canadian driver's license",
    es: "Licencia de conducir canadiense",
  },
  "School record - for persons under 18 years of age that can't present a document listed above": {
    en: "School record - for persons under 18 years of age that can't present a document listed above",
    es: "Registro escolar - para personas menores de 18 años que no pueden presentar un documento enumerado anteriormente",
  },
  "Nursery school record - for persons under 18 years of age that can't present a document listed above": {
    en: "Nursery school record - for persons under 18 years of age that can't present a document listed above",
    es: "Registro de guardería - para personas menores de 18 años que no pueden presentar un documento enumerado anteriormente",
  },
  "School record - for persons under 18 years of age that can't present a documment listed above": {
    en: "School record - for persons under 18 years of age that can't present a documment listed above",
    es: "Registro escolar - para personas menores de 18 años que no pueden presentar un documento enumerado anteriormente",
  },
  "Nursery school record - for persons under 18 years of age that can't present a documment listed above": {
    en: "Nursery school record - for persons under 18 years of age that can't present a documment listed above",
    es: "Registro de guardería - para personas menores de 18 años que no pueden presentar un documento enumerado anteriormente",
  },
  "Clinic/Doctor/Hospital record - for persons under 18 years of age that can't present a documment listed above":
    {
      en: "Clinic/Doctor/Hospital record - for persons under 18 years of age that can't present a documment listed above",
      es: "Registro de clínica/doctor/hospital - para personas menores de 18 años que no pueden presentar un documento enumerado anteriormente",
    },
  "A Social Security Account Number card, unless the card includes one of the following restrictions:\n(1) NOT VALID FOR EMPLOYMENT\n(2) VALID FOR WORK ONLY WITH INS AUTHORIZATION\n(3) VALID FOR WORK ONLY WITH DHS AUTHORIZATION":
    {
      en: "A Social Security Account Number card, unless the card includes one of the following restrictions:\n(1) NOT VALID FOR EMPLOYMENT\n(2) VALID FOR WORK ONLY WITH INS AUTHORIZATION\n(3) VALID FOR WORK ONLY WITH DHS AUTHORIZATION",
      es: "Tarjeta de número de cuenta de la Seguridad Social, a menos que la tarjeta incluya uno de los siguientes restricciones:\n(1) NO VÁLIDO PARA EMPLEO\n(2) VÁLIDO PARA TRABAJO SOLO CON AUTORIZACIÓN DE INS\n(3) VÁLIDO PARA TRABAJO SOLO CON AUTORIZACIÓN DE DHS",
    },
  "Certification of report of birth issued by the Department of State (Forms DS-1350, FS-545, FS-240)": {
    en: "Certification of report of birth issued by the Department of State (Forms DS-1350, FS-545, FS-240)",
    es: "Certificación de nacimiento emitida por el Departamento de Estado (Formularios DS-1350, FS-545, FS-240)",
  },
  "Original or certified copy of birth certificate issued by a State, county, municipal authority, or territory of the United States bearing an official seal":
    {
      en: "Original or certified copy of birth certificate issued by a State, county, municipal authority, or territory of the United States bearing an official seal",
      es: "Certificado original o copiado de certificado de nacimiento emitido por un estado, condado, autoridad municipal o territorio de los Estados Unidos con sello oficial",
    },
  "Let's upload your documents": {
    en: "Let's upload your documents",
    es: "Subamos tus documentos",
  },
  "Review the documents": {
    en: "Review the documents",
    es: "Revisar los documentos",
  },
  "Fill out the form(s) below and upload your documents.": {
    en: "Fill out the form(s) below and upload your documents.",
    es: "Complete el/los formulario(s) a continuación y suba sus documentos.",
  },
  "Please review the documents that the employee uploaded. Remember, you are legally responsible for reviewing the physical version of these documents as well.":
    {
      en: "Please review the documents that the employee uploaded. Remember, you are legally responsible for reviewing the physical version of these documents as well.",
      es: "Por favor, revise los documentos que el empleado subió. Recuerde, usted es legalmente responsable de revisar la versión física de estos documentos también.",
    },
  "Drag and drop or click to upload a document": {
    en: "Drag and drop or click to upload a document",
    es: "Arrastre y suelte o haga clic para subir un documento",
  },
  "Issuing authority": {
    en: "Issuing authority",
    es: "Autoridad que emitió",
  },
  "Document number": {
    en: "Document number",
    es: "Número de documento",
  },
  "Expiration date": {
    en: "Expiration date",
    es: "Fecha de expiración",
  },
  "The date the document expires (if applicable)": {
    en: "The date the document expires (if applicable)",
    es: "La fecha de expiración del documento (si es aplicable)",
  },
  "The country, state, or government agency that issued the document": {
    en: "The country, state, or government agency that issued the document",
    es: "El país, estado o agencia gubernamental que emitió el documento",
  },
  "Passport ID Page of the US Passport": {
    en: "Passport ID Page of the US Passport",
    es: "Página de identificación del pasaporte de EE. UU.",
  },
  "Passport Barcode Page of the US Passport": {
    en: "Passport Barcode Page of the US Passport",
    es: "Página del código de barras del pasaporte de EE. UU.",
  },
  "Passport ID Page of the US Passport Card": {
    en: "Passport ID Page of the US Passport Card",
    es: "Página de identificación de la tarjeta de pasaporte de EE. UU.",
  },
  "Passport Barcode Page of the US Passport Card": {
    en: "Passport Barcode Page of the US Passport Card",
    es: "Página del código de barras de la tarjeta de pasaporte de EE. UU.",
  },
  "Front of the Permanent Resident Card": {
    en: "Front of the Permanent Resident Card",
    es: "Frente de la tarjeta de residente permanente",
  },
  "Back of the Permanent Resident Card": {
    en: "Back of the Permanent Resident Card",
    es: "Reverso de la tarjeta de residente permanente",
  },
  "Front of the Alien Registration Receipt Card (Form I-551)": {
    en: "Front of the Alien Registration Receipt Card (Form I-551)",
    es: "Frente de la tarjeta de registro de extranjero (Formulario I-551)",
  },
  "Back of the Alien Registration Receipt Card (Form I-551)": {
    en: "Back of the Alien Registration Receipt Card (Form I-551)",
    es: "Reverso de la tarjeta de registro de extranjero (Formulario I-551)",
  },
  "Passport ID Page of the Foreign Passport": {
    en: "Passport ID Page of the Foreign Passport",
    es: "Página de identificación del pasaporte extranjero",
  },
  "Passport Barcode Page of the Foreign Passport": {
    en: "Passport Barcode Page of the Foreign Passport",
    es: "Página del código de barras del pasaporte extranjero",
  },
  "Front of the Employment Authorization Document (Form I-766)": {
    en: "Front of the Employment Authorization Document (Form I-766)",
    es: "Frente del documento de autorización de empleo (Formulario I-766)",
  },
  "Back of the Employment Authorization Document (Form I-766)": {
    en: "Back of the Employment Authorization Document (Form I-766)",
    es: "Reverso del documento de autorización de empleo (Formulario I-766)",
  },
  "Passport ID Page of the Passport": {
    en: "Passport ID Page of the Passport",
    es: "Página de identificación del pasaporte",
  },
  "Passport Barcode Page of the Passport": {
    en: "Passport Barcode Page of the Passport",
    es: "Página del código de barras del pasaporte",
  },
  "Front of the Driver's License": {
    en: "Front of the Driver's License",
    es: "Frente de la licencia de conducir",
  },
  "Back of the Driver's License": {
    en: "Back of the Driver's License",
    es: "Reverso de la licencia de conducir",
  },
  "Front of the ID Card": {
    en: "Front of the ID Card",
    es: "Frente de la tarjeta de identificación",
  },
  "Back of the ID Card": {
    en: "Back of the ID Card",
    es: "Reverso de la tarjeta de identificación",
  },
  "Front of the State ID Card": {
    en: "Front of the State ID Card",
    es: "Frente de la tarjeta de identificación estatal",
  },
  "Back of the State ID Card": {
    en: "Back of the State ID Card",
    es: "Reverso de la tarjeta de identificación estatal",
  },
  "Front of the School ID Card": {
    en: "Front of the School ID Card",
    es: "Frente de la tarjeta de identificación escolar",
  },
  "Back of the School ID Card": {
    en: "Back of the School ID Card",
    es: "Reverso de la tarjeta de identificación escolar",
  },
  "Front of the Voter's Registration Card": {
    en: "Front of the Voter's Registration Card",
    es: "Frente de la tarjeta de registro de votante",
  },
  "Back of the Voter's Registration Card": {
    en: "Back of the Voter's Registration Card",
    es: "Reverso de la tarjeta de registro de votante",
  },
  "Front of the US Military card": {
    en: "Front of the US Military card",
    es: "Frente de la tarjeta militar de EE.UU.",
  },
  "Back of the US Military card": {
    en: "Back of the US Military card",
    es: "Reverso de la tarjeta militar de EE.UU.",
  },
  "Front of the Draft Record": {
    en: "Front of the Draft Record",
    es: "Frente del registro de servicio militar",
  },
  "Back of the Draft Record": {
    en: "Back of the Draft Record",
    es: "Reverso del registro de servicio militar",
  },
  "Front of the Military Dependent's ID Card": {
    en: "Front of the Military Dependent's ID Card",
    es: "Frente de la tarjeta de identificación de dependiente militar",
  },
  "Back of the Military Dependent's ID Card": {
    en: "Back of the Military Dependent's ID Card",
    es: "Reverso de la tarjeta de identificación de dependiente militar",
  },
  "Front of the US Coast Guard Merchant Mariner Card": {
    en: "Front of the US Coast Guard Merchant Mariner Card",
    es: "Frente de la tarjeta de marinero mercante de la Guardia Costera de EE.UU.",
  },
  "Back of the US Coast Guard Merchant Mariner Card": {
    en: "Back of the US Coast Guard Merchant Mariner Card",
    es: "Reverso de la tarjeta de marinero mercante de la Guardia Costera de EE.UU.",
  },
  "Front of the Native American Tribal Document": {
    en: "Front of the Native American Tribal Document",
    es: "Frente del documento tribal nativo americano",
  },
  "Back of the Native American Tribal Document": {
    en: "Back of the Native American Tribal Document",
    es: "Reverso del documento tribal nativo americano",
  },
  "Front of the Canadian Driver's License": {
    en: "Front of the Canadian Driver's License",
    es: "Frente de la licencia de conducir canadiense",
  },
  "Back of the Canadian Driver's License": {
    en: "Back of the Canadian Driver's License",
    es: "Reverso de la licencia de conducir canadiense",
  },
  "Front of the School Record": {
    en: "Front of the School Record",
    es: "Frente del registro escolar",
  },
  "Back of the School Record": {
    en: "Back of the School Record",
    es: "Reverso del registro escolar",
  },
  "Front of the Nursery School Record": {
    en: "Front of the Nursery School Record",
    es: "Frente del registro de guardería",
  },
  "Back of the Nursery School Record": {
    en: "Back of the Nursery School Record",
    es: "Reverso del registro de guardería",
  },
  "Front of the Clinic/Doctor/Hospital Record": {
    en: "Front of the Clinic/Doctor/Hospital Record",
    es: "Frente del registro de clínica/médico/hospital",
  },
  "Back of the Clinic/Doctor/Hospital Record": {
    en: "Back of the Clinic/Doctor/Hospital Record",
    es: "Reverso del registro de clínica/médico/hospital",
  },
  "Front of the Social Security Card": {
    en: "Front of the Social Security Card",
    es: "Frente de la tarjeta de seguro social",
  },
  "Back of the Social Security Card": {
    en: "Back of the Social Security Card",
    es: "Reverso de la tarjeta de seguro social",
  },
  "Front of the Certification of Report of Birth": {
    en: "Front of the Certification of Report of Birth",
    es: "Frente de la certificación de informe de nacimiento",
  },
  "Back of the Certification of Report of Birth": {
    en: "Back of the Certification of Report of Birth",
    es: "Reverso de la certificación de informe de nacimiento",
  },
  "Front of the US Birth Certificate": {
    en: "Front of the US Birth Certificate",
    es: "Frente del certificado de nacimiento de EE.UU.",
  },
  "Back of the US Birth Certificate": {
    en: "Back of the US Birth Certificate",
    es: "Reverso del certificado de nacimiento de EE.UU.",
  },
  "Front of the US Citizen ID Card (Form I-197)": {
    en: "Front of the US Citizen ID Card (Form I-197)",
    es: "Frente de la tarjeta de identificación de ciudadano de EE.UU. (Formulario I-197)",
  },
  "Back of the US Citizen ID Card (Form I-197)": {
    en: "Back of the US Citizen ID Card (Form I-197)",
    es: "Reverso de la tarjeta de identificación de ciudadano de EE.UU. (Formulario I-197)",
  },
  "Front of the ID Card for Use of Resident Citizen in the US (Form I-179)": {
    en: "Front of the ID Card for Use of Resident Citizen in the US (Form I-179)",
    es: "Frente de la tarjeta de identificación para uso de ciudadanos residentes en EE.UU. (Formulario I-179)",
  },
  "Back of the ID Card for Use of Resident Citizen in the US (Form I-179)": {
    en: "Back of the ID Card for Use of Resident Citizen in the US (Form I-179)",
    es: "Reverso de la tarjeta de identificación para uso de ciudadanos residentes en EE.UU. (Formulario I-179)",
  },
  "Front of the Employment Authorization Document": {
    en: "Front of the Employment Authorization Document",
    es: "Frente del documento de autorización de empleo",
  },
  "Back of the Employment Authorization Document": {
    en: "Back of the Employment Authorization Document",
    es: "Reverso del documento de autorización de empleo",
  },
  "Front of the Arrival/Departure Record (Form I-94)": {
    en: "Front of the Arrival/Departure Record (Form I-94)",
    es: "Frente del registro de llegada/salida (Formulario I-94)",
  },
  "Front of the Form I-94A": {
    en: "Front of the Form I-94A",
    es: "Frente del formulario I-94A",
  },
  "Front of the Form I-20": {
    en: "Front of the Form I-20",
    es: "Frente del formulario I-20",
  },
  "Front of the Form DS-2019": {
    en: "Front of the Form DS-2019",
    es: "Frente del formulario DS-2019",
  },
  "E-Signature completed": {
    en: "E-Signature completed",
    es: "Firma electrónica completada",
  },
  "I attest, under penalty of perjury, that I have assisted in the completion of this form and that to the best of my knowledge the information is true and correct.":
    {
      en: "I attest, under penalty of perjury, that I have assisted in the completion of this form and that to the best of my knowledge the information is true and correct.",
      es: "Declaro, bajo pena de perjurio, que he asistido en la finalización de este formulario y que, según mi leal saber y entender, la información es verdadera y correcta.",
    },
  "Preparer/translator signature*": {
    en: "Preparer/Translator Signature*",
    es: "Firma del preparador/traductor*",
  },
  "Preparer first name*": {
    en: "Preparer first name*",
    es: "Nombre del preparador*",
  },
  "Preparer last name*": {
    en: "Preparer last name*",
    es: "Apellido del preparador*",
  },
  "Preparer address*": {
    en: "Preparer address*",
    es: "Dirección del preparador*",
  },
  "Preparer or translator certification": {
    en: "Preparer or translator certification",
    es: "Certificación de preparador o traductor",
  },
  "Did a preparer and/or translator assist you in completing the previous sections": {
    en: "Did a preparer and/or translator assist you in completing the previous sections",
    es: "¿Un preparador y/o traductor le asistió en completar las secciones anteriores?",
  },
  "Enter first name": {
    en: "Enter first name",
    es: "Ingrese el nombre",
  },
  "Enter last name": {
    en: "Enter last name",
    es: "Ingrese el apellido",
  },
  Yes: {
    en: "Yes",
    es: "Sí",
  },
  No: {
    en: "No",
    es: "No",
  },
  "Or type your full name here": {
    en: "Or type your full name here",
    es: "O escriba su nombre completo aquí",
  },
  Complete: {
    en: "Complete",
    es: "Completar",
  },
  "I-9 Preview for {name}.pdf": {
    en: "I-9 Preview for {name}.pdf",
    es: "Vista previa del I-9 para {name}.pdf",
  },
  "Error downloading I-9 preview. Please contact support.": {
    en: "Error downloading I-9 preview. Please contact support.",
    es: "Error al descargar la vista previa del I-9. Por favor, contacte con el soporte.",
  },
  "Error previewing I-9": {
    en: "Error previewing I-9",
    es: "Error al previsualizar el I-9",
  },
  "Employee's other last names (if applicable)": {
    en: "Employee's other last names (if applicable)",
    es: "Otros apellidos del empleado (si aplica)",
  },
  "Enter any other last names here": {
    en: "Enter any other last names here",
    es: "Ingrese aquí cualquier otro apellido",
  },
  "Preview I-9": {
    en: "Preview I-9",
    es: "Vista previa del I-9",
  },
  "I am aware that federal law provides for imprisonment and/or fines for false statements or use of false documents in connection with the completion of this form. I attest, under penalty of perjury, that I am: {authorizationType}":
    {
      en: "I am aware that federal law provides for imprisonment and/or fines for false statements or use of false documents in connection with the completion of this form. I attest, under penalty of perjury, that I am: {authorizationType}",
      es: "Soy consciente de que la ley federal prevé el encarcelamiento y/o multas por declaraciones falsas o el uso de documentos falsos en relación con la finalización de este formulario. Declaro, bajo pena de perjurio, que soy: {authorizationType}",
    },
  "Employee Signature*": {
    en: "Employee Signature*",
    es: "Firma del empleado*",
  },
  "Finalize your I-9": {
    en: "Finalize your I-9",
    es: "Finalice su I-9",
  },
  "Please review your I-9 and sign below to complete the process.": {
    en: "Please review your I-9 and sign below to complete the process.",
    es: "Por favor, revise su I-9 y firme a continuación para completar el proceso.",
  },
  "You have submitted your I-9. Please remember to bring original versions of the documents you used to complete this form to your first day of work.":
    {
      en: "You have submitted your I-9. Please remember to bring original versions of the documents you used to complete this form to your first day of work.",
      es: "Ha enviado su I-9. Recuerde traer las versiones originales de los documentos que utilizó para completar este formulario el primer día de trabajo.",
    },
  "Error signing document. Please contact support.": {
    en: "Error signing document. Please contact support.",
    es: "Error al firmar el documento. Por favor, contacte con el soporte.",
  },
  "Error getting document. Please contact support.": {
    en: "Error getting document. Please contact support.",
    es: "Error al obtener el documento. Por favor, contacte con el soporte.",
  },
  "There was an error getting the link to the signed document. Please contact support.": {
    en: "There was an error getting the link to the signed document. Please contact support.",
    es: "Hubo un error al obtener el enlace al documento firmado. Por favor, contacte con el soporte.",
  },
  "Please first view and then sign the document to complete the onboarding process": {
    en: "Please first view and then sign the document to complete the onboarding process",
    es: "Por favor, primero vea y luego firme el documento para completar el proceso de incorporación",
  },
  "View document": {
    en: "View document",
    es: "Ver documento",
  },
  "View document again": {
    en: "View document again",
    es: "Ver documento nuevamente",
  },
  "Document signature already complete!": {
    en: "Document signature already complete!",
    es: "¡Firma del documento ya completada!",
  },
  "Please enter your full name to sign this document.": {
    en: "Please enter your full name to sign this document.",
    es: "Por favor, ingrese su nombre completo para firmar este documento.",
  },
  "Sign ": {
    en: "Sign ",
    es: "Firmar ",
  },
  document: {
    en: "document",
    es: "documento",
  },
  "Fill out ": {
    en: "Fill out ",
    es: "Rellenar ",
  },
  "fillable document": {
    en: "fillable document",
    es: "documento rellenable",
  },
  "Please follow the interactive steps to complete your section of the fillable document": {
    en: "Please follow the interactive steps to complete your section of the fillable document",
    es: "Por favor, siga los pasos interactivos para completar su sección del documento rellenable",
  },
  "Edit document": {
    en: "Edit document",
    es: "Editar documento",
  },
  "Fill out document": {
    en: "Fill out document",
    es: "Rellenar documento",
  },
  "Fill document fields": {
    en: "Fill document fields",
    es: "Rellenar campos del documento",
  },
  "Confirm entries": {
    en: "Confirm entries",
    es: "Confirmar entradas",
  },
  "Document owner:": {
    en: "Document owner:",
    es: "Propietario del documento:",
  },
  "Open PDF form": {
    en: "Open PDF form",
    es: "Abrir formulario PDF",
  },
  "Confirm your section of the document": {
    en: "Confirm your section of the document",
    es: "Confirme su sección del documento",
  },
  "You do not have permissions to edit this custom field": {
    en: "You do not have permissions to edit this custom field",
    es: "No tiene permisos para editar este campo personalizado",
  },
  "Please complete the following custom field for onboarding": {
    en: "Please complete the following custom field for onboarding",
    es: "Por favor complete el siguiente campo personalizado para la incorporación",
  },
  "Add custom fields to this team member": {
    en: "Add custom fields to this team member",
    es: "Agregar campos personalizados a este miembro del equipo",
  },
  "This field is required": {
    en: "This field is required",
    es: "Este campo es obligatorio",
  },
  "Must be at least ": {
    en: "Must be at least ",
    es: "Debe ser al menos ",
  },
  "Must be at most ": {
    en: "Must be at most ",
    es: "Debe ser como máximo ",
  },
  "Select ": {
    en: "Select ",
    es: "Seleccionar ",
  },
  "Continue and complete later": {
    en: "Continue and complete later",
    es: "Continuar y completar más tarde",
  },
  "There was an error retrieving the certification: ": {
    en: "There was an error retrieving the certification: ",
    es: "Hubo un error al recuperar la certificación: ",
  },
  "Error retrieving form submission: ": {
    en: "Error retrieving form submission: ",
    es: "Error al recuperar el envío del formulario: ",
  },
  "Error getting form submission": {
    en: "Error getting form submission",
    es: "Error al obtener el envío del formulario",
  },
  "Error saving form submission: ": {
    en: "Error saving form submission: ",
    es: "Error al guardar el envío del formulario: ",
  },
  "Error saving form submission": {
    en: "Error al guardar el envío del formulario",
    es: "Error al guardar el envío del formulario",
  },
  "Fill out form": {
    en: "Fill out form",
    es: "Rellenar formulario",
  },
  "Please fill out the form to complete the onboarding process": {
    en: "Please fill out the form to complete the onboarding process",
    es: "Por favor, complete el formulario para completar el proceso de incorporación",
  },
  "Error signing document: ": {
    en: "Error signing document: ",
    es: "Error al firmar el documento: ",
  },
  "Signature*": {
    en: "Signature*",
    es: "Firma*",
  },
  "Complete ": {
    en: "Complete ",
    es: "Completar ",
  },
  " screening": {
    en: " screening",
    es: " evaluación",
  },
  "Certification expiry date": {
    en: "Certification expiry date",
    es: "Fecha de expiración de la certificación",
  },
  "Select a date": {
    en: "Select a date",
    es: "Seleccionar una fecha",
  },
  "Select a time": {
    en: "Select a time",
    es: "Seleccionar un tiempo",
  },
  "Drag 'n' drop some files here, or click to select files": {
    en: "Drag 'n' drop some files here, or click to select files",
    es: "Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos",
  },
  "File type is not allowed.": {
    en: "File type is not allowed.",
    es: "El tipo de archivo no está permitido.",
  },
  "One or more files are not allowed.": {
    en: "One or more files are not allowed.",
    es: "Uno o más archivos no están permitidos.",
  },
  "File is too large. Max size is ": {
    en: "File is too large. Max size is ",
    es: "El archivo es demasiado grande. El tamaño máximo es de ",
  },

  "MB.": {
    en: "MB.",
    es: "MB.",
  },
  "One or more files are too large. Max size is ": {
    en: "One or more files are too large. Max size is ",
    es: "Uno o más archivos son demasiado grandes. El tamaño máximo es de ",
  },
  "You can only upload one file": {
    en: "You can only upload one file",
    es: "Solo puede subir un archivo",
  },
  "Team member saved successfully.": {
    en: "Team member saved successfully.",
    es: "Miembro del equipo guardado correctamente.",
  },
  "You can only upload ": {
    en: "You can only upload ",
    es: "Solo puede subir ",
  },
  " files": {
    en: " files",
    es: " archivos",
  },
  "You have already added a bank account. If you'd like to edit your bank account info or add more accounts, see the payment info tab.":
    {
      en: "You have already added a bank account. If you'd like to edit your bank account info or add more accounts, see the payment info tab.",
      es: "Ya ha agregado una cuenta bancaria. Si desea editar la información de su cuenta bancaria o agregar más cuentas, consulte la pestaña de información de pago.",
    },
  "Please select a photo ID document from List A": {
    en: "Please select a photo ID document from List A",
    es: "Por favor, seleccione un documento de identificación con foto de la lista A",
  },
  "Please select a document containing a photo ID from either List B or List C": {
    en: "Please select a document containing a photo ID from either List B or List C",
    es: "Por favor, seleccione un documento que contenga una identificación con foto de la lista B o la lista C",
  },
  "Since your employer is using E-Verify, at least one document in List A or List B + List C must contain a photo ID.":
    {
      en: "Since your employer is using E-Verify, at least one document in List A or List B + List C must contain a photo ID.",
      es: "Dado que su empleador está utilizando E-Verify, al menos un documento en la Lista A o Lista B + Lista C debe contener una identificación con foto.",
    },
};

export default translation;
