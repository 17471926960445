import React from "react";
import "./ModalSidebar.css";
import { TogglerConfigItem } from "./Toggler";
import warning from "dashboard/assets/yellow-warning.png";

type ModalSidebarOptionProps = {
  className?: string;
  option: TogglerConfigItem;
  isActive?: boolean;
  toggle: (path: string) => void;
};

const ModalSidebarOption: React.FC<ModalSidebarOptionProps> = ({
  className = "",
  option,
  isActive,
  toggle,
}) => {
  let wrapperClassName = "modal-sidebar-option " + className;
  if (isActive) wrapperClassName = wrapperClassName + " modal-sidebar-active";

  const handleClick = () => {
    toggle(option.path);
  };

  return (
    <div onClick={handleClick} className={wrapperClassName}>
      <div className="flex">
        <span>{option.label}</span>
        {option.alert && <img src={warning} style={{ height: 15, marginLeft: 5 }} />}
      </div>
    </div>
  );
};

type ModalSidebarProps = {
  config: TogglerConfigItem[];
  active: string | undefined;
  className?: string;
  wrapperClassName?: string;
  toggle: (path: string) => void;
};

const ModalSidebar: React.FC<ModalSidebarProps> = ({
  config,
  active,
  className,
  wrapperClassName,
  toggle,
}) => {
  return (
    <div className={className + " " + wrapperClassName + " modal-sidebar-wrapper"}>
      {config.map((option, index) => {
        const isActive = option.path === active;
        return (
          <ModalSidebarOption
            key={index}
            isActive={isActive}
            option={option}
            className={className}
            toggle={toggle}
          />
        );
      })}
    </div>
  );
};

export default ModalSidebar;
