/* eslint-disable @typescript-eslint/no-explicit-any */
import Bowser from "bowser";
import { Address, Company, CompanyUser, CustomFieldValue, FormAddress } from "dashboard/miter";
import { AsyncZipOptions, AsyncZippable, zip } from "fflate";
import { NavigateOptions, useLocation, useSearchParams } from "react-router-dom";
import { inflate } from "pako";
import React, { useCallback, useMemo } from "react";
import { DateTime } from "luxon";
import { ValidationRule } from "react-hook-form";
import { states } from "./lists";
import { capitalize } from "lodash";
import { TABLE_COLUMN_CUSTOM_FIELD_PREFIX } from "../../dashboard/src/utils/custom-fields";

export const WORK_HOURS_IN_WEEK = 40;
export const WORK_HOURS_IN_DAY = 8;

/** List of Miter supported timezones */
const selectableTimezones = {
  "America/New_York": "Eastern Time",
  "America/Chicago": "Central Time",
  "America/Denver": "Mountain Time",
  "America/Phoenix": "Arizona Time",
  "America/Los_Angeles": "Pacific Time",
  "America/Anchorage": "Alaska Time",
  "Pacific/Honolulu": "Hawaii Time",
  "Africa/Johannesburg": "South Africa Standard Time",
};

/** List of all timezones that could be geocoded in Miter */
const allTimezones = {
  "America/New_York": "Eastern Time",
  "America/Detroit": "Eastern Time",
  "America/Indiana/Indianapolis": "Eastern Time",
  "America/Indiana/Marengo": "Eastern Time",
  "America/Indiana/Petersburg": "Eastern Time",
  "America/Indiana/Vevay": "Eastern Time",
  "America/Indiana/Vincennes": "Eastern Time",
  "America/Indiana/Winamac": "Eastern Time",
  "America/Kentucky/Louisville": "Eastern Time",
  "America/Kentucky/Monticello": "Eastern Time",
  "America/Chicago": "Central Time",
  "America/Indiana/Knox": "Central Time",
  "America/Indiana/Tell_City": "Central Time",
  "America/Menominee": "Central Time",
  "America/North_Dakota/Beulah": "Central Time",
  "America/North_Dakota/Center": "Central Time",
  "America/North_Dakota/New_Salem": "Central Time",
  "America/Denver": "Mountain Time",
  "America/Boise": "Mountain Time",
  "America/Phoenix": "Arizona Time",
  "America/Los_Angeles": "Pacific Time",
  "America/Anchorage": "Alaska Time",
  "America/Juneau": "Alaska Time",
  "America/Metlakatla": "Alaska Time",
  "America/Nome": "Alaska Time",
  "America/Sitka": "Alaska Time",
  "America/Yakutat": "Alaska Time",
  "Pacific/Honolulu": "Hawaii Time",
  "Africa/Johannesburg": "South Africa Standard Time",
};

export const lookupTimezoneLabel = (value: string | null | undefined): string | undefined => {
  if (!value) return;
  return allTimezones[value];
};

/** Build timezone select options */
export const timezoneOptions = Object.entries(selectableTimezones).map(([value, label]) => ({
  value,
  label,
}));

export const getOS = (): string | null => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getOSName();
};

export const getBrowser = (): string | null => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getBrowserName();
};

// Convert fflate zip to a promise
export const zipAsync = (data: AsyncZippable, options?: AsyncZipOptions): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    zip(data, options || { level: 0 }, (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

/** Easily get the query parameters form the URL */
export const useQuery = (): URLSearchParams => {
  const { search } = useLocation();

  return useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
};

/** Pick the text color based on the background color */
export const pickTextColor = (bgColor: string, lightColor = "#fff", darkColor = "#000"): string => {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

/** Performs a localeCompare on the two input strings using base sensitivity ("Adiós" = "adios") */
export const baseSensitiveCompare = (a: string, b: string): number => {
  return a.localeCompare(b, undefined, { sensitivity: "base" });
};

/************************************************************************************************************
 * Rounds a number to the specified number of places. Defaults to 2 decimal places.
 ************************************************************************************************************/
export const roundTo = (value: number, places: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 = 2): number => {
  // Important to first clean floating point errors. Example:
  // 1.5 * 35.55 should equal 53.325, which we would round to 53.33 if rounding to 2 places
  // Due to floating point errors, 1.5 * 35.55 = 53.324999999999996, which rounds down to 53.32! No good!
  const multiplier = 10 ** places;
  return Math.round(cleanFloatingPointErrors(multiplier * value)) / multiplier;
};

const EPLOG = -1 * Math.log10(Number.EPSILON);

/************************************************************************************************************
 * Cleans floating point errors. For example, in node: 1.5 * 35.55 = 53.324999999999996, even though it
 * should just be 53.325. If we wanted to round the initial result to the nearest hundredth, we'd get 53.32,
 * even though that's wrong. So we clean the FPEs by first rounding at the 10th decimal point.
 ************************************************************************************************************/
export const cleanFloatingPointErrors = (value: number): number => {
  const num = Number(value);
  const mag = Math.abs(num); // Logs are undefined for negatives
  if (mag < 1e-6) return 0; // Under a threshold, just say it's 0 so that this function returns 0 on an input of floating point error itself

  // Larger numbers have less distinction between values at the same decimal place, so let's account for that
  // Example: there's literally less of a physical difference between 10000000.01 and 10000000.02 than there is between 1.01 and 1.02 given how floats work
  // Can use properties of logs to speed this up by pre-calculating the log of EPSILON
  // This is equivalent to Math.floor(Math.log10(1 / (mag * Number.EPSILON)))
  const prec = Math.floor(EPLOG - Math.log10(mag));

  // Lob off a couple more decimal places to be a bit more aggressive
  const fixer = Math.pow(10, prec - 2);
  return Math.round(fixer * num) / fixer;
};

/** If the value is not null or undefined, then return true */
export const notNullish = <T>(value: T | null | undefined | void): value is T => value != null;

/** If the value is truthy, then return true */
export const isTruthy = <T>(value: T | false | "" | 0 | null | undefined): value is T => !!value;

/** Validate that the phone number has a valid US/Canada area code */
export const validateUSCanadaAreaCode = (phone: string | undefined): boolean => {
  if (!phone) return false;

  const areaCode = phone.replaceAll("+1", "").slice(0, 3);
  return NorthAmericaPhoneAreaCodes.includes(areaCode);
};

/** Validate phone number area codes */
export const validatePhoneAreaCode = (phone: string | undefined): boolean => {
  if (phone?.includes("+1")) {
    return validateUSCanadaAreaCode(phone);
  }

  return true;
};

/** Decompress serialized json using pako */
export const deserialize = (s: string): any => {
  return JSON.parse(inflate(Buffer.from(s, "base64"), { to: "string" }));
};

/** Unwind custom field values and prepend custom field prefix */
export const unwindCustomFieldValuesAndPrependPrefix = (customFieldObject: {
  custom_field_values: CustomFieldValue[];
}): { [key: string]: string | boolean | number } => {
  const customFieldValues = customFieldObject.custom_field_values || [];

  return customFieldValues.reduce((acc, cf) => {
    const key = `${TABLE_COLUMN_CUSTOM_FIELD_PREFIX}${cf.custom_field_id}`;
    return { ...acc, [key]: cf.value };
  }, {});
};

/** Deparamterize string (i.e. removed underscores and capitalize in sentence case) */
export const deparameterize = (str: string | undefined | null): string => {
  if (!str) return "";

  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const deparameterizeCapitalizeFirstLetter = (str: string | undefined | null): string => {
  if (!str) return "";
  return capitalize(str.split("_").join(" "));
};

/** Split camelCase string with spaces */
export const splitCamelCase = (value: string): string => {
  return value.replace(/([A-Z])/g, " $1").trim();
};

/** Custom field type to AG Grid data type map */
export const customFieldAgGridTypeMap = {
  text: "string",
  number: "number",
  select: "string",
  date: "date",
  checkbox: "boolean",
};

export const NorthAmericaPhoneAreaCodes = [
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "209",
  "210",
  "212",
  "213",
  "214",
  "215",
  "216",
  "217",
  "218",
  "219",
  "220",
  "223",
  "224",
  "225",
  "226",
  "227",
  "228",
  "229",
  "231",
  "234",
  "236",
  "239",
  "240",
  "248",
  "249",
  "250",
  "251",
  "252",
  "253",
  "254",
  "256",
  "260",
  "262",
  "267",
  "269",
  "270",
  "272",
  "274",
  "276",
  "278",
  "279",
  "281",
  "283",
  "289",
  "301",
  "302",
  "303",
  "304",
  "305",
  "306",
  "307",
  "308",
  "309",
  "310",
  "312",
  "313",
  "314",
  "315",
  "316",
  "317",
  "318",
  "319",
  "320",
  "321",
  "323",
  "325",
  "326",
  "327",
  "330",
  "331",
  "332",
  "334",
  "336",
  "337",
  "339",
  "340",
  "341",
  "343",
  "346",
  "347",
  "351",
  "352",
  "360",
  "361",
  "364",
  "365",
  "367",
  "369",
  "380",
  "385",
  "386",
  "401",
  "402",
  "403",
  "404",
  "405",
  "406",
  "407",
  "408",
  "409",
  "410",
  "412",
  "413",
  "414",
  "415",
  "416",
  "417",
  "418",
  "419",
  "423",
  "424",
  "425",
  "430",
  "431",
  "432",
  "434",
  "435",
  "437",
  "438",
  "440",
  "442",
  "443",
  "445",
  "447",
  "448",
  "450",
  "458",
  "463",
  "464",
  "469",
  "470",
  "475",
  "478",
  "479",
  "480",
  "484",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "507",
  "508",
  "509",
  "510",
  "512",
  "513",
  "514",
  "515",
  "516",
  "517",
  "518",
  "519",
  "520",
  "530",
  "531",
  "534",
  "539",
  "540",
  "541",
  "548",
  "551",
  "557",
  "559",
  "561",
  "562",
  "563",
  "564",
  "567",
  "570",
  "571",
  "572",
  "573",
  "574",
  "575",
  "579",
  "580",
  "581",
  "582",
  "585",
  "586",
  "587",
  "590",
  "601",
  "602",
  "603",
  "604",
  "605",
  "606",
  "607",
  "608",
  "609",
  "610",
  "612",
  "613",
  "614",
  "615",
  "616",
  "617",
  "618",
  "619",
  "620",
  "623",
  "626",
  "627",
  "628",
  "629",
  "630",
  "631",
  "636",
  "639",
  "640",
  "641",
  "646",
  "647",
  "650",
  "651",
  "656",
  "657",
  "659",
  "660",
  "661",
  "662",
  "667",
  "669",
  "670",
  "671",
  "678",
  "679",
  "680",
  "681",
  "682",
  "684",
  "689",
  "701",
  "702",
  "703",
  "704",
  "705",
  "706",
  "707",
  "708",
  "709",
  "712",
  "713",
  "714",
  "715",
  "716",
  "717",
  "718",
  "719",
  "720",
  "724",
  "725",
  "726",
  "727",
  "730",
  "731",
  "732",
  "734",
  "737",
  "740",
  "743",
  "747",
  "751",
  "754",
  "757",
  "760",
  "762",
  "763",
  "764",
  "765",
  "769",
  "770",
  "772",
  "773",
  "774",
  "775",
  "778",
  "779",
  "780",
  "781",
  "782",
  "785",
  "786",
  "787",
  "800",
  "801",
  "802",
  "803",
  "804",
  "805",
  "806",
  "807",
  "808",
  "810",
  "812",
  "813",
  "814",
  "815",
  "816",
  "817",
  "818",
  "819",
  "820",
  "822",
  "825",
  "828",
  "830",
  "831",
  "832",
  "833",
  "835",
  "838",
  "839",
  "840",
  "843",
  "844",
  "845",
  "847",
  "848",
  "850",
  "854",
  "855",
  "856",
  "857",
  "858",
  "859",
  "860",
  "862",
  "863",
  "864",
  "865",
  "866",
  "867",
  "870",
  "872",
  "873",
  "877",
  "878",
  "880",
  "881",
  "882",
  "883",
  "884",
  "885",
  "886",
  "887",
  "888",
  "889",
  "901",
  "902",
  "903",
  "904",
  "905",
  "906",
  "907",
  "908",
  "909",
  "910",
  "912",
  "913",
  "914",
  "915",
  "916",
  "917",
  "918",
  "919",
  "920",
  "925",
  "928",
  "929",
  "930",
  "931",
  "934",
  "935",
  "936",
  "937",
  "938",
  "940",
  "941",
  "943",
  "945",
  "947",
  "949",
  "951",
  "952",
  "954",
  "956",
  "959",
  "970",
  "971",
  "972",
  "973",
  "975",
  "978",
  "979",
  "980",
  "984",
  "985",
  "986",
  "989",
];

/** Convert meters to miles */
export const metersToMiles = (meters: number | undefined): number => {
  return roundTo((meters || 0) * 0.000621371, 2);
};

export class MapWithDefault<K, V> extends Map<K, V> {
  defaultVal: V;

  constructor(defaultVal: V) {
    super();
    this.defaultVal = defaultVal;
  }

  override get(key: K | undefined | null): V {
    return (key && super.get(key)) || this.defaultVal;
  }
}

/** Convert seconds to hours, minutes, seconds */
export const readableSeconds = (
  seconds: number | undefined | null,
  opts?: { abbreviate?: boolean }
): string => {
  if (seconds == null) return "";

  const { abbreviate } = opts || {};

  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  // Adjusting the text for each time component based on its value
  const hoursText = h === 1 ? "hour" : "hours";
  const minutesText = m === 1 ? "minute" : "minutes";
  const secondsText = s === 1 ? "second" : "seconds";

  // Determine display text based on abbreviation preference
  const hDisplay = h > 0 ? `${h}${abbreviate ? "h" : ` ${hoursText}`} ` : "";
  const mDisplay = m > 0 ? `${m}${abbreviate ? "m" : ` ${minutesText}`} ` : "";
  const sDisplay = s > 0 ? `${s}${abbreviate ? "s" : ` ${secondsText}`} ` : "";

  // Joining the parts together, handling abbreviation and empty values
  const totalDisplay = [hDisplay, mDisplay, sDisplay].filter((x) => x).join(abbreviate ? " " : ", ");
  return totalDisplay || "-";
};

/** Generate a random alphanumeric string with x characters */
export const randString = (length: number): string => {
  return Array.from(Array(length), () => Math.floor(Math.random() * 36).toString(36)).join("");
};

/** Yes / no boolean options */
export const yesNoOptions = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];
/** Get current timezone */
export const getTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/** Replace all polyfill */
export const replaceAllPolyfill = (str: string, find: string, replace: string): string => {
  return str.replace(new RegExp(find, "g"), replace);
};

/** Get team member's tenure */
export const getTenure = (startDate: string | undefined | null): number => {
  if (!startDate) return 0;

  const startDateLuxon = DateTime.fromISO(startDate);

  const tenure = DateTime.now().diff(startDateLuxon, "days").days;
  if (!tenure) return 0;

  return tenure;
};

/** Build string from years, months, days */
export const buildTenureString = (tenure: {
  years?: number | undefined;
  months?: number | undefined;
  days?: number | undefined;
}): string => {
  const { years, months, days } = tenure;
  const array: string[] = [];

  if (years) array.push(`${years} year${years > 1 ? "s" : ""}`);
  if (months) array.push(`${months} month${months > 1 ? "s" : ""}`);
  if (days) array.push(`${days} day${days > 1 ? "s" : ""}`);

  return array.join(", ");
};

/** Minimum value validator */
export const validateMin =
  (min: number) =>
  (value: number | undefined): ValidationRule<number | string> | undefined => {
    if (value == null || value >= min) return undefined;
    return `Must be at least ${min}`;
  };

/** Maximum value validator */
export const validateMax =
  (max: number) =>
  (value: number | undefined): ValidationRule<number | string> | undefined => {
    if (value == null || value <= max) return undefined;
    return `Must be at most ${max}`;
  };

/** Convert address from a FormAddress (i.e. state is an option) to an Address (state is a string) */
export const formatToAddress = (address: FormAddress | Address | undefined): Address | undefined => {
  if (!address) return address;

  return {
    ...address,
    line2: address?.line2 === "" ? null : address?.line2,
    state: typeof address?.state === "string" ? address.state : address?.state?.value,
  };
};

/** Convert address from an Address (i.e. state is a string) to a FormAddress (state is an option) */
export const formatToFormAddress = (address?: Address | null): FormAddress | undefined => {
  if (!address) return undefined;

  return {
    ...address,
    state: {
      label: states.find((s) => s.abbreviation === address.state)?.abbreviation || "",
      value: address.state,
    },
  };
};

export const isEmptyAddress = (address?: Address | null): boolean => {
  return (
    !address || (!address.line1 && !address.line2 && !address.city && !address.state && !address.postal_code)
  );
};

export const isValidAddress = (address?: $TSFixMe): boolean => {
  return (
    address &&
    address.line1 &&
    address.line1 !== "" &&
    address.city &&
    address.city !== "" &&
    address.state &&
    address.state !== "" &&
    address.postal_code &&
    address.postal_code !== ""
  );
};

/** Get last four digits from SSN alias */
export const getLastFour = (ssn: string | undefined): string | undefined => {
  if (!ssn) return;
  return ssn.slice(-4);
};

export const poBoxRegex =
  /^\s*(.*((p|post)[-.\s]*(o|0|off|office)[-.\s]*(b|box|bin)[-.\s]*)|.*((p|post)[-.\s]*(o|0|off|office)[-.\s]*)|.*((p|post)[-.\s]*(b|box|bin)[-.\s]*)|(box|bin)[-.\s]*)(#|num|number)?\s*\d+/i;

/** Join an array of strings with an and */
export const joinWithAnd = (array: string[] | undefined): string => {
  if (!array) return "";
  if (array.length === 0) return "";
  if (array.length === 1) return array[0]!;
  if (array.length === 2) return `${array[0]} and ${array[1]}`;
  return `${array.slice(0, -1).join(", ")}, and ${array.slice(-1)}`;
};

/** Join an array of strings with an or */
export const joinWithOr = (array: string[] | undefined): string => {
  if (!array) return "";
  if (array.length === 0) return "";
  if (array.length === 1) return array[0]!;
  if (array.length === 2) return `${array[0]} or ${array[1]}`;
  return `${array.slice(0, -1).join(", ")}, or ${array.slice(-1)}`;
};

/** Join react elements with a different react element as a delimiter */
export const joinWithDelimiter = (
  array: React.ReactNode[] | undefined,
  delimiter: React.ReactNode
): React.ReactNode => {
  const result: React.ReactNode[] = [];
  if (!array) return result;
  if (array.length === 0) return result;
  if (array.length === 1) return array[0]!;

  array.forEach((element, index) => {
    result.push(element);
    if (index !== array.length - 1) result.push(delimiter);
  });

  return result;
};

/** Parse a time string in the following possible formats: 09:00AM, 9:00AM, 9:00 AM, and 13:00 and return the hour and minutes */
export const parseClockInTime = (
  time: string | undefined,
  activeCompany?: Company | null
): { hour: number; minute: number; second: number } => {
  if (!time)
    if (activeCompany?.settings?.timesheets?.default_clock_in_time) {
      /**  Use default clock in time if it exists or default to 9:00 AM */
      const hours = activeCompany.settings.timesheets.default_clock_in_time.split(":")[0];
      const minutes = activeCompany.settings.timesheets.default_clock_in_time.split(":")[1];

      return { hour: parseInt(hours || "9"), minute: parseInt(minutes || "0"), second: 0 };
    } else {
      return { hour: 9, minute: 0, second: 0 };
    }

  // Clean up the input by removing extra spaces
  const cleanTime = time.replace(/\s+/g, "");
  let dt;

  // It has seconds if there are multiple colons
  const hasSeconds = cleanTime.split(":").length > 2;

  if (hasSeconds) {
    dt = DateTime.fromFormat(cleanTime, "h:mm:ssa");

    if (!dt.isValid) {
      dt = DateTime.fromFormat(cleanTime, "H:mm:ss");
      if (!dt.isValid) throw new Error(`Invalid time format: ${time}`);
    }
  } else {
    // Try parsing as 12-hour time
    dt = DateTime.fromFormat(cleanTime, "h:mma");

    if (!dt.isValid) {
      // If parsing as 12-hour time failed, try parsing as 24-hour time
      dt = DateTime.fromFormat(cleanTime, "H:mm");
      if (!dt.isValid) throw new Error(`Invalid time format: ${time}`);
    }
  }

  const hour = dt.hour;
  const minute = dt.minute;
  const second = dt.second || 0;

  return { hour, minute, second };
};

/** Deep remove properties from an object that have empty string values */
export const removeEmptyStringProps = (obj: Record<string, unknown>): Record<string, unknown> => {
  const result: Record<string, unknown> = {};
  for (const key in obj) {
    const value = obj[key];
    if (typeof value === "string" && value === "") continue;
    if (typeof value === "object" && value !== null) {
      result[key] = removeEmptyStringProps(value as Record<string, unknown>);
      continue;
    }
    result[key] = value;
  }
  return result;
};

/** Checks if string is empty */
export const isEmptyString = (str: string | undefined): boolean => {
  return !str || str.trim() === "";
};

/** State options - sorted ascending by abbreviation */
export const stateOptions = states
  .sort((s1, s2) => {
    return s1.abbreviation.localeCompare(s2.abbreviation);
  })
  .map((state) => {
    return { value: state.abbreviation, label: state.abbreviation };
  });

/** State abbreviations set */
export const stateAbbreviations = new Set(states.map((state) => state.abbreviation));

/** Is valid state */
export const isValidState = (state: string | undefined): boolean => {
  if (!state) return false;
  return stateAbbreviations.has(state);
};

/**
 * Converts seconds into a human-readable format of days, hours, and minutes.
 *
 * @param seconds - The total number of seconds.
 * @returns A string representing the duration in days, hours, and minutes.
 *
 * @example
 * ```typescript
 * console.log(convertSeconds(90061)); // Outputs: "1 day, 1 hour, 1 minute"
 * console.log(convertSeconds(172800)); // Outputs: "2 days, 0 hours, 0 minutes"
 * ```
 */
export const convertSeconds = (seconds: number | undefined): string => {
  if (!seconds) return "";

  const SECONDS_IN_A_MINUTE = 60;
  const SECONDS_IN_AN_HOUR = SECONDS_IN_A_MINUTE * 60;
  const SECONDS_IN_A_DAY = SECONDS_IN_AN_HOUR * 24;

  let remainingSeconds = seconds;

  const days = Math.floor(remainingSeconds / SECONDS_IN_A_DAY);
  remainingSeconds -= days * SECONDS_IN_A_DAY;

  const hours = Math.floor(remainingSeconds / SECONDS_IN_AN_HOUR);
  remainingSeconds -= hours * SECONDS_IN_AN_HOUR;

  const minutes = Math.floor(remainingSeconds / SECONDS_IN_A_MINUTE);

  const daysStr = `${days} ${days === 1 ? "day" : "days"}`;
  const hoursStr = `${hours} ${hours === 1 ? "hour" : "hours"}`;
  const minutesStr = `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;

  return `${daysStr}, ${hoursStr}, ${minutesStr}`;
};

/**
 * Converts seconds into an object format of days, hours, and minutes.
 *
 * @param seconds - The total number of seconds.
 * @returns An object with properties representing the days, hours, and minutes.
 *
 * @example
 * ```typescript
 * console.log(convertSecondsToObject(90061));
 * // Outputs: { days: 1, hours: 1, minutes: 1 }
 *
 * console.log(convertSecondsToObject(172800));
 * // Outputs: { days: 2, hours: 0, minutes: 0 }
 * ```
 */
export const convertSecondsToObject = (
  seconds: number | undefined
): { days: number; hours: number; minutes: number } => {
  if (!seconds) return { days: 0, hours: 0, minutes: 0 };

  const SECONDS_IN_A_MINUTE = 60;
  const SECONDS_IN_AN_HOUR = SECONDS_IN_A_MINUTE * 60;
  const SECONDS_IN_A_DAY = SECONDS_IN_AN_HOUR * 24;

  let remainingSeconds = seconds;

  const days = Math.floor(remainingSeconds / SECONDS_IN_A_DAY);
  remainingSeconds -= days * SECONDS_IN_A_DAY;

  const hours = Math.floor(remainingSeconds / SECONDS_IN_AN_HOUR);
  remainingSeconds -= hours * SECONDS_IN_AN_HOUR;

  const minutes = Math.floor(remainingSeconds / SECONDS_IN_A_MINUTE);

  return { days, hours, minutes };
};

/** Get the dates between two dates */
export const dayBetween = (
  start: string | number | Date,
  end: Date,
  includeWeekends?: boolean
): DateTime[] => {
  const arr: DateTime[] = [];
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    if (!includeWeekends && (dt.getDay() === 0 || dt.getDay() === 6)) {
      continue;
    }

    arr.push(DateTime.fromJSDate(new Date(dt)));
  }
  return arr;
};

/** Generate a random UUID */
export const generateUUID = (): string => {
  return crypto.randomUUID();
};

/** Utility type to require all keys */
export type RequireKeys<T extends Record<string, unknown>, K extends keyof T> = Required<Pick<T, K>> &
  Omit<T, K>;

/** Convert seconds into years, months and days string */
export function convertSecondsToYearsMonthsDays(seconds: number): string {
  if (typeof seconds !== "number" || isNaN(seconds) || seconds < 0) return "-";
  if (seconds === 0) return "0 seconds";

  // Approximate conversions:
  const SECONDS_PER_MINUTE = 60;
  const MINUTES_PER_HOUR = 60;
  const HOURS_PER_DAY = 24;
  const DAYS_PER_MONTH = 30.44; // Average number of days in a month
  const DAYS_PER_YEAR = 365.24; // Including leap years

  let remainingSeconds = seconds;

  const years = Math.floor(
    remainingSeconds / (SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY * DAYS_PER_YEAR)
  );
  remainingSeconds -= years * SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY * DAYS_PER_YEAR;

  const months = Math.floor(
    remainingSeconds / (SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY * DAYS_PER_MONTH)
  );
  remainingSeconds -= months * SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY * DAYS_PER_MONTH;

  const days = Math.floor(remainingSeconds / (SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY));

  const result: string[] = [];
  if (years > 0) result.push(`${years} years`);
  if (months > 0) result.push(`${months} months`);
  if (days > 0) result.push(`${days} days`);

  return result.join(", ");
}

/** Theme */
export const theme = {
  colors: {
    purple: "#4D55BB",
    green: "#27AE60",
    grey: "#CCC",
    red: "#E02D2D",
    yellow: "#f0c643",
  },
};

/** Utility to find nearest scrollable parent */
export const getScrollableParent = (node: HTMLElement | null): HTMLElement | null => {
  if (node === null || node === document.body) {
    return null;
  }

  const computedStyle = window.getComputedStyle(node);
  if (
    (node.scrollHeight > node.clientHeight &&
      (computedStyle.overflow === "auto" || computedStyle.overflow === "scroll")) ||
    (node.scrollWidth > node.clientWidth &&
      (computedStyle.overflowX === "auto" || computedStyle.overflowX === "scroll"))
  ) {
    return node;
  }

  return getScrollableParent(node.parentNode as HTMLElement);
};

/**
 * Recursively converts an object into an array of paths to the leaves of the object.
 *
 * Example:
 *  const obj = {
 *    a: {  b: 1, c: 2 },
 *    d: { e: { f: 3 } },
 *    g: 4,
 *  };
 *
 *  extractPaths(obj) => ["a.b", "a.c", "d.e.f", "g"]
 */
export const extractPaths = (obj: Record<string, $TSFixMe>, currentPath = "", delimiter = "."): string[] => {
  let paths: string[] = [];

  for (const key in obj) {
    const newPath = currentPath ? `${currentPath}${delimiter}${key}` : key;

    if (typeof obj[key] === "object" && obj[key] !== null && !(obj[key] instanceof Array)) {
      paths = paths.concat(extractPaths(obj[key], newPath, delimiter));
    } else {
      paths.push(newPath);
    }
  }

  return paths;
};

/**
 * Flatten keys
 *
 * Flatten's the keys of an object into a single string delimited by the provided delimiter.
 * */
export const flattenKeys = (obj: Record<string, $TSFixMe>, delimiter = "."): string[] => {
  return extractPaths(obj, "", delimiter);
};

/**
 * Builds the name from a company user using first + last, or email if first + last is not available.
 */
export const buildCompanyUserName = (user: CompanyUser | null | undefined): string => {
  if (!user) return "";

  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }

  return user.email || "";
};

/** Determine if user/role/TM is a Miter rep of some kind */
export const isMiterRep = <T extends { email?: string | null; miter_admin?: boolean }>(
  user: T | null | undefined
): boolean => {
  if (!user) return false;
  if (user.miter_admin) return true;
  if (!user.email) return false;
  return /@miter(?:hr)?\.com$/i.test(user.email);
};

export const sleep = (ms: number): Promise<void> => {
  return new Promise((res) => setTimeout(res, ms));
};

/** Checks if now falls in between the given start and end dates */
export const isActive = (start: string | null | undefined, end: string | null | undefined): boolean => {
  const now = DateTime.now().toISODate();
  if (start && start > now) return false;
  if (end && end < now) return false;
  return true;
};

type SetSearchParamsInputs = {
  /** If true, the resulting setter function will use `replace: true` by default in the underlying react-router-dom navigate call. Can be overwritten in the moment of calling `setSearchParams`. */
  replaceInHistory?: boolean;
};

type SetSearchParamsFunc = (
  /** Object interface for the params. Set to null or undefined to delete from the URL. */
  params: Record<string, string | null | undefined>,
  opts?: NavigateOptions & {
    /** Set to true to use the entire provided input as a replacement for the existing search params. Implicitly deletes all params not specified. */
    replaceParams?: boolean;
  }
) => void;

/** Enables setting search/query params in the URL one-off using an object interface instead of react-router-dom's default of replacing the entire thing via a string interface */
export const useSetSearchParams = (inputs?: SetSearchParamsInputs): SetSearchParamsFunc => {
  const [, setSearchParams] = useSearchParams();

  return useCallback(
    (
      params: Record<string, string | null | undefined>,
      opts?: NavigateOptions & { replaceParams?: boolean }
    ) => {
      const { replaceParams, ...finalOpts } = opts || {};
      if (finalOpts.replace === undefined) {
        finalOpts.replace = inputs?.replaceInHistory;
      }
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(replaceParams ? undefined : prev);
        for (const [key, val] of Object.entries(params)) {
          if (val != null) {
            newParams.set(key, val);
          } else {
            newParams.delete(key);
          }
        }
        return newParams;
      }, finalOpts);
    },
    [setSearchParams, inputs?.replaceInHistory]
  );
};

type UseEnhancedSearchParamsReturn<T extends string> = {
  searchParams: URLSearchParams;
  parsedSearchParams: Partial<Record<T, string>>;
  setSearchParams: SetSearchParamsFunc;
};

/** Wrapper for accessing the raw search param query object, the parsed params (in an Object interface), and the enhanced setter described in `useSetSearchParams` */
export const useEnhancedSearchParams = <T extends string>(
  inputs?: SetSearchParamsInputs
): UseEnhancedSearchParamsReturn<T> => {
  const [searchParams] = useSearchParams();
  const setSearchParams = useSetSearchParams(inputs);

  const parsedSearchParams = useMemo(() => {
    const result: Record<string, string> = {};
    for (const [key, val] of searchParams.entries()) {
      result[key] = val;
    }
    return result as Partial<Record<T, string>>;
  }, [searchParams]);

  return { searchParams, parsedSearchParams, setSearchParams };
};

/** Flatmap with unique values - has a callback for equality checking */
export const uniqueFlatMap = <T, R = T>(arrays: T[][], callback?: (element: T) => R): T[] => {
  const uniqueValues = new Set<R>();
  const result: T[] = [];

  arrays.forEach((array) => {
    array.forEach((element) => {
      // Determine the value for uniqueness check
      const checkValue = callback ? callback(element) : (element as unknown as R);

      if (!uniqueValues.has(checkValue)) {
        uniqueValues.add(checkValue);
        result.push(element); // Push the original element
      }
    });
  });

  return result;
};

/** Get a name's initials */
export const getInitials = (name: string | undefined): string => {
  if (!name) return "";

  return name
    .split(" ")
    .map((word) => word.charAt(0))
    .join("");
};

/** Deparamterize keys in a nested object */
export const deparameterizeKeys = (obj: Record<string, unknown>): Record<string, unknown> => {
  const result: Record<string, unknown> = {};
  for (const key in obj) {
    const value = obj[key];
    const newKey = deparameterize(key);
    if (typeof value === "object" && value !== null) {
      result[newKey] = deparameterizeKeys(value as Record<string, unknown>);
      continue;
    }
    result[newKey] = value;
  }
  return result;
};

/**
 * EEO options
 */

export const eeoEthnicityCategories = [
  "White (Not Hispanic or Latino)",
  "Black or African American (Not Hispanic or Latino)",
  "Hispanic or Latino",
  "American Indian or Alaska Native (Not Hispanic or Latino)",
  "Asian (Not Hispanic or Latino)",
  "Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)",
  "Two or More Races (Not Hispanic or Latino)",
  "Prefer Not To Disclose",
];

export const eeoJobCategories = [
  "Executive/Senior Level Officials and Managers",
  "First/Mid Level Officials and Managers",
  "Professionals",
  "Technicians",
  "Sales Workers",
  "Administrative Support Workers",
  "Craft Workers",
  "Operatives",
  "Laborers and Helpers",
  "Service Workers",
  "Prefer Not To Disclose",
];

export const eeoGenderCategories = ["Male", "Female", "Prefer Not To Disclose"];

export const eeoVeteranStatusCategories = [
  "Identifies as a disabled veteran",
  "Identifies as a protected veteran",
  "Identifies as a recently separated veteran",
  "Identifies as an active duty wartime or campaign badge veteran",
  "Identifies as an armed forces service medal veteran",
  "Not a protected veteran",
  "Prefer Not To Disclose",
];

export const eeoMaritalStatusCategories = [
  "Single",
  "Married",
  "Divorced",
  "Widowed",
  "Separated",
  "Prefer Not To Disclose",
];

export const eeoDisabilityStatusCategories = [
  "Yes, I have a disability (or previously had a disability)",
  "No, I do not have a disability and have not had one in the past",
  "Prefer Not To Disclose",
];

/**
 * This function verifies that the passed URL is valid and doesn't include any subpaths.
 */
export const isValidWebsiteURL = (url: string): boolean => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );

  return pattern.test(url);
};

export const EEO_FIELDS = [
  "job_category",
  "gender",
  "ethnicity",
  "marital_status",
  "disability_status",
  "veteran_status",
];

export const EE_EEO_FIELDS = EEO_FIELDS.filter((field) => field !== "job_category");
