import React, { FC } from "react";
import NavItems from "./NavItems";

import "./DesktopNav.css";

const DesktopNav: FC = () => {
  return (
    <div className="desktop-console-sidebar">
      <div className="sidebar-bottom">
        <NavItems device={"desktop"} />
      </div>
    </div>
  );
};

export default DesktopNav;
