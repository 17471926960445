import React, { useEffect, useState, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";

import TeamMemberToggler from "./TeamMemberToggler";
import MobileNav from "./nav/MobileNav";

import "./Header.css";

const Header: FC = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setShowMobileNav(false);
  }, [pathname]);

  return (
    <div className="portal-header">
      <div className="header-logo">
        <Link to="/">Miter</Link>
      </div>
      <div className="team-member-toggler">
        <TeamMemberToggler />
      </div>
      <div className="mobile-menu-toggle">
        <button
          onClick={() => {
            setShowMobileNav(!showMobileNav);
          }}
        >
          <FaBars />
        </button>
      </div>
      <div className={"mobile-nav-wrapper " + (showMobileNav ? "show-mobile-nav" : "")}>
        <MobileNav />
      </div>
    </div>
  );
};

export default Header;
