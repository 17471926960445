import React, { useCallback, useEffect, useState } from "react";
import { Notifier, WizardScreen, Loader } from "ui";
import styles from "dashboard/components/team-members/TeamMemberWizard.module.css";
import formStyles from "../forms/Forms.module.css";
import { FormProvider, useForm } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { SinglePageFormWizardScreen } from "../forms/SinglePageFormWizardScreen";
import { cleanCustomFieldValueParams } from "miter-utils";
import { DateTime } from "luxon";
import ObjectID from "bson-objectid";
import {
  MiterAPI,
  CreateFormSubmissionParams,
  UpdateFormSubmissionParams,
  AggregatedTeamMemberOnboardingTask,
  FormSubmission,
  FormOnboardingTask,
  MiterError,
} from "dashboard/miter";
import { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import { saveFormsAndUpdateAnswers } from "../forms/utils";
import { useTranslation } from "react-i18next";
import { markTaskComplete } from "./utils";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  refetchTeamMember: () => Promise<void>;
  onboardingTask: FormOnboardingTask & AggregatedTeamMemberOnboardingTask;
  updateOnboardingChecklistTask: (task: AggregatedTeamMemberOnboardingTask) => Promise<void>;
};

export const FormWizardScreen: React.FC<Props> = (props) => {
  const { onboardingTask, name, teamMember, refetchTeamMember, updateOnboardingChecklistTask } = props;

  const { t } = useTranslation<$TSFixMe>();
  const { curIndex, handleComplete, screens, setCanNext, setNextButtonText } = useWizard();
  const { form, form_id, form_submission_id, form_submission } = onboardingTask;
  const [formSubmission, setFormSubmission] = useState<FormSubmission | undefined>(undefined);
  const [formSubmissionLoading, setFormSubmissionLoading] = useState(true);

  const getFormSubmission = useCallback(async () => {
    if (!teamMember || !form) {
      return;
    }
    if (formSubmissionLoading) {
      try {
        let res: FormSubmission & MiterError;
        if (!form_submission_id) {
          res = await MiterAPI.form_submissions.create({
            form_id,
            company_id: form.company_id,
            team_member_id: teamMember._id,
            user_id: teamMember.user,
            role_id: teamMember.role?._id,
            status: "requested",
            answers: [],
          });
          await updateOnboardingChecklistTask({
            ...onboardingTask,
            form_submission_id: res._id,
          });
          await refetchTeamMember();
        } else {
          res = await MiterAPI.form_submissions.retrieve(form_submission_id);
        }
        if (res.error) {
          throw new Error(t("Error retrieving form submission: ") + res.error);
        }
        setFormSubmission(res);
      } catch (e: $TSFixMe) {
        console.error("Error getting form submission", e);
      } finally {
        setFormSubmissionLoading(false);
      }
    }
  }, [
    form_submission_id,
    form_id,
    teamMember,
    updateOnboardingChecklistTask,
    onboardingTask,
    formSubmissionLoading,
    form,
    refetchTeamMember,
    t,
  ]);

  useEffect(() => {
    if (form_submission) {
      setFormSubmission(form_submission);
      setFormSubmissionLoading(false);
    } else {
      getFormSubmission();
    }
  }, [form_submission, getFormSubmission]);

  const readonly = formSubmission?.status === "completed" && !form?.allow_editable_submissions;

  const reactForm = useForm({
    mode: "all",
  });

  const { errors, handleSubmit, trigger } = reactForm;

  useEffect(() => {
    setNextButtonText(curIndex + 1 >= screens.length ? t("Save and exit") : t("Save and continue"));
    setCanNext(formSubmission?.status === "completed" || Object.keys(errors).length === 0);
  }, [errors, setCanNext, setNextButtonText, curIndex, screens.length, t, formSubmission?.status]);

  useEffect(() => {
    if (formSubmissionLoading) return;
    // Trigger the form validation after submission is done loading and form is rendered
    trigger();
  }, [trigger, formSubmissionLoading]);

  /** Clean the form data for saving it in the backend */
  const prepareFormSubmissionParams = (formData): CreateFormSubmissionParams | UpdateFormSubmissionParams => {
    if (!form) {
      throw new Error("Form not found");
    }

    const answers = Object.keys(formData)
      // Sort into the correct order based on index
      .sort((a, b) => {
        const fieldA = form.components.findIndex((f) => f._id === a);
        const fieldB = form.components.findIndex((f) => f._id === b);

        return fieldA - fieldB;
      })
      // use string regex to determine key is a valid mongo id
      .filter((key) => key.match(/^[0-9a-fA-F]{24}$/))
      .map((key) => ({
        _id: ObjectID().toHexString(),
        form_field_id: key,
        value: cleanCustomFieldValueParams(formData[key]),
      }));

    return {
      ...reactForm.getValues(),
      company_id: form?.company_id,
      form_id: form?._id,
      ...(teamMember ? { team_member_id: teamMember._id } : {}),
      user_id: teamMember?.user,
      completed_at: DateTime.now().toSeconds(),
      status: "completed",
      answers,
    };
  };

  /** Save the form submission */
  const saveFormSubmission = async (data) => {
    if (!form) {
      return;
    }

    try {
      const params = prepareFormSubmissionParams(data);

      const res = formSubmission
        ? await MiterAPI.form_submissions.update(formSubmission?._id, params as UpdateFormSubmissionParams)
        : await MiterAPI.form_submissions.create(params as CreateFormSubmissionParams);

      if (res.error) throw new Error(t("Error saving form submission: ") + res.error);

      const fullName = teamMember?.full_name || teamMember?.role?.full_name || "Miter user";
      const title = teamMember?.title || teamMember?.role?.email || "Team member";
      // Save any esignature fields
      await saveFormsAndUpdateAnswers({
        formSubmissionId: res._id,
        params,
        formItem: form,
        account: {
          company_id: form.company_id,
          full_name: fullName,
          roleId: teamMember?.role?._id,
          userId: teamMember?.user || "",
          accountType: "team_member",
          title,
        },

        deviceType: "desktop",
      });
      Notifier.success(t("Form submission saved"));
    } catch (e: $TSFixMe) {
      Notifier.error(t("Error saving form submission: ") + e.message);
      console.error("Error saving form submission", e);

      // Re-throw the error so the wizard doesn't continue
      throw e;
    }
  };

  const onNext = async () => {
    await handleSubmit(async (data) => {
      if (!readonly) {
        await saveFormSubmission(data);
      }
      if (curIndex + 1 >= screens.length) {
        handleComplete();
      }
      await markTaskComplete(onboardingTask, updateOnboardingChecklistTask);
      await refetchTeamMember();
    })();
  };

  return (
    <WizardScreen name={name} key={name + onboardingTask._id} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>{t("Fill out form")}</h2>
          <p className={styles["subheader-description"]}>
            {t("Please fill out the form to complete the onboarding process")}
          </p>
        </div>
        {formSubmissionLoading && <Loader />}
        {!formSubmissionLoading && formSubmission && form && (
          <div className={formStyles["content"]}>
            <FormProvider {...reactForm}>
              <SinglePageFormWizardScreen
                formItem={form}
                formSubmission={formSubmission}
                readonly={readonly}
              />
            </FormProvider>
          </div>
        )}
      </div>
    </WizardScreen>
  );
};
