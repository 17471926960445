import React, { useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import FilterSelector from "./FilterSelector";
import DateFilter from "./DateFilter";
import { DateTime } from "luxon";

import "../Table.css";
import "../TableButtons.css";
import "./Filter.css";

import _ from "lodash";
import { castToFilterValue, ComparisonType, FilterType } from "./types";
import { ActionButtonConfig, ColumnConfig, ColumnConfigs } from "../types";
import ControlledInputWithCursorPosition from "../../form/ControlledInputWithCursor";

/*
Filter.js encapsulates the component that we use to generate the filter pane for all of our tables.

This component works by pulling the current filter state from the parent components and sendinb
back a modified filter state object that the parent component can use to update it's filter state
and fetch new data.

To generate the available filters, we use the columns that are passed in for the table and build a UI
based on each column's filter object.

To add/remove filters, we use a checkbox system that saves the currently opened filters in a local state
called selectedFilters. We do this so even if we mutate the original filters, we can still properly show
the filter selection UI by keep copy the non-mutated copies of the filters in local state.

For string, boolean, and enum types, all state is maintained in the filters prop. For date_string, timestamp,
and number, the filter has multiple steps: a select statement and then an input. We don't want to send back the
filter to the backend if the full filter hasn't been specified. So the state is maintained in the selectedFilters
state.

Each filter object can have the following attributes:
- field
- value
- type
- comparisonType

This component is complex so if you have any questions, feel reach out to Anuraag and Garrett!

Supported filter types:
  string:
      comparisonTypes: exact, in, id, substring (the filter automatically selects substring if "=", or "in" aren't specified.)
  date_string:
      comparisonTypes: equal, between, before, after
  number:
      comparisonTypes: equal, greater, less
  boolean:
      comparisonTypes: equal
  enum:
      comparisonTypes: equal
  timestamp:
      comparisonTypes: equal, between, before, after

*/

export type FiltersMap = { [field: string]: FilterType };

type Props = {
  filters: FiltersMap;
  filtersHandler: (filters: FiltersMap) => void;
  columns: ColumnConfigs;
  button: ActionButtonConfig;
};

const Filter: React.FC<Props> = (props: Props) => {
  const { filters, filtersHandler, columns, button } = props;

  /*********************************************************
   *  Filtering options constants
   **********************************************************/

  const booleanSelections = [
    { label: "True", value: "true" },
    { label: "False", value: "false" },
  ];
  const numberSelections = [
    { label: "is equal to", value: "=" },
    { label: "is greater than", value: ">" },
    { label: "is less than", value: "<" },
  ];

  const dateSelections = [
    { label: "is equal to", value: "=" },
    { label: "is after", value: ">" },
    { label: "is before", value: "<" },
    { label: "is between", value: "<+>" },
  ];

  /*********************************************************
   *  Filter states
   *
   *  showFilter -> state to show or hide filter pane
   *  selectedFilters -> manages the local state of the
   *  currently active filter selections
   **********************************************************/

  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(filters || {});

  /*********************************************************
   *  Filtering options constants
   **********************************************************/

  // Opens and closes filter pane.
  const handleClick = () => setShowFilter(!showFilter);

  // Closes the filter pane
  const handleClickAway = () => setShowFilter(false);

  /*********************************************************
   * Handles selecting (i.e. checking) of a new filter.
   *
   * -> if a filter has already been checked, deselecting will
   *    clear the filter selection and value.
   * -> if a filter hasn't been checked, selecting will open
   *    the filter selection options.
   ************************************************************/

  const handleFilterSelect = (field: string) => {
    let newSelectedFilters: FiltersMap;

    if (selectedFilters[field]) {
      newSelectedFilters = { ...selectedFilters };
      delete newSelectedFilters[field];

      const newFilters = { ...filters };
      delete newFilters[field];

      if (filters[field] && Object.keys(filters[field] as FilterType).length > 0) {
        filtersHandler(newFilters);
      }
    } else {
      newSelectedFilters = { ...selectedFilters, [field]: {} };
    }

    setSelectedFilters(newSelectedFilters);
  };

  // This clears all the filters and selections
  const handleClear = () => {
    setSelectedFilters({});
    filtersHandler({});
  };

  const handleDone = () => {
    // Need to clean selected as well.
    const localSelect = { ...selectedFilters };
    Object.keys(selectedFilters).forEach((key) => {
      if (!filters[key]) {
        delete localSelect[key];
      }
    });

    setSelectedFilters(localSelect);
    setShowFilter(false);
  };

  // Clean up the filters to be able to be parsable by the backend and send the data
  // back via the filter handler callback.
  const prepareFilter = (newFilter) => {
    const cleanedFilter = collapseFilters(newFilter);

    // For remaining filters, convert them into format parsable on backend:
    Object.keys(cleanedFilter).forEach((key) => {
      let localItem = cleanedFilter[key];

      if (localItem.type === "string" && !localItem.comparisonType) {
        localItem.comparisonType = "contains";
      }
      if (localItem.type === "enum") {
        localItem.value = localItem.value.value;
        localItem.type = "string";
      }
      if (localItem.type === "number" || localItem.type === "dollars-cents") {
        // Set dollars-cents to number for backend.
        localItem.type = "number";
        localItem.comparisonType = localItem.comparisonType?.value
          ? localItem.comparisonType?.value
          : localItem.comparisonType;
        localItem.value = Number(localItem.value);
      }

      if (localItem.type === "timestamp" || localItem.type === "date_string") {
        if (localItem.comparisonType && localItem.comparisonType.value) {
          localItem.comparisonType = localItem.comparisonType.value;
        }
      }

      if (localItem.type === "_id") {
        const isObjectId = localItem.value.match(/^[0-9a-fA-F]{24}$/);
        if (!isObjectId) {
          localItem = {};
        }
      }

      if (localItem.type === "enum") {
        // @ts-expect-error what, this can be an array, too??
        const enumFilter = filters.find((f) => f.field === localItem.field);
        if (enumFilter && enumFilter.values) {
          const enumOption = enumFilter.values.find((e) => e.label === localItem.value);
          localItem.value = enumOption ? enumOption.value : localItem.value;
        }
      }

      localItem.comparisonType = localItem.comparisonType || cleanedFilter[key].comparisonType;

      cleanedFilter[key] = localItem;
    });
    filtersHandler(cleanedFilter); // Passing back local since the savedFilter might be updated async.
  };

  // Collapses the selected filters. This is called right before we send them into the filter handler
  // For example, if a string is selected but empty, that filter should be cleared.
  const collapseFilters = (newFilters) => {
    const newFilter = { ...newFilters };

    Object.keys(newFilter).forEach((key) => {
      if (newFilter[key].value === "" || newFilter[key].value === "-") {
        delete newFilter[key];
      }
      // Handle case of populated input but no select entered.
      else if (newFilter[key].comparisonType === "-") {
        delete newFilter[key];
      }
      // Handle is between date case. Both dates need to be populated.
      else if (newFilter[key].comparisonType?.value === "<+>") {
        if (newFilter[key].value === "" || newFilter[key].value[0] === "" || newFilter[key].value[1] === "") {
          delete newFilter[key];
        }
      }
    });

    return newFilter;
  };

  /*********************************************************
   *  Input handlers
   **********************************************************/

  const handleStringInput = (filter, field, value) => {
    const newFilter = { ...filters };

    newFilter[field] = {
      field: filter.field,
      type: filter.type,
      comparisonType: filter.comparisonType,
      value: value,
    };

    prepareFilter(newFilter);
  };

  const handleBooleanInput = (filter, field, option) => {
    const newFilter = { ...filters };

    const value = option.value;

    newFilter[field] = {
      field: filter.field,
      type: filter.type,
      comparisonType: filter.comparisonType,
      value: value,
    };

    prepareFilter(newFilter);
  };

  const handleNumberInput = (filter, field, event, inputType = "select") => {
    const newFilter = { ...filters };
    const newSelectedFilters = { ...selectedFilters };

    let value = "";
    let comparisonType: ComparisonType = "-";

    // Modifies comparison type.
    if (inputType === "select") {
      // Save value existing in text box since recreate filter at end of method.
      if (newFilter[field]) {
        value = (newFilter[field]?.value as string) || "";
      }
      comparisonType = event;
    }

    // Modifies number.
    if (inputType === "number") {
      if (newSelectedFilters[field]?.comparisonType) {
        comparisonType = newSelectedFilters[field]!.comparisonType!;
      }
      value = event.target.value;
    }
    // Modifies dollars.
    if (inputType === "dollars-cents") {
      if (newSelectedFilters[field]?.comparisonType) {
        comparisonType = newSelectedFilters[field]!.comparisonType!;
      }
      const dollars = parseFloat(event.target.value) * 100;
      value = isNaN(dollars) ? "" : dollars.toString();
    }

    newFilter[field] = {
      field: filter.field,
      type: filter.type,
      value: value,
      comparisonType: comparisonType,
    };

    newSelectedFilters[field] = {
      comparisonType: comparisonType,
      value: value,
    };

    setSelectedFilters(newSelectedFilters);
    prepareFilter(newFilter);
  };

  const handleDateInput = (filter, field, event, inputType = "select") => {
    const newFilter = { ...filters };
    const newSelectedFilters = { ...selectedFilters };

    let value: string | number | (number | string)[] = "";
    let comparisonType: ComparisonType = "-";

    // Modifies comparison type.
    if (inputType === "select") {
      // Save existing dates since recreate filter at end of method.
      if (newSelectedFilters[field]?.value) {
        const existingValue = newSelectedFilters[field]!.value;
        const existingComparisonType =
          // @ts-expect-error this value business is a bit of a mess.
          newSelectedFilters[field]!.comparisonType?.value || newSelectedFilters[field]!.comparisonType;

        // Need to account for switching between array and date.
        if (
          (event.value === "<+>" || existingComparisonType === "<+>") &&
          event.value !== existingComparisonType
        ) {
          // Changing to "in between"
          if (event.value === "<+>") {
            value = new Array(2).fill("");
            value[0] = existingValue;
            if (existingValue) {
              if (newSelectedFilters[field]!.type === "timestamp") {
                value[1] = DateTime.now().toSeconds();
              } else {
                value[1] = DateTime.now().toISODate();
              }
            }
          }
          // Changing from "in between". For now, using first value in the in between.
          else {
            value = existingValue[0];
          }
        } else {
          value = existingValue;
        }
      } else {
        if (event.value === "<+>") {
          value = new Array(2).fill("");
        }
      }
      comparisonType = event;
    }

    // Modifies date.
    else {
      // Save comparison type.
      if (newSelectedFilters[field]?.comparisonType) {
        comparisonType = newSelectedFilters[field]!.comparisonType!;
      }

      // Ordinary, single date case
      if (inputType === "regular") {
        value = event;

        // Between case
      } else {
        if (newSelectedFilters[field]?.value) {
          value = newSelectedFilters[field]!.value;
        } else {
          value = new Array(2).fill("");
        }
        if (inputType === "between_first") {
          value[0] = event;
        } else if (inputType === "between_second") {
          value[1] = event;
        }
      }
    }

    newFilter[field] = {
      field: filter.field,
      type: filter.type,
      value: _.cloneDeep(value),
      comparisonType: comparisonType,
    };

    newSelectedFilters[field] = {
      comparisonType: comparisonType,
      field: filter.field,
      type: filter.type,
      value: _.cloneDeep(value),
    };

    setSelectedFilters(newSelectedFilters);
    prepareFilter(newFilter);
  };

  const handleEnumInput = (filter, field, event) => {
    const newFilter = { ...filters };

    newFilter[field] = {
      field: filter.field,
      type: filter.type,
      value: event,
      comparisonType: filter.comparisonType,
    };

    prepareFilter(newFilter);
  };

  /*********************************************************
   *  Function to generate the UI for each filter
   **********************************************************/

  const formatIndividualFilter = (column: ColumnConfig, field: string) => {
    if (!column.filter) throw Error("Unexpected");
    const filterExpression: React.ReactNode[] = [];

    // Don't remove two exclamation points.
    const selected = !!selectedFilters[field];
    const id = `filter-${field}`;

    filterExpression.push(
      <label htmlFor={id} className="filter-item" key={"filter-item:" + field} style={{ cursor: "pointer" }}>
        <input
          id={id}
          key={id}
          type="checkbox"
          onChange={() => handleFilterSelect(field)}
          checked={selected}
        />
        <div className="filter-item-name">{column.filter.label || column.label}</div>
      </label>
    );

    // Check to see if filter selected. If so, render input fields.
    if (selected) {
      if (column.filter.type === "boolean" || column.filter.comparisonType === "exists") {
        const selectValue =
          booleanSelections.find(({ value }) => value == filters[field]?.value.toString()) || "-";

        filterExpression.push(
          <div className="filter-item-activated" key={"item-" + field}>
            <FilterSelector
              onSelect={handleBooleanInput}
              filter={column.filter}
              index={field}
              selectOptions={booleanSelections}
              selectValue={selectValue}
            />
          </div>
        );
      } else if (column.filter.type === "string") {
        filterExpression.push(
          <div className="filter-item-activated" key={"item-" + field}>
            <div className="filter-input-text-label">contains</div>
            <div>
              <input
                type="text"
                className="filter-input-text"
                value={filters[field]?.value ?? ""}
                onChange={(e) => handleStringInput(column.filter, field, e.target.value)}
              ></input>
            </div>
          </div>
        );
      } else if (column.filter.type === "_id") {
        filterExpression.push(
          <div className="filter-item-activated" key={"item-" + field}>
            <div className="filter-input-text-label">paste ID</div>
            <div>
              <input
                type="text"
                className="filter-input-text"
                value={filters[field]?.value ?? ""}
                onChange={(e) => handleStringInput(column.filter, field, e.target.value)}
              ></input>
            </div>
          </div>
        );
      } else if (column.filter.type === "dollars-cents") {
        const selectValue = selectedFilters[field]?.comparisonType || "-";
        const value = selectedFilters[field]?.value;

        filterExpression.push(
          <div className="filter-item-activated" key={"item-" + field}>
            <FilterSelector
              onSelect={handleNumberInput}
              filter={column.filter}
              index={field}
              selectOptions={numberSelections}
              selectValue={selectValue}
            />
            <div style={{ position: "relative" }}>
              <ControlledInputWithCursorPosition
                className="filter-input-number-text unit form2-text"
                style={{ fontSize: 13, width: "12ch", textAlign: "right", height: 25 }}
                placeholder="0.00"
                autoComplete="no"
                type="text"
                // https://stackoverflow.com/a/68111137/1048433
                inputMode="numeric"
                // Display cents as dollars
                value={value ? (Number(value) / 100).toFixed(2) : ""}
                onChange={(e) => handleNumberInput(column.filter, field, e, "dollars-cents")}
              />
              <div className="unit-label" style={{ top: 5 }}>
                {"$"}
              </div>
            </div>
          </div>
        );
      } else if (column.filter.type === "number") {
        const selectValue = selectedFilters[field]?.comparisonType || "-";

        filterExpression.push(
          <div className="filter-item-activated" key={"item-" + field}>
            <FilterSelector
              onSelect={handleNumberInput}
              filter={column.filter}
              index={field}
              selectOptions={numberSelections}
              selectValue={selectValue}
            />
            <input
              type="number"
              className="filter-input-number-text"
              onChange={(e) => handleNumberInput(column.filter, field, e, "number")}
              value={selectedFilters[field]?.value || ""}
            ></input>
          </div>
        );
      } else if (column.filter.type === "date_string" || column.filter.type === "timestamp") {
        const selectValue = selectedFilters[field]?.comparisonType || null;

        filterExpression.push(
          <div className="filter-item-activated" key={"item-" + field}>
            <DateFilter
              selectOptions={dateSelections}
              onInput={handleDateInput}
              inputType={column.filter.type}
              filter={column.filter}
              index={field}
              selectValue={castToFilterValue(selectValue, dateSelections)}
              dateValue={selectedFilters[field]?.value || null}
            />
          </div>
        );
      } else if (column.filter.type === "enum") {
        const selectValue = column.filter.values?.find((item) => item.value == filters[field]?.value) || "-";
        filterExpression.push(
          <div className="filter-item-activated" key={"item-" + field}>
            <FilterSelector
              onSelect={handleEnumInput}
              filter={column.filter}
              index={field}
              selectOptions={column.filter.values!}
              selectValue={selectValue}
              isEnum={true}
            />
          </div>
        );
      } else {
        filterExpression.push(
          <div className="filter-item-activated" key={"item-" + field}>
            <div className="filter-input-text-label">Unknown filter type</div>
          </div>
        );
      }
    }
    return filterExpression;
  };

  const renderFilter = () => {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="filter-box-wrapper">
          <div className="filter-header">
            <button className="button-1" onClick={handleClear}>
              Clear
            </button>
            <div className="filter-header-title">Filters</div>
            <button className="button-2" onClick={handleDone}>
              Done
            </button>
          </div>
          {columns
            .filter((column) => column.filter)
            .map((column) => formatIndividualFilter(column!, column.filter!.field!))}
        </div>
      </ClickAwayListener>
    );
  };

  return (
    <div className="filter-button-wrapper">
      <button className={button.style} onClick={handleClick} key={"filterKey"}>
        {Object.keys(selectedFilters).filter((key) => selectedFilters[key]).length
          ? button.name + " (" + Object.keys(selectedFilters).length + ")"
          : button.name}
      </button>

      {showFilter && renderFilter()}
    </div>
  );
};

export default Filter;
