import React from "react";
import Select from "react-select";
import { getNumberDaysInMonth } from "dashboard/utils/utils";

import { dateStyles } from "./styles";
import { Controller } from "react-hook-form";
import { FormErrors } from "./types";

const dropdownHeight = 180;

type Option = {
  label: string;
  value: string | number;
};

type Props = {
  name: string;
  control: React.ComponentProps<typeof Controller>["control"];
  defaultValue?: string | null;
  errors?: FormErrors;
  year?: number;
  requiredSelect?: boolean;
};

const DateDropdown: React.FC<Props> = ({ name, control, defaultValue, errors, year, requiredSelect }) => {
  const defaultYear = defaultValue?.slice(0, 4);
  const defaultMonth = defaultValue?.slice(5, 7);
  const defaultDay = defaultValue?.slice(8, 10);

  const monthName = "month";
  const dayName = "day";
  const yearName = "year";

  const getError = () => {
    if (errors && errors[name]) {
      const dropdownErrors = errors[name]!;
      if (
        (dropdownErrors[dayName] && dropdownErrors[dayName].type === "validate") ||
        (dropdownErrors[monthName] && dropdownErrors[monthName].type === "validate") ||
        (dropdownErrors[yearName] && dropdownErrors[yearName].type === "validate")
      ) {
        return "Please enter a valid date.";
      } else {
        return "This field is required.";
      }
    }
    return false;
  };

  const getMonthOptions = () => {
    const monthOptions: Option[] = [];
    for (let i = 1; i <= 12; i++) {
      let s = i.toString();
      if (s.length === 1) {
        s = "0" + s;
      }
      monthOptions.push({ label: s, value: s });
    }
    return monthOptions;
  };

  // Ideally, we could dynamically render the days in a month.
  // But to do so, likely need to pass in a prop such as formState, which
  // would update whenever a dropdown is updated. That is a bit messy, which is
  // why we always show 31 days and then validate the date on submission.
  const getDayOptions = () => {
    const dayOptions: Option[] = [];
    for (let i = 1; i <= 31; i++) {
      let s = i.toString();
      if (s.length === 1) {
        s = "0" + s;
      }
      dayOptions.push({ label: s, value: s });
    }
    return dayOptions;
  };

  const getYearOptions = () => {
    const yearOptions: Option[] = [];
    const y = year ? year : new Date().getFullYear();
    for (let i = y; i >= 1930; i--) {
      yearOptions.push({ label: `${i}`, value: i });
    }
    return yearOptions;
  };

  const days = getDayOptions();
  const years = getYearOptions();
  const months = getMonthOptions();
  const dropdownError = getError();

  const validateDate = () => {
    const latestValues = control!.getValues()[name];

    // If at least one dropdown is non-null AND at least one is null, then this is invalid.
    if (
      (latestValues && latestValues[monthName] && latestValues[monthName].value) ||
      (latestValues && latestValues[dayName] && latestValues[dayName].value) ||
      (latestValues && latestValues[yearName] && latestValues[yearName].value)
    ) {
      if (
        !(latestValues && latestValues[monthName] && latestValues[monthName].value) ||
        !(latestValues && latestValues[dayName] && latestValues[dayName].value) ||
        !(latestValues && latestValues[yearName] && latestValues[yearName].value)
      ) {
        return false;
      }
    }

    // Otherwise, make sure all fields are filled in before validating date.
    if (
      latestValues &&
      latestValues[monthName] &&
      latestValues[monthName].value &&
      latestValues &&
      latestValues[dayName] &&
      latestValues[dayName].value &&
      latestValues &&
      latestValues[yearName] &&
      latestValues[yearName].value
    ) {
      const numDays = getNumberDaysInMonth(
        latestValues[monthName] ? latestValues[monthName].value : "",
        latestValues[yearName] ? latestValues[yearName].value : ""
      );

      if (Number(latestValues[dayName].value) > numDays) {
        return false;
      }
    }

    return true;
  };

  return (
    <div className="date-dropdown-wrapper">
      <div className="flex">
        <Controller
          as={Select}
          styles={dateStyles}
          options={months}
          name={name + "." + monthName}
          height="32px"
          placeholder="Month"
          control={control}
          defaultValue={defaultValue ? { value: defaultMonth, label: defaultMonth } : ""}
          rules={{ required: requiredSelect, validate: validateDate }}
          maxMenuHeight={dropdownHeight}
        />
        <div className="date-dropdown-spacer"></div>
        <Controller
          as={Select}
          styles={dateStyles}
          options={days}
          name={name + "." + dayName}
          height="32px"
          placeholder="Day"
          requiredSelect={requiredSelect}
          control={control}
          defaultValue={defaultValue ? { value: defaultDay, label: defaultDay } : ""}
          rules={{ required: requiredSelect, validate: validateDate }}
          maxMenuHeight={dropdownHeight}
        />
        <div className="date-dropdown-spacer"></div>
        <Controller
          as={Select}
          styles={dateStyles}
          options={years}
          name={name + "." + yearName}
          height="32px"
          placeholder="Year"
          requiredSelect={requiredSelect}
          control={control}
          defaultValue={defaultValue ? { value: defaultYear, label: defaultYear } : ""}
          rules={{ required: requiredSelect, validate: validateDate }}
          maxMenuHeight={dropdownHeight}
        />
      </div>
      {dropdownError && <div className="error-msg">{dropdownError}</div>}
    </div>
  );
};

export default DateDropdown;
