import { useMemo } from "react";
import { useActiveCompany } from "./atom-hooks";
import { TeamMember } from "dashboard/miter";

type OtExemptUtilFunc<G extends keyof TeamMember> = <T extends Partial<Pick<TeamMember, G>>>(
  tm: T | null | undefined
) => boolean;

export const useOtExemptUtils = (): {
  isTmOtExempt: OtExemptUtilFunc<"overtime_exempt" | "employment_type">;
  otExemptionIsRelevant: OtExemptUtilFunc<"employment_type">;
} => {
  const activeCompany = useActiveCompany();

  return useMemo(() => {
    const otExemptionIsRelevant: OtExemptUtilFunc<"employment_type"> = (tm) => {
      return tm?.employment_type === "employee" || !!activeCompany?.settings.payroll.ot_applies_to_1099s;
    };

    const isTmOtExempt: OtExemptUtilFunc<"overtime_exempt" | "employment_type"> = (tm) => {
      // If the exemption isn't relevant, then they're a contractor without the special `ot_applies_to_1099s` setting, meaning they're exempt from OT by default
      return !!tm?.overtime_exempt || !otExemptionIsRelevant(tm);
    };

    return { isTmOtExempt, otExemptionIsRelevant };
  }, [activeCompany]);
};
