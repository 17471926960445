import React from "react";

type Props = {
  resourceName?: string;
  searchHandler: (value: string) => void;
};

const TableSearch: React.FC<Props> = ({ resourceName = "", searchHandler }) => {
  return (
    <input
      type="text"
      className="table-search-input pw-input"
      style={{ width: 250, fontSize: 14, fontFamily: "Karla" }}
      placeholder={"Search " + resourceName}
      onChange={(e) => searchHandler(e.target.value)}
    ></input>
  );
};

export default TableSearch;
