import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormsTable from "team-portal/components/forms/FormsTable";

const Forms: FC = () => {
  const { t } = useTranslation<$TSFixMe>();

  return (
    <div>
      <h1 className="view-title">{t("Forms")}</h1>
      <div className="vertical-spacer-small" />
      <FormsTable />
    </div>
  );
};

export default Forms;
