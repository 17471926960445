import React from "react";
import { Loader } from "../loader";
import "./modal.css";

type Props = {
  text?: string;
};

const LoadingModal: React.FC<Props> = ({ text }) => {
  return (
    <div className="modal-background loading-modal">
      <div className="modal-wrapper">
        <div className="modal-body">
          <span className="loading-modal-text">{text}</span>
          <Loader />
        </div>
      </div>
    </div>
  );
};

export default LoadingModal;
