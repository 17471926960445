import React, { useEffect, useState } from "react";
import DocViewer from "@cyntler/react-doc-viewer";
import "./Documents.css";
import { MiterAPI } from "dashboard/miter";

type Props = {
  uri: string;
  /** We need to rely on a dynamic CSS import to remove the header from the document viewer */
  hideHeader?: boolean;
  wrapperStyle?: React.CSSProperties;
  /** Doc-Viewer accepts its own height prop, which determines the PDF size */
  documentHeightOverride?: number;
  /* If provided, the signed URL calculated will override the uri prop */
  fileId?: string;
};

const DEFAULT_DOCUMENT_HEIGHT = 800;

const DocumentDisplay: React.FC<Props> = ({ uri, wrapperStyle, documentHeightOverride, fileId }) => {
  const [finalURI, setFinalURI] = useState(uri);

  useEffect(() => {
    if (!fileId) return;

    MiterAPI.files
      .get_urls({
        filter: [{ field: "_id", value: fileId, type: "_id" }],
      })
      .then((res) => {
        if (res.urls.length === 0) return;
        const fetchedURI = res?.urls[0]?.value.url;
        if (fetchedURI) {
          setFinalURI(fetchedURI);
        }
      });
  }, [fileId]);

  return (
    <div className={`doc-viewer`} style={wrapperStyle}>
      <DocViewer
        documents={[
          {
            uri: finalURI,
          },
        ]}
        prefetchMethod="GET"
        config={{
          pdfZoom: {
            defaultZoom: 1,
            zoomJump: 0.2,
          },
          pdfVerticalScrollByDefault: true,
          header: {
            disableHeader: true,
            overrideComponent: () => null,
          },
        }}
        style={{
          height: documentHeightOverride || DEFAULT_DOCUMENT_HEIGHT,
        }}
        theme={{
          disableThemeScrollbar: true,
        }}
      />
    </div>
  );
};

export default React.memo(DocumentDisplay);
