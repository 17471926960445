import React from "react";
import Filter from "./filter/Filter";
import TableSearch from "./TableSearch";
import { ActionButtonConfig, ColumnConfigs } from "./types";

import "./Table.css";
import "./TableButtons.css";
import "./filter/Filter.css";
import { Button } from "../button";

type Props = {
  defaultButtons: ActionButtonConfig[];
  dynamicButtons: ActionButtonConfig[];
  selectedCount: number;
  hideSearch: boolean | undefined;
  columns: ColumnConfigs;
  filters?: React.ComponentProps<typeof Filter>["filters"];
  searchHandler?: React.ComponentProps<typeof TableSearch>["searchHandler"];
  resourceName?: string;
  // tableTitle allows passing a table title, which is properly justified with the table action buttons.
  // Search precedes title in priority, so the title will only be rendered if search isn't enabled.
  tableTitle?: string;
};

const TableActions = (props: Props): React.ReactElement => {
  const {
    defaultButtons,
    hideSearch,
    dynamicButtons,
    selectedCount,
    columns,
    filters,
    searchHandler,
    resourceName,
    tableTitle,
  } = props;

  const formatButton = (button: ActionButtonConfig, index: number) => {
    const buttonExpression: React.ReactNode[] = [];

    if (button.component) {
      buttonExpression.push(button.component);
    }

    if (button.name !== "Filter" && !button.component) {
      buttonExpression.push(
        <Button
          className={button.style}
          onClick={button.onClick}
          key={"action-button-" + index}
          text={button.name}
          loading={button.loading}
        />
      );
    }

    if (button.name === "Filter") {
      buttonExpression.push(
        <Filter
          columns={columns}
          filters={filters || {}}
          filtersHandler={button.onClick}
          button={button}
          key={index}
        />
      );
    }
    return buttonExpression;
  };

  // Render search bar
  const renderActionHeader = () => {
    if (!searchHandler || hideSearch) {
      if (tableTitle) {
        return <div className="table-title">{tableTitle}</div>;
      }
      return <></>;
    }

    return <TableSearch searchHandler={searchHandler} resourceName={resourceName} />;
  };

  // Buttons to show if selected.
  if (selectedCount !== 0) {
    return (
      <div className="buttons-wrapper">
        {renderActionHeader()}
        <div className="flex-1" />
        <div className="selected-count-text">{selectedCount} selected </div>
        <div className="divider-table-actions">|</div>
        {dynamicButtons.map((button, index) => {
          if (button.overrideElement)
            return (
              <>
                {button.overrideElement}
                <span key={index}></span>
              </>
            );

          return (
            <Button
              className={button.style}
              onClick={button.onClick}
              key={"dynamic-table-button-" + index}
              text={button.name}
              loading={button.loading}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className="buttons-wrapper">
      {renderActionHeader()}
      <div className="flex-1"></div>
      {defaultButtons.map((button, index) => formatButton(button, index))}
    </div>
  );
};

export default TableActions;
