import React, { ReactNode, useContext } from "react";

import "./Layout.css";

import Header from "./Header";
import DesktopNav from "./nav/DesktopNav";
import AppContext from "team-portal/contexts/app-context";
import LoadingPage from "./LoadingPage";

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user, activeTM } = useContext(AppContext);

  if (!activeTM || !user) return <LoadingPage />;

  return (
    <div className="app-wrapper">
      <Header />

      <div className="desktop-view">
        <DesktopNav />
        <div className="desktop-right">
          <div className="desktop-active-page">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
