import React, { useMemo } from "react";
import { DateTime } from "luxon";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { Option } from "ui/form/Input";
import {
  AggregatedPayroll,
  TeamMember,
  Activity,
  User,
  LedgerAccount,
  FieldRequirement,
  MiterIntegrationForCompany,
  Company,
  Address,
  MiterError,
  Role,
  SlimJobTeamMember,
  AggregatedTeamMember,
  CompanyUser,
} from "dashboard/miter";
import { TablePayroll } from "backend/utils/aggregations/payrollAggregations";
import { Payroll, Timesheet } from "backend/models";
import { AggregatedTimesheet } from "backend/utils/aggregations";
import Notifier from "./notifier";
import { toByteArray } from "base64-js";
import { CheckAddress, CheckBenefitType } from "backend/utils/check/check-types";
import { EnhancedMiterPayment, MiterEarning } from "dashboard/pages/payrolls/payrollTypes";
import mime from "mime-types";
import { cloneDeep } from "lodash";
import { WORK_HOURS_IN_WEEK, cleanFloatingPointErrors, notNullish } from "miter-utils";
import { LedgerCheckBenefitCategory } from "backend/utils/accounting";
import { MiterIntegrationKey } from "backend/models/integrations/integrations";
import { TimeType } from "backend/utils/time-type-utils";
import { ForageResponse } from "backend/utils/forage/forage-types";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { CompanySettings } from "backend/models/company";
import { ValueFormatterParams } from "ag-grid-community";

export type SomeRequiredRestOptional<T, K extends keyof T> = { [P in K]-?: T[P] } &
  {
    [P in Exclude<keyof T, K>]?: T[P];
  };

export const downloadFileFromBlob = (type: string, chars: string, fileNameWithExt: string): void => {
  const blob = new Blob([chars], { type });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileNameWithExt);
  document.body.appendChild(link); // Required for FF

  link.click(); // Download file.
};

export const downloadCsvFromBlob = (chars: string, fileName: string): void => {
  if (!fileName.toLowerCase().endsWith(".csv")) fileName += ".csv";
  downloadFileFromBlob("text/csv;charset=utf-8;", chars, fileName);
};

export const downloadPlainTextFromBlob = (chars: string, fileNameWithExt: string): void => {
  downloadFileFromBlob("text/plain;charset=utf-8;", chars, fileNameWithExt);
};

export const booleanOptions = [
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];

export const recentYearOptions = (): Option<number>[] => {
  const options: Option<number>[] = [];
  let year = DateTime.now().year + 1;
  while (--year >= 2022) {
    options.push({ label: year.toString(), value: year });
  }
  return options;
};

export type TimesheetGeofenceStatus =
  | "within_fence"
  | "outside_fence"
  | "missing_job"
  | "missing_job_geolocation"
  | "missing_timesheet_geolocation"
  | "n/a"
  | "current_location";

export const downloadPdfFromBase64String = (base64String: string, fileName: string): void => {
  const linkSource = `data:application/pdf;base64,${base64String}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const earningsThatRequireHours = [
  "hourly",
  "salaried",
  "overtime",
  "double_overtime",
  "paid_holiday",
  "pto",
  "sick",
];

export const getTimeTypeAdjustedRateByMult = (regRate: number, timeType: TimeType): number => {
  if (timeType === "ot") return cleanFloatingPointErrors(regRate * 1.5);
  if (timeType === "dot") return cleanFloatingPointErrors(regRate * 2);
  return regRate;
};

export const getExtension = (fileType: string | undefined): string => {
  const type = fileType === "image/jpg" ? "image/jpeg" : fileType;
  const ext = mime.extension(type) ? "." + mime.extension(type) : "";
  return ext;
};

export const addressToString = (
  addressObj: $TSFixMe,
  options?: { oneLiner: boolean; hideCountry?: boolean; hidePostalCode?: boolean }
): string => {
  if (!addressObj) return "";
  return (
    (addressObj?.postal_name || "").trim() +
    (addressObj?.postal_name && options?.oneLiner ? ", " : "\n") +
    (addressObj?.line1 || "").trim() +
    (options?.oneLiner ? " " : "\n") +
    (addressObj?.line2 ? (addressObj.line2 || "").trim() + (options?.oneLiner ? ", " : "\n") : "") +
    (
      (addressObj?.city || "") +
      ", " +
      (addressObj?.state?.value || addressObj?.state || "") +
      " " +
      (!options?.hidePostalCode ? addressObj?.postal_code || "" : "") +
      " " +
      (!options?.hideCountry ? addressObj?.country || "" : "")
    ).trim()
  ).trim();
};

// anonymizes an address
export const addressToTownStateZip = (addressObj: $TSFixMe): string => {
  if (!addressObj) return "";
  return (
    (addressObj?.city || "").trim() +
    ", " +
    (addressObj?.state?.value || addressObj?.state || "").trim() +
    " " +
    (addressObj?.postal_code || "").trim()
  ).trim();
};

export const isEmptyAddress = (address?: Partial<Address> | null): boolean => {
  return (
    !address || (!address.line1 && !address.line2 && !address.city && !address.state && !address.postal_code)
  );
};

export const isValidAddress = (address?: Partial<Address> | null | undefined): address is Address => {
  return !!(
    address &&
    address.line1 &&
    address.line1 !== "" &&
    address.city &&
    address.city !== "" &&
    address.state &&
    address.state !== "" &&
    address.postal_code &&
    address.postal_code !== ""
  );
};

export const addressString = (address: $TSFixMe, breaks = false): string => {
  if (isEmptyAddress(address)) return "";

  const b = breaks ? " \n" : ", ";

  const line1 = address.line1 ? address.line1 + b : "";
  const line2 = address.line2 ? address.line2 + b : "";
  const city = address.city ? address.city + ", " : "";
  const state = address.state ? address.state + " " : "";
  const postal_code = address.postal_code || "";

  return line1 + line2 + city + state + postal_code;
};

// Converts a number to a string with a percent symbol
export const toPercent = (num: number, numPlaces = 1): string => {
  try {
    const roundedNumber = roundTo(Number(num), numPlaces as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | undefined);
    return roundedNumber.toString() + "%";
  } catch (e) {
    return "-";
  }
};

export const cleanAddress = (address: {
  line1: string;
  line2: string;
  city: string;
  state: Option<string>;
  postal_code: string;
}): { line1: string; line2: string | null; city: string; state: string; postal_code: string } | null => {
  const cleaned = {
    line1: address.line1,
    line2: address.line2 === "" ? null : address.line2,
    city: address.city,
    state: address.state?.value,
    postal_code: address.postal_code,
  };
  if (Object.values(cleaned).every((v) => !v)) return null;
  return cleaned;
};

// dev company
export const AMERICAN_PLUMBING_ID = "62f2e99f399dfd45940c7b7c";

// staging companies
export const EN_TEST_COMPANY = "66b28cb42f1d0c862e2f5619";

// prod companies
export const AILEY_SOLAR_ELECTRIC_COMPANY_ID = "6488889417dff10069df2022";
export const ALMENDARIZ_COMPANY_ID = "62ccb5bcf6d505007eeaa0f8";
export const AMERICAN_ROOFING_METAL_COMPANY_ID = "642701b59b8fc70064e40f7d";
export const ANCHOR_DESIGN_BUILD_GROUP_COMPANY_ID = "66266917236172c15e195362";
export const ASCHER_BROTHERS_COMPANY_ID = "668fe5dababa33fde903d4b1";
export const BECKETT_ELECTRICAL_COMPANY_ID = "651432bba25d55006a1d0478";
export const BILBRO_COMPANY_ID = "64c907bda55f360069fda1d0";
export const CAL_SOLAR_COMPANY_ID = "6488fcd85f0fb200648afffa";
export const CANNING_ELECTRIC_COMPANY_ID = "6565fc4bab2c4d0067591849";
export const CVE_DEMO_COMPANY_ID = "65a0058547b3a000711318fe";
export const CVE_FRESNO_COMPANY_ID = "65a004fd47b3a0007112dbd4";
export const CVE_NORTH_BAY_COMPANY_ID = "65a0732fb5c12000724a0807";
export const DAN_GRADY_COMPANY_ID = "659dd3328a501400747a0789";
export const HAYS_ELECTRICAL_COMPANY_ID = "652d926eb2ad0a006e6486de";
export const HILLHOUSE_COMPANY_ID = "6638ebb39d591edf949458d3";
export const INDUSTRIAL_STEEL_COMPANY_ID = "66b62350a4dba4600eb8be47";
export const JGB_AGGREGATES_COMPANY_ID = "668fe3b2baba33fde90180fe";
export const CLIMATE_DESIGN_COMPANY_ID = "6698139fd70273001aa0d8bc";
export const DE_LUCIA_COMPANY_ID = "66797445b7118a2d165bd818";
export const FOSTER_COMPANY_ID = "65a546d1dbe91e007200e57f";
export const GLACIER_CONSTRUCTION_COMPANY_ID = "6604e007f8fedadfb56e0d74";
export const GREAT_FALLS_CONSTRUCTION_COMPANY_ID = "6614075b7b0b8bbb1368f8b2";
export const GOODWIN_WELL_WATER_COMPANY_ID = "6753056ca4ca036cd0c11db2";
export const JGB_AGGREGATES_COMPANY_IDS = [
  "668fe3b2baba33fde90180fe",
  "668fe396baba33fde90161ad",
  "668fe3c5baba33fde9018e7d",
];
export const KENT_POWER_COMPANY_IDS = ["66b3d069f2e91e476ab40e13", "66be61a77568c3c7db101d70"];
export const INTEGRAL_BLUE_COMPANY_ID = "6638ea7c9d591edf94938eb9";
export const LAKESHORE_COMPANY_ID = "64cd5cc11411710069b72085";
export const MARKSMEN_GC_COMPANY_ID = "6655f3b8a72b1c98024c25ec";
export const MAROIS_COMPANY_ID = "66631b52eeabc5e9efa27e80";
export const ONYX_COMPANY_ID = "659ad53fe8e8f1006d4ba8ab";
export const PARAGON_COMPANY_ID = "64beb34891f7770066dc5dfd";
export const PREMO_ROOFING_ID = "619dab682d76040050bf4342";
export const PRIMARY_UTILITY_COMPANY_ID = "66797725b11eb6fd956f0b0a";
export const QOVO_COMPANY_ID = "631916a23154db007ea839a4";
export const STILES_COMPANY_ID = "64db07f6d6692c006be0c92a";
export const SUNSTALL_COMPANY_ID = "63b7595446b67800662c28cc";
export const GMCI_COMPANY_ID = "645a303242a96400739d8125";
export const KAUFMAN_LYNN_COMPANY_ID = "64b58d5b91cef4006790cf85";
export const SYNERGY_COMPANY_ID = "638ea4edb2f5ac006c4db599";
export const PROCRAFT_COMPANY_ID = "6643cdf772946f6f4c2b709e";
export const POWERLINK_COMPANY_ID = "66e97d3c34f1f6c7215092db";
export const THREE_BROTHERS_CONCRETE_COMPANY_ID = "6684050e1b381debfa1d7047";
export const TRUEBECK_COMPANY_ID = "64d1b1431f364500669107a0";
export const TRUEBECK_COMPANY_IDS = [TRUEBECK_COMPANY_ID, PROCRAFT_COMPANY_ID];
export const UNITED_CONSTRUCTION_COMPANY_ID = "652db00db2ad0a006e6dd8f1";
export const UNITED_MECHANICAL_COMPANY_ID = "663d8b7487cd47036fec6874";
export const ALIVE_SOLAR_ROOFING_COMPANY_ID = "64db762ed6692c006be2ca21";
export const MARATHON_ELECTRICAL_ID = "6491e90434bdbb0068e673ef";
export const HB_NEILD_COMPANY_ID = "662664f6236172c15e1679ea";
export const MASS_PAVEMENT_COMPANY_ID = "668fe396baba33fde90161ad";
export const MAXIM_CONCRETE_ID = "64ee2b245fb8d50068d9981c";
export const WHITAKER_ELLIS_COMPANY_ID = "660d6afbc20b0542ef77277b";
export const FACILITY_SITE_COMPANY_ID = "6626635f236172c15e15b473";
export const MUSE_CONCRETE_COMPANY_ID = "653fdecca72b780065eb4ee5";
export const CJ_HANSON_COMPANY_ID = "666c59c39358faea4f27eb57";
export const PERIMETER_SECURITY_PARTNERS_COMPANY_ID = "65a861c8f3cf1500726d53bd";
export const SERENITY_COMPANY_ID = "6655f4a8a72b1c98024cd200";
export const SAFE_AIRE_COMPANY_ID = "64346b989d8814006785679a";
export const ACT_COMPANY_ID = "65525bda2d57f50069d9ff32";
export const SMITH_FIRE_COMPANY_ID = "6601b4dbeb6a636f3ece2c70";
export const DE_LA_FUENTE_COMPANY_ID = "67093c0edf0f550733335859";
export const NORTH_SLOPE_COMPANY_ID = "6667170cbd09cd41d0b8240d";
export const LOGAN_HEATING_COOLING_COMPANY_ID = "66981153d70273001a9eab3d";
export const ENVIRONMENTAL_SYSTEMS_COMPANY_ID = "665f21d40568180d7785037a";
export const JANDJ_COMPANY_ID = "66e346fb10ece77f8f1dc427";
export const ZONE_STRIPING_COMPANY_ID = "6728f93133b19312907766e4";

export const TECHNICAL_SYSTEMS_COMPANY_ID = "668406b71b381debfa1f47e1";
export const STAHL_COMPANY_ID = "652db119b2ad0a006e6e23bb";
export const TEKTON_COMPANY_ID = "652db10cb2ad0a006e6e1766";
export const STAHL_COMPANY_IDS = [STAHL_COMPANY_ID, TEKTON_COMPANY_ID];
export const JAMAR_COMPANY_ID = "649a034052701100673ddb7f";
export const VITAL_MECHANICAL_SERVICE_COMPANY_ID = "6661d98c2acb6e54102e39b9";
export const CARRIERE_STUMM_COMPANY_ID = "65a54140dbe91e0072feaf4e";
export const HARRINGTON_CONSTRUCTION_COMPANY_ID = "66c3b2679c55baca7168c9df";
export const CORE_ROOFING_ID = "66f567e358974c63f8617228";
export const PEERLESS_MIDWEST_LLC_ID = "663bf6cc26ccec53fc9f6624";
export const AMERICAN_HEATING_ID = "67374e1426b15f4b1825c68b";

// Churn risks
export const SPENCER_COMPANY_ID = "66e0be99a0bef04ec6791200";

// Flow Services entities
const FLOW_SERVICES_MANAGEMENT_COMPANY_ID = "66e832d5c4c4872d5b1a719d";
const CORTEZ_COMPANY_ID = "67290b68961ff8b201c8066a";
const DYNAMIC_AIR_COMPANY_ID = "6707eec420317c1b7b73fcbd";
const PERFECTION_HEATING_COMPANY_ID = "66be674b7568c3c7db1569c1";
const R_BROOKS_MECHANICAL_COMPANY_ID = "66f16de2d1de78325215f9ba";
const ALTSTADT_COMPANY_ID = "671a6d1217b5ef2abe2cf313";

export const FLOW_SERVICES_COMPANY_IDS = [
  FLOW_SERVICES_MANAGEMENT_COMPANY_ID,
  PERFECTION_HEATING_COMPANY_ID,
  R_BROOKS_MECHANICAL_COMPANY_ID,
  CORTEZ_COMPANY_ID,
  DYNAMIC_AIR_COMPANY_ID,
  ALTSTADT_COMPANY_ID,
];

export const DEBLOIS_ELECTRIC_COMPANY_ID = "668bf96f64ac8fea1437c184";

export const NEW_HORIZONS_TELECOM_COMPANY_ID = "66bbc36da74c01fc15b23288";
export const NFL_COMPANY_ID = "66bbc388a74c01fc15b2501d";

// WL French entities
export const WL_FRENCH_JR_TRUCKING_COMPANY_ID = "665ddc333fdb4a8f7f43191d";
export const WL_FRENCH_EXCAVATING_COMPANY_ID = "665ddbed3fdb4a8f7f42ea25";
export const JL_FRENCH_TRANSPORTATION_COMPANY_ID = "665ddc793fdb4a8f7f434f9d";

export const STANSELL_COMPANY_ID = "66797582b11eb6fd956dc5d6";
export const REVIM_COMPANY_ID = "65d4d3cf92b6750071b6df33";
export const DIXIE_OVERLAND_COMPANY_ID = "66461b4060366d69a40dc095";
export const PRAETORIAN_POWER_PROTECTION_COMPANY_ID = "65faf82475bc81ba5e42b988";
export const FLAMINGO_CONSTRUCTION_COMPANY_ID = "65d39603b1bf2b00701977bf";

// Arrow COMPANY IDS
const ARROW_CONSTRUCTION_HOLDINGS_COMPANY_ID = "671a6e9317b5ef2abe2e34e2";
const ROAD_PRODUCTS_COMPANY_ID = "671a6e3817b5ef2abe2dfc78";
const ARROW_CONCRETE_AND_ASPHALT_COMPANY_ID = "671a6e6717b5ef2abe2e1e59";
const ARROW_CONSTRUCTION_SUPPLY_COMPANY_ID = "671a6e7e076dca1238ea242c";
export const ARROW_COMPANY_IDS = [
  ARROW_CONSTRUCTION_HOLDINGS_COMPANY_ID,
  ROAD_PRODUCTS_COMPANY_ID,
  ARROW_CONCRETE_AND_ASPHALT_COMPANY_ID,
  ARROW_CONSTRUCTION_SUPPLY_COMPANY_ID,
];

// Southern Spear entities
export const SOUTHERN_SPEAR_IRONWORKS_COMPANY_ID = "63573004fdbcfa008260ee5a";
export const TOMAHAWK_CRANE_AND_RIGGING_COMPANY_ID = "6357322efdbcfa008260f1f2";
export const TOMAHAWK_CRANE_AND_RIGGING_MOBILE_COMPANY_ID = "659c648d5f41240073a3a3f5";
export const TOMAHAWK_CRANE_AND_RIGGING_PENSACOLA_COMPANY_ID = "66c6571afecdbd29a9cf712a";
export const TOMAHAWK_HOLDINGS_COMPANY_ID = "66c6569cfecdbd29a9cef110";
export const IRON_CROWN_COMPANY_ID = "669045b6a8a2a234f3588c31";

export const SOUTHERN_SPEAR_COMPANY_IDS = [
  SOUTHERN_SPEAR_IRONWORKS_COMPANY_ID,
  TOMAHAWK_CRANE_AND_RIGGING_COMPANY_ID,
  TOMAHAWK_CRANE_AND_RIGGING_MOBILE_COMPANY_ID,
  TOMAHAWK_CRANE_AND_RIGGING_PENSACOLA_COMPANY_ID,
  TOMAHAWK_HOLDINGS_COMPANY_ID,
  IRON_CROWN_COMPANY_ID,
];

export const NATIONAL_RAILROAD_SAFETY_SERVICES_COMPANY_ID = "668fdc0034590a22291656ee";

// 2H_MECHANICAL_LLC
export const TWOH_MECHANICAL_LLC = "66e31b9ce868f27f72071b06";

// Miter internal company IDs
export const MITER_COMPANY_ID = "610313a27dc240004465007b"; // Miter
export const DEMO_COMPANY_ID = "61228e16f0388a0093a6ba44"; // Demo Company

export const MITER_MASONRY_COMPANY_ID = "642e088eed0f30004c76061f";

export const cleanDateDropdown = (date: {
  year: Option<string>;
  month: Option<string>;
  day: Option<string>;
}): string => {
  if (!date.year.label && !date.month.label && !date.day.label) return "";
  return [date.year.label, date.month.label, date.day.label].join("-");
};

export const capitalize = (s: string | null | undefined): string => {
  if (!s || typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const changeToTitleCasing = (s: string): string => {
  return s
    ?.split(" ")
    .map((w) => w[0]?.toUpperCase() + w.substring(1).toLowerCase())
    .join(" ");
};

export const changeFromSnakeCasingToSentenceCasing = (s: string): string => {
  const lowerCasedSentence = s?.toLowerCase().split("_").join(" ");
  return lowerCasedSentence[0]?.toUpperCase() + lowerCasedSentence.substring(1);
};

export const changeToSentenceCasing = (s: string): string => {
  return s[0]?.toUpperCase() + s.substring(1).toLowerCase();
};

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const phoneNumber = parsePhoneNumberFromString(phoneNumberString);
  if (!phoneNumber) return phoneNumberString;

  if (phoneNumber.country === "US") {
    return phoneNumber.formatNational();
  } else {
    return phoneNumber.formatInternational();
  }
};

export const standardizePhoneFormat = (value: string): string | undefined => {
  if (!value) return value;

  try {
    const string = value.toString();
    let newString = "";
    for (const char of string) {
      if (Number.isInteger(parseInt(char))) {
        newString += char;
      }
    }

    if (newString.startsWith("57") || newString.startsWith("27") || newString.startsWith("52")) {
      return "+" + newString;
    } else if (newString.length === 11 && newString[0] === "1") {
      return "+" + newString;
    } else if (newString.length === 10) {
      return "+1" + newString;
    } else {
      return undefined;
    }
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const shorten = (s: string, c: number): string => {
  if (!s) {
    return "";
  } else if (s.length <= c) {
    return s;
  } else {
    const newString = s.slice(0, c).trim() + "...";
    return newString;
  }
};

export const titleCase = (str: string | null | undefined): string => {
  if (!str) {
    return "";
  }
  return str.replace(/[_-]/g, " ").replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getOrdinalSuffix = (i: number): string => {
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
};

export const formatDate = (start: string | null | undefined, end?: string, year = false): string => {
  if (!start) return "";

  const startDt = DateTime.fromISO(start);

  // If the date is invalid, return the original string
  if (!startDt.isValid) {
    return start;
  }
  const daysTillStart = Math.abs(startDt.diffNow("days").days);
  const showStartYear = daysTillStart > 180 || year ? ", yyyy" : "";
  let startFormat = "MMM d";

  if (!end) {
    return startDt.toFormat(startFormat + showStartYear);
  }

  const endDt = DateTime.fromISO(end);
  const daysTillEnd = Math.abs(endDt.diffNow("days").days);
  let endFormat = daysTillEnd > 180 || showStartYear ? ", yyyy" : "";

  if (startDt.year === endDt.year) {
    endFormat = (startDt.month === endDt.month ? "d" : "MMM d") + endFormat;
  } else {
    startFormat = endFormat = "MMM d, yyyy";
  }
  return startDt.toFormat(startFormat) + " - " + endDt.toFormat(endFormat);
};

export const getPaperCheckTotalForPayroll = (payroll?: AggregatedPayroll): number => {
  if (!payroll) return 0;
  let paperCheckAmount = 0;
  for (const mp of payroll.miter_payments) {
    if (mp.payment_type === "employee") {
      if (mp.check_item?.payment_method === "manual") {
        paperCheckAmount += Number(mp.check_item.net_pay);
      }
    } else if (mp.payment_type === "contractor") {
      if (mp.check_cp?.payment_method === "manual") {
        paperCheckAmount += Number(mp.check_cp.amount) + Number(mp.check_cp.reimbursement_amount);
      }
    }
  }
  return paperCheckAmount;
};

export const getPaymentPayMethod = (payment: EnhancedMiterPayment): "direct_deposit" | "manual" => {
  const checkPayment = payment.check_item || payment.check_cp;

  // If net_pay is truthy (reminder: it's a string, so even 0 net pay will be truthy), then we know the
  // payment is non-draft or it's been previewed, in which case we definitely want to defer to the Check
  // payment's method since that's the source of truth
  if (checkPayment?.payment_method && checkPayment.net_pay) {
    return checkPayment.payment_method;
  }

  return (
    payment.adjustment?.payment_method ||
    (payment.team_member.check_tm?.payment_method_preference as "direct_deposit" | "manual")
  );
};

export const getSupplementalTaxCalculationMethod = (
  payment: EnhancedMiterPayment,
  company: Company | null
): "flat" | "aggregate" => {
  return (
    payment.check_item?.supplemental_tax_calc_method ||
    payment.adjustment?.supplemental_tax_calc_method ||
    company?.settings.payroll.supplemental_tax_calc_method ||
    "aggregate"
  );
};

type PaymentTotalsObject = {
  hours: number;
  earnings: number;
  imputed_income?: number;
  reimbursements: number;
  taxes?: number;
  benefits?: number;
  post_tax_deductions?: number;
  net_pay: number;
};

export const imputedEarningTypes = [
  "other_imputed",
  "group_term_life",
  "2_percent_shareholder_benefits",
  "2_percent_shareholder_hsa",
];

export const getLedgerAccountLabel = (account: LedgerAccount): string => {
  const raw = `${account.external_id ? account.external_id + " " : ""}${account.label}`;
  if (account.active) return raw;
  return `${raw} (inactive)`;
};

export const getPaymentTotals = (payment: EnhancedMiterPayment): PaymentTotalsObject => {
  // Use Grouped Earnings and Miter Reimbursements whenever possible because the Check versions might not be
  // up-to-date. If Grouped Earnings don't exist (should only occur with contractors), use Miter Earnings.
  const earningsArr = (payment.grouped_earnings || payment.miter_earnings) as Pick<
    MiterEarning,
    "check_type" | "amount" | "hours"
  >[];
  const hours = roundTo(earningsArr.reduce((tot, e) => tot + (e.hours || 0), 0));

  const earnings = roundTo(earningsArr.reduce((tot, e) => tot + e.amount, 0));
  const imputedIncome = roundTo(
    earningsArr.reduce((tot, e) => tot + (imputedEarningTypes.includes(e.check_type) ? e.amount : 0), 0)
  );

  const reimbursementsArr = payment.miter_reimbursements || [];
  const reimbursements = roundTo(reimbursementsArr.reduce((tot, r) => tot + r.amount, 0));

  const taxesArr = payment.check_item?.taxes || [];
  const empTaxes = roundTo(
    taxesArr.reduce((tot, t) => tot + (t.payer === "employee" ? Number(t.amount) : 0), 0)
  );

  const benefitsArr = payment.check_item?.benefits || [];
  const benefits = roundTo(benefitsArr.reduce((tot, b) => tot + Number(b.employee_contribution_amount), 0));

  const ptdsArr = payment.check_item?.post_tax_deductions || [];
  const post_tax_deductions = roundTo(ptdsArr.reduce((tot, d) => tot + Number(d.amount), 0));

  const net_pay = Number(payment.check_item?.net_pay || payment.check_cp?.net_pay || 0);

  return {
    hours: hours,
    earnings: earnings,
    imputed_income: imputedIncome,
    reimbursements: reimbursements,
    post_tax_deductions: post_tax_deductions,
    benefits: benefits,
    taxes: empTaxes,
    net_pay: net_pay,
  };
};

// Funky little function to get the dates between (inclusive) a start and end date
export const datesBetween = (
  start: string | number | Date,
  end: Date,
  includeWeekends: boolean
): DateTime[] => {
  const arr: DateTime[] = [];
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    if (!includeWeekends && (dt.getDay() === 0 || dt.getDay() === 6)) {
      continue;
    }

    arr.push(DateTime.fromJSDate(new Date(dt)));
  }
  return arr;
};

export const payrollTypeLookup = {
  regular: "Regular",
  off_cycle: "Off-cycle",
  amendment: "Amendment",
  third_party_sick_pay: "Third-party sick pay",
  void: "Void",
  historical: "Historical",
};

export const getPayrollTypeString = <T extends Pick<Payroll, "void_of" | "type">>(payroll: T): string => {
  if (payroll.void_of) {
    return "Void";
  } else {
    return payrollTypeLookup[payroll.type];
  }
};

export const getNumberDaysInMonth = (month: number, year: number): number => {
  if (!month) {
    return 31;
  }
  return new Date(year, month, 0).getDate();
};

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const states = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];

/************************************************************************************************************
 * Rounds a number to the specified number of places. Defaults to 2 decimal places.
 ************************************************************************************************************/
export const roundTo = (value: number, places: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 = 2): number => {
  // Important to first clean floating point errors. Example:
  // 1.5 * 35.55 should equal 53.325, which we would round to 53.33 if rounding to 2 places
  // Due to floating point errors, 1.5 * 35.55 = 53.324999999999996, which rounds down to 53.32! No good!
  const multiplier = 10 ** places;
  return Math.round(cleanFloatingPointErrors(multiplier * value)) / multiplier;
};

export const getHoursForTimesheet = (ts: Timesheet | AggregatedTimesheet): number => {
  const durationInSeconds = ts.clock_in - ts.clock_out - (ts.unpaid_break_time || 0);
  if (durationInSeconds === 0) {
    return 0;
  } else {
    return roundTo(Math.max(durationInSeconds / 3600, 0.01));
  }
};

export const convertHeicToPng = async (url: string): Promise<string> => {
  // @ts-expect-error HEIC 2 ANY
  const heic2any = window.heic2any;
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) =>
        heic2any({
          blob,
        })
      )
      .then((conversionResult) => {
        const url = URL.createObjectURL(conversionResult);
        resolve(url);
      })
      .catch((error) => {
        if (error.message && error.message.includes("ERR_USER Image is already browser readable:")) {
          // If the image is already in a readable format, just create a URL from the original blob
          fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              resolve(url);
            })
            .catch(reject);
        } else {
          reject(error);
        }
      });
  });
};

export const convertHeicBlobToPng = async (blob: Blob): Promise<string> => {
  // @ts-expect-error HEIC 2 ANY
  const heic2any = window.heic2any;

  return new Promise((resolve) => {
    heic2any({ blob }).then((conversionResult) => {
      const url = URL.createObjectURL(conversionResult);
      resolve(url);
    });
  });
};

export const externalRedirect = (event: React.MouseEvent | null, url: string): void => {
  console.log("external redirect to ", url);

  if (event?.shiftKey || event?.ctrlKey || event?.metaKey) {
    window.open(url, "_blank");
  } else {
    window.location.href = url;
  }
};

const middleNpsOptions = () => {
  const options: Option<string>[] = [];
  for (let i = 9; i > 0; i--) {
    options.push({ label: i.toString(), value: i.toString() });
  }
  return options;
};

export const npsOptions: Option<string>[] = [
  {
    label: "10 - Extremely likely",
    value: "10",
  },
  ...middleNpsOptions(),
  {
    label: "0 - Not at all likely",
    value: "0",
  },
];

export const extensionToMimeType = (extension: string): string | undefined => {
  if (!extension) return;

  const types = {
    aac: "audio/aac",
    abw: "application/x-abiword",
    ai: "application/postscript",
    arc: "application/octet-stream",
    avi: "video/x-msvideo",
    azw: "application/vnd.amazon.ebook",
    bin: "application/octet-stream",
    bz: "application/x-bzip",
    bz2: "application/x-bzip2",
    csh: "application/x-csh",
    css: "text/css",
    csv: "text/csv",
    doc: "application/msword",
    dll: "application/octet-stream",
    eot: "application/vnd.ms-fontobject",
    epub: "application/epub+zip",
    gif: "image/gif",
    htm: "text/html",
    html: "text/html",
    ico: "image/x-icon",
    ics: "text/calendar",
    jar: "application/java-archive",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    js: "application/javascript",
    json: "application/json",
    mid: "audio/midi",
    midi: "audio/midi",
    mp2: "audio/mpeg",
    mp3: "audio/mpeg",
    mp4: "video/mp4",
    mpa: "video/mpeg",
    mpe: "video/mpeg",
    mpeg: "video/mpeg",
    mpkg: "application/vnd.apple.installer+xml",
    odp: "application/vnd.oasis.opendocument.presentation",
    ods: "application/vnd.oasis.opendocument.spreadsheet",
    odt: "application/vnd.oasis.opendocument.text",
    oga: "audio/ogg",
    ogv: "video/ogg",
    ogx: "application/ogg",
    otf: "font/otf",
    png: "image/png",
    pdf: "application/pdf",
    ppt: "application/vnd.ms-powerpoint",
    rar: "application/x-rar-compressed",
    rtf: "application/rtf",
    sh: "application/x-sh",
    svg: "image/svg+xml",
    swf: "application/x-shockwave-flash",
    tar: "application/x-tar",
    tif: "image/tiff",
    tiff: "image/tiff",
    ts: "application/typescript",
    ttf: "font/ttf",
    txt: "text/plain",
    vsd: "application/vnd.visio",
    wav: "audio/x-wav",
    weba: "audio/webm",
    webm: "video/webm",
    webp: "image/webp",
    woff: "font/woff",
    woff2: "font/woff2",
    xhtml: "application/xhtml+xml",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.ms-excel",
    xlsx_OLD: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    xml: "application/xml",
    xul: "application/vnd.mozilla.xul+xml",
    zip: "application/zip",
    "3gp": "video/3gpp",
    "3gp_DOES_NOT_CONTAIN_VIDEO": "audio/3gpp",
    "3gp2": "video/3gpp2",
    "3gp2_DOES_NOT_CONTAIN_VIDEO": "audio/3gpp2",
    "7z": "application/x-7z-compressed",
  };

  return types[extension.toLowerCase()];
};

export const truncateFilename = (initial: string, maxLength: number): string => {
  const split = initial.split(".");
  if (split.length === 0 || split.length === 1) return initial;

  let filename = split[0];
  const extension = split[1];

  if (filename!.length > maxLength) {
    filename = filename!.substring(0, maxLength) + "...";
  }

  return filename + "." + extension;
};

/********************************************************************************
 Calculates the time that has passed from the specified datetime to the current
 date and time.

 Ex. 1 min ago, 1 hour ago, etc.
********************************************************************************/
export const timeAgo = (dateTime: DateTime): string => {
  const units = ["year", "month", "week", "day", "hour", "minute", "second"];
  const diff = dateTime.diffNow().shiftTo(...(units as $TSFixMe));

  const milliseconds = diff.toMillis();

  if (milliseconds < -43200000) {
    return dateTime.toLocaleString(DateTime.DATETIME_MED);
  }
  if (milliseconds >= -60000) {
    return "Just now";
  }

  const unit = units.find((unit) => diff.get(unit as $TSFixMe) !== 0) || "second";

  const relativeFormatter = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit as $TSFixMe)), unit as $TSFixMe);
};

// Function to merge multiple refs into one re
export const mergeRefs = (...refs: $TSFixMe): $TSFixMe => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};
export const cx = classnames;

export const textColorSelector = (color: string, lightColor = "#ffffff", darkColor = "#000000"): string => {
  const curColor = color.charAt(0) === "#" ? color.substring(1, 7) : color;
  const r = parseInt(curColor.substring(0, 2), 16); // hexToR
  const g = parseInt(curColor.substring(2, 4), 16); // hexToG
  const b = parseInt(curColor.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0]! + 0.7152 * c[1]! + 0.0722 * c[2]!;
  return L > 0.179 ? darkColor : lightColor;
};

export const checkTmAndActivityLicenseMatch = (
  activity: Activity | undefined,
  teamMember: AggregatedTeamMember | TeamMember | undefined
): void => {
  if (
    activity?.qualification_level &&
    teamMember?.license_status &&
    activity.qualification_level !== teamMember?.license_status
  ) {
    Notifier.warning(
      `${teamMember.full_name} does not have same license status as ${activity.label}. This may result in incorrect apprentice ratios.`,
      { duration: 5000 }
    );
  }
};

/****************************************************************
 *  Checks if a string is a UUID with regex
 ***************************************************************/
export const isUUID = (str: string): boolean => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(str);
};

/****************************************************************
 * Check if file is an image file
 ***************************************************************/
export const isImageFile = (mimeType: string): boolean => {
  return mimeType?.startsWith("image/") || false;
};

export const base64toBlob = (base64Data: string, mimeType: string): Blob => {
  const byteArray = toByteArray(base64Data);
  return new Blob([byteArray], { type: mimeType });
};

export const downloadBlob = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.click(); // Download file.
};

/************************************************************************************************************
 *  Determine if a payroll is past due. If the payroll has any positive direct deposits, then we can just
 *  look at the approval deadline. If it doesn't, then we can look at the payday
 ************************************************************************************************************/
export const isPastDue = (payroll: TablePayroll | AggregatedPayroll | undefined | null): boolean => {
  if (!payroll) return false;

  const pr = "status" in payroll ? payroll : payroll.check_payroll;
  if (pr.status !== "draft") return false;

  const now = DateTime.now();
  const ad = pr.approval_deadline ? DateTime.fromISO(pr.approval_deadline) : now;

  return now > ad;
};

/****************************************************************
 * Converts a URL into a JS File object
 ***************************************************************/
export const urlToFile = (url: string, filename: string): Promise<File> => {
  return fetch(url, { method: "GET", mode: "no-cors" })
    .then((res) => res.blob())
    .then((blob) => {
      const file = new File([blob], filename);
      return file;
    });
};

export const checkBenefitCategoryMap: Record<CheckBenefitType, LedgerCheckBenefitCategory> = {
  "125_medical": "health",
  "125_vision": "health",
  "125_dental": "health",
  "125_disability": "other",
  "125_accident": "other",
  "125_cancer": "health",
  "125_critical_illness": "health",
  "125_hospital": "health",
  "125_life": "other",
  "125_medical_other": "health",
  "401k": "retirement",
  "403b": "retirement",
  "457": "retirement",
  roth_401k: "retirement",
  roth_403b: "retirement",
  roth_457: "retirement",
  fsa_medical: "health",
  fsa_dependent_care: "other",
  hsa: "health",
  simple_ira: "retirement",
};

export const addressIsBlank = (address: Partial<CheckAddress> | null | undefined): boolean => {
  return (
    !address ||
    ((!address.line1 || address.line1 === "") &&
      (!address.line2 || address.line2 === "") &&
      (!address.city || address.city === "") &&
      (!address.state || address.state === "") &&
      (!address.postal_code || address.postal_code === ""))
  );
};

export const getUserLabel = (user?: User | CompanyUser): string => {
  if (!user) return "Unknown user";

  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  } else if (user.first_name) {
    return user.first_name;
  } else if (user.email) {
    return user.email;
  } else if (user.phone) {
    return user.phone;
  } else {
    return "Unknown user";
  }
};

export const sleep = (ms: number): Promise<void> => {
  return new Promise((res) => setTimeout(res, ms));
};

/**
 * Returns the years, months, and days between two DateTimes
 */
export const getTenureString = (start: DateTime, end: DateTime): string => {
  const tenure = start.diff(end, ["years", "months", "days", "hours"]);

  return (
    (tenure.years ? `${tenure.years === 1 ? "1 year" : `${tenure.years} years`}, ` : "") +
    (tenure.months ? `${tenure.months === 1 ? "1 month" : `${tenure.months} months`}, ` : "") +
    (tenure.days ? `${tenure.days === 1 ? "1 day" : `${tenure.days} days`} ` : "")
  );
};

export const getTenureInSeconds = (start: DateTime, end: DateTime): number => {
  const value = start.diff(end, "seconds").seconds;
  if (isNaN(value)) return 0;

  return value;
};

/**
 * Get's every nth element in an array
 */
export const everyNth = <T>(arr: T[], nth: number): T[] => {
  return arr.filter((_, i) => i % nth === nth - 1);
};

/**
 * Get a sample of n elements, evenly spaced, from an array
 */
export const sampleEven = <T>(arr: T[], n: number): T[] => {
  if (n > arr.length) return arr;

  const nth = Math.floor(arr.length / n);
  return everyNth(arr, nth);
};

export const SLACK_CHANNEL = {
  APP_NOTIFICATIONS: "C03SDSH7MGR",
  ACTIVITY_FEED: "C02VCMGM5KK",
  SLACK_TESTING: "C0327PC1AV8",
  PIPING_HOT_LEADS: "C0224GP2U6A",
  SUPPORT: "C04FL3PLR60",
  FRINGE_REPORT_FEEDBACK: "C079DCRK1U2",
  BENEFITS_FEEDBACK: "C07ACHUNY4S",
  STRIPE_ONBOARDING: "C07N2G9TZRS",
};

export const BANNER_NOTIFICATION = {
  _401K_INTEGRATION_WAITLIST: "401k_integration_waitlist",
  BENEFITS_ADMIN_WAITLIST: "benefits_admin_waitlist",
  LEGACY_FRINGE_REPORT: "legacy_fringe_report",
};

/** Flattens an object with nested objects, in preparation for an atomic Mongo update.
 * For example, { a: { b: 1, c: 2 } } becomes { "a.b": 1, "a.c": 2 }
 */
export const buildAtomicMongoUpdateFromNested = (
  nested: Record<string, unknown>,
  opts?: {
    /** Number of levels of the nested object to collapse. This essentially determines the number of dots in the keys of the final flattened object. If unspecified, the object will be infinitely flattened. */
    collapseCount?: number;
  }
): Record<string, $TSFixMe> => {
  const helper = (obj: Record<string, unknown>, collapses?: number) => {
    if (collapses != null && collapses <= 0) return obj;
    let clean = true;
    const flattenedObj: Record<string, $TSFixMe> = {};
    for (const [key, val] of Object.entries(obj)) {
      if (val && typeof val === "object" && !Array.isArray(val)) {
        const entries = Object.entries(val);
        if (entries.length) {
          clean = false;
          for (const [nestedKey, nestedVal] of entries) {
            flattenedObj[`${key}.${nestedKey}`] = nestedVal;
          }
        } else {
          flattenedObj[key] = val;
        }
      } else {
        flattenedObj[key] = val;
      }
    }
    if (clean) {
      return flattenedObj;
    } else {
      return helper(flattenedObj, collapses != null ? collapses - 1 : undefined);
    }
  };
  const deepCopy = cloneDeep(nested);
  return helper(deepCopy, opts?.collapseCount);
};

/** Converts an object such that its enumerable properties that are set to `undefined` are loaded into an `$unset`, in prep for mongo, since `undefined` doesn't survive JSON.stringify  */
export const convertUndefinedToMongoUnset = (obj: Record<string, unknown>): Record<string, $TSFixMe> => {
  const clone = cloneDeep(obj);
  if (!clone || typeof clone !== "object" || Array.isArray(clone)) return clone;

  const final: Record<string, $TSFixMe> = { $set: {}, $unset: {} };
  for (const [key, val] of Object.entries(clone)) {
    if (val === undefined) {
      final["$unset"] = { [key]: "" };
    } else {
      final["$set"] = { [key]: val };
    }
  }

  return final;
};

export const hasNoTruthyValues = (obj: Record<string, unknown>): boolean => {
  for (const value of Object.values(obj)) {
    if (value) {
      return false;
    }
  }
  return true;
};

export const convertFieldRequirementToBoolean = (fieldRequirement: FieldRequirement): boolean => {
  if (fieldRequirement === "hidden" || fieldRequirement === "optional") {
    return false;
  }
  return true;
};

export type MaybeArray<T> = T | T[];

export const convertToArray = <T>(maybeArray?: MaybeArray<T | null | undefined> | null): T[] => {
  if (!maybeArray) return [];
  const arr = Array.isArray(maybeArray) ? maybeArray : [maybeArray];
  return arr.filter(notNullish);
};

export const getIntegrationConnectionByKey = (
  integrationKey: MiterIntegrationKey,
  integrations: MiterIntegrationForCompany[]
): MiterIntegrationForCompany | undefined => {
  return integrations.find((integration) => integration.key === integrationKey);
};

export const createObjectMap = <T>(
  arr: T[],
  accessorFn: (o: T) => string | null | undefined
): Record<string, T> => {
  return arr.reduce((acc, curr) => {
    const key = accessorFn(curr);
    if (typeof key === "string") acc[key] = curr;
    return acc;
  }, {});
};

export const createObjectMapToArray = <T>(
  arr: T[],
  accessorFn: (o: T) => string | null | undefined
): Record<string, T[]> => {
  return arr.reduce((acc, curr) => {
    const key = accessorFn(curr);
    if (typeof key !== "string") return acc;

    const existingArray = acc[key];
    if (existingArray) {
      existingArray.push(curr);
    } else {
      acc[key] = [curr];
    }
    return acc;
  }, {} as Record<string, T[]>);
};

export const formatFromSnakeCase = (str: string): string => {
  const words = str.split("_");
  if (words.length > 0 && words[0]?.length) {
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  }
  return words.join(" ");
};

// "Justin Kuang", "Justin Kuang, Sean Derrow", or "Justin Kuang, Sean Derrow, +3"
export const truncateTeamMemberFullNameList = (
  teamMembers: TeamMember[] | AggregatedTeamMember[] | SlimJobTeamMember[]
): string | undefined => {
  let output: string | undefined;
  if (teamMembers.length === 1) {
    output = teamMembers?.[0]?.full_name;
  }
  if (teamMembers.length === 2) {
    const sup1 = teamMembers[0];
    const sup2 = teamMembers[1];
    output = sup1?.full_name + ", " + sup2?.full_name;
  }
  if (teamMembers.length > 2) {
    const sup1 = teamMembers[0];
    const sup2 = teamMembers[1];
    const remainingSups = teamMembers.length - 2;
    output = sup1?.full_name + ", " + sup2?.full_name + ", +" + remainingSups.toString();
  }

  return output;
};

export const EMPTY_FORAGE_RESPONSE = { data: [], next_page: null, prev_page: null };

export const handleForageResponse = async (
  resProm: Promise<ForageResponse & MiterError>
): Promise<ForageResponse> => {
  try {
    const res = await resProm;
    if (res.error) throw new Error(res.error);
    return res as ForageResponse;
  } catch (e: $TSFixMe) {
    console.log("Forage error:", e.message);
    Notifier.error(e.message);
    return EMPTY_FORAGE_RESPONSE;
  }
};

// helper for checking if a given option matches inputValue
export const withValue =
  (inputValue: string | boolean | undefined | null) =>
  (option: Option<string> | Option<boolean>): boolean => {
    return option.value === inputValue;
  };

// Build ignored posthog errors
export const buildIgnoredPosthogErrors = (): string[] => {
  const base = "Enqueued failed request for retry in  ";
  const values = [6000, 12000, 24000, 48000, 96000];

  return values.map((v) => base + v.toString()).concat("please take full snapshot after start recording");
};

export function getWorkHoursInYear<T extends { settings: Company["settings"] }>(company: T): number {
  return company.settings.payroll.work_hours_in_year;
}

export function getWorkWeeksInYear<T extends { settings: Company["settings"] }>(company: T): number {
  return getWorkHoursInYear(company) / WORK_HOURS_IN_WEEK;
}

export const useEnabledDemographicQuestions = (): NonNullable<
  CompanySettings["team"]["enabled_demographic_questions"]
> => {
  const activeCompany = useActiveCompany();
  return useMemo(() => activeCompany?.settings?.team?.enabled_demographic_questions || {}, [activeCompany]);
};

/** Utility function to get the role name. */
export const getRoleName = (role?: Role): string => {
  if (!role) return "Unknown";
  const fullName = role.full_name || role.first_name;
  return role.first_name && role.last_name
    ? `${role.first_name} ${role.last_name}`
    : fullName || role.email || "Unknown";
};

/** Clears any empty string and nullish values and converts any null strings into null values */
export const clearEmptyAndNullishValues = (data: Record<string, unknown>): Record<string, unknown> => {
  const cleanedData = cloneDeep(data);
  for (const [key, val] of Object.entries(cleanedData)) {
    // Make sure people don't accidentally clear out data during upsert if they accidentally click into a cell
    // Also clear empty objects
    if (val === "" || val == null || (typeof val === "object" && Object.keys(val).length === 0)) {
      delete cleanedData[key];
    }
    // But allow people to delete certain fields by having them explicitly set the value to the string "null"
    else if (val === "null") {
      cleanedData[key] = null;
    }
  }

  return cleanedData;
};

export const replaceBlankWithDashFormatter = (params: ValueFormatterParams): string => {
  return params.value || "-";
};
