import React from "react";

import DaySelect from "./daySelect";
import TimeSelect from "./timeSelect";

import "./dateTimePicker.css";
import { DateTime } from "luxon";

type Props = {
  select: (dt: DateTime) => void;
  selection?: DateTime;
  min?: DateTime;
  max?: DateTime;
  position?: {
    top?: number;
    left?: number;
    bottom?: number;
  };
  top?: number;
  left?: number;
  time?: boolean;
  handleReset?: () => void;
  onBlur: React.MouseEventHandler<HTMLButtonElement>;
};

const DateTimePicker: React.FC<Props> = ({
  min,
  max,
  selection,
  select,
  position,
  time,
  handleReset,
  onBlur,
}) => {
  return (
    <div
      // className="dt-picker-wrapper"
      style={{
        position: "absolute",
        top: position?.top,
        left: position?.left,
        bottom: position?.bottom,
        zIndex: 5,
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
      }}
      className="datetime-picker-container"
    >
      <div style={{ display: "flex", borderBottom: "1px solid lightgray" }}>
        <DaySelect min={min} max={max} selection={selection} select={select} />
        {time && (
          <>
            <div className="dt-spacer"></div>
            <TimeSelect selection={selection} select={select} />
          </>
        )}
      </div>
      <div className="dt-picker-bottom">
        <div className="flex-1"></div>
        {handleReset && (
          <button className="button-1" onClick={handleReset}>
            Reset
          </button>
        )}
        <button className="button-2 no-margin" onClick={onBlur}>
          Done
        </button>
      </div>
    </div>
  );
};

export default DateTimePicker;
