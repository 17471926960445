import React, { useContext, useState } from "react";

import { ActionModal, Formblock } from "ui";
import { useForm } from "react-hook-form";
import AppContext from "team-portal/contexts/app-context";
import Notifier from "team-portal/utils/notifier";
import { MiterAPI } from "team-portal/utils/miter";
import { useTranslation } from "react-i18next";
import { ChangeRequest } from "../../../backend/models";
import styles from "./teamPortalComponents.module.css";

type Props = {
  onHide: () => void;
};

export const EmergencyContactsForm: React.FC<Props> = ({ onHide }) => {
  const { activeTM, fetchUserData } = useContext(AppContext);
  const form = useForm();
  const { t } = useTranslation<$TSFixMe>();

  const [saving, setSaving] = useState(false);
  const { register, control, errors, handleSubmit } = form;

  const deptPolicyId = activeTM?.department_id;
  const companyPolicyId = activeTM?.company?.settings?.team?.default_change_request_policy_id;
  const enabledChangeRequestPolicies = activeTM?.company?.settings?.team?.enable_change_request_policies;
  const hasChangeRequestPolicy = enabledChangeRequestPolicies && !!(deptPolicyId || companyPolicyId);

  const save = async (data) => {
    if (!activeTM) return;
    setSaving(true);

    if (hasChangeRequestPolicy) {
      const fieldsChanged =
        activeTM.emergency_contacts !== data.emergency_contacts
          ? [
              {
                key: "emergency_contacts",
                value: {
                  previous_value: activeTM.emergency_contacts,
                  new_value: data.emergency_contacts,
                },
              },
            ]
          : [];
      if (!data || fieldsChanged.length === 0) {
        Notifier.error("No changes detected.");
        setSaving(false);
        return;
      }
      const changeRequestData: Partial<ChangeRequest> = {
        parent_type: "team_member",
        parent_id: activeTM._id,
        company_id: activeTM.company?._id,
        author_id: activeTM?.user,
        author_team_member_id: activeTM._id,
        department_id: activeTM?.department_id,
        fields_changed: fieldsChanged,
        child_fields: { category: "emergency-contacts" },
      };
      try {
        const response = await MiterAPI.change_requests.create(changeRequestData);
        if (response.error) throw new Error(response.error);
        Notifier.success("Change request created successfully");
        onHide();
      } catch (e: $TSFixMe) {
        Notifier.error(e.message);
      }
    } else {
      try {
        const response = await MiterAPI.team_member.update(activeTM._id, data);
        if (response.error) throw new Error(response.error);

        await fetchUserData();
        onHide();

        Notifier.success("Emergency contacts updated.");
      } catch (e: $TSFixMe) {
        console.error(e);
        Notifier.error(e.message);
      }
    }

    setSaving(false);
  };

  const renderForm = () => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <h2 style={{ fontSize: 16, marginTop: 0, fontWeight: "bolder" }}>Emergency contact #1</h2>
        <Formblock
          type="text"
          name="emergency_contacts[0].name"
          label={t("Name*")}
          control={control}
          register={register()}
          editing={true}
          errors={errors}
          defaultValue={activeTM?.emergency_contacts?.[0]?.name}
          className="modal"
        />
        <Formblock
          type="text"
          name="emergency_contacts[0].relationship"
          label={t("Relationship*")}
          control={control}
          register={register()}
          editing={true}
          errors={errors}
          defaultValue={activeTM?.emergency_contacts?.[0]?.relationship}
          className="modal"
        />
        <Formblock
          type="phone"
          name="emergency_contacts[0].phone"
          label={t("Phone*")}
          control={control}
          register={register()}
          editing={true}
          errors={errors}
          defaultValue={activeTM?.emergency_contacts?.[0]?.phone}
          className="modal"
        />
        <h2 style={{ fontSize: 16, marginTop: 20, fontWeight: "bolder" }}>Emergency contact #2</h2>
        <Formblock
          type="text"
          name="emergency_contacts[1].name"
          label={t("Name*")}
          control={control}
          register={register()}
          editing={true}
          errors={errors}
          defaultValue={activeTM?.emergency_contacts?.[1]?.name}
          className="modal"
        />
        <Formblock
          type="text"
          name="emergency_contacts[1].relationship"
          label={t("Relationship*")}
          control={control}
          register={register()}
          editing={true}
          errors={errors}
          defaultValue={activeTM?.emergency_contacts?.[1]?.relationship}
          className="modal"
        />
        <Formblock
          type="phone"
          name="emergency_contacts[1].phone"
          label={t("Phone*")}
          control={control}
          register={register()}
          editing={true}
          errors={errors}
          defaultValue={activeTM?.emergency_contacts?.[1]?.phone}
          className="modal"
        />
      </div>
    );
  };

  return (
    <ActionModal
      headerText={t("Edit emergency contacts")}
      showSubmit={true}
      showCancel={true}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={hasChangeRequestPolicy ? "Request changes" : "Save"}
      onHide={onHide}
      onSubmit={handleSubmit(save)}
      loading={saving}
    >
      {hasChangeRequestPolicy && (
        <div className={[styles["modal-banner"], styles["emergency-banner"]].join(" ")}>
          Your administrator requires approvals for changes to profile info. Your changes will be approved
          upon review.
        </div>
      )}
      {renderForm()}
    </ActionModal>
  );
};
