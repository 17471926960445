import React, { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import Notifier from "../../utils/notifier";
import Layout from "../Layout";
import AppContext from "team-portal/contexts/app-context";

const ProtectedRoute: React.FC<{ children: ReactNode; accessible: boolean }> = ({ children, accessible }) => {
  const { activeTM } = useContext(AppContext);

  if (!localStorage.getItem("authToken")) {
    Notifier.error("You must be logged in to access this page.");
    return <Navigate to={"/login?redirect=" + window.location.pathname + window.location.search} />;
  }

  if (activeTM && accessible) {
    return <Layout>{children}</Layout>;
  } else if (activeTM) {
    Notifier.error("You are not authorized to access this page.");
    return <Navigate to="/dashboard" replace />;
  }
  return null;
};

export default ProtectedRoute;
