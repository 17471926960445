import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import React, { FC, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import "./DateTimePicker.css";
import { useDebouncedCallback } from "use-debounce";

export type MonthYearSelection = {
  month: number; // 0-11
  year: number; // ex. 2024
};

type Props = {
  value?: MonthYearSelection;
  min?: MonthYearSelection;
  max?: MonthYearSelection;
  timeIntervals?: number;
  dateFormat?: string;
  className?: string;
  onChange: (monthAndYear: MonthYearSelection | undefined) => void;
  sideEffect?: () => void;
  inputClassName?: string;
  disabled?: boolean;
  ignoreToText?: boolean;
  placeholder?: string;
  debounceMs?: number;
};

const MonthYearPicker: FC<Props> = ({
  min,
  max,
  value,
  dateFormat,
  className,
  onChange,
  sideEffect,
  inputClassName,
  disabled,
  placeholder,
  debounceMs,
}) => {
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState<MonthYearSelection | undefined>(value);

  const minDate = min ? new Date(min.year, min.month, 1) : DateTime.now().minus({ years: 100 }).toJSDate();
  const maxDate = max ? new Date(max.year, max.month, 1) : DateTime.now().plus({ years: 100 }).toJSDate();

  useEffect(() => {
    setSelectedMonthAndYear(value);
  }, [value]);

  const handleMonthChange = (date: Date | null) => {
    // date will be midnight of the first day of the month in the user's time zone.

    if (!date) {
      setSelectedMonthAndYear(undefined);
      onChange(undefined);
    } else {
      const month = date.getMonth(); // 0-11
      const year = date.getFullYear();

      const newMonthAndYear = {
        month: month,
        year: year,
      };

      setSelectedMonthAndYear(newMonthAndYear);
      onChange(newMonthAndYear);
    }

    if (sideEffect) sideEffect();
  };

  const debouncedHandleMonthChange = useDebouncedCallback(handleMonthChange, debounceMs || 0);

  return (
    <div className={"datetime-picker-container-v2 " + (className ? className : "")}>
      <div className={"datetime-picker-date-container " + "date-only"} style={{ alignItems: "center" }}>
        <DatePicker
          showMonthYearPicker={true}
          selected={
            selectedMonthAndYear ? new Date(selectedMonthAndYear.year, selectedMonthAndYear.month, 1) : null
          }
          onChange={(date) => debouncedHandleMonthChange(date)}
          dateFormat={dateFormat || "MMM yyyy"}
          minDate={minDate}
          maxDate={maxDate}
          className={"form2-text " + inputClassName}
          wrapperClassName={"datetime-picker-date-wrapper"}
          placeholderText={placeholder || "Select a month"}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default MonthYearPicker;
