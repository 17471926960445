export type ComparisonType =
  | "-" // what is this??
  // for "string":
  | "exact"
  | "in"
  | "id"
  // for "search" but maybe also "string":
  | "contains"
  | "not.contains"
  // For all others:
  | "equal"
  // For datestring and timestamp:
  | "between"
  | "before"
  | "after"
  // For number, but may not be in use:
  | "greater"
  | "less"
  // Not sure about these:
  | "="
  | "ne"
  | "<"
  | "<="
  | ">"
  | ">="
  | "<+>"
  | "exists"
  | "startsWith"
  | "endsWith"
  | "include_archived";

/** Should probably be replaced with MiterFilterField from the backend. */
export type FilterType = {
  type?:
    | "_id"
    | "string"
    | "date_string"
    | "number"
    | "dollars-cents"
    | "boolean"
    | "enum"
    | "timestamp"
    | "search";
  field?: string; // required except for search.
  fields?: string[]; // only for search
  label?: string;
  value?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  values?: { label: string; value: string }[]; // for selects… but may not be right…
  comparisonType?: ComparisonType;
};

// We may also need to add something for array values…
export type FilterValue = { label: string; value: string | number | null };

export const castToFilterValue = (
  selectValue: FilterValue | FilterValue["value"],
  selectOptions: FilterValue[]
): FilterValue => {
  if (typeof selectValue === "string" || typeof selectValue === "number" || selectValue == null) {
    return selectOptions?.find(({ value }) => value === selectValue) || { value: selectValue, label: "" };
  }
  return selectValue;
};
