/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { UseFormMethods, useWatch } from "react-hook-form";
import { Formblock } from "ui";
import { useTranslation } from "react-i18next";

import { CustomField, CustomFieldValue, FrontendModel } from "dashboard/miter";
import { ValidationRuleset } from "ui/form/Input";
import { getCustomFieldTypeForFormBlock, renderCustomFieldDefaultString } from "miter-utils";
import { FormField as FormField_ } from "backend/models/form";
import { DateTime } from "luxon";
import { StylesConfig } from "react-select";
import { Option } from "ui/form/Input";

type FormField = FrontendModel<FormField_>;

type Props = {
  form: UseFormMethods<$TSFixMe>;
  customFields: CustomField[];
  defaultValues: CustomFieldValue[];
  formblockClassName?: string;
  inputClassName?: string;
  editing?: boolean;
  fieldOptions?: { inlineCheckbox?: boolean; disabled?: boolean };
  replaceLabelWithPlaceholder?: boolean;
  selectStyles?: StylesConfig<Option<string>, boolean>;
};

const CustomFieldValuesForm: React.FC<Props> = ({
  form,
  customFields,
  defaultValues,
  formblockClassName,
  inputClassName,
  editing,
  fieldOptions,
  replaceLabelWithPlaceholder,
  selectStyles,
}) => {
  useWatch({ control: form.control });
  const { control, register, errors } = form;
  const { t } = useTranslation<$TSFixMe>();

  const { inlineCheckbox } = fieldOptions || {};

  const buildValidationRuleSet = (cf: CustomField): ValidationRuleset => {
    const { required, min, max } = cf.validations || {};

    const rules: ValidationRuleset = {};

    if (required) {
      // it doesn't make sense to mark a checkbox field as required, because then you're forced to select "yes"
      if (cf.type !== "checkbox") {
        rules.required = t("This field is required");
      }
    }

    if (cf.type === "number" || cf.type === "file") {
      if (min !== null && min !== undefined) {
        rules.min = {
          value: min,
          message: t("Must be at least " + min),
        };
      }

      if (max !== null && max !== undefined) {
        rules.max = {
          value: max,
          message: t("Must be at most " + max),
        };
      }
    }

    return rules;
  };

  const buildFieldSpecificProps = (cf: CustomField | FormField) => {
    const props: any = {};

    if (cf.type === "select") {
      props.options = cf.options?.map((o) => {
        if (typeof o === "object" && "_id" in o) {
          return {
            value: o._id,
            label: o.name,
          };
        } else {
          return {
            value: o,
            label: o,
          };
        }
      });
    }

    if (cf.type === "number") {
      if (cf.validations?.min !== null && cf.validations?.min !== undefined) {
        props.min = cf.validations.min;
      }

      if (cf.validations?.max !== null && cf.validations?.max !== undefined) {
        props.max = cf.validations.max;
      }
    }

    if (cf.type === "date") {
      props.dateOnly = true;

      if (cf.validations?.min_date) {
        props.min = DateTime.fromISO(cf.validations.min_date);
      }

      if (cf.validations?.max_date) {
        props.max = DateTime.fromISO(cf.validations.max_date);
      }
    }

    return props;
  };

  const renderCustomFields = () => {
    return customFields.map((cf) => {
      const cfv = defaultValues.find((df) => df.custom_field_id === cf._id);
      const defaultString = renderCustomFieldDefaultString(cf, cfv);
      const defaultValue = buildCustomFieldValue(cf, cfv);

      const hideLabel =
        (replaceLabelWithPlaceholder && cf.type !== "checkbox") || (cf.type === "checkbox" && inlineCheckbox);
      const showSideText = cf.type === "checkbox" && inlineCheckbox;
      const editable = editing !== undefined ? editing : true;

      const validations = buildValidationRuleSet(cf);
      const fieldSpecificProps = buildFieldSpecificProps(cf);

      const type = getCustomFieldTypeForFormBlock(cf);

      return (
        <Formblock
          key={cf._id}
          type={type}
          label={!hideLabel ? cf.name : undefined}
          labelInfo={cf.description}
          text={showSideText ? cf.name : undefined}
          name={cf._id}
          errors={errors}
          register={register(validations)}
          control={control}
          defaultValue={defaultValue}
          defaultString={defaultString}
          editing={editable}
          className={formblockClassName || "modal"}
          inputClassName={inputClassName}
          placeholder={replaceLabelWithPlaceholder ? t("Select ") + cf.name : cf.placeholder}
          rules={validations}
          selectStyles={selectStyles}
          // Add select options if select field
          {...fieldSpecificProps}
          disabled={fieldOptions?.disabled}
          isClearable={true}
        />
      );
    });
  };
  return <div>{renderCustomFields()}</div>;
};

export default CustomFieldValuesForm;

export const buildCustomFieldValue = (
  cf: CustomField | FormField,
  cfv: CustomFieldValue | undefined
): any => {
  if (cf.type === "date" && cfv?.value && typeof cfv.value === "string") {
    return DateTime.fromISO(cfv.value);
  }

  if (cf.type === "checkbox" && typeof cfv?.value !== "boolean") {
    return cfv?.value === "true";
  }

  if (cf.type === "select" && cf?.multiple && Array.isArray(cfv?.value)) {
    return (cfv?.value as string[]).map((val) => val?.toString());
  }

  if (cf.type === "select" && typeof cfv?.value !== "string") {
    return cfv?.value?.toString();
  }

  return cfv?.value;
};
