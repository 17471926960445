import React, { FC, ReactNode } from "react";
import "./link.css";

type Props = {
  onClick?: () => void;
  children?: ReactNode;
  style?: React.CSSProperties;
};

const Link: FC<Props> = ({ onClick, children, style }) => {
  return (
    <div style={style} className="tm-link" onClick={onClick}>
      {children}
    </div>
  );
};

export default Link;
