import ObjectID from "bson-objectid";
import { getOS, notNullish } from "./misc";
import { ESignatureItem, MiterAPI, FormSubmission } from "dashboard/miter";
import { SaveExternalFormDocuments } from "miter-components/forms/utils";

export const saveESignatures = async (
  argParams: SaveExternalFormDocuments
): Promise<FormSubmission["answers"]> => {
  const { formSubmissionId, params, formItem, account, deviceType } = argParams;

  const { roleId, userId, accountType, title, company_id, team_member_id, full_name } = account;
  const esignParams = (params.answers || [])
    .map((answer) => {
      const field = formItem.components.find((f) => f._id === answer.form_field_id);
      const esignatureItem = answer.value as ESignatureItem;
      if (!field || field.type !== "esignature" || !answer.value || !!esignatureItem?.signature?.image) {
        return;
      }

      const _id = ObjectID().toHexString();

      const item_params = {
        _id,
        company_id,
        document_type: "form_submission",
        parent_document_id: formSubmissionId,
        signature: {
          image: answer.value,
          device: { type: deviceType, os: getOS() },
          application: { name: "dashboard" },
        },
        signer: {
          name: full_name,
          type: accountType,
          title: title,
          role_id: roleId,
          user_id: userId,
          team_member_id,
        },
      };

      return { item_params, form_field_id: answer.form_field_id };
    })
    .filter(notNullish);

  if (esignParams.length === 0) return params.answers || [];

  // @ts-expect-error fix me
  const res = await MiterAPI.esignature_items.signatures.create(esignParams.map((p) => p.item_params));
  if (res.error) throw new Error(res.error);

  // Update the form submissions with the esignature item cached
  const updatedAnswers = (params.answers || []).map((answer) => {
    const field = formItem.components.find((f) => f._id === answer.form_field_id);
    if (!field || field.type !== "esignature") return answer;

    const params = esignParams.find((p) => p.form_field_id === answer.form_field_id);
    if (!params) return answer;

    const esignatureItem = res.find((r) => r._id === params.item_params._id);

    return {
      _id: answer._id,
      form_field_id: answer.form_field_id,
      value: esignatureItem,
    };
  });
  // @ts-expect-error fix me
  return updatedAnswers;
};
