import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dashboardTranslation from "./dashboard";
import tabsTranslation from "./tabs";
import onboardingTranslation from "./onboarding";
import emergencyTranslation from "./emergency";
import paystubTranslation from "./paystub";
import timeoffTranslation from "./timeoff";
import profileTranslation from "./profile";
import paymentInfoTranslation from "./paymentInfo";
import onboardingV2Translation from "./onboardingV2";

const allMappings = {};
const seenKeys = new Set();

[
  dashboardTranslation,
  tabsTranslation,
  onboardingTranslation,
  emergencyTranslation,
  paystubTranslation,
  timeoffTranslation,
  profileTranslation,
  paymentInfoTranslation,
  onboardingV2Translation,
].forEach((translationModule) => {
  Object.keys(translationModule).forEach((key) => {
    if (seenKeys.has(key)) {
      console.warn(`Duplicate translation key detected: "${key}". This may lead to unexpected behavior.`);
      // In case of a collision, the later definition will overwrite the earlier one.
      // Here, the key from the current module will be used, replacing the previous definition.
    } else {
      seenKeys.add(key);
    }
    allMappings[key] = translationModule[key];
  });
});

export type TranslationMap = {
  [key: string]: {
    en: string;
    es: string;
  };
};

const mapToi18n = (obj: TranslationMap) => {
  const i18Mapping = {
    en: {
      translation: {},
    },
    es: {
      translation: {},
    },
  };
  return Object.entries(obj).reduce((acc, curr) => {
    const [key, value] = curr;
    const { es, en } = value;
    acc.en.translation[key] = en;
    acc.es.translation[key] = es;
    return acc;
  }, i18Mapping);
};

const resources = mapToi18n(allMappings);

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  debug: false,
  resources,
});

export default i18n;
