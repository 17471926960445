import * as React from "react";

import WizardContext, { WizardContextProps } from "./WizardContext";

const useWizard = (): WizardContextProps => {
  const context = React.useContext(WizardContext);
  return context;
};

export default useWizard;
