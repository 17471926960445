/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { StylesConfig } from "react-select";
import { Option } from "./Input";

export const styles: StylesConfig<Option<string>, boolean> = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (provided, state) => ({
    boxShadow: "none",
    fontSize: "15px",
    backgroundColor: state.selectProps.isDisabled ? "#fafafa" : "white",
    borderRadius: "4px",
    border: state.isFocused ? "1px solid rgb(24, 61, 226)" : "1px solid rgb(211, 211, 211)",
    minHeight: state.selectProps.height,
    height: state.selectProps.height,
    boxSizing: "border-box",
    isDisabled: state.selectProps.isDisabled,
    width: state.selectProps.width ? state.selectProps.width : "100%",
    display: "flex",
    alignItems: "center",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 25,
  }),
  container: (provided, state) => ({
    ...provided,
    width: state.selectProps.width ? state.selectProps.width : "100%",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: state.selectProps.height,
    cursor: "pointer",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: state.selectProps.noDropdown === true ? "none" : "absolute",
  }),
  clearIndicator: (provided, state) => ({ ...provided, padding: "0px" }),
  input: (provided, state) => ({
    ...provided,
    cursor: "none",
    fontSize: "15px",
    margin: "0px",
  }),
  valueContainer: (base, state) => ({
    ...base,
    minHeight: "19px",
    height: state.selectProps.height - 2,
    padding: "4px 6px",
    display: "flex",
    alignItems: "center",
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: "none",
  }),
  placeholder: (base, state) => ({
    ...base,
    display: state.selectProps.width < 150 ? "none" : "flex",
    fontSize: "15px",
    color: "gray",
  }),
};

export const stateStyles = {
  control: (provided, state) => ({
    boxShadow: "none",
    backgroundColor: "white",
    fontSize: "15px",
    borderRadius: "4px",
    border: state.isFocused ? "1px solid rgb(24, 61, 226)" : "1px solid rgb(211, 211, 211)",
    minHeight: state.selectProps.height,
    height: state.selectProps.height,
    width: state.selectProps.width,
    display: "flex",
    alignItems: "center",
  }),
  // menuPortal: (provided, state) => ({ ...provided, position: "relative", zIndex: 6 }),
};

export const dateStyles = {
  control: (provided, state) => ({
    boxShadow: "none",
    backgroundColor: "white",
    fontSize: "15px",
    borderRadius: "4px",
    border: state.isFocused ? "1px solid rgb(24, 61, 226)" : "1px solid rgb(211, 211, 211)",
    minHeight: state.selectProps.height,
    height: state.selectProps.height,
    width: state.selectProps.width ? state.selectProps.width : "100%",
    display: "flex",
    alignItems: "center",
  }),
  container: (provided, state) => ({
    ...provided,
    width: state.selectProps.width ? state.selectProps.width : "100%",
  }),
};

export const smallStyles = {
  control: (provided, state) => ({
    boxShadow: "none",
    fontSize: "14px",
    backgroundColor: "white",
    // boxSizing: "border-box",
    borderRadius: "4px",
    border: state.isFocused ? "1px solid rgb(24, 61, 226)" : "1px solid rgb(211, 211, 211)",
    minHeight: "30px",
    height: "30px",
    width: state.selectProps.width ? state.selectProps.width : "100%",
    display: "flex",
    alignItems: "center",
  }),
  container: (provided, state) => ({
    ...provided,
    width: state.selectProps.width ? state.selectProps.width : "100%",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "30px",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: state.selectProps.noDropdown === true ? "none" : "absolute",
  }),
  clearIndicator: (provided, state) => ({ ...provided, padding: "0px" }),
  input: (provided, state) => ({
    ...provided,
    cursor: "none",
    fontSize: "14px",
    margin: "0px",
  }),
  valueContainer: (base, state) => ({
    ...base,
    minHeight: "19px",
    height: "30px",
    padding: "0 6px",
    display: "flex",
    fontSize: "14px",

    alignItems: "center",
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: "none",
  }),
  placeholder: (base, state) => ({
    ...base,
    display: state.selectProps.width < 150 ? "none" : "flex",
    fontSize: "14px",
    color: "gray",
  }),
  menu: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    padding: "0px",
    // height: "20px",
  }),
};

export const selectStyles = styles;
