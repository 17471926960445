import React, { FC, useCallback, useEffect, useState, useMemo } from "react";
import { WizardScreen, Notifier } from "ui";
import { FormProvider, useForm } from "react-hook-form";
import { CollectFormFields } from "miter-components";
import {
  FileUploadParams,
  FormField,
  generateSyncedCachedFileUploadIds,
  prepareCertificationFields,
  prepareCertificationFormAnswers,
  updateFiles,
} from "miter-utils";
import { FilePickerFile } from "ui/form/FilePicker";
import { DateTime } from "luxon";
import ObjectID from "bson-objectid";
import { isEmpty } from "lodash";
import useWizard from "ui/modal/useWizard";
import {
  MiterError,
  MiterAPI,
  CertificationOnboardingTask,
  AggregatedTeamMemberOnboardingTask,
} from "dashboard/miter";
import {
  CertificationType,
  AggregatedCertification,
  Certification,
} from "dashboard/types/certification-types";
import { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import { BulkCreateResponse } from "backend/types";
import { useTranslation } from "react-i18next";
import styles from "dashboard/components/team-members/TeamMemberWizard.module.css";
import { Loader } from "ui";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  refetchTeamMember: () => Promise<void>;
  onboardingTask: CertificationOnboardingTask;
  updateOnboardingChecklistTask: (task: Partial<AggregatedTeamMemberOnboardingTask>) => Promise<void>;
  companyId: string;
  certificationType: CertificationType;
};

export type CertificationFormValues = {
  custom_fields: { [form_field_id: string]: string };
  file_uploads: { [form_field_id: string]: FilePickerFile[] };
  expires_at: DateTime;
};

export const CertificationWizardScreen: FC<Props> = ({
  name,
  teamMember,
  refetchTeamMember,
  onboardingTask,
  updateOnboardingChecklistTask,
  companyId,
  certificationType,
}) => {
  const { t } = useTranslation<$TSFixMe>();
  const form = useForm<CertificationFormValues>({
    mode: "all",
  });

  const [certification, setCertification] = useState<AggregatedCertification | undefined>(undefined);
  const [loading, setLoading] = useState(!!onboardingTask.certification_id);

  const { trigger, errors: formErrors, formState, handleSubmit } = form;
  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();

  useEffect(() => {
    if (!isEmpty(formErrors) && !isEmpty(formState.errors)) {
      setCanNext(false);
    } else {
      setCanNext(true);
    }
  }, [formState, formErrors, setCanNext]);

  useEffect(() => {
    setNextButtonText(curIndex + 1 >= screens.length ? t("Save and exit") : t("Save and continue"));
  }, [curIndex, screens.length, setNextButtonText, t]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  const getCertification = useCallback(async () => {
    if (!onboardingTask.certification_id || !teamMember || !teamMember._id) return;
    setLoading(true);
    try {
      const res = await MiterAPI.certifications.forage({
        filter: [
          { field: "team_member_id", value: teamMember._id, type: "string" },
          { field: "certification_type_id", value: onboardingTask.certification_type_id, type: "string" },
          { field: "_id", value: onboardingTask.certification_id, type: "string" },
        ],
        limit: 1,
      });
      if (res.data.length > 0) {
        setCertification(res.data[0]);
      }
    } catch (e: $TSFixMe) {
      Notifier.error(t("There was an error retrieving the certification: ") + e.message);
    }
    setLoading(false);
  }, [onboardingTask.certification_id, onboardingTask.certification_type_id, teamMember, t]);

  useEffect(() => {
    getCertification();
  }, [getCertification]);

  const saveCertification = async (data: CertificationFormValues) => {
    if (!teamMember || !teamMember._id || !onboardingTask.certification_type_id) return;

    try {
      const customFieldValues = Object.entries(data.custom_fields || {}).map(([form_field_id, value]) => ({
        _id: new ObjectID().toHexString(),
        form_field_id,
        value,
      }));

      const params = {
        certification_type_id: onboardingTask.certification_type_id,
        team_member_id: teamMember._id,
        company_id: companyId,
        submitted: true,
        expires_at: data.expires_at ? data.expires_at.toISODate() : undefined,
        archived: false,
        custom_field_values: customFieldValues,
        file_uploads: data.file_uploads,
      };

      let res: (Certification & MiterError) | (BulkCreateResponse<Certification> & MiterError) | null = null;

      if (certification) {
        res = await MiterAPI.certifications.update(certification._id, params);
      } else {
        res = await MiterAPI.certifications.create([params]);
      }

      if (res.error) {
        throw new Error(res.error);
      }

      if ("failures" in res && (res as BulkCreateResponse<Certification>).failures.length > 0) {
        throw new Error((res as BulkCreateResponse<Certification>).failures[0]?.message);
      }

      const certificate = "successes" in res ? res?.successes?.[0] : res;
      if (!certificate) {
        throw new Error("Certification was not created.");
      }

      const fileUploadParams: FileUploadParams = {
        parent_id: certificate._id,
        parent_type: "certification",
        company_id: companyId,
      };

      const file_uploads = data.file_uploads || {};
      const promises = await Promise.all(
        Object.keys(file_uploads).map(async (formFieldIdKey) => {
          return {
            formFieldId: formFieldIdKey,
            value: await updateFiles(file_uploads[formFieldIdKey] || null, fileUploadParams),
          };
        })
      );

      const syncedCachedIds = generateSyncedCachedFileUploadIds(promises, certificate);

      await MiterAPI.certifications.update(certificate._id, {
        file_upload_values: syncedCachedIds,
      });

      await updateOnboardingChecklistTask({
        ...onboardingTask,
        status: "complete",
        certification_id: certificate._id,
      });

      await refetchTeamMember();
      Notifier.success(t("Certification uploaded successfully"));
      if (curIndex === screens.length - 1) {
        handleComplete();
      }
    } catch (e: $TSFixMe) {
      Notifier.error(t("There was an error uploading the certification: ") + e.message);
    }
  };

  const onNext = async () => {
    await handleSubmit(saveCertification)();
    if (curIndex + 1 >= screens.length) {
      handleComplete();
    }
  };

  const formattedFormFields = useMemo(
    () => prepareCertificationFields(certificationType, t),
    [certificationType, t]
  );
  const formattedFormAnswers = useMemo(() => prepareCertificationFormAnswers(certification), [certification]);

  return (
    <WizardScreen onNext={onNext} name={name} key={name}>
      <div className={styles["content"]}>
        <h2 className={styles["subheader-title"]}>{t("Upload") + " " + certificationType.title}</h2>
        <div className="form-builder">
          {certificationType.description && (
            <div style={{ marginBottom: 20 }}>{certificationType.description}</div>
          )}
          {loading && <Loader />}
          {!loading && (
            <FormProvider {...form}>
              <CollectFormFields
                formFields={formattedFormFields as FormField[]}
                formAnswers={formattedFormAnswers}
                readonly={false}
              />
            </FormProvider>
          )}
        </div>
      </div>
    </WizardScreen>
  );
};
