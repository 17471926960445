import React, { FC, CSSProperties } from "react";
import ReactTooltip from "react-tooltip";
import { IconType } from "react-icons";
import { Icon as PhosphorIcon, IconWeight as PhosphorIconWeight } from "phosphor-react";

type Props = {
  Icon?: IconType;
  PhosphorIcon?: PhosphorIcon;
  style?: CSSProperties;
  tooltip?: string;
  place?: "top" | "right" | "left" | "bottom";
  onClick?: () => void;
  size?: number;
  weight?: PhosphorIconWeight;
};

const IconWithTooltip: FC<Props> = ({
  Icon,
  PhosphorIcon,
  style,
  tooltip,
  place = "top",
  onClick,
  size,
  weight,
}) => {
  return (
    <div onClick={onClick} style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      {Icon && <Icon style={style} data-tip={tooltip} data-for={tooltip} />}
      {PhosphorIcon && (
        <PhosphorIcon
          style={{ ...style, marginBottom: -1 }}
          data-tip={tooltip}
          data-for={tooltip}
          size={size}
          weight={weight}
        />
      )}
      <ReactTooltip id={tooltip} place={place} effect={"solid"} className="tooltip">
        <span>{tooltip}</span>
      </ReactTooltip>
    </div>
  );
};

export default IconWithTooltip;
