import React from "react";

/**
 * Keep the cursor position in the same place.
 * Does not work with type="number", so you have to use type="text" and set inputMode="numeric".
 * https://stackoverflow.com/a/68928267/1048433
 */
const ControlledInputWithCursorPosition = (props: React.HTMLProps<HTMLInputElement>): React.ReactElement => {
  const { value, onChange, ...rest } = props;
  const [cursor, setCursor] = React.useState<number | null>(null);
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    const input = ref.current;
    if (input && cursor != null) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref, cursor, value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCursor(e.target.selectionStart);
    onChange?.(e);
  };

  return <input ref={ref} value={value} onChange={handleChange} {...rest} />;
};

export default ControlledInputWithCursorPosition;
