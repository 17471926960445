import { PickAndRestPartial } from "backend/utils";
import { UnionRate } from "dashboard/miter";

export type ClassificationTableEntry = PickAndRestPartial<
  UnionRate,
  "base_rate" | "classification" | "company" | "fringes" | "pay_rate_group" | "_id"
> & {
  changed?: boolean;
  new?: boolean;
  is_default?: boolean;
};

export const unionRateSorter = (a: UnionRate, b: UnionRate): number => {
  if (a.rowIndex != null && b.rowIndex != null) return a.rowIndex - b.rowIndex;
  if (a.rowIndex == null && b.rowIndex != null) return 1;
  if (a.rowIndex != null && b.rowIndex == null) return -1;
  return 0;
};

export const unionRateFringeLookup = {
  health_and_welfare: "Health & Welfare",
  pension: "Pension",
  dues: "Dues",
  training: "Training fund",
  vacation_holiday: "Vacation/holiday",
  travel_subsistence: "Travel/subsistence",
  sub: "Supplemental unemployment",
  other: "Other",
};
