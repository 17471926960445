import { FilePickerFile } from "./form/FilePicker";
import { ColumnConfig, ColumnOrGroupConfig, TData } from "./table-v2/Table";

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop(..._args: unknown[]): void {}

export const usdString = (
  num: string | number | null | undefined,
  maxDigits?: number,
  currency: CurrencyCode = "USD"
): string => {
  const sanitizedNum = !num || isNaN(Number(num)) ? 0 : Number(num);
  const formatParams: {
    style: "currency";
    currency: CurrencyCode;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
  } = {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
  };
  if (maxDigits) {
    formatParams.maximumFractionDigits = maxDigits;
  }
  return new Intl.NumberFormat("en-US", formatParams).format(sanitizedNum);
};

export const validateFilesRequired = (files: FilePickerFile[]): boolean | string => {
  const nonDeletedFiles = files.filter((file) => !file.deleted);
  if (nonDeletedFiles.length === 0) {
    return "This field is required";
  }
  return true;
};

export const validateMinFiles = (min: number): ((files: FilePickerFile[]) => boolean | string) => {
  const validateMinimum = (files: FilePickerFile[]): boolean | string => {
    const nonDeletedFiles = files.filter((file) => !file.deleted);
    if (nonDeletedFiles.length < min) {
      return "This field requires at least " + min + " files";
    }
    return true;
  };

  return validateMinimum;
};

export const getAllChildColumnConfigs = <D extends TData>(
  columns: ColumnOrGroupConfig<D>[]
): ColumnConfig<D>[] => {
  const cols: ColumnConfig<D>[] = [];
  const fullArr = columns.slice();
  while (fullArr.length) {
    const col = fullArr.shift();
    if (!col) break;
    if ("children" in col) {
      fullArr.unshift(
        ...col.children.map((child) => {
          return {
            ...child,
            parentHeaderName: col.headerName,
          };
        })
      );
    } else {
      cols.push(col as ColumnConfig<D>);
    }
  }
  return cols;
};

/** Mutates in place the requested update, accounting for dot notation in the field.  */
export const makeNestedUpdateUsingDotNotation = <D extends TData>(
  field: string,
  newValue: $TSFixMe,
  dataToUpdate: D
): void => {
  const anyData = dataToUpdate as $TSFixMe;
  if (field.includes(".")) {
    const keys = field.split(".");
    const lastKey = keys.pop();
    let nestedObj = anyData;
    for (const key of keys) {
      if (!nestedObj[key]) {
        nestedObj[key] = {};
      }
      nestedObj = nestedObj[key];
    }
    nestedObj[lastKey!] = newValue;
  } else {
    anyData[field] = newValue;
  }
};

type CurrencyCode =
  | "AED"
  | "AFN"
  | "ALL"
  | "AMD"
  | "ANG"
  | "AOA"
  | "ARS"
  | "AUD"
  | "AWG"
  | "AZN"
  | "BAM"
  | "BBD"
  | "BDT"
  | "BGN"
  | "BHD"
  | "BIF"
  | "BMD"
  | "BND"
  | "BOB"
  | "BRL"
  | "BSD"
  | "BTN"
  | "BWP"
  | "BYN"
  | "BZD"
  | "CAD"
  | "CDF"
  | "CHF"
  | "CLP"
  | "CNY"
  | "COP"
  | "CRC"
  | "CUP"
  | "CVE"
  | "CZK"
  | "DJF"
  | "DKK"
  | "DOP"
  | "DZD"
  | "EGP"
  | "ERN"
  | "ETB"
  | "EUR"
  | "FJD"
  | "FKP"
  | "FOK"
  | "GBP"
  | "GEL"
  | "GGP"
  | "GHS"
  | "GIP"
  | "GMD"
  | "GNF"
  | "GTQ"
  | "GYD"
  | "HKD"
  | "HNL"
  | "HRK"
  | "HTG"
  | "HUF"
  | "IDR"
  | "ILS"
  | "IMP"
  | "INR"
  | "IQD"
  | "IRR"
  | "ISK"
  | "JEP"
  | "JMD"
  | "JOD"
  | "JPY"
  | "KES"
  | "KGS"
  | "KHR"
  | "KID"
  | "KMF"
  | "KRW"
  | "KWD"
  | "KYD"
  | "KZT"
  | "LAK"
  | "LBP"
  | "LKR"
  | "LRD"
  | "LSL"
  | "LYD"
  | "MAD"
  | "MDL"
  | "MGA"
  | "MKD"
  | "MMK"
  | "MNT"
  | "MOP"
  | "MRU"
  | "MUR"
  | "MVR"
  | "MWK"
  | "MXN"
  | "MYR"
  | "MZN"
  | "NAD"
  | "NGN"
  | "NIO"
  | "NOK"
  | "NPR"
  | "NZD"
  | "OMR"
  | "PAB"
  | "PEN"
  | "PGK"
  | "PHP"
  | "PKR"
  | "PLN"
  | "PYG"
  | "QAR"
  | "RON"
  | "RSD"
  | "RUB"
  | "RWF"
  | "SAR"
  | "SBD"
  | "SCR"
  | "SDG"
  | "SEK"
  | "SGD"
  | "SHP"
  | "SLL"
  | "SOS"
  | "SRD"
  | "SSP"
  | "STN"
  | "SYP"
  | "SZL"
  | "THB"
  | "TJS"
  | "TMT"
  | "TND"
  | "TOP"
  | "TRY"
  | "TTD"
  | "TVD"
  | "TWD"
  | "TZS"
  | "UAH"
  | "UGX"
  | "USD"
  | "UYU"
  | "UZS"
  | "VES"
  | "VND"
  | "VUV"
  | "WST"
  | "XAF"
  | "XCD"
  | "XDR"
  | "XOF"
  | "XPF"
  | "YER"
  | "ZAR"
  | "ZMW"
  | "ZWL";
