import { Company } from "dashboard/miter";
import { DateTime } from "luxon";
import { IS_PRODUCTION } from "./environment";

const bankItemLookup = {
  bank_account_exists: "Connect bank account",
  bank_account_verified: "Verify bank account",
  bank_account_debit_authorization_submitted: "Submit debit authorization for bank account",
};

export type PayrollReadiness = {
  ready: boolean;
  status: string | null;
  onboarding: boolean;
  remaining_onboard_tasks: string[];
  payroll_blocking_tasks: string[];
  payroll_non_blocking_tasks: string[];
};

export const getPayrollReadinessForCompany = (company: Company): PayrollReadiness => {
  if (!company?.check_company) {
    return {
      ready: false,
      status: null,
      onboarding: false,
      remaining_onboard_tasks: [],
      payroll_blocking_tasks: [],
      payroll_non_blocking_tasks: [],
    };
  }

  const remainingOnboardTasks: string[] = [];
  const payrollBlockingTasks: string[] = [];
  const payrollNonBlockingTasks: string[] = [];

  const checkOnboard = company.check_company?.onboard;
  const checkImplementation = company.check_company?.implementation;
  const checkInGoodStanding = company.check_company?.in_good_standing;
  let onboardingBlocking = false;

  // Determine whether Stripe customer has added a payment method and add task
  // if necessary
  if (!company.billing?.has_payment_method) {
    remainingOnboardTasks.push("payment_method");
    onboardingBlocking = true;
  }

  // Determine whether Company has a "bad" invoice (unpaid for over 30 days)
  let hasBadInvoice = false;
  if (company.billing?.failed_invoices) {
    for (const i of company.billing.failed_invoices) {
      if (DateTime.now().toSeconds() - i.timestamp > 2592000) {
        hasBadInvoice = true;
      }
    }
  }

  if (company.has_payroll) {
    // Determine Check Onboard status
    if (checkOnboard?.status === "needs_attention") {
      remainingOnboardTasks.push("check_onboard");
    } else if (checkOnboard?.status === "blocking") {
      remainingOnboardTasks.push("check_onboard");
      onboardingBlocking = true;
    }

    // Loop through Check's list of onboard tasks and map to human-friendly descriptions
    if (checkOnboard.remaining_steps) {
      for (const step of checkOnboard.remaining_steps) {
        if (checkOnboard[step]) {
          let blocking: boolean;
          let tasksArr: string[];
          if (checkOnboard.blocking_steps?.includes(step)) {
            blocking = true;
            tasksArr = payrollBlockingTasks;
          } else {
            blocking = false;
            tasksArr = payrollNonBlockingTasks;
          }

          for (const item of checkOnboard[step]) {
            if (step === "bank_account") {
              tasksArr.push(bankItemLookup[item]);
            } else if (step === "setup_parameters") {
              if (blocking) {
                tasksArr.push(`Submit critical ${item.name} tax information`);
              } else {
                tasksArr.push(
                  `Submit any ${item.name} tax information that is in process (e.g., EIN) once you have the information`
                );
              }
            } else if (step === "filing_authorization") {
              tasksArr.push(`Submit ${item.name}`);
            } else {
              tasksArr.push(item.name);
            }
          }
        }
      }
    }

    // In non-production environments, we don't need Check to set implementation_status to true
    if (IS_PRODUCTION && checkImplementation?.status !== "completed") {
      remainingOnboardTasks.push("data_transfer");
      onboardingBlocking = true;
    }
  }

  // Dynamically set the payroll readiness status. Both active_full and
  // active_needs_attention can submit payroll, but active_needs_attention still
  // has outstanding onboarding items to complete
  let status: string;
  if (onboardingBlocking) {
    status = "onboarding_blocking";
  } else if (hasBadInvoice) {
    status = "bad_invoice";
  } else if (!checkInGoodStanding && company.has_payroll) {
    status = "check_not_good_standing";
  } else if (checkOnboard?.status === "needs_attention" && company.has_payroll) {
    status = "active_needs_attention";
  } else {
    status = "active_full";
  }

  return {
    ready: status === "active_full" || status === "active_needs_attention",
    status: status,
    onboarding: remainingOnboardTasks.length > 0,
    remaining_onboard_tasks: remainingOnboardTasks,
    payroll_blocking_tasks: payrollBlockingTasks,
    payroll_non_blocking_tasks: payrollNonBlockingTasks,
  };
};
