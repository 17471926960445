import React, { useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import AppContext from "team-portal/contexts/app-context";
import { useNavigate } from "react-router";
import styles from "./TeamMemberToggler.module.css";
import Notifier from "team-portal/utils/notifier";
import { baseSensitiveCompare } from "miter-utils";
import { DateTime } from "luxon";
import { useFetchCompanyLogoUrl } from "team-portal/hooks/useFetchCompanyLogoUrl";

const TeamMemberToggler: React.FC = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { activeTM, teamMembers, fetchUserData } = React.useContext(AppContext);
  const companyLogoUrl = useFetchCompanyLogoUrl(activeTM);

  const toggle = () => setOpen(!open);

  const toggleCompany = async (id) => {
    try {
      await fetchUserData(id);
      Notifier.success("You have successfully switched accounts.");
      navigate("/dashboard");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.log("Error:", e);
    }
  };

  const logout = () => navigate("/logout");

  const today = DateTime.now().toISODate();

  return (
    <div
      className={`${styles["toggler-wrapper"]}
      ${open ? "toggle-wrapper-open" : ""}
      ${companyLogoUrl ? styles["with-logo"] : ""}`}
    >
      <div onClick={toggle} className={`${styles["toggler-display"]} pointer`}>
        {activeTM && (
          <div className={`${styles["toggler-text"]} flex`}>
            {companyLogoUrl && (
              <img className={`${styles["toggler-logo"]}`} src={companyLogoUrl} alt="Company Logo" />
            )}
            <span>
              <div className={`${styles["name-display"]}`}>{activeTM.full_name}</div>
              <div className={`${styles["company-display"]}`}>
                <div>{activeTM?.company?.name}</div>
              </div>
            </span>
          </div>
        )}
        <img className={`${styles["down-arrow-toggler"]}`} src={"/caret-down.png"} />
      </div>
      {open && (
        <ClickAwayListener onClickAway={toggle}>
          <div className={`${styles["company-selector"]}`}>
            {teamMembers
              ?.filter((listItem) => listItem._id !== activeTM?._id)
              .sort((a, b) => {
                if (a.end_date && !b.end_date) return 1;
                if (!a.end_date && b.end_date) return -1;
                return baseSensitiveCompare(a.company.name, b.company.name);
              })
              .map((activeTM) => {
                const subText = `${activeTM.end_date && activeTM.end_date < today ? "Previous" : "Current"} ${
                  activeTM.employment_type
                }`;
                return (
                  <div
                    key={activeTM._id}
                    onClick={() => toggleCompany(activeTM._id)}
                    className={`${styles["company-option"]}`}
                  >
                    <div className={`${styles["company-option-text"]}`}>
                      <span className="bold">{activeTM.company.name}</span>
                      <span>{subText}</span>
                    </div>
                  </div>
                );
              })}
            <div onClick={logout} className={`${styles["company-option"]} ${styles["logout-option"]}`}>
              <div className={`${styles["company-option-text"]}`}>
                <span className="bold">Logout</span>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default TeamMemberToggler;
