import { TranslationMap } from "./i18n";

const translation: TranslationMap = {
  Dashboard: {
    en: "Dashboard",
    es: "Tablero",
  },
  Onboarding: {
    en: "Onboarding",
    es: "Integración",
  },
  Paystubs: {
    en: "Paystubs",
    es: "Recibos de pago",
  },
  "Payment Info": {
    en: "Payment Info",
    es: "Info del pago",
  },
  "Time Off": {
    en: "Time Off",
    es: "Tiempo libre",
  },
  Forms: {
    en: "Forms",
    es: "Formas",
  },
  Documents: {
    en: "Documents",
    es: "Documentos",
  },
  Profile: {
    en: "Profile",
    es: "Perfil",
  },
  Help: {
    en: "Help",
    es: "Ayuda",
  },
  Certifications: {
    en: "Certifications",
    es: "Certificaciones",
  },
};

export default translation;
