import React from "react";
import informationPng from "dashboard/assets/information.png";
import { ImageWithTooltip } from "ui";

type Props = {
  text: string | null | undefined;
  place?: "top" | "right" | "bottom" | "left";
  style?: React.CSSProperties;
};

const InfoButton: React.FC<Props> = ({ text, place, style }) => {
  if (!text) return null;
  return (
    <div style={{ margin: "0px 5px", ...style }}>
      <ImageWithTooltip src={informationPng} tooltip={text} place={place || "right"} />
    </div>
  );
};

export default InfoButton;
