import { useMemo } from "react";
import { AggregatedJob, Job } from "dashboard/miter";

export const isJobWFMScoped = (job: Job | AggregatedJob): boolean => {
  return job.scopes?.includes("workforce_management");
};

export const isJobSpendManagementScoped = (job: Job | AggregatedJob): boolean => {
  return job.scopes?.includes("spend_management");
};

type JobScopesPredicate = {
  wfmJobScopeWithAbility: (job: Job | AggregatedJob) => boolean;
  spendManagementJobScopeWithAbility: (job: Job | AggregatedJob) => boolean;
};

export const useJobScopesPredicate = (
  abilityPredicate: (job: Job | AggregatedJob) => boolean
): JobScopesPredicate => {
  return useMemo(
    () => ({
      wfmJobScopeWithAbility: (job: Job | AggregatedJob) => {
        return abilityPredicate(job) && isJobWFMScoped(job);
      },
      spendManagementJobScopeWithAbility: (job: Job | AggregatedJob) => {
        return abilityPredicate(job) && isJobSpendManagementScoped(job);
      },
    }),
    [abilityPredicate]
  );
};
