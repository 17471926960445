import { AggregatedCertification } from "dashboard/types/certification-types";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import AppContext from "team-portal/contexts/app-context";
import { MiterAPI } from "team-portal/utils/miter";
import { Button, TableV2, Wizard } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { ForageRequest } from "../../../../backend/utils/forage/forage-types";
import styles from "./CertificationsTable.module.css";
import { UploadCertificationWizardScreen } from "miter-components";
import { PencilSimple, Plus } from "phosphor-react";
import { UploadCertificationFromPortal } from "./UploadCertificationFromPortal";

const colors = {
  expired: "light-red",
  active: "light-green",
  missing: "orange",
};

const CertificationsTable: FC = () => {
  const { activeTM, permissionGroups } = useContext(AppContext);
  const [refreshCount, setRefreshCount] = useState(0);
  const [selectedCertification, setSelectedCertification] = useState<AggregatedCertification>();
  const [modalDisplay, setModalDisplay] = useState<"upload" | null>(null);

  const getData = useCallback(
    async (query: ForageRequest) => {
      if (!activeTM) return { data: [], next_page: null, prev_page: null, total: 0 };
      const filter = [
        ...(query.filter || []),
        { field: "company_id", value: activeTM.company._id },
        { field: "team_member_id", value: activeTM._id },
      ];

      return await MiterAPI.certifications.forage({
        ...query,
        filter,
        select: undefined,
      });
    },
    [activeTM]
  );

  const staticActions = useMemo(
    () => [
      {
        label: "Upload certification",
        className: "button-2 table-button",
        action: () => setModalDisplay("upload"),
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        important: true,
        shouldShow: () => true,
      },
    ],
    []
  );

  const columns: ColumnConfig<AggregatedCertification>[] = useMemo(
    () => [
      {
        field: "certification_type.title",
        headerName: "Certification",
        width: 250,
        dataType: "string" as const,
      },

      {
        field: "status",
        headerName: "Status",
        dataType: "string" as const,
        displayType: "badge" as const,
        minWidth: 150,
        enableRowGroup: true,
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["active", "missing", "expired"],
        },
        colors,
      },

      {
        headerName: "Requested on",
        field: "created_at",
        dataType: "date" as const,
        dateType: "timestamp" as const,
      },

      {
        field: "expires_at",
        headerName: "Expires on",
        dataType: "date" as const,
        dateType: "iso" as const,
      },
      {
        field: "actions",
        headerName: "Actions",
        dataType: "component",
        disableCellClick: true,
        cellRenderer: (params) => {
          return (
            <Button className="button-1 modal" onClick={() => setSelectedCertification(params.data)}>
              <PencilSimple weight="bold" style={{ marginRight: 3 }} />
              Update
            </Button>
          );
        },
      },
    ],
    []
  );

  const closeModal = () => {
    setRefreshCount((prev) => prev + 1);
    setSelectedCertification(undefined);
    setModalDisplay(null);
  };

  const userCanManage = userHasPermission(
    selectedCertification?.certification_type.permission_groups_can_manage || [],
    permissionGroups
  );

  const renderWizard = () => {
    if (!activeTM || !selectedCertification) return null;
    return (
      <Wizard onExit={closeModal}>
        <UploadCertificationWizardScreen
          certificationOwner={activeTM}
          certificationType={selectedCertification.certification_type}
          certification={selectedCertification}
          onClose={closeModal}
          companyId={activeTM.company._id}
          name="Upload certification"
          readOnly={!userCanManage}
        />
      </Wizard>
    );
  };

  const renderModal = () => {
    switch (modalDisplay) {
      case "upload":
        return <UploadCertificationFromPortal close={closeModal} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <TableV2
        id="certifications-table"
        getData={getData}
        ssr={true}
        columns={columns}
        resource="certifications"
        wrapperClassName={styles["base-ssr-table"]}
        containerClassName={styles["timesheets-table-container"]}
        customEmptyStateMessage="No certifications found."
        refreshCount={refreshCount}
        staticActions={staticActions}
      />
      {renderWizard()}
      {renderModal()}
    </div>
  );
};

export default CertificationsTable;

const userHasPermission = (entityGroups: string[], userGroups: string[]): boolean => {
  return entityGroups.length === 0 || entityGroups.some((group) => userGroups.includes(group));
};
