import { Form, FormSubmission, FrontendModel } from "dashboard/miter";
import React, { FC } from "react";
import { GroupedFormComponentsArray, buildGroupedFormComponentsObject } from "./utils";
import styles from "./Forms.module.css";
import { FormFields } from "./FormSubmissionWizard";
import { FormSection as FormSection_ } from "../../../backend/models/form";
type Props = {
  formItem: Form;
  readonly: boolean;
  formSubmission: FormSubmission;
};
type FormSection = FrontendModel<FormSection_>;
export const SinglePageFormWizardScreen: FC<Props> = ({ formItem, readonly, formSubmission }) => {
  const groupedFormComponentsObject = buildGroupedFormComponentsObject(formItem);
  const groupedFormComponentsArray: GroupedFormComponentsArray = Object.values(
    groupedFormComponentsObject
  ).filter((gfc) => gfc.fields.length > 0);

  return (
    <div className={styles["content"]}>
      {groupedFormComponentsArray.map((groupedFormComponents, index) => {
        const key = `grouped-form-section-${index}`;
        return (
          <div key={key}>
            <FormHeader section={groupedFormComponents.section} />
            <FormFields
              groupedFormComponents={groupedFormComponents}
              answers={formSubmission.answers}
              readonly={readonly}
            />
            <div style={{ marginBottom: 100 }}></div>
          </div>
        );
      })}
    </div>
  );
};

/** Build the header for the form section */
const FormHeader: FC<{ section?: FormSection }> = ({ section }) => {
  if (!section) return null;

  return (
    <div className={styles["subheader"]}>
      <h2 style={{ fontSize: 18 }} className={styles["subheader-title"]}>
        {section?.name}
      </h2>
      <p className={styles["subheader-description"]}>{section?.description}</p>
      <hr />
    </div>
  );
};
