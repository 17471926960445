export const WORK_HOURS_IN_WEEK = 40;
// May change depending on company setting, but leave for handling cases where
// we may not have the company object available yet
export const DEFAULT_WORK_HOURS_IN_YEAR = 2087;
export const DEFAULT_WORK_WEEKS_IN_YEAR = DEFAULT_WORK_HOURS_IN_YEAR / WORK_HOURS_IN_WEEK;

export const DEBOUNCED_SECOND = 1000;

export const PAGE_SIZE = 100;

export const BPI_ONEROOF_COMPANY_ID = "644aa0f1a63ac8006dda339f";

export const MITER_COMPANY_ID = "610313a27dc240004465007b";
export const AMERICAN_ROOFING_METAL_COMPANY_IDS = [
  "642701b59b8fc70064e40f7d",
  "6427026d9b8fc70064e42149",
  "642702d69b8fc70064e42456",
];
export const GREEN_MECHANICAL_COMPANY_ID = "645a303242a96400739d8125";
export const CVE_COMPANY_IDS = [
  "65a0058547b3a000711318fe",
  "65a004fd47b3a0007112dbd4",
  "65a0732fb5c12000724a0807",
];

export const COASTAL_GROUP_COMPANY_ID = "6548023ac1846b0061baca21";
export const COASTAL_HOMES_COMPANY_ID = "654801c1c1846b0061bac86b";
export const COASTAL_COMPANY_IDS = [COASTAL_GROUP_COMPANY_ID, COASTAL_HOMES_COMPANY_ID];

// sister companies
export const MARCON_COMPANY_ID = "64c9071aa55f360069fd986e";

// auto break time timesheet policy settings
// allan briteway has 2 companies: ABFS Engineering LLC and ABFS Florida LLC
export const ALLAN_BRITEWAY_COMPANY_IDS = ["65b140374bda400072298caa", "65b3ed085144ea00742e47c0"];
export const UNITED_PAINTING_COMPANY_IDS = ["664b6ee542d31108b9208dcb", "664b6e9342d31108b92056dd"];

// RLC/BRIX IDs
export const RLC_COMPANY_ID = "65faf54498fed3c0ff2aefd5";
export const BRIX_COMPANY_ID = "65faf59798fed3c0ff2b0d63";
export const RLC_BRIX_COMPANY_IDS = [RLC_COMPANY_ID, BRIX_COMPANY_ID];

export const BOUTEN_COMPANY_ID = "660ac8bb55a43dc5de934bb0";

export const KW_CONCRETE = "67068a767eea987759d820b2";
