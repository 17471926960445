import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";

import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./translations/i18n";
import { datadogRum, RumInitConfiguration, DefaultPrivacyLevel } from "@datadog/browser-rum";

import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY);

import App from "./App";
import { IS_DEVELOPMENT } from "./utils/environment";

type DatadogVariableConfig = Omit<RumInitConfiguration, "applicationId" | "clientToken">;

const getDatadogSettingsForEnvironment = (): DatadogVariableConfig => {
  let config: DatadogVariableConfig;
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    config = {
      sessionSampleRate: 50,
      sessionReplaySampleRate: 40,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    };
  } else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
    config = {
      sessionSampleRate: 50,
      sessionReplaySampleRate: 40,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    };
  } else {
    config = {
      sessionSampleRate: 0,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    };
  }

  if (process.env.REACT_APP_DD_RUM_SAMPLE_RATE && !isNaN(Number(process.env.REACT_APP_DD_RUM_SAMPLE_RATE))) {
    config.sessionSampleRate = Number(process.env.REACT_APP_DD_RUM_SAMPLE_RATE);
  }

  if (
    process.env.REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE &&
    !isNaN(Number(process.env.REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE))
  ) {
    config.sessionReplaySampleRate = Number(process.env.REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE);
  }

  if (process.env.REACT_APP_DD_TRACK_USER_INTERACTIONS != undefined) {
    config.trackUserInteractions = process.env.REACT_APP_DD_TRACK_USER_INTERACTIONS === "true";
  }

  if (process.env.REACT_APP_DD_TRACK_RESOURCES != undefined) {
    config.trackResources = process.env.REACT_APP_DD_TRACK_RESOURCES === "true";
  }

  if (process.env.REACT_APP_DD_TRACK_LONG_TASKS != undefined) {
    config.trackLongTasks = process.env.REACT_APP_DD_TRACK_LONG_TASKS === "true";
  }

  if (process.env.REACT_APP_DD_DEFAULT_PRIVACY_LEVEL) {
    config.defaultPrivacyLevel = process.env.REACT_APP_DD_DEFAULT_PRIVACY_LEVEL as DefaultPrivacyLevel;
  }

  return config;
};

let version: string | undefined;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  version = "latest";
} else if (process.env.REACT_APP_SENTRY_RELEASE_NAME?.startsWith("miter@v")) {
  version = process.env.REACT_APP_SENTRY_RELEASE_NAME.slice(6);
} else if (process.env.REACT_APP_SENTRY_RELEASE_NAME) {
  version = process.env.REACT_APP_SENTRY_RELEASE_NAME;
} else {
  version = "unknown";
}

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID ?? "",
  clientToken: process.env.REACT_APP_DATADOG_TOKEN ?? "",
  site: "us3.datadoghq.com",
  service: "miter-team-portal",
  env: process.env.REACT_APP_ENVIRONMENT,
  version,
  allowedTracingUrls: [process.env.REACT_APP_MITER_BACKEND_API],
  ...getDatadogSettingsForEnvironment(),
});

if (!IS_DEVELOPMENT) {
  Sentry.init({
    dsn: "https://b5e48df6a5624ff2b5ee11c46db4619e@o1003175.ingest.sentry.io/5965256",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ["error", "warn"],
      }),
      new Sentry.Replay(),
    ],
    ignoreErrors: [
      "TypeError: Failed to fetch",
      "TypeError: Load failed",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Cancelled",
      "No target window",
      "Google Maps JavaScript API has been loaded directly without",
      "TypeError: this.get_config is not a function",
      "Your session has expired. Please log in again.",
      `AG Grid: \`getSelectedNodes\` and \`getSelectedRows\` functions cannot be used with \`groupSelectsChildren\` and the server-side row model.
      Use \`api.getServerSideSelectionState()\` instead.`,
      `AG Grid: tried to call sizeColumnsToFit`,
      `AG Grid: setFilterModel`,
      `AG Grid: cannot select node until id for node is known`,
      `AG Grid: api.forEachNodeAfterFilterAndSort can only be called when gridOptions.rowModelType is clientSide`,
      /AG Grid: Grid API function .+? cannot be called as the grid has been destroyed./i,
      /AG Grid: React Component '.+?' not created within/i,
      `Force restarting websocket`,
      /__gCrWeb/,
    ],
    // Set this so that sentry doesn't send us a 413 Error.
    maxBreadcrumbs: 50,
    release: process.env.REACT_APP_SENTRY_RELEASE_NAME || undefined,

    // Capture 25% of traces
    tracesSampleRate: 0.25,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

const root = createRoot(document.getElementById("root")!);
root.render(
  <Router>
    <App />
  </Router>
);
