import React, { useEffect, useState } from "react";
import { WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import useWizard from "ui/modal/useWizard";
import { TeamPortalUser } from "team-portal/utils/miter";
import { useTranslation } from "react-i18next";
import { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import { AggregatedTeamMemberOnboardingTask, CustomOnboardingTask } from "dashboard/miter";
import { Formblock } from "ui/form";
import { OnboardingTaskRow } from "dashboard/components/tables/OnboardingTasksTable";
import { markTaskComplete } from "./utils";

type Props = {
  name: string;
  teamMember?: WizardTeamMember | TeamPortalUser;
  refetchTeamMember: () => Promise<void>;
  onboardingTask: CustomOnboardingTask;
  updateOnboardingChecklistTask:
    | ((task: Partial<AggregatedTeamMemberOnboardingTask>) => Promise<void>)
    | ((task: Partial<OnboardingTaskRow>) => Promise<void>);
};

export const CustomTaskWizardScreen: React.FC<Props> = ({
  name,
  refetchTeamMember,
  onboardingTask,
  updateOnboardingChecklistTask,
}) => {
  const { t } = useTranslation<$TSFixMe>();
  const [isTaskCompleted, setIsTaskCompleted] = useState(onboardingTask.status === "complete");

  const { curIndex, handleComplete, screens, setCanNext, setNextButtonText } = useWizard();

  const isNewHireTask = onboardingTask.assignee.some((assignee) => assignee.type === "self");

  useEffect(() => {
    if (!isNewHireTask || isTaskCompleted) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [setCanNext, isNewHireTask, isTaskCompleted]);

  useEffect(() => {
    setNextButtonText(
      !isTaskCompleted && !isNewHireTask
        ? t("Continue and complete later")
        : curIndex + 1 >= screens.length
        ? t("Save and exit")
        : t("Save and continue")
    );
  }, [curIndex, screens.length, setNextButtonText, t, isTaskCompleted, isNewHireTask]);

  const onNext = async () => {
    if (curIndex + 1 >= screens.length) {
      handleComplete();
    }
    if (isTaskCompleted) {
      await markTaskComplete(onboardingTask, updateOnboardingChecklistTask);
      await refetchTeamMember();
    }
  };

  return (
    <WizardScreen key={onboardingTask._id} name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>{onboardingTask?.title}</h2>
          <p className={styles["subheader-description"]}>{onboardingTask?.description}</p>
        </div>
        <div className="form-section">
          <Formblock
            type="checkbox"
            name="isTaskCompleted"
            text={t("Mark task as complete")}
            editing={true}
            onChange={(e) => setIsTaskCompleted(e.target.checked)}
            checked={isTaskCompleted}
          />
        </div>
      </div>
    </WizardScreen>
  );
};
