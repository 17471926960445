import { QueryClient } from "@tanstack/react-query";

// Need this to be defined separately since it's used in both the dashboard and team-portal
export const componentsQueryClient = new QueryClient();

export * from "./i-9";
export * from "./forms";
export * from "./onboarding";
export * from "./onboarding-v2";
export * from "./certifications";
export * from "./team-member";
export * from "./fillable-documents";
export * from "./documents";
