import { ClickAwayListener } from "@material-ui/core";
import React, { FC, useState } from "react";
import { BlockPicker } from "react-color";

type Props = {
  defaultOpen?: boolean;
  onChange: (color: string) => void;
  value: string;
  format?: "hex" | "rgb" | "rgba" | "hsl" | "hsla";
  placement?: "top" | "bottom";
};

const ColorPicker: FC<Props> = ({ onChange, value, defaultOpen, format = "hex" }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          style={{
            backgroundColor: value,
            width: 32,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #ccc",
            marginRight: -1,
          }}
          onClick={() => setOpen(!open)}
        ></div>
        <input
          className="form2-text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onClick={() => setOpen(!open)}
          maxLength={7}
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        />
      </div>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              marginTop: top ? -280 : 0,
              border: "1px solid rgb(228, 228, 228)",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.07)",
            }}
          >
            <BlockPicker
              color={value || undefined}
              onChangeComplete={(value) => {
                onChange(value[format]);
              }}
              triangle="hide"
              colors={COLOR_PICKER_COLORS}
            />
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default ColorPicker;

export const COLOR_PICKER_COLORS = [
  "#c7e8ee",
  "#d4e3fa",
  "#f9dcd9",
  "#c6ebd1",
  "#eaddfc",
  "#f6daec",
  "#faf6cf",
  "#fcddc7",
  "#d5f0b1",
  "#beebe7",
];

export const getColorPickerColor = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  // Use modulo to get a positive index within the bounds of the colors array
  const index = Math.abs(hash) % COLOR_PICKER_COLORS.length;
  return COLOR_PICKER_COLORS[index]!;
};
