import React, { useEffect } from "react";
import { WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import useWizard from "ui/modal/useWizard";
import { useTranslation } from "react-i18next";
import { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import { AggregatedTeamMemberOnboardingTask, ScreeningOnboardingTask } from "dashboard/miter";
import { TFunction } from "i18next";
type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  refetchTeamMember: () => Promise<void>;
  onboardingTask: AggregatedTeamMemberOnboardingTask & ScreeningOnboardingTask;
  updateOnboardingChecklistTask: (task: AggregatedTeamMemberOnboardingTask) => Promise<void>;
};

export const ScreeningWizardScreen: React.FC<Props> = ({ name, onboardingTask }) => {
  const { t } = useTranslation<$TSFixMe>();

  const { curIndex, handleComplete, screens, setCanNext, setNextButtonText } = useWizard();

  useEffect(() => {
    setCanNext(true);
    setNextButtonText(curIndex + 1 >= screens.length ? t("Exit") : t("Continue"));
  }, [curIndex, screens.length, setCanNext, setNextButtonText, t]);

  const onNext = async () => {
    if (curIndex + 1 >= screens.length) {
      handleComplete();
    }
  };

  return (
    <WizardScreen key={onboardingTask._id} name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>
            {formatCheckrPackage(onboardingTask.checkr_package, t)}
          </h2>
          <p className={styles["subheader-description"]}>
            {onboardingTask.status === "complete"
              ? t("The Checkr screening has been completed")
              : t("Please complete the Checkr screening that was sent to your email")}
          </p>
        </div>
        <div className="form-section"></div>
      </div>
    </WizardScreen>
  );
};

const formatCheckrPackage = (packageName: string, t: TFunction): string => {
  const formattedPackageName = packageName
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return formattedPackageName
    ? t("Complete " + formattedPackageName + " screening")
    : t("Complete screening");
};
