import React from "react";
import "./Table.css";
import Row from "./TableRow";
import { ColumnConfigs, TableEntry } from "./types";
import TableTotal from "./TableTotal";

type Props<D extends TableEntry> = {
  data: D[];
  columns: ColumnConfigs;
  onClick?: (entry: D) => void;
  onClickBtnConfig: React.ComponentProps<typeof Row>["onClickBtnConfig"];
  onSelect: React.ComponentProps<typeof Row>["onSelect"];
  clickLinkPrefix?: React.ComponentProps<typeof Row>["clickLinkPrefix"];
  selected?: string[];
  hideSelect?: boolean;
  maxBodyHeight?: number;
  hideHover?: boolean;
  showTotalsRow?: boolean;
};

const TableBody = <D extends TableEntry>({
  data,
  columns,
  onClick,
  onClickBtnConfig,
  onSelect,
  clickLinkPrefix,
  selected,
  hideSelect,
  hideHover,
  showTotalsRow,
}: Props<D>): React.ReactElement => {
  return (
    <tbody>
      {data.map((entry, index) => (
        <Row
          key={index}
          row_num={index}
          id={entry._id}
          entry={entry}
          columns={columns}
          clickLinkPrefix={clickLinkPrefix}
          onClick={onClick}
          onClickBtnConfig={onClickBtnConfig}
          onSelect={onSelect}
          isSelected={selected ? selected.includes(entry._id) : undefined}
          hideSelect={hideSelect}
          hideHover={hideHover || entry.hideHover}
        />
      ))}
      {showTotalsRow && (
        <TableTotal data={data} columns={columns} hideSelect={hideSelect} onSelect={onSelect} />
      )}
    </tbody>
  );
};

export default TableBody;
