import { TranslationMap } from "./i18n";

const translation: TranslationMap = {
  "Employment info": {
    en: "Employment info",
    es: "Información de empleo",
  },
  "Kiosk PIN": {
    en: "Kiosk PIN",
    es: "PIN del quiosco",
  },
  "Custom fields": {
    en: "Custom fields",
    es: "Campos Personalizados",
  },
  "Emergency contacts": {
    en: "Emergency contacts",
    es: "Contactos de emergencia",
  },
  Name: {
    en: "Name",
    es: "Nombre",
  },
  Phone: {
    en: "Phone",
    es: "Teléfono",
  },
  "Personal email": {
    en: "Personal email",
    es: "Email personal",
  },
  "Phone number": {
    en: "Phone number",
    es: "Número de teléfono",
  },
  "Date of birth": {
    en: "Date of birth",
    es: "Fecha de nacimiento",
  },
  Residence: {
    en: "Residence",
    es: "Residencia",
  },
  Address: {
    en: "Address",
    es: "Dirección",
  },
  "Mailing Address": {
    en: "Mailing Address",
    es: "Dirección de envio",
  },
  "Race/ethnicity": {
    en: "Race/ethnicity",
    es: "Raza/etnicidad",
  },
  Gender: {
    en: "Gender",
    es: "Género",
  },
  "Veteran Status": {
    en: "Veteran Status",
    es: "Estado de veterano ",
  },
  "Marital Status": {
    en: "Marital Status",
    es: "Estado civil",
  },
  "Downloading...": {
    en: "Downloading...",
    es: "Descargando...",
  },
  Download: {
    en: "Download",
    es: "Descargar",
  },
  "Payment method": {
    en: "Payment method",
    es: "Método de pago",
  },
  "& withholdings": {
    en: "& withholdings",
    es: "y retenciones",
  },
  Edit: {
    en: "Edit",
    es: "Editar",
  },
  "Direct deposit": {
    en: "Direct deposit",
    es: "Deposito directo",
  },
  "Paper check": {
    en: "Paper check",
    es: "Cheque en papel",
  },
  "Bank account": {
    en: "Bank account",
    es: "Cuenta bancaria",
  },
  Withholdings: {
    en: "Withholdings",
    es: "Retenciones",
  },
  "Personal info": {
    en: "Personal info",
    es: "Información personal",
  },
  Security: {
    en: "Security",
    es: "Seguridad",
  },
  "Enable two-factor authentication": {
    en: "Enable two-factor-authentication",
    es: "Habilitar la autenticación de dos factores",
  },
  "Enabling two-factor authentication requires you to enter a code sent to your phone and email each time you log in.":
    {
      en: "Enabling two-factor authentication requires you to enter a code sent to your phone and email each time you log in.",
      es: "Habilitar la autenticación de dos factores requiere que ingreses un código enviado a tu teléfono y a tu correo electrónico cada vez que inicies sesión.",
    },
  Cancel: {
    en: "Cancel",
    es: "Cancelar",
  },
  Save: {
    en: "Save",
    es: "Guardar",
  },
  Relationship: {
    en: "Relationship",
    es: "Relación",
  },
  Language: {
    en: "Language",
    es: "Idioma",
  },
  "Update language": {
    en: "Update language",
    es: "Actualizar idioma",
  },
};

export default translation;
