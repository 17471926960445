import { TranslationMap } from "./i18n";

const translation: TranslationMap = {
  "Name*": {
    en: "Name*",
    es: "Nombre*",
  },
  "Relationship*": {
    en: "Relationship*",
    es: "Relación*",
  },
  "Phone*": {
    en: "Phone*",
    es: "Phone*",
  },
  "Edit emergency contacts": {
    en: "Edit emergency contacts",
    es: "Editar contactos de emergencia",
  },
};

export default translation;
