import React, { FC } from "react";
import CertificationsTable from "team-portal/components/certifications/CertificationsTable";

const Certifications: FC = () => {
  return (
    <div>
      <div className="page-content-header flex">
        <h1>Certifications</h1>
      </div>
      <CertificationsTable />
    </div>
  );
};

export default Certifications;
