import React from "react";
import ReactTooltip from "react-tooltip";

type Props = {
  src: string;
  height?: number;
  width?: number;
  tooltip?: string;
  place?: "top" | "right" | "left" | "bottom";
  onClick?: () => void;
};

const ImageWithTooltip: React.FC<Props> = ({ src, height, width, tooltip, onClick, place = "top" }) => {
  const imgHeight = height || 10;
  const imgWidth = width || 10;

  return (
    <div className="flex">
      <img
        data-tip={tooltip}
        data-for={tooltip}
        src={src}
        onClick={onClick}
        style={{ height: imgHeight, width: imgWidth }}
      />
      <ReactTooltip id={tooltip} place={place} effect={"solid"} className="tooltip">
        <span>{tooltip}</span>
      </ReactTooltip>
    </div>
  );
};

export default ImageWithTooltip;
