import React from "react";
import "./modal.css";
import ConfirmModal from "./ConfirmModal";
import BasicModal from "./BasicModal";

// Simple modal to prompt for deletion confirmation.

type Props = {
  header: React.ComponentProps<typeof ConfirmModal>["title"];
  body: React.ComponentProps<typeof ConfirmModal>["body"];
  onHide: React.ComponentProps<typeof ConfirmModal>["onNo"];
  cancelText?: React.ComponentProps<typeof ConfirmModal>["noText"];
  onDelete: React.ComponentProps<typeof ConfirmModal>["onYes"];
  deleteText?: React.ComponentProps<typeof ConfirmModal>["yesText"];
  loading?: React.ComponentProps<typeof ConfirmModal>["loading"];
};

const DeleteModal: React.FC<Props> = ({
  header,
  body,
  cancelText = "Cancel",
  onHide,
  deleteText = "Delete",
  onDelete,
  loading,
}: Props) => {
  return (
    <BasicModal
      headerText={header || "Are you sure?"}
      bodyText={body}
      button1Text={cancelText || "Cancel"}
      button2Text={deleteText || "Yes"}
      button1Action={onHide}
      button2Action={onDelete}
      button2ClassName="button-3 no-margin"
      loading={loading}
    />
  );
};

export default DeleteModal;
