import AppContext from "team-portal/contexts/app-context";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MiterAPI } from "team-portal/utils/miter";
import { Loader, Notifier } from "ui";
import { ClaspReact } from "clasp-components";
import axios from "axios";
import { isActiveClaspMember, isActiveClaspCompany } from "team-portal/utils/clasp-utils";

const BenefitsAdmin = (): JSX.Element | null => {
  const { activeTM: teamMember } = useContext(AppContext);
  const [componentUrl, setComponentUrl] = useState<string | null>(null);
  const isActive = useMemo(
    () => teamMember?.clasp_id && isActiveClaspMember(teamMember) && isActiveClaspCompany(teamMember.company),
    [teamMember]
  );

  const getComponentUrl = useCallback(async () => {
    if (!isActive || !teamMember) return;
    try {
      const response = await MiterAPI.clasp.get_member_component_url(teamMember._id);
      if (response.error) throw new Error(response.error);
      setComponentUrl(response.url);
    } catch (e) {
      console.log("Clasp error: ", e);
      Notifier.error(
        "Failed to authenticate for Miter benefits administration. Please try again or reach out to support if the issue persists."
      );
    }
  }, [isActive, teamMember]);

  useEffect(() => {
    getComponentUrl();
  }, [isActive, getComponentUrl]);

  const onError = useCallback(
    async (error) => {
      if (axios.isAxiosError(error) && error.response?.status !== 403) {
        console.error("Clasp component error: ", error.response?.data);
        await getComponentUrl();
      }
    },
    [getComponentUrl]
  );

  if (!isActive) {
    return null;
  } else if (!componentUrl) {
    return <Loader />;
  }

  return (
    <div className="page-content">
      <ClaspReact className="height-100" link={componentUrl} onError={onError} />
    </div>
  );
};

export default BenefitsAdmin;
