import React, { FC, useState } from "react";
import DocViewer from "@cyntler/react-doc-viewer";
import Lightbox from "react-image-lightbox";
import { FilePickerFile } from "../form/FilePicker";

export const FilePreviewRender: FC<{ file: FilePickerFile; fileURL: string }> = ({ file, fileURL }) => {
  const [showLightbox, setShowLightbox] = useState<boolean>(false);

  if (file.data?.type?.includes("image")) {
    return (
      <>
        <img
          id="png-img"
          style={{ maxHeight: 600, maxWidth: "100%" }}
          src={fileURL}
          onClick={() => {
            setShowLightbox(true);
          }}
        />
        {showLightbox && <Lightbox mainSrc={fileURL} onCloseRequest={() => setShowLightbox(false)} />}
      </>
    );
  }

  return (
    <>
      <DocViewer
        documents={[
          {
            uri: fileURL,
          },
        ]}
        prefetchMethod="GET"
        config={{
          pdfZoom: {
            defaultZoom: 1.1,
            zoomJump: 0.2,
          },
          pdfVerticalScrollByDefault: true,
          header: {
            disableHeader: true,
          },
        }}
        style={{ minWidth: "400px" }}
      />
    </>
  );
};
