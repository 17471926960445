import React, { useState, useImperativeHandle, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import { ICellEditorParams } from "ag-grid-community";
import styles from "./AgGridTimeEditor.module.css";

type TimeEditorProps = ICellEditorParams & {
  dateType: "seconds" | "string";
};

const SECONDS_IN_HOUR = 3600;

export const AgGridTimeEditor = React.forwardRef(function RawTimeEditor(props: TimeEditorProps, ref) {
  const { dateType } = props;
  const [value, setValue] = useState("");
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // this ensures that the input is focused when the cell is clicked
    refInput.current?.focus();
  }, []);

  const getSecondsFromValue = (value: string): number => {
    return (
      DateTime.fromFormat(value, "HH:mm").hour * SECONDS_IN_HOUR +
      DateTime.fromFormat(value, "HH:mm").minute * 60
    );
  };

  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of edit
      getValue() {
        if (dateType === "seconds") return getSecondsFromValue(value);
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        return false;
      },
    };
  });

  return (
    <input
      type="time"
      className={styles["input-cell"]}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      ref={refInput}
    />
  );
});
