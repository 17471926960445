import React, { ReactNode } from "react";
import "./modal.css";
import BasicModal from "./BasicModal";

type Props = {
  title?: React.ReactNode;
  body: React.ReactNode;
  onYes: React.ComponentProps<typeof BasicModal>["button2Action"];
  onNo: React.ComponentProps<typeof BasicModal>["button1Action"];
  yesText?: React.ReactNode;
  noText?: React.ReactNode;
  loading?: boolean;
  className?: string;
  yellowBodyText?: boolean;
  children?: ReactNode;
  yesDisabled?: boolean;
};

const ConfirmModal: React.FC<Props> = ({
  title,
  body,
  onYes,
  onNo,
  yesText,
  noText,
  loading,
  className,
  yellowBodyText,
  children,
  yesDisabled,
}: Props) => {
  return (
    <BasicModal
      headerText={title || "Are you sure?"}
      bodyText={body}
      button1Text={noText || "Cancel"}
      button2Text={yesText || "Yes"}
      button1Action={onNo}
      button2Action={onYes}
      loading={loading}
      className={className}
      yellowBodyText={yellowBodyText}
      button2Disabled={yesDisabled}
    >
      {children}
    </BasicModal>
  );
};

export default ConfirmModal;
