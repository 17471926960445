import React, { FC, useMemo, useCallback, ReactNode } from "react";
import { Wizard } from "ui";
import { AggregatedTeamMemberOnboardingTask, AggregatedI9, CustomField } from "dashboard/miter";
import { TeamMemberPersonalInfoScreen } from "dashboard/components/team-members/TeamMemberPersonalInfo";
import { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import { TeamMemberEEOInfoScreen } from "dashboard/components/team-members/TeamMemberEEOInfoScreen";
import { notNullish } from "miter-utils";
import { PaymentMethodWizardScreen } from "./PaymentMethodWizardScreen";
import { BankAccountsWizardScreen } from "./BankAccountsWizardScreen";
import { WithholdingsWizardScreen } from "./WithholdingsWizardScreen";
import { EmployeeI9WizardScreen } from "./EmployeeI9WizardScreen";
import { TeamPortalUser } from "team-portal/utils/miter";
import { DemoWizardScreen } from "./DemoWizardScreen";
import { taskTypeLabels } from "./utils";
import { TeamMemberCustomFieldsScreen } from "dashboard/components/team-members/TeamMemberCustomFieldsScreen";
import { CustomTaskWizardScreen } from "./CustomTaskWizardScreen";
import { CertificationWizardScreen } from "./CertificationWizardScreen";
import { AggregatedCertificationType } from "dashboard/types/certification-types";
import { FormWizardScreen } from "./FormWizardScreen";
import { SignDocumentWizardScreen } from "./SignDocumentWizardScreen";
import { FillableDocumentWizardScreen } from "./FillableDocumentWizardScreen";
import { ScreeningWizardScreen } from "./ScreeningWizardScreen";
import { useTranslation } from "react-i18next";

type Props = {
  onExit: () => void;
  onComplete: () => void;
  onboardingTasks: AggregatedTeamMemberOnboardingTask[];
  teamMember?: WizardTeamMember;
  setTeamMember: (teamMember: WizardTeamMember) => void;
  refetchTeamMember: () => Promise<void>;
  updateOnboardingChecklistTask: (task: Partial<AggregatedTeamMemberOnboardingTask>) => Promise<void>;
  activeTM: TeamPortalUser;
  i9?: AggregatedI9;
  customFields?: CustomField[];
  lookupCertificationType: (certificationTypeId: string) => AggregatedCertificationType | undefined;
  checkOnboardLoading: boolean;
  openCheckOnboard: () => Promise<void>;
  setReverifyUser: React.Dispatch<React.SetStateAction<boolean>>;
  setOnReverifyUser: React.Dispatch<React.SetStateAction<() => void>>;
};

export const OnboardingChecklistV2Wizard: FC<Props> = ({
  onExit,
  onComplete,
  onboardingTasks,
  teamMember,
  setTeamMember,
  refetchTeamMember,
  updateOnboardingChecklistTask,
  activeTM,
  customFields,
  lookupCertificationType,
  checkOnboardLoading,
  openCheckOnboard,
  setReverifyUser,
  setOnReverifyUser,
  ...props
}) => {
  const { t } = useTranslation<$TSFixMe>();
  const mapScreen = useCallback(
    (task: AggregatedTeamMemberOnboardingTask) => {
      if (!teamMember) return null;
      switch (task.type) {
        case "personal_info":
          return (
            <TeamMemberPersonalInfoScreen
              key={task._id}
              name={t("Personal information")}
              teamMember={teamMember}
              setTeamMember={setTeamMember}
              refetchTeamMember={refetchTeamMember}
              onboardingTask={task}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
            />
          );
        case "eeo":
          return (
            <TeamMemberEEOInfoScreen
              key={task._id}
              name={t("EEO information")}
              teamMember={teamMember}
              setTeamMember={setTeamMember}
              refetchTeamMember={refetchTeamMember}
              onboardingTask={task}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
            />
          );
        case "payment_method":
          return (
            <PaymentMethodWizardScreen
              key={task._id}
              name={t("Payment method")}
              teamMember={teamMember}
              setTeamMember={setTeamMember}
              refetchTeamMember={refetchTeamMember}
              onboardingTask={task}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
            />
          );
        case "bank_accounts":
          return (
            <BankAccountsWizardScreen
              key={task._id}
              name={t("Bank accounts")}
              teamMember={teamMember}
              refetchTeamMember={refetchTeamMember}
              onboardingTask={task}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
              setReverifyUser={setReverifyUser}
              setOnReverifyUser={setOnReverifyUser}
            />
          );
        case "withholdings":
          return (
            <WithholdingsWizardScreen
              key={task._id}
              name={t("Withholdings")}
              teamMember={teamMember}
              refetchTeamMember={refetchTeamMember}
              onboardingTask={task}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
              checkOnboardLoading={checkOnboardLoading}
              openCheckOnboard={openCheckOnboard}
            />
          );
        case "i9_employee":
          return (
            <EmployeeI9WizardScreen
              key={task._id}
              name={t("Complete I-9")}
              teamMember={teamMember}
              refetchTeamMember={refetchTeamMember}
              onboardingTask={task}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
              i9={props.i9}
            />
          );
        case "custom_field":
          return (
            <TeamMemberCustomFieldsScreen
              key={task._id}
              name={t(taskTypeLabels[task.type])}
              onboardingTask={task}
              teamMember={teamMember}
              setTeamMember={setTeamMember}
              refetchTeamMember={refetchTeamMember}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
              companyId={activeTM.company._id}
              customFields={customFields}
              customFieldValues={activeTM.custom_field_values}
              isTeamPortal={true}
            />
          );
        case "custom_task":
          return (
            <CustomTaskWizardScreen
              key={task._id}
              name={t(taskTypeLabels[task.type])}
              onboardingTask={task}
              teamMember={teamMember}
              refetchTeamMember={refetchTeamMember}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
            />
          );
        case "certification":
          if (!lookupCertificationType(task.certification_type_id)) return null;
          return (
            <CertificationWizardScreen
              key={task._id}
              name={task.certification?.title || t("Certification")}
              teamMember={teamMember}
              refetchTeamMember={refetchTeamMember}
              onboardingTask={task}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
              companyId={activeTM.company._id}
              certificationType={lookupCertificationType(task.certification_type_id)!}
            />
          );
        case "form":
          return (
            <FormWizardScreen
              key={task._id}
              name={task.form?.name || t("Complete form")}
              teamMember={teamMember}
              refetchTeamMember={refetchTeamMember}
              onboardingTask={task}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
            />
          );
        case "sign_document":
          return (
            <SignDocumentWizardScreen
              key={task._id}
              name={t(taskTypeLabels[task.type])}
              onboardingTask={task}
              refetchTeamMember={refetchTeamMember}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
            />
          );
        case "fill_document":
          return (
            <FillableDocumentWizardScreen
              key={task._id}
              name={t(taskTypeLabels[task.type])}
              onboardingTask={task}
              teamMember={teamMember}
              refetchTeamMember={refetchTeamMember}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
            />
          );
        case "screening":
          return (
            <ScreeningWizardScreen
              key={task._id}
              name={t(taskTypeLabels[task.type])}
              onboardingTask={task}
              teamMember={teamMember}
              refetchTeamMember={refetchTeamMember}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
            />
          );
        default:
          return (
            <DemoWizardScreen
              key={task._id}
              name={t(taskTypeLabels[task.type])}
              onboardingTask={task}
              refetchTeamMember={refetchTeamMember}
              updateOnboardingChecklistTask={updateOnboardingChecklistTask}
            />
          );
      }
    },
    [
      updateOnboardingChecklistTask,
      refetchTeamMember,
      setTeamMember,
      teamMember,
      props.i9,
      activeTM,
      customFields,
      lookupCertificationType,
      setReverifyUser,
      setOnReverifyUser,
      checkOnboardLoading,
      openCheckOnboard,
      t,
    ]
  );

  const screens: ReactNode = useMemo(() => {
    return onboardingTasks.map(mapScreen).filter(notNullish);
  }, [onboardingTasks, mapScreen]);

  // Find the index of the first incomplete task in the onboarding checklist
  const defaultIndex = useMemo(() => {
    let renderedTaskCount = 0;
    const firstIncompleteIndex = onboardingTasks.findIndex((task) => {
      if (mapScreen(task) === null) return false;
      if (task.status === "incomplete") return true;
      renderedTaskCount++;
      return false;
    });
    return firstIncompleteIndex !== -1 ? renderedTaskCount : 0;
  }, [onboardingTasks, mapScreen]);

  return (
    <Wizard onExit={onExit} onComplete={onComplete} defaultCanGoNext={false} startIndex={defaultIndex}>
      {screens}
    </Wizard>
  );
};
