import DocumentsTable from "team-portal/components/DocumentsTable";
import React from "react";
import { useTranslation } from "react-i18next";

const Documents: React.FC = () => {
  const { t } = useTranslation<$TSFixMe>();

  return (
    <div>
      <h1 className="view-title title-with-toggler">{t("Documents")}</h1>

      <div className="vertical-spacer" />
      <DocumentsTable />
    </div>
  );
};

export default Documents;
