import { ESignatureItem } from "dashboard/miter";
import { FormAnswer } from "miter-utils/custom-fields";

export const isQuestionAnswered = (response: FormAnswer): boolean => {
  if (Array.isArray(response.value) && response.value.length === 0) {
    return false;
  }
  if (
    typeof response.value === "object" &&
    response.value &&
    "signature" in (response.value as ESignatureItem) &&
    !(response.value as ESignatureItem)?.signature?.image
  ) {
    return false;
  }
  return !!response.value;
};

export const PHOTO_UPLOAD_ACCEPTABLE_FILE_TYPES = ["image/jpeg", "image/jpg", "image/png"];
