import { IS_DEVELOPMENT } from "./utils/environment";

const MITER_COMPANY_ID = "610313a27dc240004465007b";
const QOVO_COMPANY_ID = "631916a23154db007ea839a4";
const HUTCHERSON_COMPANY_ID = "64c902f6a55f360069fd67a0";

export const useHasAccessToMFA = (companyId: string): boolean => {
  return (
    IS_DEVELOPMENT ||
    companyId === MITER_COMPANY_ID ||
    companyId === QOVO_COMPANY_ID ||
    companyId === HUTCHERSON_COMPANY_ID
  );
};
