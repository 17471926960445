import { ClickAwayListener } from "@material-ui/core";
import React, { FC, ReactElement, useEffect } from "react";
import { ModalHeader, ModalFooter } from "ui";

export type SliderModalProps = {
  title?: string | ReactElement;
  leftElement?: ReactElement;
  header?: ReactElement;
  toggler?: ReactElement;
  footer?: ReactElement;
  loading?: boolean;
  onSubmit?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
  animate?: boolean;
  hideCancel?: boolean;
  hideSubmit?: boolean;
  showDelete?: boolean;
  submitText?: string;
  cancelText?: string;
  wrapperClassName?: string;
  bodyClassName?: string;
  shouldOnclickAwayClose?: boolean;
};

const SliderModal: FC<SliderModalProps> = ({
  title,
  leftElement,
  header,
  toggler,
  footer,
  loading,
  onSubmit,
  onDelete,
  onCancel,
  children,
  animate,
  showDelete,
  hideSubmit,
  hideCancel,
  submitText,
  cancelText,
  wrapperClassName,
  bodyClassName,
  shouldOnclickAwayClose = false,
}) => {
  const onCancelCallback = shouldOnclickAwayClose && onCancel ? onCancel : () => {};

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      <div className={"modal-background"}>
        <ClickAwayListener onClickAway={onCancelCallback}>
          <div
            className={
              "modal-wrapper form slider " +
              (!animate ? "no-animation" : "") +
              (leftElement ? "two-column slider" : "") +
              wrapperClassName
            }
          >
            {leftElement}
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* right element */}
              {header || (onCancel && <ModalHeader heading={title} onHide={onCancel} />)}
              <div style={{ paddingTop: "0px" }}>{toggler}</div>
              <div className={"modal-body slider " + bodyClassName}>{children}</div>
              {footer ||
                (onCancel && onSubmit && (
                  <ModalFooter
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    submitText={submitText || "Save"}
                    cancelText={cancelText || "Cancel"}
                    deleteText="Delete"
                    showDelete={!!showDelete}
                    hideSubmit={hideSubmit}
                    hideCancel={hideCancel}
                    loading={loading}
                  />
                ))}
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </>
  );
};
export default SliderModal;
