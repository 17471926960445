import { SyncStorage } from "jotai/vanilla/utils/atomWithStorage";

/* Storage that first looks at session and then defaults to local, and clears out all sessions if local disappears */
export const getSessionAndLocalKeyValue = (key: string): string | null => {
  return sessionStorage.getItem(key) || localStorage.getItem(key);
};

export const sessionAndLocalStorage: SyncStorage<string | null> = {
  getItem: getSessionAndLocalKeyValue,
  setItem: (key: string, newValue: string | null): void => {
    // Remove the item by setting to `null` because triggering `removeItem` actually sets it back to `initialValue`, and `initialValue` will always be what's in storage at start-up
    if (newValue == null) {
      sessionStorage.removeItem(key);
      localStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, newValue);
      localStorage.setItem(key, newValue);
    }
  },
  removeItem: (key: string): void => {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  },
  subscribe: (key: string, set: (value: string | null) => void): (() => void) => {
    if (typeof window === "undefined" || typeof window.addEventListener === "undefined") {
      return () => {}; // jotai typed poorly, or else it would allow us to return `void`
    }
    const listener = (e: StorageEvent) => {
      // Allows us to auto-logout other tabs when logging out of one tab. Only need to do anything if the token is removed (set to null)
      if (e.storageArea === localStorage && e.key === key && !e.newValue) {
        // This doesn't call `setItem`, it just calls the atom's set function, which is why why we need to separately remove the item from sessionStorage in the line below
        set(null);
        sessionStorage.removeItem(key);
      }
    };
    window.addEventListener("storage", listener);
    return () => window.removeEventListener("storage", listener);
  },
};
