import React from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { MenuButton } from "../button";
import { Badge } from "../badge";
import { TableEntry, ButtonConfig, ColumnConfigs } from "./types";
import "./Table.css";
import { usdString } from "../utils";

type Props<D extends TableEntry> = {
  row_num: number;
  id: string;
  entry: D;
  onClick?: (entry: D) => void;
  onClickBtnConfig?: ButtonConfig;
  clickLinkPrefix?: string;
  columns: ColumnConfigs;
  onSelect: (id: string, isSelected: boolean) => void;
  isSelected?: boolean;
  hideSelect?: boolean;
  hideHover?: boolean;
  isTotalRow?: boolean;
  menu?: React.ComponentProps<typeof MenuButton>["options"];
};

const Row = <D extends TableEntry>(props: Props<D>): React.ReactElement => {
  const {
    id,
    entry,
    onClick,
    onClickBtnConfig,
    clickLinkPrefix,
    columns,
    onSelect,
    isSelected,
    hideSelect,
    hideHover,
    isTotalRow,
  } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    if (clickLinkPrefix) {
      navigate(clickLinkPrefix + "/" + id);
    }
    if (onClick) {
      onClick(entry);
    }
  };

  const formatValue = (column) => {
    if (entry == null) {
      return "null row passed to Row.js";
    }
    // Avoid all formatting on Totals Row.
    if (isTotalRow) {
      return entry[column.field];
    }
    if (entry[column.field] === null) {
      return "";
    }
    if (column.type === "boolean") {
      return entry[column.field] ? "True" : "False";
    } else if (column.type === "badge") {
      const value = entry[column.field];
      if (value == null || value === "") {
        const color = column.colors ? column.colors[value?.toString()] || column.colors["false"] : "red";
        return <Badge className="no-margin" text={"✕"} table color={color} />;
      }
      if (typeof value === "boolean") {
        const colors = column.colors || { true: "green", false: "red" };
        const color = colors[value.toString()];
        return <Badge className="no-margin" text={value ? "✓" : "✕"} table color={color} />;
      }
      const color = column.colors?.[value.toLowerCase?.()];
      return <Badge className="no-margin" text={value} table color={color} />;
    } else if (column.type === "timestamp") {
      const dt = DateTime.fromSeconds(entry[column.field]);
      return dt.toISODate();
    } else if (column.type === "component") {
      const Component = entry[column.field];
      return Component;
    } else if (column.type === "dollar") {
      return usdString(entry[column.field]);
    }
    return entry[column.field];
  };

  let row_class = onClick ? "entry row" : "row noClick";

  if (onClickBtnConfig) row_class += " no-hover";
  else if (hideHover) {
    row_class += " no-hover";
  }

  if (isTotalRow) row_class = "entry row table-total";

  let tdClass = hideSelect ? " hideSelect " : "";
  if (entry.grayOut) {
    tdClass = tdClass + " grayOut";
  }

  return (
    <tr className={row_class}>
      {!hideSelect && (
        <td className="table-checkbox">
          <div className="checkbox-wrapper">
            {entry.disableSelect ? (
              <div className="fake-input"></div>
            ) : (
              <input
                className={"table-checkbox-input"}
                type="checkbox"
                onChange={() => onSelect(id, !isSelected)}
                checked={isSelected}
              />
            )}
          </div>
        </td>
      )}
      {columns.map((column, index) => {
        if (!column.hidden) {
          return (
            <td
              key={index}
              onClick={onClickBtnConfig ? undefined : handleClick}
              className={`cell column-${index.toString()} ${tdClass}`}
              style={{ minWidth: column.minWidth || undefined }}
            >
              <div className={`cell-text ${column.column_class}`}>
                <span className="mobile-label">{column.label}</span>
                <span className="cell-value">{formatValue(column)}</span>
              </div>
            </td>
          );
        }
      })}
      {onClickBtnConfig && (
        <td>
          <button className={onClickBtnConfig.className} onClick={handleClick}>
            {onClickBtnConfig.text}
          </button>
        </td>
      )}
      {props.menu && (
        <td className="menu-button-td">
          <MenuButton id={id} options={props.menu} />
        </td>
      )}
    </tr>
  );
};

export default Row;
