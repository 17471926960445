import { ESignatureItem, MiterAPI } from "dashboard/miter";
import { File, UpdateFileParams } from "dashboard/miter";
import { FillableTemplate } from "dashboard/types/fillable-template-types";
import { getOS, notNullish } from "miter-utils";
import ObjectID from "bson-objectid";

export type SaveAsyncItemsForFillableDocument = {
  fillableDocumentId: string;
  fillableTemplate: FillableTemplate;
  params: UpdateFileParams;
  account: {
    company_id: string;
    full_name: string;
    roleId?: string;
    userId: string;
    accountType: "admin" | "team_member";
    title: string;
    team_member_id?: string;
  };
  deviceType: "desktop" | "team-portal";
};

export const saveESignaturesForFillableDocument = async (
  argParams: SaveAsyncItemsForFillableDocument
): Promise<File["answers"]> => {
  const { fillableDocumentId, params, fillableTemplate, account, deviceType } = argParams;

  const { roleId, userId, accountType, title, company_id, team_member_id, full_name } = account;
  const esignParams = (params.answers || [])
    .map((answer) => {
      const field = fillableTemplate.inputs.find((f) => f._id === answer.form_field_id);
      const esignatureItem = answer.value as ESignatureItem;
      if (!field || field.type !== "esignature" || !answer.value || !!esignatureItem?.signature?.image) {
        return;
      }

      const _id = ObjectID().toHexString();

      const item_params = {
        _id,
        company_id: company_id,
        document_type: "file",
        parent_document_id: fillableDocumentId,
        signature: {
          image: answer.value,
          device: { type: deviceType, os: getOS() },
          application: { name: "dashboard" },
        },
        signer: {
          name: full_name,
          type: accountType,
          title: title,
          role_id: roleId,
          user_id: userId,
          team_member_id,
        },
        options: {
          skipNewFileCreation: true,
        },
      };

      return { item_params, form_field_id: answer.form_field_id };
    })
    .filter(notNullish);

  if (esignParams.length === 0) return params.answers || [];

  // @ts-expect-error fix me
  const res = await MiterAPI.esignature_items.signatures.create(esignParams.map((p) => p.item_params));
  if (res.error) throw new Error(res.error);

  // Update the form submissions with the esignature item cached
  const updatedAnswers = (params.answers || []).map((answer) => {
    const field = fillableTemplate.inputs.find((f) => f._id === answer.form_field_id);
    if (!field || field.type !== "esignature") return answer;

    const params = esignParams.find((p) => p.form_field_id === answer.form_field_id);
    if (!params) return answer;

    const esignatureItem = res.find((r) => r._id === params.item_params._id);

    if (!esignatureItem) throw new Error("Could not find esignature item");

    return {
      _id: answer._id,
      form_field_id: answer.form_field_id,
      value: esignatureItem,
    };
  });

  return updatedAnswers;
};

export const saveFillableDocumentAnswers = async (
  params: SaveAsyncItemsForFillableDocument
): Promise<File> => {
  const signatureUpdatedAnswers = await saveESignaturesForFillableDocument(params);

  const update = {
    files: [
      {
        _id: params.fillableDocumentId,
        answers: signatureUpdatedAnswers,
        fill_status: params.params.fill_status,
      },
    ],
  };
  const res = await MiterAPI.files.update_many(update);
  if (res.error) throw new Error(res.error);
  return res[0]!;
};
