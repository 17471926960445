import React from "react";
import { Formblock, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { states } from "dashboard/utils";
import { isEmptyAddress, isValidAddress } from "miter-utils";
import { MiterAPI } from "dashboard/miter";
import { Props } from "./types";
import useWizard from "ui/modal/useWizard";

const rules = {
  required: "This field is required",
};

export const PersonalInformationWizardScreen: React.FC<Props> = ({
  task,
  name,
  onboardingChecklistItem,
  getOnboardingChecklistItem,
}) => {
  const { new_hire } = onboardingChecklistItem;
  const { curIndex, handleComplete, screens } = useWizard();

  const form = useForm({
    defaultValues: {
      dob: new_hire.dob ? DateTime.fromISO(new_hire.dob) : undefined,
      address: new_hire.address
        ? {
            ...new_hire.address,
            state: {
              label: states.find((s) => s.abbreviation === new_hire.address?.state)?.abbreviation,
              value: new_hire.address?.state,
            },
          }
        : undefined,
      ssn: "",
    },
    mode: "all",
  });

  const { watch, errors } = form;
  const formData = watch();

  const onNext = async () => {
    const { dob, address, ssn } = formData;

    if ((!new_hire?.ssn_last_four && !ssn) || (ssn && (isNaN(Number(ssn)) || ssn.length !== 9))) {
      Notifier.error("Please enter a valid SSN.");
      throw new Error("Please enter a valid SSN.");
    }

    if (!dob || !dob.isValid) {
      Notifier.error("Please enter a valid date of birth.");
      throw new Error("Please enter a valid date of birth.");
    }

    const cleanAddress = {
      ...address,
      state: address?.state && "value" in address?.state ? address?.state?.value : address?.state,
    };

    // @ts-expect-error fix me
    const addressIsEmpty = isEmptyAddress(cleanAddress);
    // If the address is not empty and is not valid, throw an error

    if (addressIsEmpty || !isValidAddress(cleanAddress)) {
      Notifier.error("Please enter a valid address.");
      throw new Error("Address is not valid. Please make sure all the required fields are filled out.");
    }

    const cleanParams = {
      ssn: ssn || undefined,
      dob: dob?.toISODate(),
      address: !addressIsEmpty ? { ...address, state: address?.state?.value } : undefined,
    };

    try {
      // @ts-expect-error fix me
      const res = await MiterAPI.team_member.update(new_hire._id, cleanParams);
      if (res.fields?.length) {
        throw new Error(res.fields?.map((field) => field.error).join(", "));
      }
      if (res.error) {
        throw new Error(res.error);
      }
    } catch (e: $TSFixMe) {
      console.log("Error saving team member", e);
      Notifier.error(e.message);
      throw e;
    }

    await getOnboardingChecklistItem();

    if (curIndex === screens.length - 1) {
      handleComplete();
    }
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <h3>{task.title}</h3>
        <p>{task.description}</p>
        <Formblock
          label={`Date of birth*`}
          type="datetime"
          dateOnly={true}
          name="dob"
          placeholder="MM/DD/YYYY"
          errors={errors}
          control={form.control}
          // register={register(rules)}
          form={form}
          rules={rules}
          className="modal wizard"
          editing={true}
          defaultValue={undefined}
        />

        <Formblock
          label={`Address*`}
          type="address"
          name="address"
          form={form}
          className="modal wizard"
          editing={true}
          required={true}
        />

        <Formblock
          label="SSN*"
          type="ssn"
          name="ssn"
          form={form}
          className="modal"
          editing={true}
          defaultValue={undefined}
          placeholder={"XXX-XX-" + (new_hire.check_tm?.ssn_last_four || new_hire?.ssn_last_four || "XXXX")}
        />
      </div>
    </WizardScreen>
  );
};
