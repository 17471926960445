import React, { FC, useEffect } from "react";
import { PlaidLinkOnExit, PlaidLinkOnSuccess, PlaidLinkOptions, usePlaidLink } from "react-plaid-link";

type Props = { token: string; onSuccess: PlaidLinkOnSuccess; onExit: PlaidLinkOnExit };

const PlaidLink: FC<Props> = ({ token, onSuccess, onExit }) => {
  const { open, ready } = usePlaidLink({ token, onSuccess, onExit } as PlaidLinkOptions);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [open, ready]);

  return <></>;
};

export default PlaidLink;
