import React, { FC, useState } from "react";
import { Props } from "./types";
import { Formblock, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import { useForm } from "react-hook-form";
import { MiterAPI } from "dashboard/miter";
import useWizard from "ui/modal/useWizard";

export const CustomTaskWizardScreen: FC<Props> = ({ task, name, onboardingChecklistItem }) => {
  const isComplete =
    onboardingChecklistItem.custom_statuses?.find((status) => status.task_id === task._id)?.status ===
    "complete";
  const { curIndex, handleComplete, screens } = useWizard();

  const [checked, setChecked] = useState(isComplete);
  const onNext = async () => {
    try {
      const onboardingStatuses = onboardingChecklistItem.custom_statuses || [];
      const curIndex = onboardingStatuses.findIndex((status) => status.task_id === task._id);
      const newStatus = {
        task_id: task._id,
        status: (checked ? "complete" : "incomplete") as "complete" | "incomplete",
      };
      if (curIndex === -1) {
        // @ts-expect-error fix me
        onboardingStatuses.push(newStatus);
      } else {
        // @ts-expect-error fix me
        onboardingStatuses[curIndex] = newStatus;
      }

      const res = await MiterAPI.onboarding_checklist_items.update(onboardingChecklistItem._id, {
        custom_statuses: onboardingStatuses,
      });

      if (res.error) {
        throw new Error("Failed to save onboarding checklist item: " + res.error);
      }
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      throw e;
    }

    if (curIndex === screens.length - 1) {
      handleComplete();
    }
  };

  const form = useForm();

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <h3>{task.title}</h3>
        <p>{task.description}</p>
        <Formblock
          type="checkbox"
          text="I've completed this task."
          label="Completion status"
          editing={true}
          className="modal wizard"
          form={form}
          onChange={(_e) => {
            setChecked(!checked);
          }}
          defaultValue={isComplete}
        />
      </div>
    </WizardScreen>
  );
};
