import { Company, TeamMember, TeamMemberIntegrations } from "dashboard/miter";
import { Assign } from "utility-types";
import { CompanyIntegrations } from "backend/models/company";
import { ClaspEmployer, ClaspMember, ClaspPayRate } from "backend/utils/clasp/clasp-types";
import { TableReadyTm } from "dashboard/pages/team-members/TeamUtils";

type CompanyWithClasp = Assign<
  Company,
  {
    integrations: Assign<
      CompanyIntegrations,
      { clasp: { clasp_employer: ClaspEmployer; disabled?: boolean } }
    >;
  }
>;

export const isClaspCompany = (company: Company | null | undefined): company is CompanyWithClasp => {
  return !!company?.integrations?.clasp?.clasp_employer?.id;
};

export const isActiveClaspCompany = (company: Company | null | undefined): company is CompanyWithClasp => {
  return (
    isClaspCompany(company) &&
    company.integrations.clasp.clasp_employer.status === "completed" &&
    !company.integrations.clasp?.disabled
  );
};

type TeamMemberWithClasp = Assign<
  TeamMember,
  {
    integrations: Assign<
      TeamMemberIntegrations,
      { clasp: { clasp_member: ClaspMember; clasp_pay_rate?: ClaspPayRate } }
    >;
  }
>;

export const isClaspMember = (tm: TeamMember | TableReadyTm): tm is TeamMemberWithClasp => {
  return !!tm.integrations?.clasp?.clasp_member?.id;
};
