import React, { FC } from "react";

import "./MobileNav.css";
import NavItems from "./NavItems";

const MobileNav: FC = () => {
  return (
    <div className="mobile-nav">
      <div className="sidebar-bottom">
        <NavItems device={"mobile"} />
      </div>
    </div>
  );
};

export default MobileNav;
