import React, { useEffect, useMemo, useState } from "react";
import { Button, Formblock, Notifier, WizardScreen } from "ui";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { getPlaidLinkToken } from "dashboard/utils/expenses";
import PlaidLink from "dashboard/components/banking/PlaidLink";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { MiterAPI } from "dashboard/miter";
import { CreateRawBankAccountParams } from "../../../backend/services/bank-accounts/bank-accounts-service";
import { Option } from "ui/form/Input";
import { capitalize } from "lodash";
import { BankAccountSubtype } from "backend/utils/plaid";
import useWizard from "ui/modal/useWizard";
import { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import { AggregatedTeamMemberOnboardingTask } from "dashboard/miter";
import styles from "dashboard/components/team-members/TeamMemberWizard.module.css";
import { useFetchBankAccounts } from "miter-components/bank-accounts/utils";
import { Loader } from "ui";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  refetchTeamMember: () => Promise<void>;
  onboardingTask: AggregatedTeamMemberOnboardingTask;
  updateOnboardingChecklistTask: (task: AggregatedTeamMemberOnboardingTask) => Promise<void>;
  setReverifyUser: React.Dispatch<React.SetStateAction<boolean>>;
  setOnReverifyUser: React.Dispatch<React.SetStateAction<() => void>>;
};

type CreateBankAccountForm = {
  account_number: string;
  routing_number: string;
  account_subtype: Option<string>;
};

export const BankAccountsWizardScreen: React.FC<Props> = ({
  name,
  teamMember,
  refetchTeamMember,
  onboardingTask,
  updateOnboardingChecklistTask,
  setReverifyUser,
  setOnReverifyUser,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation<$TSFixMe>();
  const { control, errors, register, handleSubmit, watch } = useForm<CreateBankAccountForm>({
    reValidateMode: "onChange",
    mode: "all",
  });
  const formData = watch();

  const [loadingPlaid, setLoadingPlaid] = useState(false);
  const [plaidLinkToken, setPlaidLinkToken] = useState<string>();
  const [plaidSuccessData, setPlaidSuccessData] = useState<{
    public_token: string;
    metadata: PlaidLinkOnSuccessMetadata;
  }>();

  const { setCanNext, setNextButtonText, handleComplete, screens, curIndex } = useWizard();

  const accountSubtypeOptions = useMemo(
    () => [
      { label: capitalize(t("checking")), value: "checking" },
      { label: capitalize(t("savings")), value: "savings" },
    ],
    [t]
  );

  const isFormComplete = useMemo(() => {
    return formData.account_number && formData.routing_number && formData.account_subtype?.value;
  }, [formData]);

  const companyId = useMemo(() => {
    if (!teamMember?.company) return null;
    return typeof teamMember.company === "object" ? teamMember.company._id : teamMember.company;
  }, [teamMember?.company]);

  const { data: result, isLoading: loadingBankAccounts } = useFetchBankAccounts({
    companyId: companyId,
    teamMemberId: teamMember?._id,
  });

  const hasBankAccounts = useMemo(() => {
    return !loadingBankAccounts && !!result?.length;
  }, [loadingBankAccounts, result]);

  useEffect(() => {
    const canNextValue = !!(plaidSuccessData || isFormComplete || hasBankAccounts);
    setCanNext(canNextValue);
    setNextButtonText(curIndex + 1 >= screens.length ? t("Save and exit") : t("Save and continue"));
  }, [
    plaidSuccessData,
    isFormComplete,
    hasBankAccounts,
    curIndex,
    screens.length,
    setCanNext,
    t,
    setNextButtonText,
  ]);

  const createNewRawBankAccount = async (data: CreateBankAccountForm) => {
    if (!teamMember?._id) throw new Error("No team member");
    if (!companyId) throw new Error("No company");

    const cleanedData: CreateRawBankAccountParams = {
      full_account_number: data.account_number,
      routing_number: data.routing_number,
      account_subtype: data.account_subtype.value as BankAccountSubtype,
      company_id: companyId,
      team_member_id: teamMember._id,
      external_financial_account_type: "team_member",
    };

    try {
      const response = await MiterAPI.bank_accounts.create(cleanedData);
      if (response.error) {
        throw new Error(response.error);
      }
      Notifier.success(t("Bank account created."));
      await refetchTeamMember();
    } catch (err: $TSFixMe) {
      Notifier.error(t("Could not create bank account.") + " " + err.message);
    }
  };

  const createNewPlaidBankAccount = async () => {
    if (!plaidSuccessData || !teamMember?._id || !companyId) return;

    try {
      const { public_token, metadata } = plaidSuccessData;

      const res = await MiterAPI.banking.plaid.connect_accounts({
        company: companyId,
        team_member_id: teamMember._id,
        external_financial_account_type: "team_member",
        public_token,
        metadata,
      });

      if (res.error) throw new Error(res.error);
      for (const connectAccountResult of res) {
        const last4 = connectAccountResult.account?.mask;
        if (connectAccountResult.error) {
          Notifier.error(`Account ending in ${last4} wasn't connected to Miter.`);
        } else {
          Notifier.success(`Account ending in ${last4} connected to Miter.`);
        }
      }

      await refetchTeamMember();
    } catch (err: $TSFixMe) {
      Notifier.error(t("Could not connect account to Miter.") + " " + err.message);
    }
  };

  const onNext = async () => {
    if (plaidSuccessData) {
      await createNewPlaidBankAccount();
    } else if (isFormComplete) {
      await handleSubmit(() => {
        setReverifyUser(true);
        setOnReverifyUser(() => {
          return createNewRawBankAccount(formData);
        });
      })();
    }

    if (onboardingTask.status !== "complete") {
      await updateOnboardingChecklistTask({ ...onboardingTask, status: "complete" });
    }

    if (curIndex + 1 >= screens.length) {
      handleComplete();
    }
  };

  const renderPlaidSuccessData = () => {
    if (!plaidSuccessData) return null;

    return (
      <div>
        <h4>Linked accounts from {plaidSuccessData.metadata.institution?.name}</h4>
        {plaidSuccessData.metadata.accounts.map((account) => (
          <p key={account.id}>
            {account.name} ····{account.mask}
          </p>
        ))}
      </div>
    );
  };

  const renderManualEntryForm = () => {
    return (
      <div className="margin-top-15">
        <Formblock
          label={t("Routing number")}
          type="text"
          name="routing_number"
          className="modal"
          editing={true}
          control={control}
          errors={errors}
          register={register(vals.isValidBankRoutingNumber)}
        />
        <Formblock
          label={t("Account number")}
          type="text"
          name="account_number"
          className="modal"
          editing={true}
          control={control}
          errors={errors}
          register={register(vals.isValidBankAccountNumber)}
        />
        <Formblock
          label={t("Type")}
          type="select"
          name="account_subtype"
          className="modal"
          options={accountSubtypeOptions}
          editing={true}
          control={control}
          errors={errors}
          requiredSelect
        />
      </div>
    );
  };

  const renderFormSection = () => {
    if (hasBankAccounts) {
      return (
        <div className="form-section">
          {t(
            "You have already added a bank account. If you'd like to edit your bank account info or add more accounts, see the payment info tab."
          )}
        </div>
      );
    }

    return (
      <div className="form-section">
        {plaidSuccessData ? (
          renderPlaidSuccessData()
        ) : (
          <div className="margin-top-25">
            <Button
              onClick={() => {
                setReverifyUser(true);
                setOnReverifyUser(() => {
                  return getPlaidLinkToken({
                    company: companyId,
                    external_financial_account_type: "team_member",
                    setLoading: setLoadingPlaid,
                    setPlaidLinkToken,
                    products: ["auth"],
                  });
                });
              }}
              text={t("Connect with Plaid")}
              loading={loadingPlaid}
              className="button-2"
            />
          </div>
        )}
        {!plaidSuccessData && (
          <div className="pointer margin-top-15 color-gray" onClick={() => setOpen(!open)}>
            <div className="flex space-between width-100-percent margin-right-15">
              <p>{t("Or add manually")}</p>
              {open ? <FaChevronUp className="font-size-12" /> : <FaChevronDown className="font-size-12" />}
            </div>
          </div>
        )}
        {open && !plaidSuccessData && renderManualEntryForm()}
        {plaidLinkToken && (
          <PlaidLink
            token={plaidLinkToken}
            onSuccess={(public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
              setPlaidSuccessData({ public_token, metadata });
            }}
            onExit={() => setPlaidLinkToken(undefined)}
          />
        )}
      </div>
    );
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>{t("Add bank account")}</h2>
          <p className={styles["subheader-description"]}>
            {t("Please add your bank account to complete the onboarding process")}
          </p>
        </div>
        {loadingBankAccounts ? <Loader message={t("Loading bank accounts...")} /> : renderFormSection()}
      </div>
    </WizardScreen>
  );
};
