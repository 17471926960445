import React, { ReactElement } from "react";
import styles from "./EmptyState.module.css";

import emptyPng from "dashboard/assets/empty.png";

type Props = {
  image?: string;
  icon?: ReactElement;
  title: string;
  className?: string;
  description?: string;
  children?: React.ReactNode;
  type?: string;
  ctaButtonText?: string;
  ctaButtonAction?: () => void;
};

const EmptyState: React.FC<Props> = ({
  image,
  icon,
  title,
  description,
  children,
  className,
  type,
  ctaButtonText,
  ctaButtonAction,
}) => {
  return (
    <div
      className={
        styles["empty-state-container"] +
        " " +
        className +
        " " +
        (type === "small" ? styles["small-empty-state-container"] : "") +
        (type === "modal" ? styles["modal-empty-state-container"] : "")
      }
    >
      <div className={styles["empty-state"]}>
        {(image || !icon) && <img className={styles["empty-state-img"]} src={image ? image : emptyPng} />}
        {icon ? icon : null}
        <h1 className={styles["empty-state-title"]}>{title}</h1>
        <p
          className={
            styles["empty-state-description"] +
            " " +
            (type === "small" ? styles["small-empty-state-description"] : "")
          }
        >
          {description}
        </p>
        {ctaButtonText && ctaButtonAction && (
          <div className={styles["empty-state-cta"]}>
            <button className={"button-2 " + styles["empty-state-cta-button"]} onClick={ctaButtonAction}>
              {ctaButtonText}
            </button>
          </div>
        )}

        <div className={styles["empty-state-content"]}>{children}</div>
      </div>
    </div>
  );
};

export default EmptyState;
