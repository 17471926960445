import { OnboardingChecklistWizard } from "miter-components";
import { filterTasksByCompletableStatus } from "miter-components/onboarding/utils";
import { OnboardingTaskRow, convertOnboardingChecklistItem } from "miter-utils";
import React, { useContext, useMemo, useState } from "react";
import AppContext from "team-portal/contexts/app-context";
import { Label, Notifier } from "ui";
import { TableV2 } from "ui/table-v2";

export const OnboardingChecklist: React.FC = () => {
  const { onboardingChecklistItem, activeTM, getOnboardingChecklistItem } = useContext(AppContext);

  const [selectedTaskIndex, setSelectedTaskIndex] = useState<number>(0);
  const [showWizard, setShowWizard] = useState(false);

  const tasks = useMemo(() => {
    return convertOnboardingChecklistItem(onboardingChecklistItem, activeTM?.user, true);
  }, [onboardingChecklistItem, activeTM]);

  if (!onboardingChecklistItem) return <div></div>;

  const openChecklistWizard = () => {
    setShowWizard(true);
  };

  const goToTask = (task: OnboardingTaskRow) => {
    if (task.prerequisites.length > 0) {
      // eslint-disable-next-line no-undef
      Notifier.error(
        "The following must be completed before starting this task: " + task.prerequisites.join(", ") + "."
      );
      return;
    }

    // In the OnboardingChecklistWizard, we filter by both the assignee and also do NOT show tasks that can't be completed.
    // So when deciding the index, we need to use a similarly filtered array.
    const tasksFilteredByAssigneeAndCompletability = filterTasksByCompletableStatus(
      tasks,
      onboardingChecklistItem.statuses
    );
    const taskIndex = tasksFilteredByAssigneeAndCompletability.findIndex((t) => t._id === task._id);
    setSelectedTaskIndex(Math.max(taskIndex, 0));
    openChecklistWizard();
  };

  const closeChecklistWizard = () => {
    getOnboardingChecklistItem();
    setShowWizard(false);
    setSelectedTaskIndex(0);
  };

  const primaryActions = [
    {
      label: "Complete checklist",
      action: openChecklistWizard,
      important: true,
      className: "button-2 no-margin",
    },
  ];

  const columns = [
    {
      field: "title",
      headerName: "Title",
      dataType: "component" as const,
      cellRenderer: (params) =>
        params.data.prerequisites.length ? (
          <Label
            labelInfo={
              "Not completable because following tasks are incomplete: " +
              params.data.prerequisites.join(", ") +
              "."
            }
            label={params.data.title}
            underlineTooltip={true}
            tooltipPlace="right"
          ></Label>
        ) : (
          params.data.title
        ),
    },
    {
      field: "status",
      headerName: "Status",
      displayType: "badge" as const,
      colors: {
        complete: "green",
        incomplete: "red",
      },
    },
    {
      field: "due_date",
      headerName: "Due",
      dataType: "date" as const,
      dateType: "iso" as const,
    },
  ];
  return (
    <div>
      <h1>{onboardingChecklistItem.cached_onboarding_checklist.title}</h1>
      <TableV2
        title="Tasks"
        resource="onboarding tasks"
        id="onboarding-checklist-table"
        ssr={false}
        data={tasks}
        columns={columns}
        staticActions={primaryActions}
        onClick={goToTask}
      />
      {showWizard && activeTM && (
        <OnboardingChecklistWizard
          company={activeTM.company}
          checklistItem={onboardingChecklistItem}
          getOnboardingChecklistItem={getOnboardingChecklistItem}
          onComplete={closeChecklistWizard}
          onExit={closeChecklistWizard}
          assigneeUserId={activeTM?.user}
          startIndex={selectedTaskIndex}
        />
      )}
    </div>
  );
};
