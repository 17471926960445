import React, { FC, useMemo, useState } from "react";
import { TimeOffPolicyWithBalanceAndLevel } from "team-portal/pages/TimeOff";
import { capitalize } from "team-portal/utils/utils";
import styles from "./TeamMemberTimeOffPolicy.module.css";

/*********************************************************
 *  TeamMemberTimeOffPolicy
 *
 *  This component is responsible for rendering a single
 *  time off policy for a team member. I kept it in this
 *  file because it is a simple component that most
 *  likely will not be used anywhere else.
 **********************************************************/

type Props = {
  timeOffPolicy: TimeOffPolicyWithBalanceAndLevel;
};

const TeamMemberTimeOffPolicy: FC<Props> = ({ timeOffPolicy }) => {
  /*********************************************************
   *  Setup states
   **********************************************************/
  const [expanded, setExpanded] = useState(false);

  const policyLevel = useMemo(() => {
    return timeOffPolicy.levels.find((level) => level._id === timeOffPolicy.level_id);
  }, [timeOffPolicy]);
  /*********************************************************
   *  Rendering functions
   **********************************************************/

  const renderBalance = () => {
    const balance = policyLevel?.unlimited ? "Unlimited" : timeOffPolicy.balance;
    return (
      <>
        <span className={styles["team-member-time-off-policy-attr-title"]}>Balance:</span>
        <span className={styles["team-member-time-off-policy-balance-value"]}>
          {balance} {balance === 1 ? "hour" : "hours"}
        </span>
      </>
    );
  };

  const renderExpandedButton = () => {
    if (policyLevel?.unlimited) {
      return <></>;
    } else if (expanded) {
      return (
        <>
          <span
            className={styles["team-member-time-off-policy-edit-link"]}
            onClick={() => setExpanded(false)}
          >
            Less Info
          </span>
        </>
      );
    } else {
      return (
        <span className={styles["team-member-time-off-policy-edit-link"]} onClick={() => setExpanded(true)}>
          More Info
        </span>
      );
    }
  };

  const renderUnlimitedPolicy = () => {
    return (
      <div className={styles["time-off-policy-attr"]}>
        <span className={styles["time-off-policy-attr-name"]}>Unlimited Time Off</span>
        <span className={styles["time-off-policy-attr-title"]}>Yes</span>
      </div>
    );
  };

  const renderLimitedPolicy = () => {
    if (!policyLevel?.accrual_config) return;
    const balance = policyLevel.unlimited ? "-" : timeOffPolicy.balance;

    // Build the time off policy rate display text based on the accrual configuration
    const rateText = policyLevel?.accrual_config.fixed
      ? policyLevel?.accrual_config.fixed.total_hours + " hours per year"
      : policyLevel?.accrual_config.hourly!.rate.hours_earned +
        " hour earned per " +
        policyLevel?.accrual_config.hourly!.rate.hours_based_on +
        " hours " +
        (policyLevel?.accrual_config.hourly!.count_hours_paid ? "paid" : "worked");

    return (
      <>
        <div className={styles["time-off-policy-attr"]}>
          <span className={styles["time-off-policy-attr-name"]}>Balance</span>
          <span className={styles["time-off-policy-attr-title"]}>
            {balance} {balance === 1 ? "hour" : "hours"}
          </span>
        </div>
        <div className={styles["time-off-policy-attr"]}>
          <span className={styles["time-off-policy-attr-name"]}>Rate</span>
          <span className={styles["time-off-policy-attr-title"]}>{rateText}</span>
        </div>
        <div className={styles["time-off-policy-attr"]}>
          <span className={styles["time-off-policy-attr-name"]}>Max Balance</span>
          <span className={styles["time-off-policy-attr-title"]}>
            {policyLevel?.accrual_config.max_balance
              ? policyLevel?.accrual_config.max_balance + " hours"
              : "-"}
          </span>
        </div>
        <div className={styles["time-off-policy-attr"]}>
          <span className={styles["time-off-policy-attr-name"]}>Max Yearly Accrual</span>
          <span className={styles["time-off-policy-attr-title"]}>
            {policyLevel?.accrual_config.yearly_limit !== undefined &&
            policyLevel?.accrual_config.yearly_limit !== null
              ? policyLevel?.accrual_config.yearly_limit + " hours"
              : "-"}
          </span>
        </div>
        <div className={styles["time-off-policy-attr"]}>
          <span className={styles["time-off-policy-attr-name"]}>Max Yearly Carryover</span>
          <span className={styles["time-off-policy-attr-title"]}>
            {policyLevel?.accrual_config.carryover_limit !== undefined &&
            policyLevel?.accrual_config.carryover_limit !== null
              ? policyLevel?.accrual_config.carryover_limit + " hours"
              : "-"}
          </span>
        </div>
        <div className={styles["time-off-policy-attr"]}>
          <span className={styles["time-off-policy-attr-name"]}>Accrual Waiting Period</span>
          <span className={styles["time-off-policy-attr-title"]}>
            {policyLevel.min_tenure_for_accruals != null
              ? policyLevel.min_tenure_for_accruals + " days"
              : "-"}
          </span>
        </div>
        {policyLevel.accrual_config && policyLevel.accrual_config.hourly && (
          <div className={styles["time-off-policy-attr"]}>
            <span className={styles["time-off-policy-attr-name"]}>Count Overtime Hours</span>
            <span className={styles["time-off-policy-attr-title"]}>
              {policyLevel.accrual_config.hourly.count_overtime ? "Yes" : "No"}
            </span>
          </div>
        )}
      </>
    );
  };

  const renderBody = () => {
    return (
      <>
        {policyLevel?.unlimited || !policyLevel?.accrual_config
          ? renderUnlimitedPolicy()
          : renderLimitedPolicy()}
      </>
    );
  };

  return (
    <div className={styles["team-member-time-off-policy"]}>
      <h3 className={styles["team-member-time-off-policy-name"]}>{timeOffPolicy.name}</h3>
      <p className={styles["team-member-time-off-policy-type"]}>{capitalize(timeOffPolicy.type)}</p>
      {expanded && renderBody()}
      {!expanded && renderBalance()}

      <div className={styles["team-member-time-off-policy-footer"]}>
        <div className={styles["team-member-time-off-policy-balance"]}>{renderExpandedButton()}</div>
      </div>
    </div>
  );
};

export default TeamMemberTimeOffPolicy;
