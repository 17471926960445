import React, { FC } from "react";
import { AggregatedTeamMember } from "dashboard/miter";
import { TeamPortalUser } from "team-portal/utils/miter";
import Banner from "dashboard/components/shared/Banner";
import { useTranslation } from "react-i18next";
import styles from "./PaymentInfoWarningBanners.module.css";

export const PaymentInfoWarningBanners: FC<{ teamMember: AggregatedTeamMember | TeamPortalUser }> = ({
  teamMember,
}) => {
  const { t } = useTranslation<$TSFixMe>();
  // if true, the team member has not set their payment method preference
  const paymentMethodPrefNeedsAttention = teamMember?.check_tm?.onboard?.payment_method?.includes(
    "payment_method_preference_set"
  );

  // if true, the team member has not added a bank account for DD or the existing one is invalid
  const bankAccountNeedsAttention = teamMember?.check_tm?.onboard?.payment_method?.some((s) =>
    s.includes("bank_account")
  );

  return (
    <>
      {paymentMethodPrefNeedsAttention && (
        <Banner
          type="error"
          content={t("Payment method preference (direct deposit vs paper check) has not been set.")}
        />
      )}
      {paymentMethodPrefNeedsAttention && bankAccountNeedsAttention && (
        <div className={styles.bannerSpacer} />
      )}
      {bankAccountNeedsAttention && (
        <Banner
          type="error"
          content={t(
            "Direct deposit error: bank account has not been added or the existing one is invalid. Add a new bank account and update the direct deposit information."
          )}
        />
      )}
    </>
  );
};
