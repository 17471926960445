import { CustomField, CustomFieldValue } from "dashboard/miter";

/****************************************************************************************
 * Render a custom field's value
 *****************************************************************************************/
export const renderCustomFieldValue = (cf: CustomField, cfv?: CustomFieldValue): string => {
  if (!cfv) return "-";

  const value = cfv.value;

  if (cf.type === "checkbox") {
    if (value !== undefined && value !== null && (value as unknown as boolean) === false) {
      return "No";
    } else if (cfv) {
      return "Yes";
    }
  }

  return (value as unknown as string) || "-";
};
