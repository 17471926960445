import { AggregatedForm, FormSubmission } from "dashboard/miter";
import { FormSubmissionWizard } from "miter-components";
import { Plus } from "phosphor-react";
import React, { FC, useContext, useState, useMemo } from "react";
import AppContext from "team-portal/contexts/app-context";
import { Notifier } from "ui";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";

const columns: ColumnConfig<FormSubmission>[] = [
  {
    headerName: "Status",
    field: "status",
    dataType: "string",
    displayType: "badge",
    pinned: "left",
  },
  {
    headerName: "Completed at",
    field: "completed_at",
    dataType: "date",
    dateType: "timestamp",
    dateFormat: "ff",
    pinned: "left",
    minWidth: 200,
  },
];

type Props = {
  form: AggregatedForm;
  getForm: () => void;
};

const FormSubmissionsTable: FC<Props> = ({ form, getForm }) => {
  const [selectedFormSubmission, setSelectedFormSubmission] = useState<FormSubmission | null>(null);
  const [showSubmissionWizard, setShowSubmissionWizard] = useState<boolean>(false);
  const { activeTM, user } = useContext(AppContext);
  const formSubmissions: FormSubmission[] = useMemo(() => {
    return (form?.submissions || []).sort((a, _) => {
      if (a.status === "completed") {
        return 1;
      }
      return -1;
    });
  }, [form]);

  const handleRowClick = (row: FormSubmission) => {
    setSelectedFormSubmission(row);
    setShowSubmissionWizard(true);
  };

  const onExit = () => {
    setSelectedFormSubmission(null);
    setShowSubmissionWizard(false);
    getForm();
  };

  const handleAdd = () => {
    // Check if the form has multiple submissions on
    if (!form.allow_multiple_submissions) {
      const hasSubmission = formSubmissions.some((submission) => submission.user_id === user?._id);
      if (hasSubmission) {
        Notifier.error("You have already have a submission for this form.");
        return;
      }
    }

    setShowSubmissionWizard(true);
  };

  return (
    <div>
      <TableV2
        id={"forms-table"}
        resource="form submissions"
        data={formSubmissions}
        columns={columns}
        staticActions={[
          {
            label: "Create a submission",
            className: "button-2 no-margin",
            action: handleAdd,
            important: true,
            icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
            shouldShow: () => !!form.allow_unrequested_submissions,
          },
        ]}
        onClick={handleRowClick}
      />
      {form && user && showSubmissionWizard && activeTM && (
        <FormSubmissionWizard
          onExit={onExit}
          onComplete={onExit}
          mode={"create"}
          formSubmission={selectedFormSubmission || undefined}
          formItem={form}
          accountType="team_member"
          teamMember={{
            company: activeTM.company._id,
            full_name: activeTM.full_name,
            _id: activeTM._id,
          }}
          userId={user._id}
          readonly={selectedFormSubmission?.status === "completed" && !form.allow_editable_submissions}
        />
      )}
    </div>
  );
};

export default FormSubmissionsTable;
