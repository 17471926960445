import { AggregatedOnboardingChecklistItem, OnboardingChecklist } from "dashboard/types/onboarding-types";
import { DateTime } from "luxon";
import { filterTasksByAssignee } from "miter-components/onboarding/utils";

export type OnboardingTaskRow = OnboardingChecklist["tasks"][0] & {
  due_date: string;
  status: AggregatedOnboardingChecklistItem["statuses"][0]["status"];
  prerequisites: AggregatedOnboardingChecklistItem["statuses"][0]["prerequisites"];
};

export const convertOnboardingChecklistItem = (
  onboardingChecklist: AggregatedOnboardingChecklistItem | null,
  assigneeUserId?: string,
  filterByAssignee?: boolean
): OnboardingTaskRow[] => {
  if (!onboardingChecklist || !assigneeUserId) return [];
  const assignedDate = DateTime.fromISO(onboardingChecklist.assigned_date);
  const filteredTasks = filterByAssignee
    ? filterTasksByAssignee(
        onboardingChecklist.cached_onboarding_checklist.tasks,
        assigneeUserId,
        onboardingChecklist.new_hire.user
      )
    : onboardingChecklist.cached_onboarding_checklist.tasks;
  const allStatuses = onboardingChecklist.statuses.concat(onboardingChecklist.custom_statuses || []);
  return filteredTasks.map((task) => {
    const status = allStatuses.find((status) => status.task_id === task._id);
    const completed = status?.status === "complete";
    const prerequisites = (status?.prerequisites || []).map((prerequisite) => {
      return (mapPreReqIdToLabel[prerequisite] as string) || (prerequisite as string);
    });
    return {
      ...task,
      due_date: assignedDate.plus({ days: task.due_after }).toISODate(),
      status: completed ? "complete" : "incomplete",
      prerequisites,
    };
  });
};

const mapPreReqIdToLabel = {
  ssn: "team member SSN",
  employee_i9: "team member completion of I-9",
  address: "team member  address",
  start_date: "team member start date",
};

export const salaryRateOptions = [
  { value: "year", label: "per year" },
  { value: "month", label: "per month" },
  { value: "week", label: "per week" },
];
