import { ValidationRuleset } from "ui/form/Input";
import { FormField as FormField_ } from "../../backend/models/form";
import { esignatureBaseValidation, esignatureRequired } from "dashboard/utils/validators";
import { CustomField, FrontendModel } from "dashboard/miter";
import { AnyAaaaRecord } from "dns";
type FormField = FrontendModel<FormField_>;
/** Build validations for the form */
export const buildValidationRuleSetForFormField = (field: FormField): ValidationRuleset => {
  const { required, min, max } = field.validations || {};

  const rules: ValidationRuleset = {};

  if (required) {
    rules.required = "This field is required";
  }

  if (field.type === "number") {
    if (min !== null && min !== undefined) {
      rules.min = {
        value: min,
        message: `Must be at least ${min}`,
      };
    }

    if (max !== null && max !== undefined) {
      rules.max = {
        value: max,
        message: `Must be at most ${max}`,
      };
    }
  }

  if (field.type === "file" || field.type === "photo") {
    if (min !== null && min !== undefined) {
      rules.min = {
        value: min,
        message: `At least ${min} uploads are required`,
      };
    }

    if (max !== null && max !== undefined) {
      rules.max = {
        value: max,
        message: `At most ${max} uploads are allowed`,
      };
    }
  }

  if (field.type === "esignature") {
    if (required) {
      rules.validate = esignatureRequired;
    } else {
      rules.validate = esignatureBaseValidation;
    }
  }

  return rules;
};

/** Build extra props for the formblocks based on the field type */
export const buildFieldSpecificPropsForFormField = (field: FormField): AnyAaaaRecord => {
  const props: $TSFixMe = {};

  if (field.type === "select") {
    props.options = field.options?.filter((o) => !o.archived).map((o) => ({ value: o._id, label: o.value }));
  }

  if (field.type === "number" || field.type === "file" || field.type === "photo") {
    if (field.validations?.min !== null && field.validations?.min !== undefined) {
      props.min = field.validations.min;
    }

    if (field.validations?.max !== null && field.validations?.max !== undefined) {
      props.max = field.validations.max;
      props.maxFilesAllowed = field.validations.max;
    }
  }

  return props;
};

export const getCustomFieldTypeForFormBlock = (customField: CustomField | FormField): string => {
  if (customField.type === "select" && customField.multiple) {
    return "multiselect";
  }

  if (customField.type === "quantity") {
    return "text";
  }

  if (customField.type === "date") {
    return "datetime";
  }

  // For now, we still use the file component for photo types
  if (customField.type === "photo") {
    return "file";
  }

  return customField.type;
};

export const getFormFieldTypeTitle = (input: {
  formFieldType: FormField["type"];
  multiple?: boolean;
}): string => {
  const { formFieldType, multiple } = input;

  if (formFieldType === "text") {
    return "Text";
  } else if (formFieldType === "paragraph") {
    return "Paragraph";
  } else if (formFieldType === "number") {
    return "Number";
  } else if (formFieldType === "select" && !multiple) {
    return "Select";
  } else if (formFieldType === "select" && multiple) {
    return "Multi-select";
  } else if (formFieldType === "checkbox") {
    return "Checkbox";
  } else if (formFieldType === "esignature") {
    return "E-signature";
  } else if (formFieldType === "date") {
    return "Date";
  }
  throw new Error(`Unknown form field type: ${formFieldType}`);
};

export const mapFieldToBadgeColor = (input: {
  formFieldType: FormField["type"];
  multiple?: boolean;
}): string => {
  const { formFieldType, multiple } = input;
  if (formFieldType === "text") {
    return "blue";
  } else if (formFieldType === "paragraph") {
    return "orange";
  } else if (formFieldType === "number") {
    return "green";
  } else if (formFieldType === "select" && !multiple) {
    return "yellow";
  } else if (formFieldType === "select" && multiple) {
    return "Multi-select";
  } else if (formFieldType === "checkbox") {
    return "light-purple";
  } else if (formFieldType === "esignature") {
    return "light-gray";
  } else if (formFieldType === "date") {
    return "gray";
  }
  throw new Error(`Unknown form field type: ${formFieldType}`);
};
