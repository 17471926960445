import { Assign } from "utility-types";
import { TeamPortalUser } from "./miter";

type TeamPortalUserWithClasp = Assign<TeamPortalUser, { clasp_id: string }>;

export const isActiveClaspMember = (
  teamMember: TeamPortalUser | null | undefined
): teamMember is TeamPortalUserWithClasp => {
  return !!teamMember?.clasp_id && teamMember?.benefits_eligibility_status !== "ineligible";
};

export const isActiveClaspCompany = (company: TeamPortalUser["company"]): boolean => {
  return !!company?.clasp_id && company.clasp_status === "completed";
};
