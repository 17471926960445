import React, { ReactNode, useState } from "react";
import "./button.css";
import ReactTooltip from "react-tooltip";
import { Loader } from "ui";
import { ClickAwayListener } from "@material-ui/core";

export type DropdownItem = {
  key?: string;
  text?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  component?: React.ReactElement;
};

type Props = {
  dropdownItems?: DropdownItem[];
  singleClick?: boolean;
  state?: "clicked" | "loading";
  disabled?: boolean;
  hideTextWhileLoading?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  className?: string;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
  text?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  active?: boolean;
  submit?: boolean;
  children?: ReactNode;
  tooltip?: string;
  uncenterText?: boolean;
};

const Button: React.FC<Props> = ({
  dropdownItems,
  children,
  className = "button-1",
  wrapperStyle,
  wrapperClassName,
  hideTextWhileLoading,
  disabled,
  loading,
  onClick,
  singleClick,
  state,
  text,
  style,
  submit,
  tooltip,
  uncenterText,
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!dropdownItems) {
      if (singleClick && state === "clicked") {
        return null;
      }
      if (!disabled && onClick) onClick(e);
    } else {
      setDropdownActive(!dropdownActive);
    }
  };

  const handleDropdownItemClick = (item) => {
    setDropdownActive(false);
    item.onClick?.();
  };

  let buttonClass = state === "loading" || loading ? className + " inactive" : className + " " + state;
  if (disabled) buttonClass = buttonClass + " inactive";

  let showText = !!text;
  if (hideTextWhileLoading && loading) {
    showText = false;
  }

  return (
    <div className={"button-wrapper " + (wrapperClassName ? wrapperClassName : "")} style={wrapperStyle}>
      <button
        style={{ display: "flex", alignItems: "center", justifyContent: "center", ...style }}
        className={buttonClass}
        onClick={handleClick}
        disabled={loading || disabled}
        type={submit ? "submit" : "button"}
        data-tip={tooltip}
        data-for={tooltip}
      >
        <div style={uncenterText ? { margin: "0px" } : { margin: "0px auto" }} className="flex">
          {loading && <Loader className="button" />}
          {showText ? <span>{text}</span> : children}
        </div>
        {tooltip && (
          <ReactTooltip id={tooltip} effect={"solid"} className="tooltip">
            <span>{tooltip}</span>
          </ReactTooltip>
        )}
      </button>
      {dropdownActive && (
        <ClickAwayListener onClickAway={() => setDropdownActive(false)}>
          <div className="button-dropdown">
            {dropdownItems?.map((item) => {
              return (
                <div
                  key={item.key || item.text}
                  className="button-dropdown-item"
                  style={item.style}
                  onClick={() => handleDropdownItemClick(item)}
                >
                  {item.component || item.text}
                </div>
              );
            })}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Button;
