/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColumnVO,
  Column,
  IServerSideGetRowsParams,
  ISimpleFilterModel,
  SelectionChangedEvent,
} from "ag-grid-community";
import { DateTime } from "luxon";
import { getTimezone, notNullish } from "miter-utils";
import {
  FilterArray,
  SortArray,
  SelectArray,
  GroupOperation,
  GroupArray,
} from "../../../backend/utils/forage/forage-types";
import { TData, ColumnConfig } from "./Table";
import { MiterFilterField } from "dashboard/miter";

export const buildForageGroupFilter = <D extends TData>(
  groupKeys: string[],
  rowGroupCols: ColumnVO[] | ColumnConfig<D>[],
  columns: ColumnConfig<D>[],
  timezone?: string
): FilterArray => {
  const filter: FilterArray = [];

  groupKeys.forEach((key, index) => {
    const groupCol = rowGroupCols[index];
    if (!groupCol || !groupCol.field) return;

    const fullColumn = columns.find((c) => c.field === groupCol.field);
    if (!fullColumn) return;

    if (fullColumn.groupFormatter) {
      const range = [
        DateTime.fromISO(key).setZone(timezone).startOf("day").toSeconds(),
        DateTime.fromISO(key).setZone(timezone).endOf("day").toSeconds(),
      ];

      filter.push({
        field: fullColumn.filterField || groupCol.field,
        type: "number",
        comparisonType: "<+>",
        value: range,
      });
    } else {
      const value = fullColumn.groupFilterValueGetter ? fullColumn.groupFilterValueGetter(key) : key;

      filter.push({
        field: fullColumn.filterField || groupCol.field,
        comparisonType: "=",
        value,
      });
    }
  });

  return filter;
};

export const buildForageSearchFilter = <D extends TData>(
  search: string,
  visibleColumns: Column[],
  rowGroupCols: ColumnVO[] | ColumnConfig<D>[],
  params: IServerSideGetRowsParams | SelectionChangedEvent<D>
): FilterArray => {
  const filter: FilterArray = [];

  const nonGroupPropertyIds = visibleColumns
    .filter((c) => {
      const colDef = c.getColDef() as ColumnConfig<D>;
      if (colDef.suppressForageSearch) return false;

      const dataType = colDef.dataType;
      return dataType === "string" || dataType === "number";
    })
    .map((c) => (c.getColDef() as ColumnConfig<D>).filterField || c.getColDef().field)
    .filter(notNullish);

  const groupPropertyIds = rowGroupCols
    // @ts-expect-error fix me
    .filter((c) => {
      const column = params.columnApi.getColumn(c.field);
      if (!column) return;

      const dataType = (column.getColDef() as ColumnConfig<D>).dataType;
      return dataType === "string" || dataType === "number";
    })
    .map((c) => {
      const column = params.columnApi.getColumn(c.field);
      if (!column) return;

      return (column.getColDef() as ColumnConfig<D>).filterField || c.field;
    })
    .filter(notNullish);

  const searchablePropertyIds = [...nonGroupPropertyIds, ...groupPropertyIds];
  if (!searchablePropertyIds.length) return filter;

  filter.push({
    fields: searchablePropertyIds,
    type: "search",
    value: search,
  });

  return filter;
};

export const buildForageSortFromAgGridSort = <D extends TData>(
  params: IServerSideGetRowsParams
): SortArray => {
  const { sortModel, rowGroupCols } = params.request;

  return sortModel
    .map((s) => {
      const colDef = params.columnApi.getColumn(s.colId)?.getColDef() as ColumnConfig<D>;
      if (!colDef) return;

      // if the column being sorted is being grouped, use the group field
      let field = colDef.sortField || s.colId;
      if (rowGroupCols.some((c) => c.field === s.colId)) {
        field = colDef.field || field;
      }

      return { field, direction: s.sort === "asc" ? 1 : -1 };
    })
    .filter(notNullish) as SortArray;
};

export const buildForageSelectFromAgGridColumns = <D extends TData>(columns: Column[]): SelectArray => {
  const hasVisibleCustomFieldColumn = columns.some((c) => c.getColDef().field?.includes("custom_field"));

  const selectColumns = columns
    .filter((c) => c.getColDef().field)
    .flatMap((c) => {
      const colDef = c.getColDef() as ColumnConfig<D>;
      const fields = [{ field: colDef.field!, show: true }];

      // Add the filter field if it exists
      if (colDef.filterField) {
        fields.push({ field: colDef.filterField, show: true });
      }

      return fields;
    });

  if (hasVisibleCustomFieldColumn) {
    selectColumns.push({ field: "custom_fields", show: true });
  }

  return selectColumns;
};

export const buildForageComparisonType = (
  type: ISimpleFilterModel["type"]
): FilterArray[number]["comparisonType"] => {
  switch (type) {
    case "equals":
      return "=";
    case "notEqual":
      return "ne";
    case "contains":
      return "contains";
    case "notContains":
      return "not.contains";
    case "startsWith":
      return "startsWith";
    case "endsWith":
      return "endsWith";
    case "lessThan":
      return "<";
    case "lessThanOrEqual":
      return "<=";
    case "greaterThan":
      return ">";
    case "greaterThanOrEqual":
      return ">=";
    case "inRange":
      return "<+>e";
    default:
      return undefined;
  }
};

export const buildForageDateComparisonType = (
  dateType: string | undefined,
  type: ISimpleFilterModel["type"]
) => {
  if (dateType === "iso") {
    return buildForageComparisonType(type);
  }
  return buildForageTimestampComparisonType(type);
};

export const buildForageTimestampComparisonType = (
  type: ISimpleFilterModel["type"]
): FilterArray[number]["comparisonType"] => {
  switch (type) {
    case "equals":
      return "<+>e";
    case "inRange":
      return "<+>e";
    case "notEqual":
      return "<+>";
    case "lessThan":
      return "<";
    case "lessThanOrEqual":
      return "<=";
    case "greaterThan":
      return ">";
    case "greaterThanOrEqual":
      return ">=";
    default:
      return undefined;
  }
};

export const buildForageStringFilter = <D extends TData>(
  field: string,
  filterItem: any,
  params: IServerSideGetRowsParams | SelectionChangedEvent
): FilterArray[number] => {
  const { type, filter: value } = filterItem;
  const comparisonType = buildForageComparisonType(type);

  const filterField =
    (params.columnApi.getColumn(field)?.getColDef() as ColumnConfig<D>).filterField || field;

  return { field: filterField, value, comparisonType, type: "string" } as FilterArray[number];
};

export const buildForageNumberFilter = <D extends TData>(
  field: string,
  filterItem: any,
  params: IServerSideGetRowsParams | SelectionChangedEvent
): FilterArray[number] => {
  const { type, filterTo } = filterItem;
  const comparisonType = buildForageComparisonType(type);

  const filterField =
    (params.columnApi.getColumn(field)?.getColDef() as ColumnConfig<D>).filterField || field;

  const value = filterItem.filter && filterTo ? [filterItem.filter, filterTo] : filterItem.filter;

  return { field: filterField, value, comparisonType, type: "number" } as FilterArray[number];
};

export const buildForageDateFilterValue = <D extends TData>(
  field: string,
  filterItem: any,
  columns: ColumnConfig<D>[],
  timezone?: string
): string | number | (string | number)[] => {
  const { dateFrom, dateTo } = filterItem;

  // Convert the date from the date picker to the correct format
  const parsedDataFrom = DateTime.fromFormat(dateFrom, "yyyy-MM-dd HH:mm:ss").setZone(timezone, {
    keepLocalTime: true,
  });

  const parsedDataTo = dateTo
    ? DateTime.fromFormat(dateTo, "yyyy-MM-dd HH:mm:ss").setZone(timezone, { keepLocalTime: true })
    : undefined;

  const columnDef = columns.find((c) => c.field === field);
  if (!columnDef) throw new Error("Column definition not found");

  // If the dateType is iso, we need to convert to a string otherwise we can use a timestamp
  if (columnDef.dateType === "iso") {
    return parsedDataFrom && parsedDataTo
      ? [parsedDataFrom.toISODate(), parsedDataTo.toISODate()]
      : parsedDataFrom.toISODate();
  } else {
    if (parsedDataFrom && parsedDataTo) {
      return [parsedDataFrom.startOf("day").toSeconds(), parsedDataTo.endOf("day").toSeconds()];
    } else {
      return filterItem.type === "equals" || filterItem.type === "notEqual"
        ? [parsedDataFrom.startOf("day").toSeconds(), parsedDataFrom.endOf("day").toSeconds()]
        : parsedDataFrom.toSeconds();
    }
  }
};

export const buildForageDateFilter = <D extends TData>(
  field: string,
  filterItem: any,
  params: IServerSideGetRowsParams | SelectionChangedEvent,
  columns: ColumnConfig<D>[],
  timezone?: string
): FilterArray[number] => {
  const { type } = filterItem;
  const columnDef = columns.find((c) => c.field === field);
  if (!columnDef) throw new Error("Column definition not found");

  const comparisonType = buildForageDateComparisonType(columnDef.dateType, type);

  const filterField =
    (params.columnApi.getColumn(field)?.getColDef() as ColumnConfig<D>).filterField || field;
  const value = buildForageDateFilterValue(field, filterItem, columns, timezone);
  const forageType = columnDef.dateType === "iso" ? "date_string" : "number";

  return {
    field: filterField,
    value,
    comparisonType,
    type: forageType,
    zone: timezone || getTimezone(),
  } as FilterArray[number];
};

export const BLANK_SET_FILTER_OPTION = "(blank)";

const buildForageSetFilter = <D extends TData>(
  field: string,
  filterItem: any,
  params: IServerSideGetRowsParams | SelectionChangedEvent,
  columns: ColumnConfig<D>[],
  timezone?: string
) => {
  // Make sure we can support blank values
  let includesBlank = false;
  const values: any[] = [];
  for (const v of filterItem.values || []) {
    if (v === BLANK_SET_FILTER_OPTION) {
      includesBlank = true;
    } else {
      values.push(v);
    }
  }

  const columnDef = columns.find((c) => c.field === field);
  if (!columnDef) throw new Error("Column definition not found");

  let formattedValues;
  let type;
  let comparisonType: "in" | undefined = "in";

  if (columnDef.filterParams?.valueGetter) {
    type = columnDef.dataType;
    formattedValues = columnDef.filterParams.valueGetter(values);
  } else if (columnDef.dataType === "number") {
    type = "number";
    formattedValues = values.map((v: string) => Number(v));
  } else if (columnDef.dataType === "date") {
    type = columnDef.dateType === "iso" ? "date_string" : "timestamp";
    formattedValues = values.map((v: string) =>
      buildForageDateFilterValue(field, { dateFrom: v }, columns, timezone)
    );
  } else if (columnDef.dataType === "string") {
    type = "string";
    formattedValues = values;
  } else {
    type = "or";
    comparisonType = undefined;

    if (values.length === 0) {
      formattedValues = [];
      type = "boolean";
      comparisonType = "in";
    } else {
      formattedValues = values.map((v) => {
        if (v === "✓" || v === true || v === "true") {
          return {
            field: field,
            comparisonType: "in",
            value: [true, "true"],
            type: "boolean",
          };
        } else {
          return [
            {
              type: "or",
              value: [
                { field: field, comparisonType: "not.exists", type: "boolean" },
                { field: field, comparisonType: "not.exists", type: "string" },
                { field: field, comparisonType: "=", value: false, type: "boolean" },
                { field: field, comparisonType: "=", value: "false", type: "string" },
              ],
            },
          ];
        }
      });
    }
  }

  const filterField =
    (params.columnApi.getColumn(field)?.getColDef() as ColumnConfig<D>).filterField || field;

  const base: MiterFilterField = {
    field: filterField,
    value: formattedValues.flat(),
    comparisonType,
    type,
  };

  if (includesBlank) {
    const compound: MiterFilterField = {
      type: "or",
      value: [base, { field: filterField, comparisonType: "exists", value: false, type }],
    };
    return compound;
  } else {
    return base;
  }
};

export const buildForageFilterFromAgGridFilter = <D extends TData>(
  filterModel: any,
  params: IServerSideGetRowsParams | SelectionChangedEvent,
  columns: ColumnConfig<D>[],
  timezone?: string
): FilterArray => {
  const filter: FilterArray = [];

  Object.entries(filterModel).forEach(([field, filterItem]: [string, any]) => {
    const agFilterType = filterItem.filterType;

    const column = columns.find((c) => c.field === field);
    if (!column) return;

    // If the column has a custom filter builder, use that
    if (column.customFilterBuilder) {
      const customFilter = column.customFilterBuilder(filterItem);
      if (customFilter) return filter.push(customFilter);
    }

    if ("condition1" in filterItem && "condition2" in filterItem) {
      const condition1 = buildForageFilterFromAgGridFilter(
        { [field]: filterItem.condition1 },
        params,
        columns,
        timezone
      )[0];

      const condition2 = buildForageFilterFromAgGridFilter(
        { [field]: filterItem.condition2 },
        params,
        columns,
        timezone
      )[0];

      const operator = filterItem.operator;

      if (operator === "AND" && condition1 && condition2) {
        filter.push(condition1);
        filter.push(condition2);
      } else {
        const vals: FilterArray = [];
        if (condition1) vals.push(condition1);
        if (condition2) vals.push(condition2);
        filter.push({ type: "or", value: vals });
      }
    } else {
      let forageFilter: FilterArray[number] | undefined;
      if (agFilterType === "text") {
        forageFilter = buildForageStringFilter(field, filterItem, params);
      } else if (agFilterType === "number") {
        forageFilter = buildForageNumberFilter(field, filterItem, params);
      } else if (agFilterType === "date") {
        forageFilter = buildForageDateFilter(field, filterItem, params, columns, timezone);
      } else if (agFilterType === "set") {
        forageFilter = buildForageSetFilter(field, filterItem, params, columns, timezone);
      }

      if (forageFilter) {
        filter.push(forageFilter);
      }
    }
  });

  const finalFilter = filter.filter(notNullish).map((f) => {
    if (f.field?.includes("ag-Grid-AutoColumn-")) {
      return {
        ...f,
        field: f.field.replace("ag-Grid-AutoColumn-", ""),
      };
    }

    return f;
  });

  return finalFilter;
};

const buildGroupFromAggFunc = (aggFunc: string): GroupOperation | undefined => {
  if (!aggFunc) return;

  switch (aggFunc) {
    case "sum":
      return "sum";
    case "sumValues":
      return "sum";
    case "avg":
      return "avg";
    case "min":
      return "min";
    case "max":
      return "max";
    case "count":
      return "count";
    default:
      return undefined;
  }
};

export const buildForageGroup = (params: IServerSideGetRowsParams): GroupArray => {
  const { rowGroupCols, groupKeys } = params.request;
  if (groupKeys.length === params.columnApi.getRowGroupColumns().length) return [];

  // Get the column definition for the current group
  const col = rowGroupCols[groupKeys.length];
  if (!col) return [];

  const { field } = col;
  if (!field) return [];

  // Get current active agg functions for all columns
  const currentAccumulators = (params.columnApi.getColumns() || [])
    .map((c) => {
      const localAggFunc = c.getAggFunc();
      const localField = c.getColDef().field;
      if (!localAggFunc || !localField) return;

      const localAggFuncName = typeof localAggFunc === "function" ? localAggFunc.name : localAggFunc;
      if (!localAggFuncName) return;

      const operation = buildGroupFromAggFunc(localAggFuncName);
      if (!operation) return;

      return {
        operation,
        field: localField,
        label: localField,
      };
    })
    .filter(notNullish);

  const baseAccumulators = [{ operation: "count" as const, field, label: "count" }];
  const accumulators = currentAccumulators.concat(baseAccumulators);

  const fullColumn = params.columnApi.getColumn(field)?.getColDef() as ColumnConfig<TData>;
  const formatter = fullColumn.groupFormatter;

  // We want the field to be groupField if it exists, otherwise the field but the label should always be field b/c the label is used to filter child data
  return [{ field: fullColumn.groupField || field, label: field, accumulators, formatter }];
};
