import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import React, { FC, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import "./DateTimePicker.css";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";

export type DateRange = {
  start?: DateTime | undefined | null;
  end?: DateTime | null;
};

type Props = {
  value?: DateRange;
  min?: DateTime;
  max?: DateTime;
  timeIntervals?: number;
  dateFormat?: string;
  className?: string;
  onChange: (range: DateRange | undefined) => void;
  sideEffect?: () => void;
  inputClassName?: string;
  disabled?: boolean;
  ignoreToText?: boolean;
  startPlaceholder?: string;
  endPlaceholder?: string;
  debounceMs?: number;
};

const DateRangePicker: FC<Props> = ({
  min,
  max,
  value,
  dateFormat,
  className,
  onChange,
  sideEffect,
  inputClassName,
  ignoreToText,
  disabled,
  startPlaceholder,
  endPlaceholder,
  debounceMs,
}) => {
  const { t } = useTranslation<"$TSFixMe">();

  const [range, setRange] = useState<DateRange | undefined>(value);

  const minDate = min ? min.toJSDate() : DateTime.now().minus({ years: 100 }).toJSDate();
  const maxDate = max ? max.toJSDate() : DateTime.now().plus({ years: 100 }).toJSDate();

  useEffect(() => {
    setRange(value);
  }, [value]);

  const handleDatetimeChange = (date: Date | null, startOrEnd: "start" | "end") => {
    const now = DateTime.now();
    const newRange = { ...range };

    // If date is null, it means the user cleared the date
    if (!date) {
      if (startOrEnd === "start") {
        newRange.start = undefined;
      } else if (startOrEnd === "end") {
        newRange.end = undefined;
      }
      setRange(newRange);
      onChange(newRange);
      return;
    }

    const updatedDateTime = now.set({
      year: date?.getFullYear(),
      month: date ? date.getMonth() + 1 : undefined,
      day: date?.getDate(),
    });

    if (startOrEnd === "start") {
      newRange.start = updatedDateTime.startOf("day");
    } else if (startOrEnd === "end") {
      newRange.end = updatedDateTime.endOf("day");
    }

    if (newRange.start && newRange.end && newRange.start > newRange.end) {
      if (startOrEnd === "start") newRange.end = newRange.start;
      else newRange.start = newRange.end;
    }

    setRange(newRange);
    onChange(newRange);

    if (sideEffect) sideEffect();
  };

  const debouncedHandleDatetimeChange = useDebouncedCallback(handleDatetimeChange, debounceMs || 0);

  return (
    <div className={"datetime-picker-container-v2 " + (className ? className : "")}>
      <div className={"datetime-picker-date-container " + "date-only"} style={{ alignItems: "center" }}>
        <DatePicker
          selected={range?.start?.toJSDate()}
          onChange={(date) => debouncedHandleDatetimeChange(date, "start")}
          dateFormat={dateFormat || "MMM d, yyyy"}
          minDate={minDate}
          maxDate={maxDate}
          showYearDropdown
          className={"form2-text " + inputClassName}
          wrapperClassName={"datetime-picker-date-wrapper"}
          placeholderText={startPlaceholder || t("Select a date")}
          disabled={disabled}
        />
      </div>
      <div style={{ margin: "0px 10px", alignItems: "center", verticalAlign: "center", display: "flex" }}>
        {ignoreToText ? "" : "to"}
      </div>
      <div className={"datetime-picker-date-container " + "date-only"}>
        <DatePicker
          selected={range?.end?.toJSDate()}
          onChange={(date) => debouncedHandleDatetimeChange(date, "end")}
          dateFormat={dateFormat || "MMM d, yyyy"}
          minDate={minDate}
          maxDate={maxDate}
          showYearDropdown
          className={"form2-text " + inputClassName}
          wrapperClassName={"datetime-picker-date-wrapper"}
          placeholderText={endPlaceholder || t("Select a date")}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
