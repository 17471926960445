import React from "react";
import { ICellRendererParams } from "ag-grid-community";

type CheckboxRendererProps<TData = $TSFixMe> = ICellRendererParams<TData, boolean | undefined> & {
  disabled?: boolean | ((data: TData) => boolean);
};

export const AgGridCheckboxRenderer: React.FC<CheckboxRendererProps> = (props) => {
  const disabled = typeof props.disabled === "function" ? props.disabled(props.data) : props.disabled;

  const isChecked = !!(props.getValue?.() || props.value);

  return (
    <div className="ag-cell-wrapper ag-checkbox-cell" role="presentation">
      <div className="ag-labeled ag-label-align-right ag-checkbox ag-input-field" role="presentation">
        <div
          className={
            "ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" +
            (isChecked ? " ag-checked" : "") +
            (disabled ? " ag-disabled" : "")
          }
          role="presentation"
        >
          <input
            className="ag-input-field-input ag-checkbox-input"
            type="checkbox"
            defaultChecked={isChecked}
            onChange={(e) => props.setValue?.(e.target.checked)}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
