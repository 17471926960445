import React from "react";
import FilterSelector from "./FilterSelector";
import { DateTimeInput } from "ui";
import { DateTime } from "luxon";
import { FilterType, FilterValue } from "./types";

type FormatType = "regular" | "between_first" | "between_second";

type Props = {
  filter: FilterType;
  index: string;
  selectOptions: FilterValue[];
  selectValue: FilterValue | null;
  onInput: (filter: FilterType, index: string, returnVal: string | number, type: FormatType) => void;
  dateValue: number | string; // ??
  inputType: "timestamp" | "date_string";
};

const DateFilter: React.FC<Props> = (props) => {
  const { filter, index, selectOptions, selectValue, onInput, dateValue, inputType } = props;

  const inbetween = selectValue?.value === "<+>";

  // Convert from number/string to luxon object, which is the format
  // expected in the datetime picker.
  const formatInput = (val) => {
    let inputVal: DateTime | null = null;
    if (val) {
      if (inputType === "timestamp") {
        inputVal = DateTime.fromSeconds(Number(val));
      } else if (inputType === "date_string") {
        inputVal = DateTime.fromISO(val);
      }
    }
    return inputVal;
  };

  // Filter component stores strings and numbers for the date filters.
  // Convert to expected format, and only return if the value changed.
  // If don't check for value change, then the component renders infinitely.
  const formatOutput = (dt: DateTime, type: FormatType) => {
    let returnVal: string | number = "";
    if (inputType === "timestamp") {
      const iso = dt.toISODate();
      const lux = DateTime.fromISO(iso);
      returnVal = parseInt(lux.toSeconds() as unknown as string);
    } else if (inputType === "date_string") {
      returnVal = dt.toISODate();
    }
    let currentVal = dateValue;

    if (selectValue?.value === "<+>") {
      if (type === "between_first") {
        currentVal = dateValue[0];
      } else {
        currentVal = dateValue[1];
      }
    }
    if (currentVal !== returnVal) {
      onInput(filter, index, returnVal, type);
    }
  };

  return (
    <div className="date-filter-wrapper">
      <FilterSelector
        // @ts-expect-error not yet sure who's right here.
        onSelect={onInput}
        filter={filter}
        index={index}
        selectOptions={selectOptions}
        selectValue={selectValue}
      />
      <div className="date-picker-wrapper">
        {!inbetween && (
          <DateTimeInput
            className={"date-filter"}
            onChange={(dt) => formatOutput(dt!, "regular")}
            defaultValue={formatInput(dateValue)}
            closeOnSelect={true}
          />
        )}
        {inbetween && (
          <>
            <DateTimeInput
              className={"date-filter"}
              onChange={(dt) => formatOutput(dt!, "between_first")}
              defaultValue={formatInput(dateValue[0])}
              closeOnSelect={true}
            />
            <div className="date-text-wrapper">and</div>
            <DateTimeInput
              className={"date-filter"}
              onChange={(dt) => formatOutput(dt!, "between_second")}
              defaultValue={formatInput(dateValue[1])}
              closeOnSelect={true}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DateFilter;
