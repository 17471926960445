import React from "react";

import "./Loader.css";

type LoaderProps = {
  className?: string;
  message?: React.ReactNode;
  style?: $TSFixMe;
  small?: boolean;
};
const Loader: React.FC<LoaderProps> = ({ className, message, style, small }: LoaderProps) => {
  if (small) className += " small-text ";

  return (
    <div className={"loader-wrapper " + className} style={style}>
      <div className={"loader " + className}></div>
      {message && <div className={"loader-text"}>{message}</div>}
    </div>
  );
};

export default Loader;
