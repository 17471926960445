import { GridApi, ValueFormatterFunc, IAggFuncParams } from "ag-grid-community";
import { usdString } from "ui";
import { roundTo } from "./misc";

export function getRowData<T>(gridApi: GridApi<T> | undefined): T[] | undefined {
  if (!gridApi) return;
  const allRowData: T[] = [];
  gridApi.forEachNode((node) => {
    if (node.data) allRowData.push(node.data);
  });
  return allRowData;
}

export const identityAggFunc = (params: $TSFixMe): string => {
  for (const value of params.values || []) {
    if (value) return value;
  }
  return "-";
};

export const toDollarFormat = (params: $TSFixMe): string => {
  if (params.value == null) return "";
  return usdString(params.value);
};

export const sumValues = (params: $TSFixMe): number => {
  let sum = 0;
  if (params.values.length > 0) {
    for (const value of params.values) {
      if (isNaN(Number(value))) continue;
      sum += Number(value);
    }
  }
  return roundTo(sum);
};

export const roundToTwoDecimals = (params: $TSFixMe): string => {
  if (params.value == null) return "";
  return roundTo(params.value).toString();
};

export const agGridIsoDateComparator = (a: Date, b: string): number => {
  const comparisonString = a?.toISOString().split("T")[0];
  let returnValue = 0;
  if (comparisonString) {
    if (comparisonString > b) {
      returnValue = -1;
    } else if (comparisonString < b) {
      returnValue = 1;
    }
  }
  return returnValue;
};

export const hoursFormatter: ValueFormatterFunc = (params): string => {
  if (params?.value === 0) {
    return "-";
  } else {
    return params?.value?.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 2 });
  }
};

export const groupDedupedSumValues = (params: IAggFuncParams<$TSFixMe>): number => {
  let sum = 0;
  const colId = params.column.getColId();
  const groupsAccountedFor: Set<string> = new Set();
  const entries = params.rowNode.allLeafChildren.map((rn) => rn.data);
  entries.forEach((e) => {
    if (e?.groupId && !groupsAccountedFor.has(e.groupId)) {
      sum += Number(e[colId]) || 0;
      groupsAccountedFor.add(e.groupId);
    }
  });
  return sum;
};
