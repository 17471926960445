import React from "react";

type Props = {
  buttons: { className?: string; onClick?: React.MouseEventHandler; text?: React.ReactNode }[];
};

const ButtonGroup: React.FC<Props> = (props) => {
  return (
    <div className="flex">
      {props.buttons.map((button) => (
        <button onClick={button.onClick} className={button.className}>
          {button.text}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
