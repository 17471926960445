import { DateTime } from "luxon";
import { ESignatureInputValue } from "ui/form/ESignatureInput";

export const required = { required: "This field is required" };

export const email = {
  pattern: {
    value:
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    message: "Please enter a valid email address.",
  },
};

export const phoneNumber = {
  pattern: {
    value: /^\\+[1-9]\\d{1,14}$/,
    message: "Please enter a valid phone number.",
  },
};

// If you have a form with a start and end date, use this validator to make sure that the start date is before the end date
export const isValidEndDate = (startDate: DateTime, endDate: DateTime): boolean | string => {
  if (startDate && endDate) {
    return startDate <= endDate || "Invalid end date.";
  } else {
    return true;
  }
};

export const esignatureRequired = (value: ESignatureInputValue | null | undefined): boolean | string => {
  const isEmpty = !value?.data || !value.type || !value?.data?.length;
  if (isEmpty) return "You must e-sign to continue.";
  if (!value?.agreed_to_disclosure) return "You must agree to the e-signature disclosure to continue.";

  return true;
};
