import React from "react";
import ReactTooltip from "react-tooltip";
import styles from "./textWithTooltip.module.css";

type Props = React.ComponentProps<typeof ReactTooltip> & {
  /** Each tooltip should get a unique ID so multiple tooltips don't show up in the same place. */
  id: string;
  text?: React.ReactNode;
  tooltip?: React.ReactNode;
  children?: React.ReactNode;
  place?: "top" | "right" | "left" | "bottom";
  spanStyle?: $TSFixMe;
  suppressUnderline?: boolean;
};

const TextWithTooltip = ({
  id,
  text,
  tooltip,
  children,
  place = "top",
  spanStyle,
  suppressUnderline,
  ...tooltipProps
}: Props): React.ReactElement => {
  return (
    <div>
      <span
        data-tip
        data-for={id}
        style={spanStyle}
        className={suppressUnderline ? "" : styles["underlined-text"]}
      >
        {text}
        {children}
      </span>
      {tooltip && (
        <ReactTooltip id={id} place={place} effect={"solid"} className="tooltip" {...tooltipProps}>
          <span>{tooltip}</span>
        </ReactTooltip>
      )}
    </div>
  );
};

export default TextWithTooltip;
