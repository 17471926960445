import { MiterAPI } from "dashboard/miter";
import { useCallback } from "react";
import { Notifier } from "ui";
import { FilePickerFile } from "ui/form/FilePicker";
type Props = {
  file: FilePickerFile;
};
export const useFileDownload = (): ((params: Props) => Promise<void>) => {
  const handleLinkClick = useCallback(async (params: { file: FilePickerFile }) => {
    const { file } = params;
    if (file.url) {
      window.open(file.url, "_blank");
    } else if (file.blob) {
      const url = URL.createObjectURL(file.blob);
      window.open(url, "_blank");
    } else if (file.data) {
      const res = await MiterAPI.files.get_urls({
        filter: [{ field: "_id", value: file.data._id, type: "_id" }],
      });

      if (res.error) {
        Notifier.error("We are unable to open this file. Please try again later.");
      } else if (res.urls.length) {
        window.open(res.urls[0]!["value"].url, "_blank");
      }
    } else {
      Notifier.error("Invalid file");
    }
  }, []);
  return handleLinkClick;
};
