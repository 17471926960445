import { FC, useMemo, useState } from "react";
import { Button, TableV2 } from "ui";
import { AggregatedTeamMember, BankAccount, TeamMember } from "dashboard/miter";
import { ColumnConfig } from "ui/table-v2/Table";
import React from "react";
import { useFetchBankAccounts } from "./utils";
import { ViewBankAccountModal } from "./ViewBankAccountModal";
import { CreateBankAccountModal } from "./CreateBankAccountModal";
import { useTranslation } from "react-i18next";
import { TeamPortalUser } from "team-portal/utils/miter";
import { CheckBankAccountStatus } from "../../../backend/utils/check/check-types";
import type { TeamMemberAbilities, TeamMemberParams } from "dashboard/hooks/abilities-hooks/useTeamAbilities";

type Props = {
  teamMember?: TeamMember | AggregatedTeamMember | TeamPortalUser;
  companyId: string;
  verify2FA: (onSuccess: () => void) => void;
  teamAbilities?: TeamMemberAbilities;
};

export const BankAccountsTable: FC<Props> = ({ verify2FA, teamMember, companyId, teamAbilities }) => {
  const {
    data: result,
    isLoading: loading,
    refetch: refetchBankAccounts,
  } = useFetchBankAccounts({ companyId, teamMemberId: teamMember?._id });

  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();
  const [openCreate, setOpenCreate] = useState(false);
  const { t } = useTranslation<$TSFixMe>();

  const handleOpenCreate = () => {
    verify2FA?.(() => {
      setOpenCreate(true);
    });
  };

  // remember that these columns will also be shown on the team portal!
  const columns = useMemo(() => {
    const isTeamMemberEnrolledInPayroll = !!teamMember?.check_tm;

    const cols: ColumnConfig<BankAccount>[] = [
      {
        headerName: t("Last 4"),
        field: "account_number_last_4",
        dataType: "string",
        valueFormatter: (params) => `····${params.value}`,
      },
      {
        headerName: t("Type"),
        field: "account_subtype",
        dataType: "string",
        displayType: "badge",
        colors: {
          checking: "green",
          savings: "orange",
        },
      },
    ];

    if (isTeamMemberEnrolledInPayroll) {
      cols.push({
        headerName: t("Direct deposit verification"),
        field: "check_bank_account.status",
        dataType: "string" as const,
        displayType: "badge" as const,
        colors: {
          verified: "green",
          pending: "blue",
          disabled: "red",
        },
        valueGetter: (params) => {
          const checkStatus: CheckBankAccountStatus | undefined = params.data?.check_bank_account?.status;
          switch (checkStatus) {
            case "disabled_irrecoverable":
            case "disabled_recoverable":
              return "disabled";
            case "ownership_verified":
            case "validated":
              return "verified";
            case "validation_pending":
              return "pending";
          }
        },
      });
    }

    return cols;
  }, [t, teamMember?.check_tm]);

  return (
    <div>
      <div className="flex space-between margin-bottom-negative-15">
        <h2>{t("Bank accounts")}</h2>
        {(!teamAbilities || teamAbilities.can("update_pay_info", teamMember as TeamMemberParams)) && (
          <Button onClick={handleOpenCreate} text={t("Add bank account")} />
        )}
      </div>
      <TableV2
        id="bank-accounts-table"
        resource="bank accounts"
        columns={columns}
        data={result}
        isLoading={loading}
        onClick={setSelectedBankAccount}
        hideSearch
        hideSecondaryActions
      />
      {selectedBankAccount && (
        <ViewBankAccountModal
          teamMember={teamMember}
          bankAccount={selectedBankAccount}
          onUpdate={refetchBankAccounts}
          onClose={() => setSelectedBankAccount(undefined)}
          verify2FA={verify2FA}
        />
      )}
      {openCreate && (
        <CreateBankAccountModal
          teamMemberId={teamMember?._id}
          companyId={companyId}
          onSubmit={refetchBankAccounts}
          onClose={() => {
            setOpenCreate(false);
          }}
        />
      )}
    </div>
  );
};
