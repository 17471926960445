import React from "react";
import "./Table.css";
import Row from "./TableRow";
import { ColumnConfigs, TableEntry } from "./types";
import { roundTo } from "dashboard/utils/utils";
import { usdString } from "../utils";

type Props<D extends TableEntry> = {
  data: D[];
  columns: ColumnConfigs;
  onSelect: React.ComponentProps<typeof Row>["onSelect"];
  hideSelect?: boolean;
};

const TableTotal = <D extends TableEntry>({
  data,
  columns,
  hideSelect,
  onSelect,
}: Props<D>): React.ReactElement => {
  const calculateTotals = () => {
    const totalsEntry: TableEntry = { _id: "total", disableSelect: true };

    // Construct dictionary of array of values for each column, with field name as key.
    // Only iterate through table data once to avoid repetitive processing.
    const columnToValuesDictionary = {};
    for (const row of data) {
      for (const column of columns) {
        if (!column.hidden && column.field) {
          if (column.total_type && column.total_type !== "custom") {
            if (!columnToValuesDictionary[column.field]) {
              columnToValuesDictionary[column.field] = [];
            }
            columnToValuesDictionary[column.field].push(row[column.field]);
          }
        }
      }
    }

    for (const column of columns) {
      if (!column.field || column.hidden) {
        continue;
      }
      // Default value for no aggregation. Overwrites below if aggregation specified.
      totalsEntry[column.field] = "-";

      if (column.total_type === "number") {
        totalsEntry[column.field] = calculateNumberTotal(columnToValuesDictionary[column.field]);
      } else if (column.total_type === "dollar") {
        totalsEntry[column.field] = calculateDollarTotal(columnToValuesDictionary[column.field]);
      } else if (column.total_type === "dollar_string") {
        totalsEntry[column.field] = calculateDollarStringTotal(columnToValuesDictionary[column.field]);
      } else if (column.total_type === "custom" && column.total_function) {
        // For now, pass all table data back to aggregation function rather than cleaned array
        // of data for that column. Though not as clean, the aggregation might use other columns/data.
        totalsEntry[column.field] = column.total_function(data);
      }
    }

    return totalsEntry;
  };

  const calculateNumberTotal = (values: (number | string)[]) => {
    const ans = values.reduce((sum, val) => {
      if (isNaN(Number(val))) {
        return sum;
      } else {
        return (sum as number) + Number(val);
      }
    }, 0) as number;

    return roundTo(ans, 4); // round to 4th decimal point to prevent displaying weird float math results
  };

  const calculateDollarTotal = (values: number[]) => {
    return usdString(values.reduce((sum, val) => sum + Number(val), 0));
  };

  const calculateDollarStringTotal = (values: string[]) => {
    let sum = 0;
    for (const val of values) {
      if (val && val !== "-" && val !== "") {
        sum += Number(val.replace(/[^0-9.-]+/g, ""));
      }
    }
    return usdString(sum);
  };

  return (
    <>
      <Row
        key={data.length}
        row_num={data.length}
        id={"total"}
        entry={calculateTotals()}
        columns={columns}
        onSelect={onSelect}
        hideHover={true}
        hideSelect={hideSelect}
        isTotalRow={true}
      />
    </>
  );
};

export default TableTotal;
