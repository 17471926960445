import AppContext from "team-portal/contexts/app-context";
import React, { useContext } from "react";
import { BankAccountsTable } from "miter-components/bank-accounts/BankAccountsTable";
import { useTranslation } from "react-i18next";
import { InlineNetPaySplits } from "miter-components/bank-accounts/InlineNetPaySplits";
import { InlinePaymentMethodAndWithholdings } from "miter-components/onboarding/InlinePaymentMethodAndWithholdings";
import { PaymentInfoWarningBanners } from "miter-components/bank-accounts/PaymentInfoWarningBanners";

const PaymentInfo = (): JSX.Element | null => {
  const { activeTM: teamMember, fetchUserData, setReverifyUser, setOnReverifyUser } = useContext(AppContext);
  const { t } = useTranslation<$TSFixMe>();

  const verify2FA = (onSuccess: () => void) => {
    setReverifyUser(true);
    setOnReverifyUser(() => onSuccess);
  };

  if (!teamMember) return <></>;

  return (
    <div>
      <h1 className="view-title">{t("Payment Info")}</h1>
      <PaymentInfoWarningBanners teamMember={teamMember} />
      <BankAccountsTable teamMember={teamMember} companyId={teamMember.company._id} verify2FA={verify2FA} />
      {teamMember.check_tm && (
        <InlinePaymentMethodAndWithholdings teamMember={teamMember} onUpdate={fetchUserData} />
      )}
      <div className="margin-top-50">
        {teamMember.check_tm?.payment_method_preference === "direct_deposit" && (
          <InlineNetPaySplits teamMember={teamMember} verify2FA={verify2FA} onUpdate={fetchUserData} />
        )}
      </div>
    </div>
  );
};

export default PaymentInfo;
