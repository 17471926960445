import React, { Dispatch, SetStateAction } from "react";
import { Customer, CustomField, Tag, MiterIntegrationForCompany } from "dashboard/miter";

type AppContext = {
  shiftPage: boolean;
  integrations: MiterIntegrationForCompany[];
  getIntegrations: () => Promise<void>;
  setShiftPage: Dispatch<SetStateAction<boolean>>;
  customers: Customer[];
  getCustomers: () => Promise<void>;
  tags: Tag[];
  getTags: () => Promise<void>;
  fetchUserData: () => Promise<void>;
  showUserSettingsModal?: boolean;
  setShowUserSettingsModal: Dispatch<SetStateAction<boolean>>;
  customFields: CustomField[];
  getCustomFields: () => Promise<void>;
  reverifyUser: boolean;
  setReverifyUser: Dispatch<SetStateAction<boolean>>;
  onReverifyUser: () => void;
  setOnReverifyUser: Dispatch<SetStateAction<() => void>>;
};

const AppContext = React.createContext<AppContext>({
  shiftPage: false,
  customers: [],
  tags: [],
  getCustomers: async () => {},
  getTags: async () => {},
  integrations: [],
  getIntegrations: async () => {},
  setShiftPage: () => {},
  fetchUserData: async () => {},
  showUserSettingsModal: false,
  setShowUserSettingsModal: () => {},
  reverifyUser: false,
  setReverifyUser: () => () => {},
  onReverifyUser: async () => {},
  setOnReverifyUser: () => {},
  customFields: [],
  getCustomFields: async () => {},
});

export default AppContext;
