import { AggregatedTeamMember, BankAccount, MiterAPI, MiterFilterArray } from "dashboard/miter";
import { NetPaySplit } from "./ManageNetPaySplits";
import { CheckNetPaySplit, CheckNetPaySplitCreationParams } from "../../../backend/utils/check/check-types";
import ObjectID from "bson-objectid";
import { TeamPortalUser } from "team-portal/utils/miter";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { componentsQueryClient } from "..";

export const BANK_ACCOUNTS_QUERY_KEY = "BANK_ACCOUNTS";

export const useFetchBankAccounts = (params: {
  companyId: string | undefined | null;
  teamMemberId?: string;
}): UseQueryResult<BankAccount[], Error> => {
  const { companyId, teamMemberId } = params;

  const q = useQuery(
    {
      queryKey: [BANK_ACCOUNTS_QUERY_KEY, { companyId, teamMemberId }] as const,
      queryFn: async ({ queryKey }) => {
        const [_, { companyId, teamMemberId }] = queryKey;

        if (!companyId) return [];
        const filter: MiterFilterArray = [{ field: "company_id", value: companyId }];

        if (teamMemberId) {
          filter.push({ field: "team_member_id", value: teamMemberId });
          filter.push({ field: "external_financial_account_type", value: "team_member" });
        } else {
          filter.push({ field: "external_financial_account_type", value: "company" });

          // remove bank accounts added through Check, which are payroll only
          filter.push({ field: "type", comparisonType: "ne", value: "check_originated_bank_account" });
        }

        const res = await MiterAPI.bank_accounts.forage({ filter });
        return res.data;
      },
    },
    componentsQueryClient
  );

  return q;
};

export const convertCheckNetPaySplit = (
  checkNetPaySplit: CheckNetPaySplit,
  existingBankAccounts: BankAccount[]
): NetPaySplit[] => {
  return checkNetPaySplit.splits.map((split) => {
    const matchingBankAccount = existingBankAccounts.find(
      (bankAccount) => bankAccount.check_id === split.bank_account
    );

    const { percentage, amount } = split;
    return {
      percentage: percentage ? parseFloat(percentage) : undefined,
      dollarAmount: amount ? parseFloat(amount) : undefined,
      bankAccountId: matchingBankAccount?._id,
      _id: new ObjectID().toHexString(),
    };
  });
};

export const convertMiterNetPaySplitToCheckCreationParams = (
  netPaySplits: NetPaySplit[],
  teamMember: AggregatedTeamMember | TeamPortalUser,
  existingBankAccounts: BankAccount[] // required for lookup
): CheckNetPaySplitCreationParams => {
  const checkEmployeeId = teamMember.employment_type === "employee" ? teamMember.check_id : undefined;
  const checkContractorId = teamMember.employment_type === "contractor" ? teamMember.check_id : undefined;

  const params: CheckNetPaySplitCreationParams = {
    employee: checkEmployeeId ?? undefined,
    contractor: checkContractorId ?? undefined,
    splits: netPaySplits.map((split, index) => {
      return {
        bank_account: existingBankAccounts?.find((bank) => bank._id === split.bankAccountId)?.check_id || "",
        amount: split.dollarAmount ?? null,
        percentage: split.percentage ?? null,
        priority: index + 1,
      };
    }),
    is_default: true, // always new default
  };

  return params;
};

export const validateMiterNetPaySplitForCheckSubmission = (netPaySplits: NetPaySplit[]): boolean => {
  if (netPaySplits.length === 0) return false; // must have at least one split

  // every split must have a bank account
  const hasBankAccount = netPaySplits.every((split) => split.bankAccountId);

  // every split must either have a percentage or a dollar amount
  const hasPercentage = netPaySplits.every(
    (split) =>
      (split.percentage !== undefined && split.percentage > 0 && split.percentage < 100) ||
      (!split.percentage && !split.dollarAmount)
  );

  // confirm that all percentages are not greater than 100
  if (hasPercentage) {
    const totalPercentage = netPaySplits.reduce((acc, split) => {
      return acc + (split.percentage || 0);
    }, 0);

    if (totalPercentage > 100) return false;
  }

  const hasDollarAmount = netPaySplits.every(
    (split) =>
      (split.dollarAmount !== undefined && split.dollarAmount > 0) ||
      (!split.percentage && !split.dollarAmount)
  );

  return hasBankAccount && (hasPercentage || hasDollarAmount);
};
