import React from "react";
import Select, { ValueType } from "react-select";
import { castToFilterValue, FilterType, FilterValue } from "./types";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    // borderColor: "#9e9e9e",
    minHeight: "25px",
    height: "25px",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: "25px",
    padding: "0 6px",
  }),

  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "25px",
  }),
};

type Props = {
  filter: FilterType;
  index: number | string;
  selectOptions: FilterValue[];
  selectValue: FilterValue | FilterValue["value"];
  onSelect: (filter: FilterType, index: number | string, e: $TSFixMe) => void;
  isEnum?: boolean;
};

const FilterSelector: React.FC<Props> = ({ filter, index, selectOptions, selectValue, onSelect }) => {
  // I think you're *supposed* to pass the full `{label: "thing", value: "thing"}` object every time,
  // but we seem to often just pass "thing" instead, which works?
  const value = castToFilterValue(selectValue, selectOptions);

  return (
    <Select
      className="filter-input-select"
      options={selectOptions}
      styles={customStyles}
      onChange={(e: ValueType<FilterValue, false>) => onSelect(filter, index, e)}
      value={value}
    />
  );
};

export default FilterSelector;
