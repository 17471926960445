import DocumentsTable from "team-portal/components/DocumentsTable";
import React, { useCallback, useContext, useEffect, useState, useMemo } from "react";
import AppContext from "team-portal/contexts/app-context";
import { BasicModal, Button, Loader } from "ui";
import styles from "./Onboarding.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { MiterAPI } from "team-portal/utils/miter";
import Notifier from "team-portal/utils/notifier";
import { useCheckComponent } from "team-portal/utils/check";
import { CheckCircle } from "phosphor-react";
import { AggregatedI9, AggregatedTeamMemberOnboardingChecklist, CustomField } from "dashboard/miter";
import { saveAs } from "file-saver";
import CustomFieldValueModal from "team-portal/components/custom-fields/CustomFieldValuesModal";
import { EmergencyContactsForm } from "team-portal/components/EmergencyContactsForm";
import { useTranslation } from "react-i18next";
import EditProfileModal from "team-portal/components/profile/EditProfileModal";
import { I9Wizard } from "miter-components";
import { UpdatePaymentMethodAndWithholdingsModal } from "miter-components/onboarding/UpdatePaymentMethodAndWithholdingsModal";
import { CheckAddress, CheckEmployee } from "backend/utils/check/check-types";
import { OnboardingChecklistV2 } from "miter-components";
import { AggregatedTeamMemberOnboardingTask } from "dashboard/miter";
import { AggregatedCertificationType } from "dashboard/types/certification-types";
import EmptyState from "ui/empty-state/EmptyState";

const Onboarding: React.FC = () => {
  const {
    activeTM,
    checkOnboardLoading,
    openCheckOnboard,
    fetchUserData,
    onboardingChecklistItem,
    I9,
    setReverifyUser,
    setOnReverifyUser,
    setConfetti,
  } = useContext(AppContext);

  const [openedCheckContractorOnboard, setOpenedCheckContractorOnboard] = useState(false);
  const [openedPersonalInfoModal, setOpenPersonalInfoModal] = useState(false);
  const [openedI9Modal, setOpenedI9Modal] = useState(false);
  const [completedI9Modal, setCompletedI9Modal] = useState(false);
  const [openPaymentMethodAndWithholdingsModal, setOpenPaymentMethodAndWithholdingsModal] = useState(false);
  const [checklist, setChecklist] = useState<AggregatedTeamMemberOnboardingChecklist | undefined>();
  const [checklistLoading, setChecklistLoading] = useState(true);

  const [creatingI9, setCreatingI9] = useState(false);
  const [editingCustomFields, setEditingCustomFields] = useState(false);
  const [editingEmergencyContacts, setEditingEmergencyContacts] = useState(false);
  const [downloadingI9, setDownloadingI9] = useState(false);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [certificationTypeMap, setCertificationTypeMap] = useState<Map<string, AggregatedCertificationType>>(
    new Map()
  );

  const { setComponentURL, renderComponent, setLoadingComponent } = useCheckComponent();
  const companyRequiresI9 = !!activeTM?.company?.settings?.team?.onboarding?.enable_online_i9s;
  const onboarded =
    activeTM?.onboarded &&
    (I9?.status === "complete" || !companyRequiresI9) &&
    (!onboardingChecklistItem ||
      (onboardingChecklistItem.custom_statuses?.every((item) => item.status === "complete") &&
        onboardingChecklistItem.statuses.every((item) => item.status === "complete")));
  const { t } = useTranslation<$TSFixMe>();
  const navigate = useNavigate();

  const hidePaymentInfoTab = activeTM?.company.settings.team.enabled_portal_tabs.payment_info === false;

  const showOnboardingChecklistV2 = checklist !== undefined || checklistLoading; // Show the old onboarding page if the team member has no checklist

  const newHireOnboardingTasks = useMemo(() => {
    return (
      checklist?.tasks?.filter((task) => task.assignee.some((assignee) => assignee.type === "self")) || []
    );
  }, [checklist?.tasks]);

  const getWitholdingsSetupLink = async () => {
    if (!activeTM) return;
    if (!activeTM.check_tm?.ssn_last_four) {
      Notifier.error(t("Please setup your SSN before you try to add your withholdings."));
      return;
    }

    if (!activeTM.check_tm?.start_date) {
      Notifier.error(
        t(
          "Please ask your company administrator to add your start date to your profile before you try to add your withholdings."
        )
      );
      return;
    }

    let address: CheckAddress | null = null;
    if ("address" in activeTM.check_tm) address = activeTM.check_tm?.address;
    if ("residence" in activeTM.check_tm) address = activeTM.check_tm?.residence;
    if (!address?.line1) {
      Notifier.error(t("Please setup your address before you try to add your withholdings."));
      return;
    }

    setLoadingComponent(true);
    try {
      const res = await MiterAPI.team_member.retrieve_withholdings_setup_link(activeTM?._id);
      setComponentURL(res.url);
    } catch (e: $TSFixMe) {
      console.error("Error getting withholdings setup link", e);
      Notifier.error(t("There was an error opening this page. Please contact support."));
    }
    setLoadingComponent(false);
  };

  const createI9 = async () => {
    if (!activeTM) return;
    setCreatingI9(true);

    try {
      const params = {
        team_member_id: activeTM._id,
        company_id: activeTM.company._id,
        manual: false,
      };

      const res = await MiterAPI.i_9s.create(params);
      if (res.error) throw new Error(res.error);

      await fetchUserData();
    } catch (e: $TSFixMe) {
      console.error("Error creating I9", e);
      Notifier.error(t("There was an error starting your I9. Please contact support."));
    }

    setCreatingI9(false);
  };

  const getCustomFields = useCallback(async () => {
    if (!activeTM) return;
    try {
      const filter = [{ field: "company_id", value: activeTM.company._id }];
      const response = await MiterAPI.custom_fields.search(filter);
      if (response.error) throw new Error(response.error);

      const accessibleCustomFields = response.filter((cf) => {
        return (
          cf.parent_type === "team_member" &&
          cf.team_member_access &&
          cf.team_member_access !== "none" &&
          cf.onboarding
        );
      });

      setCustomFields(accessibleCustomFields);
    } catch (e) {
      console.error(e);
    }
  }, [activeTM]);

  const getChecklist = useCallback(async () => {
    if (!activeTM) return;
    try {
      const filter = [{ field: "team_member_id", value: activeTM._id }];
      let response = await MiterAPI.team_member_onboarding_checklists.search(filter, true);
      if (response.error) throw new Error(response.error);

      /**
       * If no results, try searching by email and company ID
       * This edge case comes up when multiple users are created for different companies
       * with the same email, activeTM used the incorrect team member ID
       * (getActiveAccount doesn't find the correct account)
       */
      if (response.length === 0 && activeTM.email && activeTM.company?._id) {
        const fallbackFilter = [
          { field: "email", value: activeTM.email },
          { field: "company_id", value: activeTM.company._id },
        ];
        response = await MiterAPI.team_member_onboarding_checklists.search(fallbackFilter, true);

        if (response.error) throw new Error(response.error);
      }
      if (response.length) {
        setChecklist(response[0]);
      }
    } catch (e: $TSFixMe) {
      console.error("Error getting onboarding checklist tasks", e);
      Notifier.error(
        t("There was an error getting your onboarding checklist tasks. Please contact support.")
      );
    } finally {
      setChecklistLoading(false);
    }
  }, [activeTM, t]);

  const updateOnboardingChecklistTask = useCallback(
    async (task: Partial<AggregatedTeamMemberOnboardingTask>) => {
      if (!checklist?._id) return;
      try {
        const response = await MiterAPI.team_member_onboarding_checklists.update_tasks(checklist._id, {
          updatedTasks: [task],
        });
        if (response.error) throw new Error(response.error);
        // Set the checklist to the updated checklist
        setChecklist(response);
      } catch (e) {
        console.error("Error updating onboarding checklist", e);
        Notifier.error("Error updating onboarding checklist");
      }
    },
    [checklist]
  );

  const generateCertificationTypeOptions = useCallback(async () => {
    if (!activeTM) return;
    try {
      const res = await MiterAPI.certification_types.retrieve_many([
        { field: "company_id", value: activeTM.company._id },
      ]);
      if (res.error) throw res.error;

      const certificationTypeMap = new Map(res.map((certType) => [certType._id, certType]));
      setCertificationTypeMap(certificationTypeMap);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  }, [activeTM]);

  useEffect(() => {
    getCustomFields();
    getChecklist();
    generateCertificationTypeOptions();
  }, [getCustomFields, getChecklist, generateCertificationTypeOptions]);

  const lookupCertificationType = useCallback(
    (certificationTypeId: string) => certificationTypeMap?.get(certificationTypeId),
    [certificationTypeMap]
  );

  const ssnCompleted =
    !activeTM?.check_tm?.onboard?.remaining_steps?.includes("ssn") && activeTM?.ssn_last_four;

  const employeeDetailsComplete = !activeTM?.check_tm?.onboard?.remaining_steps?.includes("employee_details");

  const residenceComplete = !(activeTM?.check_tm as CheckEmployee)?.onboard?.employee_details?.includes(
    "residence"
  );

  const personalInfoCompleted = ssnCompleted && employeeDetailsComplete;

  const withholdingsCompleted =
    activeTM && residenceComplete && !activeTM?.check_tm?.onboard?.remaining_steps?.includes("withholdings");

  const paymentMethodCompleted = activeTM && !!activeTM.check_tm?.payment_method_preference;

  const netPaySplitCompleted =
    activeTM?.check_tm &&
    activeTM.check_tm.payment_method_preference === "direct_deposit" &&
    !!activeTM.check_tm.default_net_pay_split;

  const handleOpenPersonalInfo = () => {
    setOpenPersonalInfoModal(true);
  };

  const handleClosePersonalInfo = () => {
    setOpenPersonalInfoModal(false);
  };

  const handleOpenI9 = async () => {
    if (!I9) {
      await createI9();
    }

    if (!activeTM?.ssn_last_four) {
      Notifier.error(t("Please setup your SSN before you try to start your I-9."));
      return;
    }

    if (!activeTM.start_date) {
      Notifier.error(t("Your company start date is not set. Please reach out to your company admin."));
      return;
    }

    setOpenedI9Modal(true);
  };

  const handleCompleteI9 = async () => {
    if (!I9) return;

    setOpenedI9Modal(false);
    setCompletedI9Modal(true);
  };

  const handleCheckOnboardButtonClick = () => {
    if (!openedCheckContractorOnboard) {
      openCheckOnboard();
      setOpenedCheckContractorOnboard(true);
    }
  };

  const handleDownloadI9 = async () => {
    if (!I9) return;
    setDownloadingI9(true);
    try {
      const res = await MiterAPI.i_9s.retrieve_pdf(I9._id);
      if (res.error) throw new Error(res.error);

      const blob = await res.blob();
      saveAs(blob, `I-9_${activeTM?.full_name}.pdf`);
    } catch (e: $TSFixMe) {
      Notifier.error(t("Error downloading I-9. Please contact support."));
      console.error("Error downloading I-9", e);
    }
    setDownloadingI9(false);
  };

  const handleSaveCustomFields = () => {
    fetchUserData();
    setEditingCustomFields(false);
  };

  const renderOnboardingContent = () => {
    return (
      <>
        {renderOnboardingChecklist()}
        {renderProfileSection()}
        {companyRequiresI9 && renderI9Section()}
        {renderCustomFieldsSection()}
        {renderEmergencyContactsSection()}
        {renderOnboardingDocs()}
        {renderComponent()}
      </>
    );
  };

  const renderOnboardingChecklist = () => {
    if (!onboardingChecklistItem) return;
    const allComplete = onboardingChecklistItem?.statuses.every((item) => item.status === "complete");
    const allIncomplete = onboardingChecklistItem?.statuses.every((item) => item.status === "incomplete");
    return (
      <div style={{ marginTop: -10 }}>
        <h2 className={styles["sub-header"]}>{t("Onboarding checklist")}</h2>
        <Button className="button-1 margin-left-0" onClick={() => navigate("/onboarding/checklist")}>
          {allIncomplete ? "Start " : allComplete ? "Edit " : "Continue "} onboarding tasks
        </Button>
      </div>
    );
  };

  const renderOnboardingChecklist2_0 = () => {
    if (checklistLoading || !activeTM || !lookupCertificationType) {
      return <Loader />;
    }
    if (!checklist?.tasks) {
      return (
        <EmptyState
          title={t("No onboarding tasks found")}
          description={t(
            "Tasks will appear here once your company administrator creates an onboarding checklist for you."
          )}
        />
      );
    }

    return (
      <div style={{ marginTop: -10 }}>
        <OnboardingChecklistV2
          onboardingTasks={newHireOnboardingTasks}
          startDate={activeTM?.start_date}
          activeTM={activeTM}
          teamMemberId={activeTM?._id}
          checklist={checklist}
          refetchChecklist={getChecklist}
          refetchTeamMember={async () => {
            await fetchUserData(activeTM?._id);
          }}
          updateOnboardingChecklistTask={updateOnboardingChecklistTask}
          i9={I9}
          lookupCertificationType={lookupCertificationType}
          checkOnboardLoading={checkOnboardLoading}
          openCheckOnboard={openCheckOnboard}
          setReverifyUser={setReverifyUser}
          setOnReverifyUser={setOnReverifyUser}
          setConfetti={setConfetti}
        />
      </div>
    );
  };

  const renderProfileSection = () => {
    return (
      <>
        <h2 className={styles["sub-header"]}>{t("Profile")}</h2>
        <p className={styles["explainer"]}>{t("Setup your personal profile and payroll information.")}</p>
        <div className={styles["employee-profile-section"]}>
          {activeTM?.employment_type === "employee" && renderEmployeeProfileSection()}
          {activeTM?.employment_type === "contractor" && renderContractorProfileSection()}
        </div>
      </>
    );
  };

  const renderSetupPaymentMethodButton = () => {
    if (!activeTM?.check_tm) return;

    return (
      <Button
        className={`${styles["onboarding-button"]} button-1`}
        onClick={() => setOpenPaymentMethodAndWithholdingsModal(true)}
        disabled={!personalInfoCompleted}
        tooltip={personalInfoCompleted ? undefined : t("Please complete your personal info first.")}
      >
        {paymentMethodCompleted ? (
          <>
            <CheckCircle weight="fill" color={"#34b233"} style={{ marginRight: 5 }} />
            {t("Edit your payment method")}
          </>
        ) : (
          <>
            <img
              className="sidebar-img"
              style={{ height: 10, width: 10, marginRight: 5 }}
              src={"/alert.png"}
            />
            {t("Setup payment method")}
          </>
        )}
      </Button>
    );
  };

  const renderNetPaySplitButton = () => {
    if (hidePaymentInfoTab) return;

    // only show if team member selected direct deposit
    if (activeTM?.check_tm?.payment_method_preference !== "direct_deposit") return;
    return (
      <Button className="button-1" style={{ marginLeft: 5 }} onClick={() => navigate("/payment-info")}>
        {activeTM.check_tm.default_net_pay_split ? (
          <>
            {<CheckCircle weight="fill" color={"#34b233"} style={{ marginRight: 5 }} />}
            {t("Edit paycheck distribution")}
          </>
        ) : (
          <>
            <img
              className="sidebar-img"
              style={{ height: 10, width: 10, marginRight: 5, marginLeft: -0.5 }}
              src={"/alert.png"}
            />
            {t("Set up paycheck distribution")}
          </>
        )}
      </Button>
    );
  };

  const renderEmployeeCheckFields = () => {
    if (!activeTM?.check_tm) return;

    // Gate if payment method is not direct deposit
    const shouldShowPaycheckDistributionButton =
      activeTM.check_tm.payment_method_preference === "direct_deposit" && !hidePaymentInfoTab;

    return (
      <>
        {
          <Button
            className={`${styles["onboarding-button"]} button-1`}
            onClick={getWitholdingsSetupLink}
            disabled={!personalInfoCompleted}
            tooltip={personalInfoCompleted ? undefined : t("Please complete your personal info first.")}
          >
            {withholdingsCompleted ? (
              <>
                {<CheckCircle weight="fill" color={"#34b233"} style={{ marginRight: 5 }} />}
                {t("Edit your withholdings")}
              </>
            ) : (
              <>
                <img
                  className="sidebar-img"
                  style={{ height: 10, width: 10, marginRight: 5 }}
                  src={"/alert.png"}
                />
                {t("Setup withholdings")}
              </>
            )}
          </Button>
        }

        {shouldShowPaycheckDistributionButton && (
          <Button
            className={`${styles["onboarding-button"]} button-1`}
            onClick={() => navigate("/payment-info")}
            disabled={!personalInfoCompleted}
            tooltip={personalInfoCompleted ? undefined : t("Please complete your personal info first.")}
          >
            {paymentMethodCompleted && netPaySplitCompleted ? (
              <>
                <CheckCircle weight="fill" color={"#34b233"} style={{ marginRight: 5 }} />
                {t("Edit paycheck distribution")}
              </>
            ) : (
              <>
                <img
                  className="sidebar-img"
                  style={{ height: 10, width: 10, marginRight: 5 }}
                  src={"/alert.png"}
                />
                {t("Set up paycheck distribution")}
              </>
            )}
          </Button>
        )}
      </>
    );
  };

  const renderEmployeeProfileSection = () => {
    if (!activeTM) return;

    return (
      <div className={styles["employee-profile-buttons"]}>
        <Button className={`${styles["onboarding-button"]} button-1`} onClick={handleOpenPersonalInfo}>
          {personalInfoCompleted ? (
            <>
              <CheckCircle weight="fill" color={"#34b233"} style={{ marginRight: 5 }} />
              {t("Edit your personal info")}
            </>
          ) : (
            <>
              <img
                className="sidebar-img"
                style={{ height: 10, width: 10, marginRight: 5 }}
                src={"/alert.png"}
              />
              {t("Add your personal info")}
            </>
          )}
        </Button>
        {renderSetupPaymentMethodButton()}
        {renderEmployeeCheckFields()}
      </div>
    );
  };

  const renderContractorProfileSection = () => {
    return (
      <div className={styles["employee-profile-buttons"]}>
        <Button className="button-1 margin-left-0" onClick={handleOpenPersonalInfo}>
          {personalInfoCompleted ? (
            <>
              <CheckCircle weight="fill" color={"#34b233"} style={{ marginRight: 5 }} />
              {t("Edit your personal info")}
            </>
          ) : (
            <>
              <img
                className="sidebar-img"
                style={{ height: 10, width: 10, marginRight: 5, marginLeft: -0.5 }}
                src={"/alert.png"}
              />
              {t("Add your personal info")}
            </>
          )}
        </Button>
        {renderSetupPaymentMethodButton()}
        {renderNetPaySplitButton()}
        <Button
          className="button-1 margin-left-0"
          onClick={handleCheckOnboardButtonClick}
          loading={checkOnboardLoading}
          disabled={!personalInfoCompleted || !paymentMethodCompleted} // requires personal info and payment method to be completed
          tooltip={t("Please complete your personal info and payment method first.")}
        >
          {t("Sign W9")}
        </Button>
      </div>
    );
  };

  const renderOnboardingDocs = () => {
    return (
      <div>
        <div className="vertical-spacer"></div>
        <h2 className={styles["sub-header"]} style={{ marginBottom: 5 }}>
          {t("Documents")}
        </h2>
        <p className={styles["explainer"]}>{t("Review and sign your company's onboarding documents.")}</p>

        <DocumentsTable onboarding={true} />
      </div>
    );
  };

  const renderI9Button = () => {
    const draft = I9?.status === "draft";
    const complete = I9?.status === "complete";
    const employeeSubmitted = !!I9?.section_1?.employee_esignature_id;

    if (draft) {
      return (
        <Button
          className="button-1 no-margin"
          onClick={handleOpenI9}
          style={{ marginLeft: 0, marginRight: 10 }}
          loading={creatingI9}
        >
          {employeeSubmitted ? (
            <>
              <CheckCircle weight="fill" color={"#34b233"} style={{ marginRight: 5 }} />
              Edit your I-9
            </>
          ) : (
            "Continue I-9"
          )}
        </Button>
      );
    } else if (complete) {
      return (
        <Button
          className="button-1 no-margin"
          onClick={handleDownloadI9}
          style={{ marginLeft: 0, marginRight: 10 }}
          loading={downloadingI9}
        >
          <CheckCircle weight="fill" color={"#34b233"} style={{ marginRight: 5 }} />
          Download I-9
        </Button>
      );
    } else {
      return (
        <Button
          className="button-1 no-margin"
          onClick={handleOpenI9}
          style={{ marginLeft: 0, marginRight: 10 }}
          loading={creatingI9}
        >
          Start I-9
        </Button>
      );
    }
  };

  const renderI9Section = () => {
    if (activeTM?.employment_type !== "employee") return;

    return (
      <div>
        <div className="vertical-spacer"></div>
        <div className="vertical-spacer"></div>

        <h2 className={styles["sub-header"]} style={{ marginTop: -10 }}>
          I-9
        </h2>
        <p className={styles["explainer"]}>{t("Fill out your I-9 and upload the relevant documents")}</p>
        {renderI9Button()}
      </div>
    );
  };

  const renderI9Modal = () => {
    if (!openedI9Modal || !I9 || !activeTM) return;

    const requiredCheckFields = ["first_name", "last_name", "dob", "address"];
    if (requiredCheckFields.some((field) => !activeTM?.[field])) {
      return Notifier.error(t("Please complete your personal info before starting the I-9"));
    }

    return (
      <I9Wizard
        onExit={() => setOpenedI9Modal(false)}
        I9={I9 as AggregatedI9}
        onComplete={handleCompleteI9}
        activeTM={activeTM}
      />
    );
  };

  const renderCompletedI9Modal = () => {
    if (!completedI9Modal || !I9) return;

    return (
      <BasicModal
        headerText={"I-9 Complete"}
        button2Text={"Close"}
        button2Action={() => setCompletedI9Modal(false)}
        bodyText={t(
          "You have submitted your I-9. Please remember to bring the original versions of the documents you used to complete this form, on your first day of work."
        )}
      />
    );
  };

  const renderPaymethodMethodAndWithholdingsModal = () => {
    if (!activeTM) return;

    return (
      <UpdatePaymentMethodAndWithholdingsModal
        teamMember={activeTM}
        onClose={() => {
          fetchUserData(activeTM._id);
          setOpenPaymentMethodAndWithholdingsModal(false);
        }}
      />
    );
  };

  const renderCustomFieldsSection = () => {
    if (!activeTM || !customFields.length) return;

    const completedCustomFields = customFields.every((field) => {
      const value = activeTM?.custom_field_values?.find((v) => v.custom_field_id === field._id);
      return value?.value;
    });

    return (
      <>
        <div style={{ marginTop: 50 }}>
          <h2 className={styles["sub-header"]} style={{ marginBottom: 5 }}>
            Other information
          </h2>
          <p className={styles["explainer"]}>{t("Additional information requested by your company.")}</p>

          <Button
            className="button-1 no-margin"
            onClick={() => setEditingCustomFields(true)}
            style={{ marginLeft: 0, marginRight: 10 }}
          >
            {completedCustomFields ? (
              <>
                <CheckCircle weight="fill" color={"#34b233"} style={{ marginRight: 5 }} />
                {t("Edit your other info")}
              </>
            ) : (
              t("Add your other info")
            )}
          </Button>
        </div>
        {editingCustomFields && activeTM && (
          <CustomFieldValueModal
            parentID={activeTM?._id}
            parentType={"team_member"}
            defaultValues={activeTM?.custom_field_values || []}
            customFields={customFields}
            onFinish={() => handleSaveCustomFields()}
            onHide={() => setEditingCustomFields(false)}
          />
        )}
      </>
    );
  };

  const renderEmergencyContactsSection = () => {
    return (
      <div>
        <div className="vertical-spacer"></div>
        <h2 className={styles["sub-header"]} style={{ marginBottom: 5 }}>
          {t("Emergency Contacts")}
        </h2>
        <p className={styles["explainer"]}>
          {t("Add emergency contacts to be notified in case of an emergency.")}
        </p>

        <Button className="button-1 margin-left-0" onClick={() => setEditingEmergencyContacts(true)}>
          {activeTM?.emergency_contacts?.length ? (
            <>
              <CheckCircle weight="fill" color={"#34b233"} style={{ marginRight: 5 }} />
              {t("Edit your emergency contacts")}
            </>
          ) : (
            t("Add your emergency contacts")
          )}
        </Button>

        {editingEmergencyContacts && activeTM && (
          <EmergencyContactsForm onHide={() => setEditingEmergencyContacts(false)} />
        )}
      </div>
    );
  };

  // TODO: Deprecate current usage / setting of the onboarded TM field
  if (onboarded && !showOnboardingChecklistV2) {
    return <Navigate to="/profile" replace />;
  }

  return (
    <div>
      <h1 className="view-title">{t("Onboarding")}</h1>
      {showOnboardingChecklistV2 ? <>{renderOnboardingChecklist2_0()}</> : renderOnboardingContent()}
      {openedPersonalInfoModal && <EditProfileModal hide={handleClosePersonalInfo} />}
      {openedI9Modal && renderI9Modal()}
      {completedI9Modal && renderCompletedI9Modal()}
      {openPaymentMethodAndWithholdingsModal && renderPaymethodMethodAndWithholdingsModal()}
    </div>
  );
};

export default Onboarding;
