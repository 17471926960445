import React, { FC } from "react";
import styles from "./DisplaySignature.module.css";
import { DateTime } from "luxon";
type Props = {
  signature?: string;
  signerName?: string;
  signerTitle?: string;
  createdAt?: number;
  wrapperStyle?: React.CSSProperties;
};

const DisplaySignature: FC<Props> = ({ signature, signerName, signerTitle, createdAt, wrapperStyle }) => {
  const displaySecondBox = signerName || signerTitle || createdAt;
  return (
    <div style={wrapperStyle}>
      {signature ? <img className={styles["signature-img"]} src={signature} alt="signature" /> : null}
      {displaySecondBox ? (
        <div className={styles["signature-info"]}>
          {signerName ? <p style={{ marginBottom: 7, marginTop: 0 }}>{signerName}</p> : null}
          {signerTitle ? (
            <p style={{ marginTop: 3, marginBottom: 0, opacity: 0.8, fontSize: "0.9rem" }}>{signerTitle}</p>
          ) : null}
          {createdAt && (
            <p style={{ marginTop: 3, opacity: 0.6, fontSize: "0.8rem", marginBottom: 0 }}>
              Signed on {DateTime.fromSeconds(createdAt).toFormat("FF")}
            </p>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DisplaySignature;
