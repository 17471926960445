/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { UseFormMethods, useWatch } from "react-hook-form";
import { Formblock } from "ui";

import { CustomField, CustomFieldValue } from "dashboard/miter";
import { renderCustomFieldValue } from "team-portal/utils/custom-fields";
import { buildFieldSpecificProps, buildValidationRuleSet, getCustomFieldTypeForFormBlock } from "miter-utils";
import { DateTime } from "luxon";
import { FormField } from "../../../../backend/models/form";

type Props = {
  form: UseFormMethods;
  customFields: CustomField[];
  defaultValues: CustomFieldValue[];
  formblockClassName?: string;
  editing?: boolean;
  fieldOptions?: { inlineCheckbox?: boolean };
};

const CustomFieldValuesForm: React.FC<Props> = ({
  form,
  customFields,
  defaultValues,
  formblockClassName,
  editing,
  fieldOptions,
}) => {
  useWatch({ control: form.control });
  const { control, register, errors } = form;

  const { inlineCheckbox } = fieldOptions || {};

  const buildCustomFieldValue = (cf: CustomField | FormField, cfv: CustomFieldValue | undefined) => {
    if (cf.type === "date" && cfv?.value && typeof cfv.value === "string") {
      return DateTime.fromISO(cfv.value);
    }

    return cfv?.value;
  };

  const renderCustomFields = () => {
    return customFields.map((cf) => {
      const cfv = defaultValues.find((df) => df.custom_field_id === cf._id);

      const defaultString = renderCustomFieldValue(cf, cfv);
      const defaultValue = buildCustomFieldValue(cf, cfv);

      const hideLabel = cf.type === "checkbox" && inlineCheckbox;
      const showSideText = cf.type === "checkbox" && inlineCheckbox;
      const editable = editing !== undefined ? editing : true;
      const teamMemberEditDisabled = cf.team_member_access !== "editable";

      const validations = buildValidationRuleSet(cf);
      const fieldSpecificProps = buildFieldSpecificProps(cf);
      const type = getCustomFieldTypeForFormBlock(cf);

      return (
        <Formblock
          key={cf._id}
          type={type}
          label={!hideLabel ? cf.name : undefined}
          labelInfo={cf.description}
          text={showSideText ? cf.name : undefined}
          name={cf._id}
          errors={errors}
          register={register(validations)}
          control={control}
          defaultValue={defaultValue}
          defaultString={defaultString}
          editing={editable}
          className={formblockClassName || "modal"}
          placeholder={cf.placeholder}
          rules={validations}
          disabled={teamMemberEditDisabled}
          // Add select options if select field
          {...fieldSpecificProps}
        />
      );
    });
  };

  return <div>{renderCustomFields()}</div>;
};

export default CustomFieldValuesForm;
