/*******************************************************************
 * The Notifier is a simple wrapper for the react-hot-toast library.
 * We built this into a separate class in order to reuse option logic.
 ********************************************************************/

import toast, { ToastOptions } from "react-hot-toast";

const styles = { fontFamily: '"Karla", sans-serif', fontSize: "15px", maxWidth: "600px" };

class Notifier {
  static success(message: string, options?: ToastOptions): string {
    return toast.success(message, {
      style: styles,
      duration: 3000,
      ...options,
    });
  }

  static error(message: string | JSX.Element, options?: ToastOptions): string {
    return toast.error(message, {
      style: styles,
      duration: 7000,
      ...options,
    });
  }

  static info(message: string, options?: ToastOptions): string {
    return toast(message, {
      style: styles,
      duration: 7000,
      ...options,
    });
  }

  static warning(message: string, options?: ToastOptions): string {
    return toast(message, { style: styles, duration: 7000, icon: "⚠️", ...options });
  }

  static dismissToast(toastID: string): void {
    toast.dismiss(toastID);
  }

  static dismiss(): void {
    toast.dismiss();
  }
}

export default Notifier;
