import React, { useEffect, useState } from "react";
import { Notifier, WizardScreen, Button, Formblock } from "ui";
import styles from "dashboard/components/team-members/TeamMemberWizard.module.css";
import { FormProvider, useForm } from "react-hook-form";
import useWizard from "ui/modal/useWizard";
import { MiterAPI, AggregatedTeamMemberOnboardingTask, SignDocumentOnboardingTask } from "dashboard/miter";
import { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import { useTranslation } from "react-i18next";
import { ESignatureInputValue } from "ui/form/ESignatureInput";
import { SignESignatureRequestParams } from "dashboard/miter";
import { getBrowser, getOS, markFilesAsViewed } from "miter-utils";
import { Option } from "ui/form/Input";
import { esignatureRequired } from "dashboard/utils/validators";
import onboardingV2WizardStyles from "./onboardingChecklistWizardScreens.module.css";
import { markTaskComplete } from "./utils";

type Props = {
  name: string;
  teamMember?: WizardTeamMember;
  refetchTeamMember: () => Promise<void>;
  onboardingTask: AggregatedTeamMemberOnboardingTask & SignDocumentOnboardingTask;
  updateOnboardingChecklistTask: (task: Partial<AggregatedTeamMemberOnboardingTask>) => Promise<void>;
};

export const SignDocumentWizardScreen: React.FC<Props> = (props) => {
  const { onboardingTask, name, refetchTeamMember, updateOnboardingChecklistTask } = props;

  const { t } = useTranslation<$TSFixMe>();
  const { curIndex, handleComplete, screens, setCanNext, setNextButtonText } = useWizard();
  const form = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });

  // For now, we only support signing documents, no "view" action
  const action: "sign" | "view" = "sign";

  const {
    errors,
    handleSubmit,
    formState: { errors: formErrors },
    trigger,
  } = form;

  const [documentViewed, setDocumentViewed] = useState(false);

  const isDocumentSigned = onboardingTask.esignature?.status === "signed";

  /** Update the next button text and canNext state */
  useEffect(() => {
    // Only allow the user to continue if they have viewed the document and the form is valid (form is signed)
    setCanNext((documentViewed && Object.keys(errors).length === 0) || isDocumentSigned);
    setNextButtonText(curIndex + 1 >= screens.length ? t("Save and exit") : t("Save and continue"));
  }, [
    errors,
    formErrors,
    setCanNext,
    setNextButtonText,
    curIndex,
    screens.length,
    t,
    documentViewed,
    isDocumentSigned,
  ]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  const buildESignatureParams = async (data: ESignatureInputValue): Promise<SignESignatureRequestParams> => {
    if (!data) {
      throw new Error(t("Please enter your full name to sign this document."));
    }

    return {
      signature: {
        image: data.data || "",
        device: {
          type: "desktop",
          os: getOS() || undefined,
        },
        application: {
          name: "team-portal",
        },
      },
    };
  };

  const saveSignature = async (data) => {
    try {
      if (!onboardingTask.esignature_id)
        throw new Error(t("Error signing document. Please contact support."));
      const formattedESignatureParams = await buildESignatureParams(data);
      const res = await MiterAPI.esignature_items.requests.sign(
        onboardingTask.esignature_id,
        formattedESignatureParams
      );
      if (res.error) throw new Error(res.error);

      Notifier.success(t("Document signed successfully"));
    } catch (err: $TSFixMe) {
      console.error(t("Error signing document: ") + err.message);
      Notifier.error(err.message);
    }
  };

  const viewDocument = async () => {
    try {
      if (!onboardingTask.file_id) throw new Error(t("Error getting document. Please contact support."));
      const responseData = await MiterAPI.files.retrieve(onboardingTask.file_id);
      if (responseData.error) throw responseData.error;

      const browser = getBrowser();

      if (browser?.includes("Safari")) {
        window.location.href = responseData.url;
      } else {
        window.open(responseData.url, "_blank");
      }
      markFilesAsViewed([onboardingTask.file_id]);
    } catch (e) {
      console.error(e);
      Notifier.error(
        t("There was an error getting the link to the signed document. Please contact support.")
      );
    }
  };

  const onNext = async () => {
    if (action === "sign" && onboardingTask.esignature?.status !== "signed") {
      handleSubmit(async (data: { [x: string]: Option<string> | null }) => {
        saveSignature(data.esignature);
      })();
      await refetchTeamMember();
    }
    await markTaskComplete(onboardingTask, updateOnboardingChecklistTask);
    if (curIndex + 1 >= screens.length) {
      handleComplete();
    }
  };

  return (
    <WizardScreen name={name} key={name + onboardingTask._id} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>
            {t("Sign ") + `${onboardingTask.sign_document?.label || t("document")}`}
          </h2>
          <p className={styles["subheader-description"]}>
            {t("Please first view and then sign the document to complete the onboarding process")}
          </p>
        </div>
        <div className="form-section">
          <Button
            className={`button-2 no-margin ${onboardingV2WizardStyles["large-button"]} ${onboardingV2WizardStyles["margin-bottom-20"]}`}
            onClick={async () => {
              await viewDocument();
              setDocumentViewed(true);
            }}
          >
            {documentViewed ? t("View document again") : t("View document")}
          </Button>
          {action === "sign" && (documentViewed || isDocumentSigned) ? (
            isDocumentSigned ? (
              <p className={styles["signed"]}>{t("Document signature already complete!")}</p>
            ) : (
              <FormProvider {...form}>
                <Formblock
                  type="esignature"
                  name="esignature"
                  label={t("Signature*")}
                  form={form}
                  editing={true}
                  className="modal wizard"
                  rules={{ validate: esignatureRequired }}
                  onError={Notifier.error}
                  onChange={(_e) => {
                    trigger();
                  }}
                />
              </FormProvider>
            )
          ) : null}
        </div>
      </div>
    </WizardScreen>
  );
};
