import React from "react";

import { UploadDocumentsForm } from "miter-components";
import { AggregatedI9 } from "dashboard/miter";

type Props = {
  I9: AggregatedI9;
  setI9: (I9: AggregatedI9) => void;
  name: "Review documents";
  getI9: () => Promise<AggregatedI9 | undefined>;
};

const ReviewDocumentsForm: React.FC<Props> = ({ name, getI9, I9, setI9 }) => {
  return <UploadDocumentsForm name={name} I9={I9} setI9={setI9} getI9={getI9} />;
};

export default ReviewDocumentsForm;
