import React, { useState } from "react";
import Grid from "./grid";

import { DateTime } from "luxon";

import left from "dashboard/assets/dtpicker left arrow.png";

type Props = {
  select: (dt: DateTime) => void;
  selection?: DateTime;
  max?: DateTime;
  min?: DateTime;
};

const DaySelect: React.FC<Props> = ({ select, selection = DateTime.now(), max, min }) => {
  if (min && selection < min) {
    selection = min;
  } else if (max && selection > max) {
    selection = max;
  }

  const [month, setMonth] = useState(selection);

  const back = () => {
    setMonth(month.minus({ months: 1 }));
  };

  const next = () => {
    setMonth(month.plus({ months: 1 }));
  };

  return (
    <div className="day-select-container">
      <div className="day-select-top">
        <div>{month.monthShort + " " + month.year}</div>
        <div className="flex-1"></div>
        <img src={left} className="dtp-arrow" onClick={back} />
        <img src={left} className="dtp-arrow right" onClick={next} />
      </div>
      <Grid
        month={month}
        selection={selection}
        max={max}
        min={min}
        select={(day) => {
          if (day.color === "black" || day.color === "gray" || day.color === "blue") {
            select(day.dt);
          }
        }}
      />
    </div>
  );
};

export default DaySelect;
