import React from "react";
import { ActionMenuRelative, Button, DropdownButton } from "ui";
import { ActionLink } from "ui/action-menu/ActionMenu";
import { CaretDown } from "phosphor-react";

import xPng from "dashboard/assets/x.png";

import "./modal.css";

type Props = {
  heading: React.ReactNode;
  onHide: () => void;
  className?: string;
  headingStyle?: React.CSSProperties;
  actions?: ActionLink[];
  children?: React.ReactElement;
  actionsType?: "button" | "icon";
  hideActions?: boolean;
  hideExit?: boolean;
};

// Simple wrapper of header and "X" icon to close window.
const ModalHeader: React.FC<Props> = (props: Props) => {
  const { heading, headingStyle, onHide, className, actions, children, hideExit } = props;

  const filteredActions =
    actions?.filter((action) => !("shouldShow" in action) || action.shouldShow?.()) || [];

  return (
    <div className={"modal-header form " + className}>
      <h4 style={headingStyle}>{heading}</h4>
      {children}
      <div className="flex" style={{ marginLeft: "auto" }}>
        {!actions && !hideExit && <img className="exit-icon" src={xPng} onClick={onHide} />}
        {!props.hideActions && actions && (!props.actionsType || props.actionsType === "icon") && (
          <ActionMenuRelative links={actions} />
        )}
        {!props.hideActions && actions && props.actionsType === "button" && (
          <>
            {filteredActions
              .filter((action) => action.important || action.secondary)
              .map((action, index) => {
                const buttonClass = action.className || (action.important ? "button-2" : "button-1");
                return (
                  <Button
                    className={`${buttonClass} modal-dropdown-important-button`}
                    key={index}
                    onClick={action.action}
                    loading={action.loading}
                    style={action.style}
                  >
                    {action.icon}
                    {action.label}
                  </Button>
                );
              })}

            <DropdownButton
              className={"button-1 no-margin modal-dropdown-button"}
              options={filteredActions.filter((action) => !action.important && !action.secondary)}
            >
              <div className="flex">
                Actions
                <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
              </div>
            </DropdownButton>
            <img
              className="exit-icon"
              src={xPng}
              onClick={onHide}
              style={{ marginLeft: 15, height: 12, width: 12 }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ModalHeader;
