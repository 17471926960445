import {
  useLookupDepartment,
  useLookupJob,
  useLookupTeam,
  useLookupTeamMemberCrews,
  usePolicies,
} from "dashboard/hooks/atom-hooks";
import {
  useTeamMemberGroups,
  GroupTypeHasOptions,
  GroupTypeLabelLookup,
} from "dashboard/hooks/useTeamMemberGroups";
import {
  AggregatedJob,
  AggregatedTeamMember,
  ApprovalItem,
  ApprovalStage,
  ApproverGroup,
  Department,
  Policy,
  PolicyRule,
  TeamMember,
  Job,
  TentativeApprovalItem,
  TeamMemberGroup,
} from "dashboard/miter";
import { deparameterize, joinWithOr, notNullish } from "miter-utils";
import { ColumnConfig } from "ui/table-v2/Table";
import { PolicyType } from "backend/services/approvals/types";
import { useCallback, useMemo } from "react";

type TeamMemberGroupLabeler = {
  groupLabeler: (
    group: ApproverGroup | TeamMemberGroup | TeamMemberGroup[] | undefined,
    opts?: {
      teamMember?: TeamMember | AggregatedTeamMember;
      job?: Job | AggregatedJob;
      department?: Department;
      isAutoApproved?: boolean;
    }
  ) => string;
};

export const useApprovalGroupColumns = (type: PolicyType): ColumnConfig<$TSFixMe>[] => {
  const { groupLabeler } = useTeamMemberGroupLabeler();
  const policies = usePolicies();

  return useMemo(() => {
    const hasPolicies = policies.some((policy) => policy.type === type);
    if (!hasPolicies) return [];

    return [
      {
        headerName: "Next approver",
        field: "approval_stage",
        dataType: "string",
        filter: type === "timesheet",
        sortable: type === "timesheet",
        useValueFormatterForExport: true,
        valueFormatter: (params) => {
          const output: string[] = [];
          const approvalStage = params.value as ApprovalStage;
          if (!approvalStage) return "N/A";

          for (const approverGroup of approvalStage?.approvers?.pending || []) {
            output.push(
              groupLabeler(approverGroup, {
                teamMember: type === "time_off_request" ? params.data.employee : params.data.team_member,
                job: params.data.job,
                department: type === "time_off_request" ? params.data.department_id : params.data.department,
              })
            );
          }
          return output.join(` ${approvalStage?.condition} `);
        },
        minWidth: 250,
      },
    ];
  }, [groupLabeler, policies, type]);
};

/**
 * A custom hook that provides a function for labeling team member groups in approval contexts.
 *
 * @param labelOverrides - An optional object where keys are group type identifiers (e.g., 'direct_manager',
 *                         'self') and values are custom labels. This allows for customization of group
 *                         labels in approval-related UI components.
 *
 * @returns An object containing:
 *          - groupLabeler: A function that takes an ApproverGroup or TeamMemberGroup (or array of these)
 *                          and optional context information, and returns a formatted string label for
 *                          the group. This label includes relevant team member names and roles based on
 *                          the group type and context.
 */
export const useTeamMemberGroupLabeler = (
  labelOverrides?: Record<string, string>
): TeamMemberGroupLabeler => {
  const { getOptions } = useTeamMemberGroups(undefined, labelOverrides);
  const lookupTeam = useLookupTeam();
  const lookupTeamMemberCrews = useLookupTeamMemberCrews();
  const lookupDepartment = useLookupDepartment();
  const lookupJob = useLookupJob();

  const groupLabeler: TeamMemberGroupLabeler["groupLabeler"] = useCallback(
    (group, opts) => {
      if (opts?.isAutoApproved) return "Auto Approved";
      if (!group) return "Other";

      if (Array.isArray(group)) {
        return group.map((g) => groupLabeler(g, opts)).join(", ");
      }

      // Only show the label with team member information if opts are passed in
      if (opts) {
        let { job } = opts;
        let { department, teamMember } = opts;

        teamMember = typeof teamMember === "string" ? lookupTeam(teamMember) : teamMember;
        department = typeof department === "string" ? lookupDepartment(department) : department;
        job = typeof job === "string" ? lookupJob(job) : job;

        if (group.type === "direct_manager" && teamMember) {
          const directManager =
            !teamMember.reports_to || typeof teamMember.reports_to === "string"
              ? lookupTeam(teamMember.reports_to)
              : teamMember.reports_to;

          if (!directManager) return "N/A (Direct Manager)";

          return directManager.full_name + ` (${labelOverrides?.["direct_manager"] || "Direct Manager"})`;
        }

        if (group.type === "crew_lead" && teamMember) {
          const crews = lookupTeamMemberCrews(teamMember._id) || [];

          const crewLeads = crews
            .map((crew) => lookupTeam(crew.lead_team_member_id)?.full_name)
            .filter(notNullish);

          if (!crewLeads.length) return "N/A (Crew Lead)";

          return joinWithOr(crewLeads) + " (Crew Lead)";
        }

        if (group.type === "department_head" && teamMember) {
          const departmentHead = lookupTeam(department?.department_head_id);
          if (!departmentHead) return "N/A (Department Head)";

          return departmentHead.full_name + " (Department Head)";
        }

        if (group.type === "job_supervisor" && job) {
          const jobSupervisors = job?.supervisors
            ?.map((supervisorId) => lookupTeam(supervisorId)?.full_name)
            .filter(notNullish);

          if (!jobSupervisors?.length) return "N/A (Job Supervisor)";

          return joinWithOr(jobSupervisors) + " (Job Supervisor)";
        }

        if (group.type === "job_superintendent" && job) {
          const jobSuperintendents = job?.superintendent_ids
            ?.map((superintendent) => lookupTeam(superintendent)?.full_name)
            .filter(notNullish);

          if (!jobSuperintendents?.length) return "N/A (Job Superintendent)";

          return joinWithOr(jobSuperintendents) + " (Job Superintendent)";
        }

        if (group.type === "self" && teamMember) {
          return teamMember.full_name + ` (${labelOverrides?.["self"] || "Self"})`;
        }
      }

      const hasOptions = GroupTypeHasOptions(group.type);
      if (!hasOptions) {
        // If a label override exists for this group type, use it;
        // otherwise, use the default label (deparameterized group type).
        const label = labelOverrides?.[group.type] || deparameterize(group.type);
        return label;
      }

      const options = getOptions(group.type);
      const option = options.find((option) => option.value === group.value);

      if (group.type === "team_member") {
        return option?.label || "";
      }

      const groupLabel = GroupTypeLabelLookup(group.type, labelOverrides);

      return option?.label + " " + groupLabel.toLocaleLowerCase();
    },
    [getOptions, lookupTeam, lookupTeamMemberCrews, lookupDepartment, lookupJob, labelOverrides]
  );

  return useMemo(() => ({ groupLabeler }), [groupLabeler]);
};

export const getStatusField = (item: ApprovalItem): string => {
  if ("approval_status" in item) return "approval_status";
  return "status";
};

/**
 * Finds which rule in the item's policy should apply this item
 * @param item
 * @param policy
 * @returns
 */
export const findItemPolicyRule = (
  item: ApprovalItem | TentativeApprovalItem | undefined | null,
  policy: Policy | undefined | null
): PolicyRule | null => {
  if (!item || !policy) return null;

  const conditionalRules = (policy.rules as PolicyRule[])?.filter((r) => !r.default);

  const matchedRule = conditionalRules?.find((r) => matchesRule(item, r));
  const defaultRule = (policy.rules as PolicyRule[])?.find((r) => r.default);

  return matchedRule || defaultRule || null;
};
/** Checks if an item matches a rule */
const matchesRule = (item: ApprovalItem | TentativeApprovalItem, rule: PolicyRule): boolean => {
  const { condition } = rule;

  if (!condition) return false;
  if (!condition.field) return false;

  let itemValue = condition.type === "number" ? Number(item[condition.field]) : item[condition.field];

  let conditionValue;
  // condition.value could be an array
  if (Array.isArray(condition.value)) {
    // convert each value
    conditionValue = condition.value.map((value) => {
      return condition.type === "number" ? Number(value) : value;
    });
  } else {
    conditionValue = condition.type === "number" ? Number(condition.value) : condition.value;
  }

  // if passing in a form, itemValue can sometimes be an Option. convert to the value
  if (itemValue && typeof itemValue === "object" && "value" in itemValue) {
    itemValue = itemValue["value"];
  }

  switch (condition.operator) {
    case "=":
      return itemValue === conditionValue;
    case "ne":
      return itemValue !== conditionValue;
    case "in":
      return !!conditionValue && typeof conditionValue === "string" && conditionValue.includes(itemValue);
    case "<":
      return !!conditionValue && itemValue < conditionValue;
    case "<=":
      return !!conditionValue && itemValue <= conditionValue;
    case ">":
      return !!conditionValue && itemValue > conditionValue;
    case ">=":
      return !!conditionValue && itemValue >= conditionValue;
    case "<+>":
      return !!condition.value && itemValue > condition.value[0] && itemValue < condition.value[1];
    case "<+>e":
      return (
        !!condition.value &&
        item[condition.field] >= condition.value[0] &&
        item[condition.field] <= condition.value[1]
      );
    case "exists":
      return itemValue != undefined; // != instead of !== because null !== undefined = true
    case "not.exists":
      return itemValue == undefined;
    case "contains":
      return itemValue.includes(condition.value);
    case "not.contains":
      return !itemValue.includes(condition.value);
    case "startsWith":
      return itemValue.startsWith(condition.value);
    case "endsWith":
      return itemValue.endsWith(condition.value);
    default:
      return false;
  }
};
