import { TranslationMap } from "./i18n";

const translation: TranslationMap = {
  Policies: {
    en: "Policies",
    es: "Políticas",
  },
  Requests: {
    en: "Requests",
    es: "Peticiones",
  },
  Updates: {
    en: "Updates",
    es: "Actualizaciones",
  },
  "Create Time Off Request": {
    en: "Create Time Off Request",
    es: "Crear solicitud de tiempo libre",
  },
  "Update Time Off Request": {
    en: "Update Time Off Request",
    es: "Actualizar solicitud de tiempo libre",
  },
  "Time Off Request": {
    en: "Time Off Request",
    es: "Solicitud de tiempo libre",
  },
  "Delete Time Off Request": {
    en: "Delete Time Off Request",
    es: "Eliminar solicitud de tiempo libre",
  },
  "Are you sure you want to delete this time off request?": {
    en: "Are you sure you want to delete this time off request?",
    es: "¿Está seguro de que desea eliminar esta solicitud de tiempo libre?",
  },
  "Company note": {
    en: "Company note",
    es: "nota de la empresa",
  },
  "Submitted on": {
    en: "Submitted on",
    es: "Enviado el",
  },
  "Time Off Policy*": {
    en: "Time Off Policy*",
    es: "Política de tiempo libre*",
  },
  "Select the time off policy that you want to use for this request.": {
    en: "Select the time off policy that you want to use for this request.",
    es: "Seleccione la política de tiempo libre que desea utilizar para esta solicitud.",
  },
  "Start Date": {
    en: "Start Date",
    es: "Fecha de inicio",
  },
  "Start date for the time off request": {
    en: "Start date for the time off request",
    es: "Fecha de inicio de la solicitud de tiempo libre",
  },
  "End Date": {
    en: "End Date",
    es: "Fecha final",
  },
  "End date for the time off request": {
    en: "End date for the time off request",
    es: "Fecha de finalización de la solicitud de tiempo libre",
  },
  "Are you taking these fully off or partially?": {
    en: "Are you taking these fully off or partially?",
    es: "¿Está tomando estos completamente o parcialmente?",
  },
  "Include weekend hours?": {
    en: "Include weekend hours?",
    es: "¿Incluir horas de fin de semana?",
  },
  "Time Off Reason": {
    en: "Time Off Reason",
    es: "Motivo del tiempo libre",
  },
  Yes: {
    en: "Yes",
    es: "Sí",
  },
  No: {
    en: "No",
    es: "No",
  },
  "New request": {
    en: "New request",
    es: "Nueva solicitud",
  },
  Unapproved: {
    en: "Unapproved",
    es: "No aprobado",
  },
  Approved: {
    en: "Approved",
    es: "Aprobado",
  },
  Processing: {
    en: "Processing",
    es: "Procesando",
  },
  Denied: {
    en: "Denied",
    es: "Negado",
  },
  All: {
    en: "All",
    es: "Todos",
  },
  "You are not enrolled in any time off policies at this time. If you would like to enroll in a time off policy or request time off, please reach out to your HR admin.":
    {
      en: "You are not enrolled in any time off policies at this time. If you would like to enroll in a time off policy or request time off, please reach out to your HR admin.",
      es: "Actualmente no está inscrito en ninguna política de tiempo libre. Si desea inscribirse en una política de tiempo libre o solicitar tiempo libre, comuníquese con su administrador de recursos humanos.",
    },
  "Full Days": {
    en: "Full Days",
    es: "Días completos",
  },
  "Partial Days": {
    en: "Partial Days",
    es: "Días parciales",
  },
};

export default translation;
