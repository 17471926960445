import React, { Dispatch, SetStateAction } from "react";
import { UserData } from "team-portal/App";
import { AggregatedI9, User } from "dashboard/miter";
import { TeamPortalUser } from "team-portal/utils/miter";
import { AggregatedOnboardingChecklistItem } from "dashboard/types/onboarding-types";

type AppContext = {
  user: User | undefined;
  activeTM: TeamPortalUser | undefined;
  teamMembers: TeamPortalUser[] | undefined;
  permissionGroups: string[];
  setOnboarded: Dispatch<SetStateAction<boolean>>;
  device: string | null;
  openCheckOnboard: () => Promise<void>;
  checkOnboardLoading: boolean;
  onboardLink: string | undefined;
  onboarded: boolean;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  setTeamMembers: Dispatch<SetStateAction<TeamPortalUser[] | undefined>>;
  fetchUserData: (id?: string) => Promise<UserData | undefined>;
  setActiveTM: Dispatch<SetStateAction<TeamPortalUser | undefined>>;
  companyHasPrgs: boolean;
  setOnboardLink: Dispatch<SetStateAction<string | undefined>>;
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  getOnboardingChecklistItem: () => Promise<void>;
  onboardingChecklistItem: AggregatedOnboardingChecklistItem | null;
  I9: AggregatedI9 | undefined;
  setI9: Dispatch<SetStateAction<AggregatedI9 | undefined>>;

  reverifyUser: boolean;
  setReverifyUser: Dispatch<SetStateAction<boolean>>;
  onReverifyUser: () => void;
  setOnReverifyUser: Dispatch<SetStateAction<() => void>>;

  confetti: boolean;
  setConfetti: Dispatch<SetStateAction<boolean>>;
};

const AppContext = React.createContext<AppContext>({
  user: undefined,
  activeTM: undefined,
  teamMembers: [],
  device: null,
  openCheckOnboard: async () => {},
  checkOnboardLoading: false,
  onboardLink: undefined,
  onboarded: false,
  setUser: () => {},
  fetchUserData: async (): Promise<UserData | undefined> => {
    return;
  },
  permissionGroups: [],
  companyHasPrgs: false,
  setTeamMembers: () => {},
  setActiveTM: () => {},
  setOnboardLink: () => {},
  setOnboarded: () => {},
  modalOpen: false,
  setModalOpen: () => {},
  onboardingChecklistItem: null,
  getOnboardingChecklistItem: async () => {},
  I9: undefined,
  setI9: () => {},

  reverifyUser: false,
  setReverifyUser: () => () => {},
  onReverifyUser: async () => {},
  setOnReverifyUser: () => {},

  confetti: false,
  setConfetti: () => {},
});

export default AppContext;
