import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { GooglePlaceAutocompleteProps } from "./Input";

export const GooglePlaceAutocompleteInput: React.FC<{ props: GooglePlaceAutocompleteProps }> = ({
  props,
}) => {
  const { placeholder, name, className, value, defaultValue, disabled, inputProps } = props;

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      if (!!props.onChange) {
        props.onChange(place);
      }
    },
    options: { types: ["address"] },
  });

  return (
    <input
      className={"form2-text " + className}
      placeholder={placeholder ? placeholder : undefined}
      autoComplete="address-line1" // tells browser to autocomplete w address, ex from password manager
      value={value}
      defaultValue={defaultValue ?? undefined}
      name={name}
      ref={ref}
      disabled={disabled}
      {...inputProps}
    />
  );
};
