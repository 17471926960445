import React from "react";
import styles from "./NotFound.module.css";
import Balloon from "../assets/404.svg";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <div className={styles["not-found-container"]}>
      <div className={styles["not-found"]}>
        <img src={Balloon} alt="404" />
        <h1>Whoops! It looks like you&apos;re lost.</h1>
        <p className={styles["subtitle"]}>
          We can&apos;t seem to find the page you&apos;re looking for. Click the button below to go home.
        </p>
        <Link className={"button-2 " + styles["dashboard-btn"]} to={"/dashboard"}>
          Back to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
