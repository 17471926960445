import React, { useState } from "react";
import { FormErrors } from "./types";

type Props = {
  errors: FormErrors;
  name: string;
  default: React.ReactNode;
  top?: boolean;
  label?: React.ReactNode;
  children?: React.ReactNode;
};

const InfoField: React.FC<Props> = ({ errors, name, default: default_, top, label, children }) => {
  const [editing, setEditing] = useState(false);

  const errorMsg = errors?.[name]?.message;
  const missing = !default_;

  const toggle = () => {
    setEditing(!editing);
  };

  return (
    <div className="info-field">
      <div className="field-label">
        <div className={top ? "label top" : "label"}>{label}</div>
        <div className="flex-1"></div>
        <div onClick={toggle} className={top ? "ssn-edit top" : "ssn-edit"}>
          {editing ? "Cancel" : "Edit"}
        </div>
      </div>
      {!editing && <div className="default-value">{!missing ? default_ : "-"}</div>}
      {editing && children ? children : null}
      {errorMsg && <div className="error-msg">{errorMsg}</div>}
    </div>
  );
};

export default InfoField;
