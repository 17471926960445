import { MiterAPI } from "dashboard/miter";
import { OnboardingChecklistCompletionData, OnboardingTask } from "dashboard/types/onboarding-types";
import { OnboardingTaskStatus } from "../../../backend/models/onboarding/onboarding-checklist-item";

export const getChecklistCompletionData = async (params: {
  new_hire_id: string;
  assignee_user_id: string;
  checklist_id: string;
}): Promise<OnboardingChecklistCompletionData> => {
  const { new_hire_id, assignee_user_id, checklist_id } = params;
  try {
    if (!new_hire_id || !checklist_id || !assignee_user_id) {
      throw new Error("No company id");
    }

    const response = await MiterAPI.onboarding_checklist_items.completion_data(params);
    if (response.error) throw new Error(response.error);
    return response;
  } catch (e: $TSFixMe) {
    console.error(e);
    throw new Error(e.message);
  }
};

export const filterTasksByAssignee = (
  tasks: OnboardingTask[],
  assigneeUserId: string,
  newHireUserId: string
): OnboardingTask[] => {
  return tasks.filter((task) => {
    return (
      (task.assignee.type === "new_hire" && assigneeUserId === newHireUserId) ||
      assigneeUserId === task.assignee.user_id
    );
  });
};

// Filters for tasks that are actually completable, where the remaining prerequisites are empty
export const filterTasksByCompletableStatus = (
  tasks: OnboardingTask[],
  statuses: OnboardingTaskStatus[]
): OnboardingTask[] => {
  return tasks.filter((task) => {
    const status = statuses.find((status) => status.task_id === task._id);
    return (status?.prerequisites || []).length === 0;
  });
};
