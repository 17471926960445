import React from "react";
import { ClickAwayListener } from "@material-ui/core";
import "./modal.css";
import { ModalHeader, ModalFooter } from "ui";

import styles from "./ActionModal.module.css";
import { ActionLink } from "../action-menu/ActionMenu";
import { FooterProps } from "./ModalFooter";
import { use100vh } from "react-div-100vh";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useDisableBackgroundScroll } from "../hooks/useDisableBackgroundScroll";

const MOBILE_WIDTH = 768;

type Props = {
  headerText: React.ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
  deleting?: boolean;
  actions?: ActionLink[];
  actionsType?: "button" | "icon";

  className?: string;
  backgroundClassName?: string;
  headerClassName?: string;
  footerClassName?: string;
  bodyClassName?: string;
  wrapperClassName?: string;

  showCancel?: boolean;
  showSubmit?: boolean;
  showDelete?: boolean;
  showEdit?: boolean;
  overflowVisible?: boolean;
  hideExit?: boolean;

  hideFooter?: boolean;

  cancelText?: React.ReactNode;
  submitText?: React.ReactNode;
  deleteText?: React.ReactNode;
  editText?: React.ReactNode;

  bodyStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;

  submitDisabled?: boolean;
  deleteDisabled?: boolean;
  editDisabled?: boolean;
  CustomFooter?: React.FC<FooterProps>;

  onHide: () => void;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  onEdit?: React.MouseEventHandler<HTMLButtonElement>;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
};

const ActionModal: React.FC<Props> = (props) => {
  const {
    headerText,
    actions,
    actionsType,
    children,
    loading,
    className,
    backgroundClassName,
    headerClassName,
    footerClassName,
    bodyClassName,
    wrapperClassName,
    showCancel,
    hideExit,
    wrapperStyle,
    cancelText,
    submitText,
    showSubmit,
    showDelete,
    submitDisabled,
    deleteDisabled,
    editDisabled,
    deleteText,
    showEdit,
    editText,
    overflowVisible,
    onHide,
    onSubmit,
    onCancel,
    bodyStyle,
    onEdit,
    onDelete,
    hideFooter,
    deleting,
    CustomFooter,
  } = props;
  const height = use100vh();
  const { width } = useWindowDimensions();
  useDisableBackgroundScroll();

  const isMobile = width < MOBILE_WIDTH;

  const footerProps = {
    loading: loading,
    className: styles["action-modal-footer"] + " " + className + " " + footerClassName,
    onCancel: onCancel,
    hideCancel: !showCancel,
    cancelText: cancelText,
    submitText: submitText,
    hideSubmit: !showSubmit,
    showDelete: showDelete,
    deleteText: deleteText,
    showEdit: showEdit,
    editText: editText,
    submitDisabled: submitDisabled,
    deleteDisabled: deleteDisabled,
    editDisabled: editDisabled,
    onEdit: onEdit,
    onDelete: onDelete,
    onSubmit: onSubmit,
    hide: hideFooter,
    deleting: deleting,
    headerText: headerText,
    onHide: onHide,
  };

  return (
    <div className={`modal-background ${backgroundClassName} ${isMobile ? styles["mobile-background"] : ""}`}>
      <ClickAwayListener onClickAway={() => {}}>
        <div
          className={`modal-wrapper ${styles["action-modal-wrapper"]} ${wrapperClassName} ${
            isMobile ? styles["mobile-wrapper"] : ""
          }`}
          style={{
            ...wrapperStyle,
            height: isMobile ? height || "100vh" : undefined,
          }}
        >
          <ModalHeader
            heading={headerText}
            onHide={onHide}
            actions={actions}
            actionsType={actionsType}
            className={headerClassName}
            hideExit={hideExit}
          />

          <div
            className={`modal-body ${styles["action-modal-body"]} ${bodyClassName} ${
              isMobile ? styles["mobile-body"] : ""
            }`}
            style={{
              overflow: overflowVisible ? "visible" : undefined,
              height: isMobile ? `calc(${height}px - 120px)` : undefined,
              ...bodyStyle,
            }}
          >
            {children}
          </div>

          {CustomFooter ? <CustomFooter {...footerProps} /> : <ModalFooter {...footerProps} />}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ActionModal;
