import React, { FC, useState } from "react";
import { Wizard } from "ui";
import { AggregatedI9 } from "dashboard/miter";
import VerifyEmployeeInformationForm from "./wizard-screens/VerifyEmployeeInformationForm";
import ReviewDocumentsForm from "./wizard-screens/ReviewDocumentsForm";
import SignAndSubmitForm from "./wizard-screens/SignAndSubmitForm";

type Props = {
  onExit: () => void;
  onComplete?: () => void;
  I9: AggregatedI9;
  getI9: () => Promise<AggregatedI9 | undefined>;
};

const I9Wizard: FC<Props> = ({ onExit, onComplete, ...props }) => {
  const [I9, setI9] = useState(props.I9);

  return (
    <Wizard onExit={onExit} onComplete={onComplete || onExit}>
      <VerifyEmployeeInformationForm I9={I9} setI9={setI9} name="Verify employee information" />
      <ReviewDocumentsForm I9={I9} setI9={setI9} name="Review documents" getI9={props.getI9} />
      <SignAndSubmitForm I9={I9} setI9={setI9} name="Sign and submit" />
    </Wizard>
  );
};

export default I9Wizard;
