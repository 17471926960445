import pluralize from "pluralize";
import React from "react";
import "./Table.css";
import { TableEntry } from "./types";

export const DEFAULT_LOW_RESULTS_PER_PAGE = 25;
export const DEFAULT_HIGH_RESULTS_PER_PAGE = 50;

type Props = {
  resultCount: number;
  numResultsPerPage: number;
  page: number;
  activeResults: TableEntry[] | null;
  onNext: () => void;
  onPrevious: () => void;
  setPage: (p: number) => void;
  hideFooterButtons?: boolean;
  setNumResultsPerPage: (num) => void;
  hideFooterText?: boolean;
  lowNumResultsPerPage?: number;
  highNumResultsPerPage?: number;
  resourceName?: string;
};

const TableFooter: React.FC<Props> = (props) => {
  const {
    resultCount,
    numResultsPerPage,
    page,
    activeResults,
    onNext,
    setNumResultsPerPage,
    onPrevious,
    setPage,
    hideFooterButtons,
    hideFooterText,
    lowNumResultsPerPage,
    highNumResultsPerPage,
    resourceName,
  } = props;

  const displayButtons = !hideFooterButtons || resultCount > numResultsPerPage;
  const displayFooterText = !hideFooterText || resultCount > numResultsPerPage;

  const setFooterText = () => {
    let footer_text = "";

    if (resultCount <= numResultsPerPage) {
      if (resultCount === 1) {
        footer_text = resultCount.toString() + " " + pluralize.singular(resourceName || "result");
      } else {
        footer_text = resultCount.toString() + " " + pluralize(resourceName || "result");
      }
    } else if (activeResults && activeResults.length === 1) {
      footer_text = "Result " + resultCount.toString() + " of " + resultCount.toString();
    } else if (resultCount / numResultsPerPage <= page) {
      footer_text =
        "Results " +
        (page * numResultsPerPage - (numResultsPerPage - 1)).toString() +
        "-" +
        resultCount.toString() +
        " of " +
        resultCount.toString();
    } else {
      footer_text =
        "Results " +
        (page * numResultsPerPage - (numResultsPerPage - 1)).toString() +
        "-" +
        (page * numResultsPerPage).toString() +
        " of " +
        resultCount.toString();
    }
    return footer_text;
  };

  const prev_button_status = page === 1 ? "inactive" : "";
  const next_button_status = resultCount / numResultsPerPage <= page ? "inactive" : "";

  const footer_text = setFooterText();

  const highPerPage = highNumResultsPerPage || DEFAULT_HIGH_RESULTS_PER_PAGE;
  const lowPerPage = lowNumResultsPerPage || DEFAULT_LOW_RESULTS_PER_PAGE;

  const renderSeeMoreButtons = () => {
    const showMore = () => {
      const newNumResults = highPerPage;
      const newPage = Math.max(Math.floor((page * numResultsPerPage) / newNumResults), 1);
      setPage(newPage);
      setNumResultsPerPage(newNumResults);
    };

    const showFewer = () => {
      const newNumResults = lowPerPage;
      const newPage = Math.max(Math.floor((page * numResultsPerPage) / newNumResults), 1);
      setPage(newPage);
      setNumResultsPerPage(newNumResults);
      window.scrollTo(0, 0);
    };

    return (
      <div style={{ display: "flex", alignItems: "center", fontSize: 14, marginTop: 3 }}>
        {resultCount > numResultsPerPage && numResultsPerPage < highPerPage && (
          <span onClick={showMore} className="blue-link">
            Show more
          </span>
        )}
        {numResultsPerPage === highPerPage && (
          <span onClick={showFewer} className="blue-link">
            Show fewer
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="transparent">
      {displayButtons && displayFooterText && (
        <div className="table-footer" style={{ marginTop: 15 }}>
          <div>
            <div className="footer-text">{footer_text}</div>
            {renderSeeMoreButtons()}
          </div>
          <div className="flex-1"></div>
          {resultCount > numResultsPerPage && (
            <button onClick={onPrevious} className={"button-1 " + prev_button_status}>
              Previous
            </button>
          )}
          {resultCount > numResultsPerPage && (
            <button onClick={onNext} className={"button-1 " + next_button_status}>
              Next
            </button>
          )}
        </div>
      )}
      {!displayButtons && displayFooterText && (
        <div className="table-footer">
          <div className="footer-text">{footer_text}</div>
          <div className="flex-1"></div>
        </div>
      )}
    </div>
  );
};

export default TableFooter;
