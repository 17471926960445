import React from "react";
import { SortConfig, SortOrder, ButtonConfig, ColumnConfigs, ColumnConfig } from "./types";
import "./Table.css";
import { TextWithTooltip } from "..";

type Props = {
  columns: ColumnConfigs;
  sortColumn?: SortConfig;
  onSort: (config: SortConfig) => void;
  disableSort?: boolean;
  onSelect: (isSelected: boolean) => void;
  isSelected?: boolean;
  hideSelect?: boolean;
  onClickBtnConfig?: ButtonConfig;
  disableSelect?: boolean;
};

const TableHeader = ({
  columns,
  sortColumn,
  onSort,
  disableSort,
  onSelect,
  isSelected,
  hideSelect,
  onClickBtnConfig,
  disableSelect,
}: Props): React.ReactElement => {
  const handleClick = (column: ColumnConfig) => {
    if (column.sort_disabled) return;
    let order: SortOrder = "asc";
    if (sortColumn) {
      if (sortColumn.field === column.field) {
        order = sortColumn.order === "asc" ? "desc" : "asc";
      }
    }
    onSort({ field: column.field, order: order, type: column.type, sort_field: column.sort_field });
  };

  const renderLabel = (column) => {
    const field = column.field;
    if (sortColumn && field === sortColumn.field) {
      if (sortColumn.order === "asc")
        return <div className={`cell-text ${column.column_class}`}>{column.label.toUpperCase()} &#8595;</div>;
      return <div className={`cell-text ${column.column_class}`}>{column.label.toUpperCase()} &#8593;</div>;
    }
    return (
      <div className={`cell-text ${column.column_class}`}>
        {column.tooltip ? (
          <TextWithTooltip
            text={column.label.toUpperCase()}
            tooltip={column.tooltip.content}
            id={column.field}
            place={"top"}
          />
        ) : (
          <span>{column.label.toUpperCase()}</span>
        )}
      </div>
    );
  };

  const hiddenClass = hideSelect ? " hideSelect " : "";

  const renderColumns = () => {
    return columns.map((column, itemIndex) => {
      if (!column.hidden) {
        return (
          <th
            key={itemIndex}
            onClick={!disableSort ? () => handleClick(column) : undefined}
            className={`width-${column.width} ${
              column.sort_disabled || disableSort ? "noClick" : "pointer"
            } cell column-${itemIndex.toString()} ${hiddenClass} ${column.column_class}`}
          >
            {renderLabel(column)}
          </th>
        );
      }
    });
  };

  return (
    <thead>
      <tr className={`row noClick table-header header`}>
        {!hideSelect && (
          <th className="table-checkbox">
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                onChange={() => onSelect(!isSelected)}
                checked={isSelected}
                disabled={disableSelect}
              />
            </div>
          </th>
        )}

        {renderColumns()}
        {onClickBtnConfig && <th></th>}
      </tr>
    </thead>
  );
};

export default TableHeader;
