import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import { AggregatedI9 } from "dashboard/miter";
import useWizard from "ui/modal/useWizard";
import { TeamPortalUser } from "team-portal/utils/miter";
import { I9Wizard } from "../i-9/employee/I9Wizard";
import { useTranslation } from "react-i18next";
import { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import { AggregatedTeamMemberOnboardingTask, MiterAPI, I9EmployeeOnboardingTask } from "dashboard/miter";
import onboardingV2WizardStyles from "./onboardingChecklistWizardScreens.module.css";
import { markTaskComplete } from "./utils";
import IERRightToWorkInfoEN from "./assets/IER_RightToWorkPoster_Eng.jpg";
import IERRightToWorkInfoES from "./assets/IER_RightToWorkPoster_Es.jpg";
import EVerifyParticipationPoster from "./assets/federal-e-verify-participation-poster.jpg";

type Props = {
  name: string;
  teamMember?: WizardTeamMember | TeamPortalUser;
  refetchTeamMember: () => Promise<void>;
  onboardingTask: AggregatedTeamMemberOnboardingTask & I9EmployeeOnboardingTask;
  updateOnboardingChecklistTask: (task: AggregatedTeamMemberOnboardingTask) => Promise<void>;
  i9?: AggregatedI9;
};

export const EmployeeI9WizardScreen: React.FC<Props> = ({
  name,
  teamMember,
  refetchTeamMember,
  onboardingTask,
  updateOnboardingChecklistTask,
  ...props
}) => {
  const { t } = useTranslation<$TSFixMe>();
  const [i9, setI9] = useState<AggregatedI9>({} as AggregatedI9);
  const [loadingI9, setLoadingI9] = useState(false);

  const [fillingI9, setFillingI9] = useState(false);
  const [i9Completed, setI9Completed] = useState(false);
  const { curIndex, handleComplete, screens, setCanNext, setNextButtonText } = useWizard();

  const isEVerifyTask = useMemo(() => {
    return !!onboardingTask.e_verify;
  }, [onboardingTask.e_verify]);

  const companyId = useMemo(() => {
    if (!teamMember?.company) return null;
    return typeof teamMember.company === "object" ? teamMember.company._id : teamMember.company;
  }, [teamMember?.company]);

  const createI9 = useCallback(async (): Promise<void> => {
    if (!teamMember?._id || !companyId) return;
    try {
      const params = {
        team_member_id: teamMember._id,
        company_id: companyId,
        manual: false,
      };
      const res = await MiterAPI.i_9s.create(params);
      if (res.error) throw new Error(res.error);
      setI9(res);
      await refetchTeamMember();
    } catch (e: $TSFixMe) {
      console.error("Error creating I9", e);
      Notifier.error(t("There was an error starting your I9. Please contact support."));
    }
  }, [teamMember?._id, companyId, refetchTeamMember, t]);

  const getI9 = useCallback(async (): Promise<void> => {
    if (!props.i9?._id) {
      await createI9();
    } else {
      setI9(props.i9);
    }
  }, [props.i9, createI9]);

  useEffect(() => {
    setLoadingI9(true);
    getI9();
    setLoadingI9(false);
  }, [getI9]);

  useEffect(() => {
    setCanNext(!!i9.employee_esignature);
    setNextButtonText(curIndex + 1 >= screens.length ? t("Save and exit") : t("Save and continue"));
  }, [i9.employee_esignature, curIndex, screens.length, setCanNext, setNextButtonText, t]);

  const onExit = () => {
    setFillingI9(false);
  };

  const onComplete = async () => {
    Notifier.success(t("I-9 saved successfully"));
    setI9Completed(true);
    setFillingI9(false);
    setCanNext(true);
    await refetchTeamMember();
  };

  const onNext = async () => {
    await refetchTeamMember();
    await markTaskComplete(onboardingTask, updateOnboardingChecklistTask);
    if (curIndex + 1 >= screens.length) {
      handleComplete();
    }
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>{t("Complete your I-9")}</h2>
          <p className={styles["subheader-description"]}>
            {t("Please follow the interactive steps to complete your section of the I-9")}
          </p>
        </div>
        <div className="form-section">
          {i9.status === "complete" ? (
            <div>{t("I9 has been completed.")}</div> // both new hire and admin completed their tasks
          ) : (
            <Button
              onClick={() => setFillingI9(true)}
              text={i9.employee_esignature || i9Completed ? t("Edit I-9") : t("Fill out I-9")}
              className={`button-2 no-margin ${onboardingV2WizardStyles["large-button"]}`}
              loading={loadingI9}
            />
          )}
        </div>
        {fillingI9 && (
          <I9Wizard
            onExit={onExit}
            onComplete={onComplete}
            I9={i9}
            activeTM={teamMember as TeamPortalUser}
            eVerify={isEVerifyTask}
          />
        )}
        {isEVerifyTask && (
          <>
            <div className="form-section">
              <img
                src={EVerifyParticipationPoster}
                alt="E-Verify participation poster"
                style={{ maxWidth: "100%" }}
              />
            </div>
            <div className="form-section">
              <img
                src={IERRightToWorkInfoEN}
                alt="Right to work info: English"
                style={{ maxWidth: "100%" }}
              />
            </div>
            <div className="form-section">
              <img
                src={IERRightToWorkInfoES}
                alt="Right to work info: Spanish"
                style={{ maxWidth: "100%" }}
              />
            </div>
          </>
        )}
      </div>
    </WizardScreen>
  );
};
