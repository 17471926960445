import React, { useEffect } from "react";
import { Button, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import useWizard from "ui/modal/useWizard";
import { TeamPortalUser } from "team-portal/utils/miter";
import { useTranslation } from "react-i18next";
import { WizardTeamMember } from "dashboard/components/team-members/TeamMemberWizard";
import onboardingV2WizardStyles from "./onboardingChecklistWizardScreens.module.css";
import { AggregatedTeamMemberOnboardingTask } from "dashboard/miter";

type Props = {
  name: string;
  teamMember?: WizardTeamMember | TeamPortalUser;
  refetchTeamMember: () => Promise<void>;
  onboardingTask: AggregatedTeamMemberOnboardingTask;
  updateOnboardingChecklistTask: (task: AggregatedTeamMemberOnboardingTask) => Promise<void>;
};

export const DemoWizardScreen: React.FC<Props> = ({
  name,
  // eslint-disable-next-line unused-imports/no-unused-vars
  teamMember,
  // eslint-disable-next-line unused-imports/no-unused-vars
  refetchTeamMember,
  onboardingTask,
  updateOnboardingChecklistTask,
}) => {
  const { t } = useTranslation<$TSFixMe>();

  const { curIndex, handleComplete, screens, setCanNext, setNextButtonText } = useWizard();

  useEffect(() => {
    setCanNext(onboardingTask.status === "complete");
    setNextButtonText(curIndex + 1 >= screens.length ? t("Save and exit") : t("Save and continue"));
  }, [curIndex, screens.length, setCanNext, setNextButtonText, t, onboardingTask.status]);

  const onNext = async () => {
    await updateOnboardingChecklistTask({
      ...onboardingTask,
      status: "complete",
    });
    if (curIndex + 1 >= screens.length) {
      handleComplete();
    }
  };

  return (
    <WizardScreen key={onboardingTask._id} name={name} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>{name}</h2>
          <p className={styles["subheader-description"]}>{t("Please complete this task")}</p>
        </div>
        <div className="form-section">
          <Button
            onClick={() => {
              Notifier.success("Task completed successfully");
              setCanNext(true);
            }}
            text={t("Complete task")}
            className={`button-2 no-margin ${onboardingV2WizardStyles["large-button"]}`}
          />
        </div>
      </div>
    </WizardScreen>
  );
};
