import React, { useState } from "react";
import "./MenuButton.css";

import menuPng from "dashboard/assets/menu.png";

type Props = {
  className?: string;
  options: { action: (id: string) => void; label: React.ReactNode }[];
  id: string;
};

const MenuButton: React.FC<Props> = ({ className = "", options, id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const mouseLeave = () => {
    if (isOpen) {
      toggle();
    }
  };

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div onMouseLeave={mouseLeave} className="menu-button-system-wrapper">
      <div onClick={toggle} className={"menu-button-wrapper " + className}>
        <img className={"menu-dots " + className} alt="Menu ellipsis" src={menuPng} />
      </div>
      <div className="helper"></div>
      {isOpen && (
        <div className="menu-button-dropdown">
          {options.map((option) => (
            <div className="menu-button-option" onClick={() => option.action(id)}>
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuButton;
