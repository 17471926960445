import { CustomHoliday } from "dashboard/miter";
import { DateTime } from "luxon";
import { isValidWebsiteURL } from "miter-utils";
import { Validate } from "react-hook-form";
import { ESignatureInputValue } from "ui/form/ESignatureInput";
import { FilePickerFile } from "ui/form/FilePicker";
import { ValidationRuleset } from "ui/form/Input";

export const required: ValidationRuleset = { required: "This field is required." };

export const neitherOrBothPeriod = (
  companyContributionType?: string,
  employeeContributionType?: string
): true | string => {
  const PERIOD_AMOUNT = "period_amount";
  if (
    (companyContributionType === PERIOD_AMOUNT && employeeContributionType === PERIOD_AMOUNT) ||
    (companyContributionType !== PERIOD_AMOUNT && employeeContributionType !== PERIOD_AMOUNT)
  ) {
    return true;
  }
  return "Contributions must both be monthly if selecting 'per month'.";
};

export const filesListNotEmpty = (files: FilePickerFile[], errMsg: string): true | string => {
  if (files.filter((item) => !item?.deleted).length > 0) {
    return true;
  }
  return errMsg;
};

export const email: ValidationRuleset = {
  pattern: {
    value:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Please enter a valid email address.",
  },
  required: "This field is required.",
};

export const emailNotRequired: ValidationRuleset = {
  pattern: {
    value:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Please enter a valid email address.",
  },
};

// Percentage validator
export const percent: ValidationRuleset = {
  pattern: {
    value: /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/,
    message: "Please enter a valid percent.",
  },
  required: "This field is required.",
};

export const isNumber: ValidationRuleset = {
  validate: {
    isNumber: (value) => {
      const cleanedValue = typeof value == "number" ? value : value?.replace(/,/g, "");
      const cleanedValueNum = Number(cleanedValue);
      const isNotANumber = !isNaN(cleanedValueNum);

      return isNotANumber || "Please enter a numeric value.";
    },
    required: (value) => value !== "" || "This field is required.",
  } as Record<string, Validate>,
};

export const isNumberFunc = (
  value: string,
  min?: number | null | undefined,
  max?: number | null | undefined
): true | string => {
  const cleanedValue = value.replace(/,/g, "");
  const cleanedValueNum = Number(cleanedValue);
  const isNotANumber = !isNaN(cleanedValueNum);

  if (isNotANumber) {
    return "Please enter a numeric value.";
  } else if (min !== undefined && min !== null) {
    return cleanedValueNum >= min || "Please enter a value greater than or equal to " + min;
  } else if (max !== undefined && max !== null) {
    return cleanedValueNum <= max || "Please enter a value less than or equal to " + max;
  } else {
    return true;
  }
};

export const isPositiveNumber: ValidationRuleset = {
  validate: {
    isPositiveNumber: (value) => {
      const cleanedValue = value.replace(/,/g, "");
      const cleanedValueNum = Number(cleanedValue);
      return Number(cleanedValueNum) > 0 || "Must be greater than zero.";
    },
  },
  required: "This field is required.",
};

export const isNonNegativeNumberButNotRequired: ValidationRuleset = {
  validate: {
    isNonNegativeNumber: (value) => {
      const cleanedValue = value.replace(/,/g, "");
      const cleanedValueNum = Number(cleanedValue);
      return Number(cleanedValueNum) >= 0 || "Cannot be less than zero.";
    },
  },
  required: false,
};

export const numberValidator = (params?: {
  /** Default: true */
  required?: boolean;
  /** Default: false */
  excludeNegatives?: boolean;
  /** Default: false */
  excludeZero?: boolean;
  min?: number;
  max?: number;
  /** Default: infinite */
  maxDecimals?: number;
  otherValidators?: Record<string, Validate>;
}): NonNullable<ValidationRuleset> => {
  const required = params?.required ?? true;
  const excludeNegatives = params?.excludeNegatives ?? false;
  const excludeZero = params?.excludeZero ?? false;
  const maxDecimals = params?.maxDecimals;
  const minVal = params?.min ?? null;
  const maxVal = params?.max ?? null;
  return {
    validate: {
      ...params?.otherValidators,
      validator: (value: string) => {
        const cleanedValue = (value || "").toString().replace(/,/g, ""); // remove commas

        // If it's empty string, then leave it up to the required validator
        if (!cleanedValue) return true;

        const cleanedValueNum = Number(cleanedValue);
        if (Number.isNaN(cleanedValueNum)) return "Must be a valid number";
        if (excludeNegatives && cleanedValueNum < 0) return "Must not be negative";
        if (excludeZero && cleanedValueNum === 0) return "Must not be zero";
        if (minVal != null && cleanedValueNum < minVal) return `Must be at least ${minVal}`;
        if (maxVal != null && cleanedValueNum > maxVal) return `Must be at most ${maxVal}`;
        if (maxDecimals != null) {
          // Here we look at just the part to the right of the decimal point, truncate lagging zeros, and
          // then determine if there are more digits than the max specified
          const parts = cleanedValue.split(".");
          const decimal = parts[1] ? Number("0." + parts[1]) : NaN;
          if (decimal && decimal.toString().length - 2 > maxDecimals) {
            return `Must have no more than ${maxDecimals} digits after the decimal point`;
          }
        }
        return true;
      },
    },
    required: required && "This field is required.",
  };
};

// Validate, if a value is entered, that the value is a number
export const isNumberButNotRequired: ValidationRuleset = {
  validate: {
    isNumber: (value) => {
      const cleanedValue = value.replace(/,/g, "");
      const cleanedValueNum = Number(cleanedValue);
      return !isNaN(cleanedValueNum) || "Please enter a numeric value.";
    },
  },
  required: false,
};

export const dollar: ValidationRuleset = {
  pattern: {
    value: /^[0-9]+\.?[0-9]?[0-9]?$/,
    message: "Please enter a valid dollar amount.",
  },
  required: "This field is required.",
};

export const dollarNotRequired: ValidationRuleset = {
  pattern: {
    value: /^[0-9]+\.?[0-9]?[0-9]?$/,
    message: "Please enter a valid dollar amount.",
  },
};

export const zip: ValidationRuleset = {
  pattern: {
    value: /^[0-9]{5}(?:-[0-9]{4})?$/,
    message: "Please enter a valid postal code.",
  },
  required: "This field is required.",
};

const isPhone = (value: string): boolean | string => {
  if (value == null) return true;
  let newString = "";
  for (let i = 0; i < value.length; i++) {
    const char = value[i];
    const numChar = isNaN(Number(char)) || char === " " ? "" : char;
    newString = newString + numChar;
  }
  newString = newString.slice(newString[0] === "1" ? 1 : 0);

  if (value.startsWith("+57") || value.startsWith("+27") || value.startsWith("+52")) {
    return true;
  }

  return newString.length === 10 || "Please enter a valid 10-digit phone number.";
};

export const phone: ValidationRuleset = {
  validate: { isPhone },
  required: "This field is required.",
};

export const phoneNotRequired: ValidationRuleset = {
  validate: { isPhone },
  required: false,
};

const isValidWebsiteValidator = (value: string): boolean | string => {
  if (value == null || value === "") return true;
  const isValid = isValidWebsiteURL(value);
  return isValid || "Please enter a valid website URL.";
};

export const websiteNotRequired: ValidationRuleset = {
  validate: { isValidWebsiteValidator },
  required: false,
};

// Validator used specifically for child support post-tax deductions
export const fips_code: ValidationRuleset = {
  pattern: {
    value: /^[0-9]{5}(?:[0-9]{2})?$/,
    message: "Please enter a valid FIPS code",
  },
  required: false,
};

// Validator used specifically for hour rate setting in time off
export const policy_hour_rate: ValidationRuleset = {
  pattern: {
    value: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
    message: "Please make sure you entire a number greater than 0.",
  },
  required: "This field is required.",
};

export const policy_days: ValidationRuleset = {
  min: {
    value: 0,
    message: "Please enter a number between 0 and 1000.",
  },
  max: {
    value: 1000,
    message: "Please enter a number between 0 and 1000",
  },
};

export const policy_hour_mins: ValidationRuleset = {
  min: {
    value: 0,
    message: "Please enter a number between 0 and 1000",
  },
  max: {
    value: 1000,
    message: "Please enter a number between 0 and 1000",
  },
};
/*
const phone = {
  validate: {
    isPhone: (value) => validPhone(value) !== false || "Please enter a valid 10-digit phone number.",
    required: (value) => value !== "" || "This field is required.",
  },
};
*/
export function isValidEmail(email: string): boolean {
  const re =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return re.test(String(email).toLowerCase());
}

export const isValidPassword = (password: string): boolean => {
  return password.length > 7;
};

export const isWithinLengthRange = (min: number, max: number): ValidationRuleset => {
  return {
    validate: {
      isMoreThanMin: (value: string): string | boolean =>
        value.length >= min || `Please enter a value more than ${min} characters.`,
      isLessThanMax: (value: string): string | boolean =>
        value.length <= max || `Please enter a value less than ${max} characters.`,
      required: (value: string): string | boolean => value !== "" || "This field is required!",
    },
  };
};

// If you have a form with a start and end date, use this validator to make sure that the start date is before the end date
export const isValidEndDate = (
  startDate?: string,
  endDate?: string,
  errorMessage?: string
): true | string => {
  if (startDate && endDate) {
    return (
      DateTime.fromFormat(startDate, "DD") <= DateTime.fromFormat(endDate, "DD") ||
      errorMessage ||
      "End date must fall on or after start date."
    );
  } else {
    return true;
  }
};

export const esignatureRequired = (value: ESignatureInputValue | null | undefined): boolean | string => {
  const isEmpty = !value?.data || !value.type || !value?.data?.length;
  if (isEmpty) return "You must e-sign to continue.";
  if (!value?.agreed_to_disclosure) return "You must agree to the e-signature disclosure to continue.";

  return true;
};

/** Validates that the esignature disclosure has been agreed to if the esignature is not empty */
export const esignatureBaseValidation = (
  value: ESignatureInputValue | null | undefined
): boolean | string => {
  if (value && !value.agreed_to_disclosure) {
    return "You must agree to the e-signature disclosure to continue.";
  }

  return true;
};

// a valid custom holiday does not collide with any other custom holidays of the schedule
export const isValidCustomHoliday = (
  date: DateTime | null,
  customHolidays: CustomHoliday[],
  idOfEditingHoliday: string | undefined
): boolean | string => {
  if (!date) return "This field is required";
  const sameDateHoliday = customHolidays.find((holiday) => {
    return holiday.date === date?.toISODate() && holiday._id !== idOfEditingHoliday && !holiday.archived;
  });

  return sameDateHoliday ? `This date is already a holiday - ${sameDateHoliday.label}` : true;
};

// validates a proper kiosk pin code
const numberRegex = /^\d{4}$/;
export const isValidKioskPin: ValidationRuleset = {
  validate: {
    isValidKioskPin: (pin: string) => {
      if (!pin) return "This field is required";
      if (!numberRegex.test(pin)) return "Must be exactly 4 numerical digits";
      return true;
    },
  },
};

// Required validation
export const isRequired = (val: $TSFixMe): boolean | string => {
  if (val === undefined || val === null || val === "") return "This field is required";
  return true;
};

export const isValidBankRoutingNumber: ValidationRuleset = {
  validate: {
    isValidAbaNumber: (value) => {
      const stringNumber = value.toString().trim().padStart(9, "0");

      const regexMatch = stringNumber.match(/^\d{9}$/);
      if (!regexMatch) {
        return "Please enter a number with 9 digits.";
      }

      // https://en.wikipedia.org/wiki/ABA_routing_transit_number#MICR_routing_number_format
      const f2 = Number(stringNumber.slice(0, 2));
      const validF2 = (0 <= f2 && f2 <= 12) || (21 <= f2 && f2 <= 32) || (61 <= f2 && f2 <= 72) || f2 === 80;
      if (!validF2) {
        return "Please enter a valid ABA routing number.";
      }

      // https://en.wikipedia.org/wiki/ABA_routing_transit_number#Check_digit
      const weights = [3, 7, 1, 3, 7, 1, 3, 7, 1];
      let tot = 0;
      for (let i = 0; i < 9; i++) {
        tot += Number(stringNumber[i]) * weights[i]!;
      }

      if (tot % 10 !== 0) {
        return "Please enter a valid ABA routing number.";
      }

      return true;
    },
    required: (value) => value !== "" || "This field is required.",
  } as Record<string, Validate>,
};

export const isValidBankAccountNumber: ValidationRuleset = {
  validate: {
    isValidAccountNumber: (value) => {
      const stringNumber = value.toString().trim();

      if (stringNumber.length < 4 || stringNumber.length > 17) {
        return "Please enter a valid account number.";
      }

      return true;
    },
    required: (value) => value !== "" || "This field is required.",
  } as Record<string, Validate>,
};

export const isValidLinkedInID: ValidationRuleset = {
  validate: {
    onlyNumbers: (value) => {
      const numberPattern = /^\d+$/;
      return (
        numberPattern.test(value) ||
        "Please enter your LinkedIn ID, which is required for an integration. Your LinkedIn ID should only be numbers."
      );
    },
    required: (value) =>
      value !== "" || "Please enter your LinkedIn ID, which is required for an integration.",
  } as Record<string, Validate>,
};
